import React from "react";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { downArrow, searchIcon, logoImage } from "./assets";
import { userStyles } from "./PremiumListing.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import Reviews from "../../../components/src/Reviews.web"
// Customizable Area End

// Customizable Area Start
import BasicListingController, {
    Props,
} from "./BasicListingController";
import { Backdrop, Box, CircularProgress, Typography,Link } from "@material-ui/core";
import AppBarPage from "../../../components/src/AppBar.web";

import Slider from "react-slick";
import CustomErrorToast  from "../../../components/src/CustomErrorToast.web";

// Customizable Area End
export class BasicListing extends BasicListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderSimilar = (classes: any) => {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
        };
        return (<>
            <Box>
                <Slider className={classes.basicSlider} {...settings}>
                    {this.state.similar_listings && this.state.similar_listings.map((list: any) => {
                        return (
                            <div key={list.id}>
                                <Link color="inherit" href={"/basiclisting/"+list.id} underline="none">
                                <img className={classes.basicImg} alt="logo-listing" src={list.logo}/>
                                <Typography className={`${classes.marginTop10} ${classes.color666} ${classes.heading12}`}>by {list.name}</Typography>
                                <Typography className={` ${classes.marginTop10} ${classes.heading12} ${classes.ellipsisMultiline}`}>{list.short_description}</Typography>
                                </Link>
                            </div>

                        )
                    })}

                </Slider>

            </Box>
        </>)
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { isLoading, logo, description, name, reviewData }: any = this.state


        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                    logoImg={logoImage}
                    handleClick={this.goToLoginPage}
                    serachIcon={searchIcon}
                    handleUserSignout={this.handleUserSignout}
                    goToHomePage={()=>this.handleNavigation("Home")}
                    data-testId={"signInBtn"}
                    downarrow={downArrow}
                    handleHeaderNavigation={()=>this.navigateToAccount(this.state.userdetails?.user_type)}
                    username={this.state.userdetails}
                    dummyicon={""}
                    searchText={this.state.searchTermProp}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                   
                />
                <Box className={classes.root}>
                    <Box className={`${classes.container}`}>
                        <Box className={classes.headerContainer}>
                            <ListingHeader
                                bookmarked={this.state.bookmarked}                             
                                data-testid="bookmark"
                                handleBookmarkCall={this.handleBookmark}
                                description={description}
                                name={name}
                                logo={logo}
                                domain_url={this.state.domain_url}
                                userdetails={this.state.userdetails}
                                handleBookmarkDelete={this.handleBookmarkDelete} />

                        </Box>
                        <Reviews  hideRatingForm={this.state.hideRatingForm}
                            handleEditBtn={this.handleEditBtn}
                            isEdit={this.state.editReview}
                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadBtn}
                            accordionState="reviewOpen" anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            handleRating={this.handleUserRate}
                            handleClose={this.handleClose}
                            ratingVal={this.state.ratingValue}
                            reviewComment={this.state.reviewText}
                            handleReviewSubmit={this.handleReviewSubmit}
                            handleReviewComment={this.handleReviewText}
                            data-testid="reviewAccordion" classes={classes}
                            reviewData={reviewData}
                            handleAccordionToggle={this.handleAccordion}
                            loadmore={this.state.loadmore}
                            handleReviewUpdate={this.handleReviewUpdate}
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            filterValue={this.state.filterValue}
                            expanded={this.state.reviewOpen}
                        />


                        <Box className={`${classes.accordionContainer} ${classes.headerContainer}`}>
                            <Box style={{ padding: "30px 40px 0px 40px" }}>
                                <Typography className={classes.similarHeading}>Similar Listings</Typography>

                            </Box>
                            <Box style={{ padding: "0px 30px" }}>
                                {this.renderSimilar(classes)}
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <CustomErrorToast data-testid= "customToast" open={this.state.reviewError} onClose={this.handleCloseToast}/>

            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(userStyles)(BasicListing)
// Customizable Area End

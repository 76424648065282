import React from "react";

// Customizable Area Start
import {
    Typography,
    OutlinedInput,
    Box,
    Button,
    Dialog,
    TextField,
    Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import FeedbackFormController, {
    Props,
    configJSON,
} from "./FeedbackFormController";
import { CloseICon } from "./assets";
// Customizable Area End

export class FeedbackForm extends FeedbackFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            <Dialog
                open={this.props.openFeedback}
                onClose={this.props.closeFeedback}
                data-testid={'close_modal'}
                maxWidth="md"
                PaperProps={{ className: classes.contactUsDialog }}
            >

                <Box className={classes.cancelGridStyle}>
                    <img
                        src={CloseICon}
                        className={classes.closeIconStyle}
                        onClick={this.props.closeFeedback}
                        data-testid={"closeModal"}
                    />
                </Box>
                <Box className={classes.BoxScrollbar_1}>
                    <Typography className={classes.contactUsFont}>{configJSON.feedback}</Typography>
                    <Box className={classes.contentBoxStyle_1}>
                        <StyledTextField
                            variant="outlined"
                            placeholder={"Email"}
                            fullWidth
                            data-testid={"user-email"}
                            value={this.state.userEmail}
                            onChange={this.handleChangeFormEmail}
                            InputProps={{
                                disableUnderline: true,
                                classes: { input: classes["inputStyle"] },
                            }}
                            error={this.state.emailErr}
                            helperText={this.state.emailErrMsg}
                            className={classes.nameTextfeild}
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            data-testid={"message-text"}
                            rows={6}
                            multiline
                            value={this.state.feedbackMessage}
                            onChange={this.handleChangeMessage}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: 'Your Message',
                                className: classes.placeholder,
                            }}
                            classes={{
                                root: classes.outlinedInput_1,
                                input: classes.inputText,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline,
                            }}
                        />
                    </Box>
                    <Box className={classes.sendBtnBox_1}>
                        <Button
                            fullWidth
                            className={classes.sendButton}
                            variant="contained"
                            data-testid={"send_button"}
                            onClick={this.submitFeedback}
                        >
                            {configJSON.send}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const StyledTextField = withStyles({
    root: {
        fontSize: "14",
        "& input::placeholder": {
            fontSize: "14",
            fontFamily: "Poppins",
            fontWeight: 400,
        },
        "& .MuiInputBase-input-283:focus": {
            outline: 'none',
            backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        backgroundColor: "#fff",
        "&  .MuiFormHelperText-root.Mui-error": {
            margin: 0,
            paddingLeft: 10,
            backgroundColor: "#FFFFFF",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
    },
})((props: any) => <TextField {...props} />);

export const userStyles = (theme:Theme) => ({
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        width: '45%'
    },
    fontStyle4: {
        fontSize: '14px',
        color: '#6200EA',
        fontWeight: 400,
        fontFamily: 'Poppins'
    },

    inputStyle: {
        fontFamily: "Poppins",
        "&::placeholder": {
            fontFamily: "Poppins",
        },
    },
    fontStyle5: {
        fontSize: '16px',
        color: '#262424',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    contactUsDialog: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '600px',
        backgroundColor: ' #FFFFFF',
        borderRadius: '8px 8px 32px 8px',
        marginTop: '5%',
        display: 'flex',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },

    nameTextfeild: {
        borderRadius: '1px',
        width: "100%",
        backgroundColor: '#FAF5FF',
    },

    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        padding: '5px',
        width: '32px',
    },
    contactUsFont: {
        fontWeight: 700,
        fontSize: "28px",
        color: '#0F172A',
        fontFamily: "Poppins",
        textAlign: 'center' as const,
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        },
    },
    BoxScrollbar_1: {
        height: '100%',
        overflowY: 'scroll' as const,
        overflowX: 'hidden' as const,
        scrollbarColor: "transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            padding: '30px',
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            minHeight: 24,
            borderRadius: 6,
            minWidth: 14,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
    },

    contentBoxStyle_1: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '15px',
        padding: '30px 10% 30px 10%',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 5% 15px 5%',
        },
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    outlinedInput_1: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        borderRadius: '8px',
    },
    focused: {},
    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },
    notchedOutline: {
        borderWidth: '1px',
    },
    sendBtnBox_1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8%',
        marginBottom: '8%'
    },
    sendButton: {
        backgroundColor: "#B300ED",
        fontSize: "18px",
        fontWeight: 400,
        color: "#FFFFFF",
        fontFamily: "Poppins",
        width: '65%',
        padding: '5px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
});

export default withStyles(userStyles)(FeedbackForm)
// Customizable Area End
//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import { Country, City } from "country-state-city";
import { getStorageData } from "../../../framework/src/Utilities";

export interface ICity {
    name: string
    countryCode: string
    stateCode: string
    latitude: string | null | undefined
    longitude: string
}

export interface ICountry {
    name: string
    isoCode: string
    flag: string
    phonecode: string
    currency: string
    latitude: string
    longitude: string
    timezones?: Timezone[]
}

export interface Timezone {
    zoneName: string
    gmtOffset: number
    gmtOffsetName: string
    abbreviation: string
    tzName: string
}

export interface RoleType {
    id: number
    name: string
    created_at: string
    updated_at: string
}



export interface DepartmentType {
    id: string
    type: string
    attributes: Attributes
}

export interface TypeOfBusinessData {
    id: string
    type: string
    attributes: Attributes
}

export interface Attributes {
    name: string
}


// Customizable Area End


export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    modalOpen: boolean,
    modalClose: () => void,
    classes: any
}



export interface S {
    // Customizable Area Start
    openSurvey: boolean,
    tab: number,
    country: ICountry,
    countryOption: ICountry[],
    city: ICity,
    cityOption: any,
    role: number | null,
    roleOption: RoleType[],
    departmentOption: DepartmentType[],
    department: string | null,
    otherDepartment: boolean,
    otherTextDepartment: string,

    companyName: string,
    noOfLocation: string,
    businessData: TypeOfBusinessData[],
    businessDataSelected: any[],
    businessTab: string,
    selectedBusiness: any | null,
    otherBusiness: boolean,
    otherTextBusiness: string,
    annualRevenue: number,

    selectedCountry: string | null | any,
    selectedCity: string | null | any,
    isSuccess: boolean,
    // Customizable Area End
}


export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class SurveyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailLoginCallId: string = "";
    apiRoleId: string = "";
    apiDepartmentId: string = "";
    apiDepartmentPostId: string = "";
    apiTypeOfBusinessId: string = "";
    apiTypeOfBusinessSelectedId: string = "";
    apiPostBusinessSubCategory: string = "";
    apiSurveyFormID: string = "";
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            openSurvey: true,
            tab: 1,
            country: {
                name: "",
                isoCode: "",
                flag: "",
                phonecode: "",
                currency: "",
                latitude: "",
                longitude: "",
                timezones: []
            },
            countryOption: [],
            city: {
                name: "",
                countryCode: "",
                stateCode: "",
                latitude: "",
                longitude: ""
            },
            cityOption: [{
                name: "",
                countryCode: "",
                stateCode: "",
                latitude: "",
                longitude: ""
            }],
            role: null,
            roleOption: [],
            departmentOption: [],
            department: null,
            otherDepartment: false,
            otherTextDepartment: "",

            companyName: "",
            noOfLocation: "",
            businessData: [],
            businessDataSelected: [],
            businessTab: "",
            selectedBusiness: null,
            otherBusiness: false,
            otherTextBusiness: "",
            annualRevenue: 0,
            selectedCountry: "",
            selectedCity: "",
            isSuccess: false,

            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }



    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );


            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.setResponseStates(apiRequestCallId, responseJson)

        }

        // Customizable Area End
    }



    // Customizable Area Start

    setResponseStates = (apiRequestCallId, responseJson) => {

        let responseData

        switch (apiRequestCallId) {
            case this.apiRoleId:
                this.setState({ roleOption: responseJson });
                break;

            case this.apiDepartmentId:
                responseData = responseJson ? responseJson.data : [];
                this.setState({ departmentOption: responseData });
                break;

            case this.apiDepartmentPostId:
                responseData = responseJson ? responseJson.data.id : null;
                this.setState({ department: responseData });
                break;

            case this.apiTypeOfBusinessId:
                responseData = responseJson && responseJson.data
                this.setState({ businessData: responseData});
                break;

            case this.apiTypeOfBusinessSelectedId:
                responseData = responseJson ? responseJson.data?.attributes?.business_categories : [];
                this.setState({ businessDataSelected: responseData });
                break;

            case this.apiPostBusinessSubCategory:
                this.setState({ selectedBusiness: responseJson.data.id, otherTextBusiness: "", otherBusiness: false },()=>this.getTypeOfBusinessSelected());
                break;

            case this.apiSurveyFormID:
                this.setState({ isSuccess: true })
                break;

            default:
                break;
        }
    }

    getRole = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiRoleId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.viewApiURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDepartment = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("registerToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDepartmentId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.viewDepartmentApiURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    postDepartment = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("registerToken"),
        };

        const attrs = {
            "name": this.state.otherTextDepartment
        }

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDepartmentPostId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.viewDepartmentApiURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    };


    postBusinessSubCategory = async () => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("registerToken")
        };

        const attrs = {
            "name": this.state.otherTextBusiness,
            "type_of_business_id": this.state.businessTab
        }

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiPostBusinessSubCategory = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postBusinessSubCategory
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    getTypeOfBusiness = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiTypeOfBusinessId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.typeOfBusiness
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getTypeOfBusinessSelected = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("registerToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiTypeOfBusinessSelectedId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.typeOfBusiness}/${this.state.businessTab}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateSurveyForm = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("registerToken"),
        };

        const attrs = {
            "account_block_departments_id": this.state.department,
            "no_of_location": this.state.noOfLocation,
            "type_of_businesses_id": this.state.businessTab,
            "annual_revenue": `${this.state.annualRevenue}`,
            "country": this.state.country.name,
            "city": this.state.city.name,
            "role_id": this.state.role,
            "company_name": this.state.companyName,
            "type": "email_account"
        }

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSurveyFormID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateSurveyURL
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );

        this.handleCloseSurveyModal()

        runEngine.sendMessage(requestMessage.id, requestMessage);

    };


    componentDidMount(): any {
        this.setState({ countryOption: Country.getAllCountries() })
        this.getRole()
        this.getDepartment()
        this.getTypeOfBusiness()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.country !== this.state.country) {
            this.setState({ cityOption: City && City.getCitiesOfCountry(this.state.country?.isoCode) })
        }

        if (prevState.businessTab !== this.state.businessTab) {
            this.getTypeOfBusinessSelected()
        }


    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.postDepartment()
        }
    };

    handleKeyPressBusinessSubCategory = (event) => {
        if (event.key === "Enter") {
            this.postBusinessSubCategory()
        }
    }

    toggleTab = (value: number) => {
        this.setState({ tab: value })
    }

    toggleOther = () => {
        if (this.state.department !== null) {
            this.setState({ department: null })
        }
        this.setState({ otherDepartment: !this.state.otherDepartment })
    }

    toggleOtherBusiness = () => {
                this.setState({ otherBusiness: !this.state.otherBusiness })
    }

    onChangeAnnualRevenue = (event: any, value: any) => {
        this.setState({ annualRevenue: value })
    }

    handleCountry = (value: any) => {
        this.setState({ country: value })
    }

    handleCity = (value: any) => {
        this.setState({ city: value })
    }

    handleLocation = (value: any) => {
        this.setState({ noOfLocation: value })
    }

    selectRoleLevel = (value: any) => {
        this.setState({ role: value })
    }

    selectDepartment = (value: any) => {
        this.setState({ department: value, otherDepartment: false })
    }

    handleChangeDepartment = (value: any) => {
        this.setState({ otherTextDepartment: value })
    }

    changeBusinessTab = (value: any) => {
        this.setState({ businessTab: value, otherBusiness: false })
    }

    selectBusiness = (value: any) => {

        this.setState({ selectedBusiness: value, otherBusiness: false })
    }

    handleChangeBusiness = (value: any) => {
        this.setState({ otherTextBusiness: value })
    }

    handleCompanyName = (value: any) => {
        this.setState({ companyName: value })
    }
    handleSignUp = async () => {
        const data = await getStorageData("component")
        const parsedData = JSON.parse(data)
        this.props.navigation.navigate(parsedData.component, { id: parsedData.id })
    }

    handleCloseSuccessDialog = async () => {
        this.setState({ isSuccess: false });
        if (window.location.href.includes("listing")) {
            this.handleSignUp();
        }
        else {
            this.goToHome()


        }

    }

    handleCloseSurveyModal = () => {
        this.setState({ openSurvey: false });

    }

    goToHome = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    // Customizable Area End
}

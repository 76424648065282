Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.signupAPIEndPoint = "/account_block/accounts";
exports.termsCondAPIEndPoint = "/bx_block_terms_and_conditions/terms_and_conditions";
exports.privacyAPIEndPoint = "/bx_block_terms_and_conditions/privacy_policies";
exports. welcomeText = "Welcome to Restaurant Resource !";
exports.continueText = "Please enter the details to continue";
exports.fullname = "Full Name";
exports.email = "Email";
exports.pass = "Password";
exports.reEnterPass = "Re-Enter Password";
exports.bySignIn = "By signing up, you agree to our";
exports.terms = "Terms";
exports.seeOur = "see how we use your data in our";
exports.privicay = "Privacy policy";
exports.continueBtn = "Continue";
exports.alreadyHaveAcc = "Already have account?";
exports.login = "Login";
exports.signupAPIEndPoint = "/account_block/accounts";
exports.passnotmatch = "Your password doesn't match";
exports.entername = "Feild is required";
exports.emailRequire = "Email Address is Required";
exports.enteremail = "Enter Valid email address";
exports.passformate = "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character";
exports.passRequire = "Password is required";
exports.passmatch = "Password matched";
exports.validSpacePass = "Your password can't start or end with a blank space";
exports.termsContent = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
exports.privacyContent = "Privacy :Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
exports.acceptBtn = "Accept";
exports.declineBtn = "Decline";
exports.passmatch =  "Password matched";
exports.chooseAccount = "Choose your account type";
exports.buyer = "Buyer";
exports.buyerTxt = "I'd like to browse and purchase products and attend events in the hospitality industry.";
exports.vendor = "Vendor";
exports.vendorText = "I'd like to sell my product, services or events to buyers in the hospitality industry and create a profile on Restaurant Resource."
// Customizable Area End

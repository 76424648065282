import { suppliersImg1, suppliersImg2, suppliersImg3, suppliersImg4 } from "../../blocks/catalogue/src/assets";

export const countryList = [
    'India',
    'Russia',
    'USA',
    'UK'
];


export const regionList = [
    'Delhi',
    'Mumbai'
];


export const categories = [
    {
        "category": "Beverage",
        "count": 0
    },
    {
        "category": "Beverage & Bar Equipment",
        "count": 0
    },
    {
        "category": "Cleaning",
        "count": 3
    },
    {
        "category": "Entertainment",
        "count": 0
    },
    {
        "category": "Offers Takeout",
        "count": 2
    },
    {
        "category": "Food",
        "count": 0
    },
    {
        "category": "Good for Groups",
        "count": 2
    },
    {
        "category": "Furniture & Décor",
        "count": 2
    },
    {
        "category": "Beer & Wine Only",
        "count": 2
    },
    {
        "category": "General Supplies",
        "count": 1
    },
    {
        "category": "Hotel Supplies",
        "count": 0
    },
    {
        "category": "Kitchen Equipment",
        "count": 0
    },
    {
        "category": "Restaurant Equipment",
        "count": 2
    }
]

export const subCategories = [
    {
        id: 2,
        name: 'Cleaning',
        count: 1,
        sub_catergory: [
            {
                id: 1,
                name: 'Offers Takeout',
                count: 2
            },
            {
                id: 2,
                name: 'Good for Groups',
                count: 2
            },
            {
                id: 3,
                name: 'Beer & Wine Only',
                count: 2
            },
        ]
    },
    {
        id: 7,
        name: 'Furniture & Décor',
        count: 2,
        sub_catergory: [
            {
                id: 1,
                name: 'Offers Takeout',
                count: 2
            },
            {
                id: 2,
                name: 'Good for Groups',
                count: 2
            },
            {
                id: 3,
                name: 'Beer & Wine Only',
                count: 2
            },
        ]
    },
];

export const featuredList = [
    {label:'Recent',value:""},
    {label:'Featured First',value:"featured"},
   {label: 'Average Review',value:"average_review"},
    {label:"A-Z",value:"A-Z"},
    {label:"Z-A",value:"Z-A"},
];


export const catList = [
    {
        id: 1,
        name: 'Beverage',
        count: 1,
        sub_catergory: []
    },
    {
        id: 2,
        name: 'Cleaning',
        count: 1,
        sub_catergory: [
            {
                id: 1,
                name: 'Offers Takeout',
                count: 2
            },
            {
                id: 2,
                name: 'Good for Groups',
                count: 2
            },
            {
                id: 3,
                name: 'Beer & Wine Only',
                count: 2
            },
        ]
    },
    {
        id: 3,
        name: 'Entertainment',
        count: 0,
        sub_catergory: []
    },
    {
        id: 4,
        name: 'Offers Takeout',
        count: 2,
        sub_catergory: []
    },
    {
        id: 5,
        name: 'Food',
        count: 0,
        sub_catergory: []
    },
    {
        id: 6,
        name: 'Good for Groups',
        count: 2,
        sub_catergory: []
    },
    {
        id: 7,
        name: 'Furniture & Décor',
        count: 2,
        sub_catergory: [
            {
                id: 1,
                name: 'Offers Takeout',
                count: 2
            },
            {
                id: 2,
                name: 'Good for Groups',
                count: 2
            },
            {
                id: 3,
                name: 'Beer & Wine Only',
                count: 2
            },
        ]
    },
    {
        id: 8,
        name: 'Beer & Wine Only',
        count: 2,
        sub_catergory: []
    },
    {
        id: 9,
        name: 'General Supplies',
        count: 1,
        sub_catergory: []
    },
    {
        id: 10,
        name: 'Hotel Supplies',
        count: 0,
        sub_catergory: []
    },
    {
        id: 11,
        name: 'Kitchen Equipment',
        count: 0,
        sub_catergory: []
    },
    {
        id: 12,
        name: 'Restaurant Equipment',
        count: 2,
        sub_catergory: []
    },
];

export const cardList = [
    { img: suppliersImg1 },
    { img: suppliersImg2 },
    { img: suppliersImg3 },
    { img: suppliersImg4 }
];
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";

export interface ContactData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    contact: {
                        address: string;
                        fburl: string;
                        instaurl: string;
                        linkdlnurl: string;
                        phonenumber: string;
                        twitterurl: string;
                        contactus: string;
                        youtubeurl: string;
                    };
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress:(value:number)=>void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    handleDataSaved:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    contactData: {
        address: string;
        fburl: string;
        instaurl: string;
        linkdlnurl: string;
        phonenumber: string;
        twitterurl: string;
        contactus: string;
        youtubeurl: string;
    };
    updatedContactData: {
        address: string;
        fburl: string;
        instaurl: string;
        linkdlnurl: string;
        phonenumber: string;
        twitterurl: string;
        contactus:string;
        youtubeurl: string;
    };
    isSucess: boolean;
    isFeedback: boolean;
    feedbackEmail: string;
    feedbackMessage: string;
    isValidEmail:{valid:boolean,message:string};
    isLoadContactData: boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ContactsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getContactListDataAPICalledId: string = "";
    updateContactListAPICallId: string = "";
    feedbackContactId: string = "";
    emailReg: RegExp;


    phoneNumberReg: RegExp
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            contactData: {
                address: '',
                fburl: '',
                instaurl: '',
                linkdlnurl: '',
                phonenumber: '',
                twitterurl: '',
                contactus: '',
                youtubeurl: '',
            },
            updatedContactData: {
                address: '',
                fburl: '',
                instaurl: '',
                linkdlnurl: '',
                phonenumber: '',
                twitterurl: '',
                contactus: '',
                youtubeurl: '',
            },
            isSucess: false,
            isFeedback: false,
            feedbackEmail:"",
            feedbackMessage: "",
            isValidEmail:{valid:false,message:""},
            isLoadContactData: false
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.phoneNumberReg =new RegExp(/^\d*$/);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getContactListData();

    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getContactListDataAPICalledId:
                        this.handleContactLitsResponse(responseJson);
                        break;
                    case this.updateContactListAPICallId:
                        this.handleUpdateContactListResponse(responseJson);
                        break;
                    case this.feedbackContactId:
                        this.setState({isFeedback:false})
                        break;
                }
            }
        }
    }

    // Web Events
    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal();
        }
        else{
           this.props.handleBack();
        }
      
    }
   
    handleCloseSuccessDialog = () => {
        this.setState({ isSucess: false });
    }

    handleUpdatedContactData = () => {
        this.setState({ updatedContactData: { ...this.state.contactData } });
    }

    handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, address: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeFbUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, fburl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeInstaUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, instaurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeLinkdlnUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, linkdlnurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value;

        if (this.phoneNumberReg.test(phoneNumber) && phoneNumber.length<=15) {
            this.setState({
                updatedContactData: { ...this.state.updatedContactData, phonenumber: phoneNumber },
            });
        }
        this.props.handleDataChanged()
    }

    handleChangeTwitterUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, twitterurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeContactUs = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, contactus: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeYouTubeUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, youtubeurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    getContactListData = async () => {
        this.setState({isLoadContactData: true})
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getContactListDataAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    isValidValue=(value:any)=>{
        if(value){
            return value;
        }
        else return "";
    }

    updateContactListData = async () => {
        this.props.handleDataSaved()
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };
        let formdata = new FormData();
        formdata.append("[contact][address]", this.isValidValue(this.state.updatedContactData?.address));
        formdata.append("[contact][fburl]", this.isValidValue(this.state.updatedContactData?.fburl))
        formdata.append("[contact][instaurl]",this.isValidValue(this.state.updatedContactData?.instaurl))
        formdata.append("[contact][linkdlnurl]", this.isValidValue(this.state.updatedContactData?.linkdlnurl))
        formdata.append("[contact][phonenumber]", this.isValidValue(this.state.updatedContactData?.phonenumber))
        formdata.append("[contact][twitterurl]", this.isValidValue(this.state.updatedContactData?.twitterurl))
        formdata.append("[contact][contactus]", this.isValidValue(this.state.updatedContactData?.contactus))
        formdata.append("[contact][youtubeurl]", this.isValidValue(this.state.updatedContactData?.youtubeurl))

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateContactListAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    handleSubmitFeedbackContact = async (event: any) => {
        let formdata = new FormData();

        if(!this.state.feedbackEmail){
            this.setState({isValidEmail:{valid:true,message:"required"}})
            return
        }
        else if (!this.validateEmailContact(this.state.feedbackEmail)) {
            this.setState({isValidEmail:{valid:true,message:"Email not valid"}})
            return
        } else{
            this.setState({isValidEmail:{valid:false,message:""}})

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackContactId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // API Response
    handleContactLitsResponse = (responseJson: ContactData) => {
        const apiresponse = responseJson?.data?.catalogue?.data?.attributes?.contact
        if (apiresponse) {
            this.setState({
                isLoadContactData: false,
                contactData: {
                    ...this.state.contactData,
                    address: apiresponse?.address,
                    fburl: apiresponse?.fburl,
                    instaurl: apiresponse?.instaurl,
                    linkdlnurl: apiresponse?.linkdlnurl,
                    phonenumber: apiresponse?.phonenumber,
                    twitterurl: apiresponse?.twitterurl,
                    contactus: apiresponse?.contactus,
                    youtubeurl: apiresponse?.youtubeurl
                }
            },
                () => this.handleUpdatedContactData()
            )
        }
    }

    handleUpdateContactListResponse = (responseJson: []) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucess: true })
            this.getContactListData()
        }
    }

    handleOpenFeedbackModalContact = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModalContact = () => {
        this.setState({ isFeedback: false, isValidEmail:{ valid:false, message:"" } })
    }


    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }


    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailContact = (email:string) => {
        const emailRegex = this.emailReg;      
        return emailRegex.test(email);
      }
    goVendorAccount = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }  
    // Customizable Area End
}
// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { Grid, Box, Link, Typography, List, ListItem, ListItemText, Button } from '@material-ui/core';
import MegaMenuController from './MegaMenuController.web';
import { arrow } from '../../blocks/landingpage/src/assets'
// Customizable Area End

class MegaMenu extends MegaMenuController {
  // Customizable Area Start

  render() {
    const { classes }: any = this.props;
    return (
      <Box>
        <Link
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="primary"
          onClick={this.handleClick}
          className={classes.directories}
        >
          Directories <img src={arrow} />
        </Link>
        <Menu
          PopoverClasses={{ paper: classes.popoverPaper }}
          id="customized-menu"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: "right" }}
          transformOrigin={{ vertical: 'top', horizontal: "right" }}
          getContentAnchorEl={null}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <Box className={classes.megamenu}>
            <Grid container className={`${classes.megamenuHeader} ${classes.m0}`}>
              <Grid className={classes.titleContainer} item xs={3}>
                <Typography className={classes.megamenuTitle}>Suppliers</Typography>
              </Grid>
              <Grid className={classes.titleContainer} item xs={3}>
                <Typography className={classes.megamenuTitle}>Softwares</Typography>
              </Grid>
              <Grid className={classes.titleContainer} item xs={3}>
                <Typography className={`${classes.megamenuTitle}`}>Services</Typography>
              </Grid>
              <Grid className={classes.titleContainer} item xs={3}>
                <Typography className={`${classes.megamenuTitle}`}>Events</Typography>
              </Grid>
            </Grid>
            <div className={classes.megamenuBody}>
              <Grid container className='m-0'>
                <Grid item xs={3} className={classes.megaMenuColumnContainer}>
                  <List className={classes.megamenuUL}>
                    {this.props.data.Suppliers?.map((element: any) => (
                      <ListItem key={element.id} className={classes.megamenuLi}>
                        <span className={classes.megamenuBullet} />
                        <Link onClick={this.handleCategoryRedirection} href={`/Catalogue/Suppliers?adv_category_id=${element.id}`} underline='none' className={classes.megamenuUlLiA}>
                          <ListItemText className={classes.listItemNameText} primary={this.truncateText(element.name, 12)} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>

                  <Button href="/Catalogue/Suppliers"className={classes.megamenuBodyMenubtn}><span>View all</span><img src={arrow} className={classes.megamenuBodyMenubtnImg} /></Button>
                </Grid>
                <Grid item xs={3} className={`${classes.megaMenuColumnContainer} ${classes.megaMenuSecondColumn}`}>
                  <List className={classes.megamenuUL}>
                    {this.props.data.Softwares?.map((element: any) => (
                      <ListItem key={element.id} className={classes.megamenuLi}>
                        <span className={classes.megamenuBullet} />
                        <Link  href={`/Catalogue/Software?adv_category_id=${element.id}`} underline="none" onClick={this.handleCategoryRedirection} className={classes.megamenuUlLiA}>
                          <ListItemText className={classes.listItemNameText} primary={this.truncateText(element.name, 12)} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>

                  <Button href="/Catalogue/Software" className={`${classes.megamenuBodyMenubtn} ${classes.megamenuBodyMenubtn1}`}><span>View all</span><img src={arrow} className={classes.megamenuBodyMenubtnImg} /></Button>

                </Grid>
                <Grid item xs={3} className={`${classes.megaMenuColumnContainer} ${classes.megaMenuThirdColumn}`}>
                  <List className={classes.megamenuUL}>
                    {this.props.data.Services?.map((element: any) => (
                      <ListItem key={element.id} className={classes.megamenuLi}>
                        <span className={classes.megamenuBullet} />
                        <Link onClick={this.handleCategoryRedirection} underline='none' href={`/Catalogue/Services?adv_category_id=${element.id}`} className={classes.megamenuUlLiA}>
                          <ListItemText className={classes.listItemNameText} primary={this.truncateText(element.name, 12)} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>

                  <Button href="/Catalogue/Services"  className={`${classes.megamenuBodyMenubtn} ${classes.megamenuBodyMenubtn2}`}><span>View all</span><img src={arrow} className={classes.megamenuBodyMenubtnImg} /></Button>

                </Grid>
                <Grid item xs={3} className={`${classes.megaMenuColumnContainer} ${classes.megaMenuFourthColumn}`}>
                  <List className={classes.megamenuUL}>
                    {this.props.data.Events?.map((element: any) => (
                      <ListItem key={element.id} className={classes.megamenuLi}>
                        <span className={classes.megamenuBullet} />
                        <Link href={`/Catalogue/Events?adv_category_id=${element.id}`} onClick={this.handleCategoryRedirection} underline='none' className={classes.megamenuUlLiA}>
                          <ListItemText className={classes.listItemNameText} primary={this.truncateText(element.name, 12)} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>

                  <Button href="/Catalogue/Events" className={`${classes.megamenuBodyMenubtn} ${classes.megamenuBodyMenubtn3}`}><span>View all</span><img src={arrow} className={classes.megamenuBodyMenubtnImg} /></Button>

                </Grid>
              </Grid>
            </div>
          </Box>
        </Menu >
      </Box>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
const styles = () => ({
  popoverPaper: {
    width: '932px',
    maxHeight: 'unset',
    marginTop: 25,
  },
  megamenuBullet: {
    content: '""',
    width: '8px',
    height: '8px',
    background: '#5C15C2',
    borderRadius: '50%',
    marginRight: "10px",
    "@media(max-width:550px)": {
      width: '4px',
      height: '4px',
      marginRight: "5px",
    }
  },
  directories: {
    fontSize: "16px",
    lineHeight: "14px",
    cursor: 'pointer',
    color: "#000",
    letterSpacing: "0.01",
    "&:hover": {
      textDecoration: "none",
    },
    "@media(max-width: 480px)": {
      fontSize: '12px',
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'end'
  },

  container: {
    maxWidth: "1200px",
    margin: '0 auto',
    width: '100%'
  },
  megamenu: {
    fontFamily: 'Poppins, sans-serif',
  },
  megamenuUl: {
    margin: '0 !important',
    padding: '0 !important',
  },
  titleContainer: {
    display: "flex",
  },
  megamenuHeader: {
    padding: '48px 0 37px 0px',
    borderBottom: '1px solid #F2EAFF',
    "@media(max-width:780px)": {
      padding: '20px 0 10px 0px',
    },
  },

  megamenuHeaderHeaderImg: {
    width: '20px',
    height: '18px',
    marginRight: '10px',
    display: 'block',
    float: 'left' as 'left',
  },
  megaMenuColumnContainer: {
    display: "flex",
    flexDirection: "column" as 'column',
    justifyContent: "space-between",
  },

  megamenuTitle: {
    color: '#262424',
    fontSize: '22px',
    fontStyle: 'normal' as 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    fontFamily: "Poppins",
    marginLeft: "83px",
    "@media(max-width:880px)": {
      marginLeft: "50px"
    },
    "@media(max-width:780px)": {
      fontSize: '16px',
      lineHeight: "5px"
    },
    "@media(max-width:640px)": {
      marginLeft: "20px"
    },
    "@media(max-width:550px)": {
      fontSize: '13px',
      marginLeft: "10px"
    },
  },
  listItemNameText: {
    // overflow: "hidden",
    whiteSpace: "nowrap" as const,
    textOverflow: "ellipsis",
    paddingTop: "0px",
    "& span": {
      fontFamily: "Poppins",
      fontSize: "18px",
      lineHeight: "14px",
      fontWeight: 400,
      "@media(max-width:780px)": {
        fontSize: '14px',
        lineHeight: "5px",
      },
      "@media(max-width:550px)": {
        fontSize: '11px',
      }
    }
  },

  megamenuUlLiA: {
    color: '#6C6C6C',
    textDecoration: 'none' as 'none',
    fontSize: '18px',
    fontStyle: 'normal' as 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    display: "flex",
    alignItems: "center" as const,
    "@media(max-width:700px)": {
      fontSize: '16px',
    },
    "@media(max-width:500px)": {
      fontSize: '12px',
    },
    "@media(max-width:430px)": {
      fontSize: '10px',
    }
  },

  megamenuLi: {
    listStyle: 'disc' as 'disc',
    color: '#5C15C2',
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center" as const,
    "@media(max-width:550px)": {
      padding: "5px 0 5px 3px",
    }
  },
  megamenuBody: {
    padding: '25px 0px 25px 0px',
  },
  m0: {
    margin: '0px !important',
  },
  megamenuBodyMenubtn: {
    borderRadius: '6px',
    background: '#EAFFF9',
    padding: '7px',
    border: '0',
    marginTop: '10px',
    marginLeft: "61px",
    width: "120px",
    textTransform: "none" as "none",
    "& span": {
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight:"14px",
      "@media(max-width:780px)": {
        fontSize: "16px"
      },
      "@media(max-width:550px)": {
        fontSize: "8px"
      },
    },
    "@media(max-width:880px)": {
      marginLeft: "25px"
    },
    "@media(max-width:550px)": {
      marginLeft: "0px",
      padding: '5',
      width: "80px",
    },
  },

  megamenuBodyMenubtn1: {
    background: '#FCF1FF !important',
  },
  megamenuBodyMenubtn2: {
    background: '#EFF2FF !important',
  },
  megamenuBodyMenubtn3: {
    background: '#FFF6F9 !important',
  },
  megamenuBodyMenubtnImg: {
    width: 12,
    height:10,
    transform: 'rotate(-90deg)',
    marginLeft:10
  },
  megamenuUL: {
    padding: '0 !important',
    marginLeft: "45px",
    "@media(max-width:880px)": {
      marginLeft: "10px"
    },
    "@media(max-width:640px)": {
      marginLeft: "0px"
    },
  },
  megamenuServicesTitle: {
    paddingLeft: "7px"
  },
  megamenuEventsTitle: {
    paddingLeft: "15px"
  }
});
export default withStyles(styles)(MegaMenu);

// Customizable Area End
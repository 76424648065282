import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, { ChangeEvent } from "react";

// Customizable Area Start
export const mockPath = [
    {
        name: "Home",
        path: "LandingPage"
    },

    {
        name: "Join Us",
        path: "JoinUsLandingPage"
    },
    {
        name: "Subscriptions",
        path: "JoinUsSubscription"
    },

    {
        name: "Listings",
        path: "SearchListing"
    },
]
interface Plan {
    id: number;
    name: string;
    price: string;
    description: string
}
interface SelectedPlan {
    selectedPlan: {
        plan: Plan;
        directory_Name: string;
        directory_id: number;
        description: string
    }
}
export interface ListingData {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        description: string;
        domain_url?: string | null;
        short_description: string;
        logo: string;
        claim_status: string
    }
}

export interface ListingDataProps {
    data: ListingData[]
}

export interface CreateListData {
    listing:{
    data: {
        id: number,
        type: string,
        attributes: {
            id: number,
            name: string,
            short_description: string,
            domain_url: string,
            logo: null,
            domain_verified:boolean,
        },
    },
    }
    error: string,
    errors: string[]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
export interface SearchDirectory{
    directory_id: number,
    directory_name:string,
    count: number
}

export interface S {
    // Customizable Area Start
    Username: any;
    isDescriptionModal: boolean;
    listingData: ListingData[];
    searchInput: string;
    logoPreview: File | null;
    changeName: string;
    changeDescription: string;
    changeDomain: string;
    successMessage: string;
    errorMessage: string;
    isError: boolean;
    isNavigate: boolean;
    isSearchAPI: boolean;
    isCompanyChange: boolean;
    isDomainChange: boolean;
    isNameError: boolean;
    nameErrorMessage: string;
    availableDomains: string[];
    logoErrorMessage: string | null
    selectedPlan: SelectedPlan | null;
    selectedPrice: number;
    isSelectedPlan: boolean;
    showDirectoryPopup:boolean,
    searchDirectoryData:SearchDirectory[],
    searchTermProp:string,
    isModalOpen:boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SearchListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    listingDataAPICallid: string = "";
    inputRef: React.RefObject<HTMLInputElement> = React.createRef();
    createListingAPICallId: string = "";
    domainListAPICallId: string = "";
    getDirectoryDataResultApiId:string ="";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            Username: null,
            isDescriptionModal: false,
            listingData: [],
            searchInput: "",
            logoPreview: null,
            changeName: "",
            changeDescription: "",
            changeDomain: "",
            successMessage: "",
            errorMessage: "",
            isError: false,
            isNavigate: false,
            isSearchAPI: false,
            isCompanyChange: false,
            isDomainChange: false,
            isNameError: false,
            nameErrorMessage: "",
            availableDomains: [],
            logoErrorMessage: null,
            selectedPlan: null,
            selectedPrice: 0,
            isSelectedPlan: false,
            searchDirectoryData:[],
            showDirectoryPopup:false,
            searchTermProp:"",
            isModalOpen:false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ Username: accountDetails });

        this.handelSelectPlan()
        this.domainListAPI()
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.listingDataAPICallid:
                        this.handleGetSearchListAPIResponse(responseJson)
                        break;
                    case this.createListingAPICallId:
                        this.handleCreateListAPIResponse(responseJson);
                        break;
                    case this.domainListAPICallId:
                        this.handleChangeDomainAPIResponse(responseJson);
                        break;

                    case this.getDirectoryDataResultApiId:
                         this.handleDirectorySearchResponse(responseJson);
                         break;  
                }
            }
        }
    }

    // Web Events
    goToLoginPage = () => {
        this.props.navigation.navigate("SearchListing2", { type: "EmailAccountLoginBlock" })
    }

    goToMockPath = (path: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), path);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    goToCheckoutPage = () => {
        const newMsg = new Message(getName(MessageEnum.NavigationMessage));
        newMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ListingCheckOutPage");
        newMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(newMsg)
    }

    gotoVenderaccount = () => {
        const newMsg = new Message(getName(MessageEnum.NavigationMessage));
        newMsg.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        newMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(newMsg)
    }

    gotoBuyeraccount = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    gotoHomePage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleHeadernavigation = async () => {
        const usertype = await getStorageData("user_type")
        if (usertype == "Buyer") {
            this.gotoBuyeraccount()
        } 
        if (usertype == "vendor" || "Vendor") {
            this.gotoVenderaccount()
        }
    }

    handleUserSignout = async () => {
        this.gotoHomePage()
        await removeStorageData("loginToken")
    }

    handleSelectDefaultValue = () => {
        this.setState({ selectedPrice: 1 })
    }

    handlePriceSelection =  ()=>{
        this.setState({ isSelectedPlan: true })
    }

    handleDescriptionModal = () => {
        this.setState({ isDescriptionModal: true,changeName:this.state.searchInput })
    }
    handleAddListing = async () => {
        const loginToken = await getStorageData("loginToken")
        const userType = await getStorageData("user_type")
        if (loginToken && (userType === "vendor" || userType === "Vendor")) {
            this.handleDescriptionModal()
        } else if (loginToken && userType === "Buyer") {
            this.gotoHomePage()
        } else {
            this.goToLoginPage()
        }
        this.removeDataStorage()
        await removeStorageData("searchItem_id")
    }

    removeDataStorage = async () => {
        await removeStorageData("user_listing_id")
    }
    handleCloseModal = () => {
        this.setState({ isDescriptionModal: false })
    }
    handleDefaulSetError = ()=>{
        this.setState({ errorMessage: "" })
    }

    goToClaimListingPage = async (itemId: string) => {
        const loginToken = await getStorageData("loginToken")
        if (loginToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "ClaimListingPage");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
            raiseMessage.addData(getName(MessageEnum.SessionResponseData), { searchItem_id: itemId });
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message)
            await removeStorageData("catlougue_Id")
        } else {
            this.goToLoginPage()
        }
    }

    handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchInput: event.target.value,
            isCompanyChange: true,
            isDomainChange: false
        }, () => {
            this.getListingDataAPI();
        });
    }

    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
    }

    handleUploadLogoClick = () => {
        this.inputRef.current?.click()
    }

    handelSelectPlan = async () => {
        const localStorageData = await getStorageData('selectedPlan');
        const parsedData = JSON.parse(localStorageData);
        this.setState({selectedPlan:parsedData})
    }
    handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
    }

    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }


    handleLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result as string;
             
              setStorageData('logo',base64String)
            }  
            reader?.readAsDataURL(file);
           
            if (allowedTypes.includes(file.type)) {
                this.setState({
                    logoPreview: file,
                    errorMessage: ""
                });
            } else {
                this.setState({ logoErrorMessage: configJSON.fileLogoErrorMessage })
            }
        }
    };

    handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ changeName: event.target.value })
    }

    handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ changeDescription: event.target.value })
    }

    handleChangeDomain = (event: ChangeEvent<HTMLInputElement>, isSearchAPI: boolean) => {
        const inputValue = event.target.value.trim();

        this.setState({
            changeDomain: inputValue,
        });

        if (inputValue === '') {
            this.setState({
                isError: true,
                errorMessage: configJSON.productURLrequ,
                successMessage: "",
            });
        } else if (this.state.availableDomains.includes(inputValue)) {
            this.setState({
                isError: true,
                errorMessage: configJSON.domainunav,
                successMessage: "",
            });
        } else {
            this.setState({
                isError: false,
                successMessage: configJSON.domainav,
                errorMessage: "",
            });
        }

        this.setState({
            isCompanyChange: false,
            isDomainChange: true,
        });

        if (isSearchAPI) {
            this.getListingDataAPI();
        }
    }

    // API Integration
    getListingDataAPI = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const directoryid = await getStorageData("directory_id")

        const query = this.state.isCompanyChange ? this.state.searchInput : this.state.changeDomain

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.listingDataAPICallid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listingDataAPIEndPoint}${query}&directory_id=${directoryid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    handleCreateListing=async()=>{
        const localStorageData = await getStorageData('selectedPlan');
        const parsedData = JSON.parse(localStorageData);
        setStorageData("domain_url",this.state.changeDomain)
        setStorageData("name", this.state.changeName);
        setStorageData("short_description", this.state.changeDescription);
        setStorageData("subscription_id", Number(parsedData?.selectedPlan?.plan?.id));
        if (Number(parsedData?.selectedPlan?.plan?.price) != 0) {
            this.goToCheckoutPage()
          
        }
        else{
            this.createListingAPI()
           
        }

    }


    createListingAPI = async () => {
        const localStorageData = await getStorageData('selectedPlan');
        const directoryId = await getStorageData("directory_id")
        const parsedData = JSON.parse(localStorageData);
        const header = {
            token: await getStorageData("loginToken"),
        };

        let formdata: any = new FormData();
        formdata.append("directory_id", directoryId);
        formdata.append("domain_url", this.state.changeDomain);
        if (this.state.logoPreview !== null) {
            formdata.append("logo", this.state.logoPreview);
        }
        formdata.append("name", this.state.changeName);
        formdata.append("short_description", this.state.changeDescription);
        formdata.append("subscription_id", Number(parsedData?.selectedPlan?.plan?.id));
        if (Number(parsedData?.selectedPlan?.plan?.price) === 0) {
            formdata.append("domain_verified", true);
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createListingAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createListingAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    domainListAPI = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.domainListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.domainListAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleGetSearchListAPIResponse = (responseJson: ListingDataProps) => {
        const apiResponse = responseJson.data
        if (apiResponse) {
            this.setState({ listingData: apiResponse });
        }
    }

    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
      }



    handleCreateListAPIResponse = async (responseJson: CreateListData) => {
        const catlougueId = responseJson.listing.data.id
        if (catlougueId) {
            this.setState({isDescriptionModal:false,isModalOpen:true})
        } else if (responseJson?.error) {
            this.setState({ isError: true, errorMessage: responseJson.error })
        } else if (responseJson?.errors) {
            this.setState({ isNameError: true, nameErrorMessage: responseJson?.errors[0] })
        }
        else {
            this.setState({ isNavigate: true }, () => {
                this.handleCloseModal()
            })
        }
    }
    handleChangeDomainAPIResponse = (responseJson: string[]) => {
        const availableDomains = responseJson;
        this.setState({ availableDomains: availableDomains })
    }
    getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
          const searchDataRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
    
        this.getDirectoryDataResultApiId = searchDataRequestMessage.messageId;
        searchDataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchDataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
      );
    
      runEngine.sendMessage(searchDataRequestMessage.id, searchDataRequestMessage);
    
    
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }

        
    // Customizable Area End
}

import React, { Component } from "react";
import {
    Box,
    Typography,
    LinearProgress,
    Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

type LinearProgressBarProps = {
    value: number;
    classes: Record<string, string>;
};

export class LinearProgressBar extends Component<LinearProgressBarProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, value }: LinearProgressBarProps = this.props;
        return (
            <Box sx={{ position: 'relative', marginTop: '16px' }}>
                <Box className={classes.labelPosition} style={{ left: `${value}%` }}>
                    <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={value} classes={{
                        root: classes.remainingProgressBar,
                        bar: classes.progressBarDummy,
                    }} />
                </Box>
            </Box>
        );
    }
}

export const userStyles = (theme:Theme) => ({
    labelPosition: {
        position: 'absolute' as const,
        top: '-24px',
        [theme.breakpoints.down('md')]: {
            transform:"translate(-40px,0)"
        },
    },
    progressBarDummy: {
        backgroundColor: "#B300ED"
    },
    remainingProgressBar: {
        backgroundColor: "#A09B9B33"
    }
});

export default withStyles(userStyles)(LinearProgressBar)
import React from "react";

import {
    Typography,
    Button,
    // Customizable Area Start
    Select, MenuItem, FormControl, InputLabel,
    withStyles,
    Theme,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Dialog,
    Box,
    TextareaAutosize,
    Avatar,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TablePagination from '@material-ui/core/TablePagination';
import { personImage, starIcon, closeIcon } from './assets';
import Rating from '@material-ui/lab/Rating';
import moment from "moment";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const styles: any = (theme: Theme) => ({
    reviewVendor: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "14px",
        margin:"2rem",
         "@media(max-width: 480px)": {
            marginLeft: "3vw",
        }
    },
    containerVendorReview: {
        maxHeight: 780,
        boxShadow:"none",
        borderRadius: "16px",
        "&::-webkit-scrollbar": {
            display: "none",
            width: "0",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        "@media(max-width: 480px)": {
            overflowX: 'auto',
            width: "95vw",
            marginLeft: "2.5vw",
        }
    },
    tableVendorReview: {
        borderRadius: "16px",
    },
    tableHeaderVendorReview: {
        "& th:first-child": {
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
        },
        "& th:last-child": {
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
        },
        "& th": {
            backgroundColor: "#B300ED",
            color: "#fff",
            fontSize: "18px",
            fontWeight: 500,
        },
    },
    headVendorReview: {
        flexShrink: 0,
        height: "58px",
        borderRadius: "16px",
        '& .MuiTableCell-head': {
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "14px",
        },
    },
    bodyVRS: {
    },
    rowactionRowVRS: {
        height: "88px",
    },
    formControl: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #006FFF",
        borderRadius: "5px",
        width: "87px",
        height: "50px",
        '& .MuiInputBase-root': {
            textAlign: 'center',
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderBottom: 'unset',
                },
            },
        },
    },
    logoHeadVendorReview: {
        padding: "0 25px 0 25px",
    },
    listingNameHeadVendorReview: {
        // paddingRight: "30px",
        // width: "120px",
        textWrap:"nowrap"
    },
    ReviewHeadVendorReview2: {
        // paddingRight: "25px",
        // width: "40px",
    },
    ReviewHeadVendorReview: {
        // paddingRight: "25px",
    },
    DateHeadVendorReview: {
        // paddingRight: "84px",
        // width: "58px",
        // paddingLeft: "58px",
    },
    ActionsHeadVendorReview: {},
    logoRowVRS: {
        // paddingLeft: "56px",
        // width: "15%",
    },
    listinNameRowVRS: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "14px",
        // width: "20%",
    },
    reviewNameRowVRS: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "14px",
        // width: "15%",
    },
    reviewNameRowVRS2: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "15px",
        maxWidth: "195px",wordBreak:"break-all",
    },
    dateRowVRS: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "14px",
        // paddingLeft: "58px",
        // width: "15%",
    },
    actionRowVRS: {
        borderRadius: "8px",
        border: "1px solid #6200EA",
        background: "#FFF",
        color: "#6200EA",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textTransform: "initial",
        // width: "15%",
        '&:hover': {
            background: "#FFF",
        }
    },
    dialogStyleEditVRS: {
        width: "996px",
        borderRadius: "8px 8px 32px 8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        display: "flex",
        flexDirection: "column",
        scrollbarColor: "#D6D6D6 transparent",
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: "22px 40px 40px",
        boxSizing: "border-box",
        "&::-webkit-scrollbar": {
            width: 0,
        },
    },
    updateReviewVRS: {
        color: "var(--basic-black, #000)",
        fontFamily: "Poppins",
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: "20px",
        marginBottom: "0",
    },
    commnetVendorRly: {
        color: "var(--basic-black, #000)",
        fontFamily: "Poppins",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: "20px",
        marginBottom: "0",wordBreak:"break-word",maxWidth:"911px",
    },
    personImgText: {
        color: "var(--basic-black, #000)",
        fontFamily: "Poppins",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: "20px",
    },
    starReviewTxt: {
        color: "var(--basic-black, #000)",
        fontFamily: "Poppins",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: "22px",
        marginBottom: "0",
    },
    starReviewDays: {
        color: "#64748B",
        fontSize: "16px",
        lineHeight: "22px",
        fontWeight: 400,
        fontFamily: "Poppins",
    },
    reviewTabAlgin: {
        alignItems: "center",
        display: "flex",
    },
    updateReviewdisplay: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        maxWidth: "100%",
        gap: "10px",
        marginTop: "10px",
        marginBottom: "10px",
    },
    avatarImg: {
        width: "50px",
        height: "50px",
        border: "1px solid"
    },
    ratingVRS: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0',
        marginBottom: "0",
        color: "#6200EA",
        width: "25%",
        height: "26px",
    },
    closeIconStyle: {
        height: '25px',
        width: '25px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    closeIconGridStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 10px 0px 0px'
    },
    fullWidthTextareaVRS: {
        marginTop: "20px",
        width: '100%',
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "8px 9px 10px 8px",
        color: "#000",
        textOverflow: "ellipsis",
        whiteSpace: "wrap",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        minHeight: "144px",
        fontFamily: "Poppins",
        '&:hover': {
            borderColor: '#CBD5E1',
        },
        '&:focus': {
            borderColor: '#CBD5E1',
        },
    },
    btnContainerVRS: {
        display: "flex",
        justifyContent: "end",
        marginTop: "20px",
    },
    updateBtnVRS: {
        padding: "6px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "initial",
        height: "32px",
        backgroundColor: "#B300ED",
        flexShrink: 0,
        gap: "6px",
        borderRadius: "8px",
        color: "#fff",
        width: '89px',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    LogosImg: {
        objectFit: "cover",
        maxWidth: "79px",
        width: "57px",
    },
    listImageNologo:{
        fontSize:"14px",
        fontWeight:400,
        fontFamily:"Poppins",
        color:"#000000"
    },
    withicon: {
        color: '#006FFF',
    },
    select: {
        width: '100%',
        height: '100%',
        '& .MuiSelect-root': {
            padding: '10px',
            lineHeight: '1.5',
        },
    },
    icon: {
        color: '#006FFF',
    },

    starIcon: {
        height: 32, // Set the height of the star icon
        width: 32, // Set the width of the star icon
    },

    menuPaper:{
        background:"#FFF",
        boxShadow:"1px 1px 0px 0px rgba(0, 0, 0, 0.06), 0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 0px 8px 2px rgba(0, 0, 0, 0.06)"
    },
    menuItem:{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight:"22px"
    }
})

export const CustomFormControl = withStyles({
    root: {
        border: "1px solid #006FFF",
        alignItems: "center",
        height: "40px",
        width: "85px",
        borderRadius: "8px",
        // display: "flex",
        flexDirection: "unset",
        margin: "0",
        alignContent: "center" as const,
        position: "relative",
        padding: "0",
        justifyContent: "center",
        "& .MuiInputLabel-formControl": {
            display: "flex",
            transform: "unset",
            width: "90%",
            color: "#006FFF",
            left: "5%",
            top: "unset",
            alignContent: "center" as const,
        },
        "& .MuiInput-underline::before": {
            borderBottom: "unset",
        },
        "& .label + .MuiInput-formControl": {
            margin: "unset"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "unset",
        },
        "& .MuiInput-underline::after": {
            borderBottom: "unset",
        },
    },
    withicon: {
        display: 'block',
        color: '#006FFF',
        position: "unset",

    }
})((props: any) => <FormControl {...props} />);
// Customizable Area End

import ReviewController, { Props } from "./ReviewController.web";

class Review extends ReviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { reviewCollection } = this.state
        const { classes } = this.props
        const currentDate: any = moment();
        const duration = moment.duration(currentDate.diff(this.state.editedData?.attributes?.created_at));
        const days = duration.asDays();
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Typography className={classes.reviewVendor}>Reviews Submitted</Typography>
                <TableContainer component={Paper} className={classes.containerVendorReview}>
                    <Table stickyHeader className={classes.tableVendorReview} aria-label="caption table">
                        <TableHead className={classes.headVendorReview}>
                            <TableRow className={classes.tableHeaderVendorReview}>
                                <TableCell align="center" className={classes.logoHeadVendorReview}>Logo</TableCell>
                                <TableCell align="left" className={classes.listingNameHeadVendorReview}>Listing Name</TableCell>
                                <TableCell align="left" className={classes.ReviewHeadVendorReview2}>Review</TableCell>
                                <TableCell align="left" className={classes.ReviewHeadVendorReview}>Review</TableCell>
                                <TableCell align="left" className={classes.DateHeadVendorReview}>Date</TableCell>
                                <TableCell align="center" className={classes.ActionsHeadVendorReview}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.bodyVRS}>
                            {reviewCollection && reviewCollection.map((item, index) => {
                                return (
                                    <TableRow className={classes.rowVRS} key={index}>
                                        <TableCell component="th" scope="row" align="center" className={classes.logoRowVRS}>
                                            {item.attributes.logo_url ?
                                             <img className={classes.LogosImg} src={item.attributes.logo_url} alt={item.attributes.listing_name} />
                                            :
                                            <Box>
                                             <p className={classes.listImageNologo}>No Logo</p>
                                            </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="left" className={classes.listinNameRowVRS}>{item.attributes.listing_name}</TableCell>
                                        <TableCell align="center" className={classes.reviewNameRowVRS}>
                                            <Typography className={classes.reviewTabAlgin}><Typography className={classes.updateReviewVRS} style={{ marginRight: "5px",marginLeft:"10px" }}> {item.attributes.rating}  </Typography><img src={starIcon} alt="starIcon" style={{ width: "22px", height: "22px" }} /></Typography>
                                        </TableCell>
                                        <TableCell align="left" className={classes.reviewNameRowVRS2}>
                                            {item.attributes.comment}</TableCell>
                                        <TableCell align="left" className={classes.dateRowVRS}>{moment(item.attributes.created_at).format("D-MMM-YY")}</TableCell>
                                        <TableCell align="center" className={classes.logoRowVRS}>
                                            <CustomFormControl>
                                           
                                                {this.state.selectedRows && (
                                                    <InputLabel
                                                        id="demo-simple-select-autowidth-label"
                                                        className={classes.withicon}
                                                    >
                                                        <SettingsOutlinedIcon />
                                                    </InputLabel>
                                                )}
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    autoWidth
                                                    value={this.state.selected || ""}
                                                    data-test-id={"action-select"}
                                                    onChange={(event) => this.handleSelectRow(event, item,index)}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ margin: "unset" }}
                                                    className={classes.select}                                                   
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: { paper: classes.menuPaper },
                                                    }}
                                                    IconComponent={(props) => (<KeyboardArrowDownIcon className={classes.icon} style={{ color: '#006FFF' }} {...props} />)}
                                                    renderValue={(value) => value === '' && null}
                                                >
                                                    <MenuItem classes={{ root: classes.menuItem }} data-test-id="view-id" value={1}>View</MenuItem>
                                                    {<MenuItem classes={{ root: classes.menuItem }} data-test-id="reply-id" value={2}>Reply</MenuItem>}
                                                    <MenuItem classes={{ root: classes.menuItem }} data-test-id="report-review-id" value={3}>Report Review</MenuItem>
                                                </Select>
                                            </CustomFormControl>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                <Dialog
                    open={this.state.edit}
                    onClose={this.handleCloseEdit}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyleEditVRS }}
                    data-testid={"openmodaledit"}
                >
                    <Box>
                        <Grid item xs={12} className={classes.closeIconGridStyle}>
                            <img src={closeIcon}
                                className={classes.closeIconStyle}
                                onClick={this.handleCloseEdit}
                                data-testid={"closeIcon"}
                            />
                        </Grid>
                        <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                            <Box style={{ display: "flex", width: "80%", alignItems: "center" }}>
                                <Rating
                                    className={classes.ratingVRS}
                                    name="simple-controlled"
                                    value={this.state.ratingVal}
                                    data-testid={"starrating"}
                                    classes={{
                                        iconFilled: classes.starIcon,
                                        iconEmpty: classes.starIcon,
                                    }}
                                    readOnly
                                />
                                <Typography className={classes.starReviewTxt}>
                                    {this.state.ratingVal}.0
                                </Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "10%" }}>
                                <Typography className={classes.starReviewDays}>
                                    {`${Math.floor(days)} days`}
                                </Typography>

                            </Box>
                        </Box>
                        <Box className={classes.updateReviewdisplay}>
                            <Avatar alt="Tony" src={this.state.editedData?.attributes?.profile_image || personImage} className={classes.avatarImg} />
                            <Typography className={classes.personImgText}> {this.state.editedData?.attributes?.user_name || `John Mathew`} </Typography>
                        </Box>
                        <Typography className={classes.commnetVendorRly}>{this.state.editedData?.attributes?.comment}</Typography>
                        {this.state.selected === 2 && <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Empty"
                            className={classes.fullWidthTextareaVRS}
                            value={this.state.newRly}
                            onChange={this.handleReviewCommentChange}
                            data-testid={"textarea"}
                        />}
                        <Box className={classes.btnContainerVRS}>
                            {this.state.selected === 2 ?
                                <Button className={classes.updateBtnVRS} data-testid={"updateBtn"} onClick={this.handleUpdate}>Update </Button> :
                                <Button className={classes.updateBtnVRS} data-testid={"updateClose"} onClick={this.handleCloseEdit}>Close</Button>}
                        </Box>
                    </Box>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export { Review }
export default withStyles(styles)(Review)

// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    categoryId:any;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    


    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    imagePreview: string;
    value: number;
    progressVal: number;
    isSucessModalOpen: boolean;
    aboutDetails: {
        short_description: string;
        long_description: string;
        domain_url: string
    }
    updatedAboutDetails: {
        short_description: string;
        long_description: string;
        domain_url: string
    },
    isFeedbackFormOpen: boolean;
    allcountriesdata: any,
    selectedCountries: any,
    isLoading:boolean,
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ServicesAboutPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getVideosDataAPICalledId: string = "";
    getCatlougeListDataAPICalledId: string = "";
    updateAboutListingAPICallId: string = "";
    getCountriesServicesId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            imagePreview: "",
            value: 0,
            progressVal: 0,
            isSucessModalOpen: false,
            aboutDetails: {
                short_description: "",
                long_description: "",
                domain_url: ""
            },
            updatedAboutDetails: {
                short_description: "",
                long_description: "",
                domain_url: ""
            },
            isFeedbackFormOpen: false,
            allcountriesdata: [],
            selectedCountries: [],
            isLoading:false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getCountriesServices()
        this.getAboutCatlougeList()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
        if (this.props.categoryId !== prevProps.categoryId) {
            this.getAboutCatlougeList();
        }
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getCatlougeListDataAPICalledId:
                        this.handleAboutAPIResponse(responseJson);
                        break;
                    case this.updateAboutListingAPICallId:
                        this.handleUpdateAboutListResponse(responseJson);
                        break;
                    case this.getCountriesServicesId:
                        this.setState({allcountriesdata:responseJson.countries})
                        break;                  
                }
            }
        }
    }

    // Web Events
    goToVendorAccount = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleOpenFeedbackFrom = () => {
        this.setState({ isFeedbackFormOpen: true })
    }

    handleCloseFeedbackForm = () => {
        this.setState({ isFeedbackFormOpen: false })
    }

    handleUpdatedAboutData = () => {
        this.setState({ updatedAboutDetails: { ...this.state.aboutDetails } });
    };

    handleChangeShortDescription = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ updatedAboutDetails: { ...this.state.updatedAboutDetails, short_description: event.target.value } })
        this.props.handleDataChanged()
    }

    handleChangeLongDescription = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ updatedAboutDetails: { ...this.state.updatedAboutDetails, long_description: event.target.value } })
        this.props.handleDataChanged()
    }

    // API
    getAboutCatlougeList = async () => {
        this.setState({isLoading:true})
        const category_Id =this.props.categoryId || await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCatlougeListDataAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${category_Id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCountriesServices = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCountriesServicesId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCountries}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateAboutListingData = async () => {
        this.props.handleDataSaved()

        const category_Id = this.props.categoryId || await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        formdata.append("short_description", this.state.updatedAboutDetails?.short_description);
        formdata.append("description", this.state.updatedAboutDetails?.long_description)

        this.state.selectedCountries.length > 0 ? this.state.selectedCountries.forEach((item: any) => {
            formdata.append("market_ids[]", item.country_id);
            })  :  formdata.append("market_ids[]", "")
       

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateAboutListingAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${category_Id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleAboutAPIResponse = (responseJson: any) => {
        const apiresponse = responseJson.data.catalogue.data.attributes
        if (responseJson) {
            this.setState({
                aboutDetails: {
                    ...this.state.aboutDetails,
                    short_description: apiresponse?.short_description,
                    long_description: apiresponse?.description,
                    domain_url: apiresponse?.domain_url
                }
            }, () => this.handleUpdatedAboutData()
            )
        }

        if (apiresponse.markets) {
            this.setState({ selectedCountries: apiresponse.markets })
        }
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }

    handleUpdateAboutListResponse = (responseJson: any) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucessModalOpen: true })
            this.getAboutCatlougeList()
        }
    }

    handleCloseSuccess = ()=>{
        this.setState({ isSucessModalOpen: false })
    }

    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ selectedCountries: newValue })
        this.props.handleDataChanged()
    }

    // Customizable Area End
}
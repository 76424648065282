import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  value:string,
  noOfCatagories:number,
  noOfProductgroups:number,
  noOfListings:number,
  listOfCategories:any,
  listOfProductgroups:any,
  screenName:string,
  searchTermProp:string,
  userData:any,
  catalogues:any,
  catalogue_count:number,
  categories_count:number,
  sub_categories_count:number,
  categories:any,
  sub_categories:any,
  featured_catalogues:any,
  loading:boolean,
  showAllCategoriesData:any,
  showAllSubCategoriesData:any,
  showDirectoryPopup:boolean,
  showAllCatalogueData:any,
  directoryWiseData:any,
  searchDirectoryData:any


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  advancedsearchDataApiCallId:any;
  getSearchResultDataReqId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      value:"All",
      noOfCatagories:2,
      noOfProductgroups:2,
      noOfListings:2,
      listOfCategories:[],
      listOfProductgroups:[],
      screenName:"summary",
      searchTermProp:"",
      userData:{},
      catalogues: [
        {
          "id": 443,
          "payment_status":"success",
          "name": "Aerapy UV Disinfection",
          "directory":"Suppliers",
          "short_description": "Powerful UV Disinfection for Commercial, Healthcare, Industrial, and Vehicle Applications",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcDBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f5c30cb84bebaed31421e678755a98157a36751d/Abastur-1.png"
        },
        {
          "id": 444,
          "name": "Appreciation in Action",
          "short_description": "Changing companies with more than a thank you.",
          "logo": null,
          "directory":"Software",
          "payment_status":"success",
        },
        {
          "id": 160,
          "name": "Allied-Eco-Grip Flooring",
          "short_description": "Eco-Grip is a commercial flooring provider that offers PVC flooring, vinyl flooring, & more. We provide high-performance and cost-effective flooring.",
          "logo": null,
          "directory":"Events",
          "payment_status":"success",
        },
        {
          "id": 30,
          "name": "Service_type_al",
          "payment_status":"success",
          "short_description": "",
          "logo": null,
          "directory":"Services"
        },
        {
          "id": 230,
          "name": "Arizona State University College of Health Solutions",
          "short_description": "Short Ddescription",
          "logo": null,
          "directory":"Software",
          "payment_status":"pending",
        },
        {
          "id": 64,
          "directory":"Suppliers",
          "name": "ACP - Acoustic Ceiling Products",
          "short_description": "ACP's beautiful and functional ceiling, backsplash and wall products add affordable elegance and practical solutions for residential and commercial use.",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdE1CIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--73b7afc1051bdc1a175f9dfc8b9c832e883231dc/ss.png"
         
        },
        {
          "id": 67,
          "name": "Alpha_ser",
          "short_description": null,
          "logo": null,
          "directory":"Services",
          "payment_status":"pending"
        },
        {
          "id": 226,
          "name": "American Culinary Federation",
          "short_description": "ACF, North America's premier chefs' association, promotes the professional chef image through education, certification, apprenticeship and accreditation.",
          "logo": null,
          "directory":"Suppliers",
          "payment_status":"pending"
        },
        {
          "id": 162,
          "directory":"Software",
          "payment_status":"pending",
          "name": "Always Food Safe",
          "short_description": "Always Food Safe is an ANSI accredited, specialist web-based e-learning provider for the food service sectors.",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGNDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ddbedd0f691b715fb611e060af43867e1dbc7f0f/penguin-158551__340.webp"
         
        },
        {
          "id": 228,
          "name": "Antunes",
          "short_description": "At Antunes, we're committed to providing you with innovative foodservice solutions. ",
          "logo": null,
          "directory":"Events",
          "payment_status":"pending"
        },
        {
          "id": 161,
          "name": "Altro",
          "short_description": "Altro is an international manufacturer of high-quality flooring and wall cladding solutions.",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcHdDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5b90dc3e8616fd2af99a7ad2435114dabccc541b/10-strawberry-street-logo-1.png",
          "directory":"Suppliers"
        }
      ],
      catalogue_count:10,
      categories_count:6,
      sub_categories_count:5,
      categories: [
        {
          "id": 142,
          "name": "Demo_service_cat",
          "directory_id": 8,
          "category_slug": "demo_service_cat",
          "count": 4,
          "directory":"Suppliers"
        },
        {
          "id": 45,
          "name": "Construction",
          "directory_id": 8,
          "category_slug": "_construction",
          "count": 5,
          "directory":"Suppliers"
        },
        {
          "id": 55,
          "name": "Legal",
          "directory_id": 8,
          "category_slug": "_legal",
          "count": 1,
          "directory":"Suppliers"
        },
        {
          "id": 57,
          "name": "Misc Services",
          "directory_id": 8,
          "category_slug": "_misc-services",
          "count": 2,
          "directory":"Suppliers"
        },
        {
          "id": 60,
          "name": "Training",
          "directory_id": 8,
          "category_slug": "_training",
          "count": 5,
          "directory":"Suppliers"
        },
        {
          "id": 65,
          "name": "Food",
          "directory_id": 6,
          "category_slug": "food",
          "count": 20,
          "directory":"Suppliers"
        }
      ],
      sub_categories: [
        {
          "id": 67,
          "name": "Demo service sub",
          "category_id": 10,
          "sub_category_slug": "demo_service_sub",
          "count": 2
        },
        {
          "id": 549,
          "name": "Ceilings (Furniture & Décor)",
          "category_id": 66,
          "sub_category_slug": "ceilings",
          "count": 2
        },
        {
          "id": 551,
          "name": "Decor & Display Materials (Furniture & Décor)",
          "category_id": 66,
          "sub_category_slug": "decor-display-materials",
          "count": 2
        },
        {
          "id": 660,
          "name": "Fabrication (Kitchen Equipment)",
          "category_id": 69,
          "sub_category_slug": "fabrication",
          "count": 2
        },
        {
          "id": 839,
          "name": "Platters (Tableware)",
          "category_id": 71,
          "sub_category_slug": "platters",
          "count": 8
        }
      ],
      featured_catalogues: [
        {
          "id": 64,
          "name": "ACP - Acoustic Ceiling Products",
          "short_description": "ACP's beautiful and functional ceiling, backsplash and wall products add affordable elegance and practical solutions for residential and commercial use.",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdE1CIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--73b7afc1051bdc1a175f9dfc8b9c832e883231dc/ss.png"
        },
        {
          "id": 162,
          "name": "Always Food Safe",
          "short_description": "Always Food Safe is an ANSI accredited, specialist web-based e-learning provider for the food service sectors.",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGNDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ddbedd0f691b715fb611e060af43867e1dbc7f0f/penguin-158551__340.webp"
        },
        {
          "id": 228,
          "name": "Antunes",
          "short_description": "At Antunes, we're committed to providing you with innovative foodservice solutions. ",
          "logo": null
        },
        {
          "id": 161,
          "name": "Altro",
          "short_description": "Altro is an international manufacturer of high-quality flooring and wall cladding solutions.",
          "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcHdDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5b90dc3e8616fd2af99a7ad2435114dabccc541b/10-strawberry-street-logo-1.png"
        }
      ],
      loading:false,
      showAllCategoriesData:[],
      showAllSubCategoriesData:[],
      showDirectoryPopup:false,
      showAllCatalogueData:[],
      directoryWiseData: [
        {
          "directory": "Software",
          "catalogues": [
            {
              "id": 11,
              "name": "pooj",
              "short_description": "omksnww",
              "directory": "Software",
              "payment_status": "success",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1FTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9b98a47c9bfef10eafbe441110d1112ae8cb3e2a/765d591d698594d818f581647748e563.jpg"
            },
            {
              "id": 16,
              "name": "ruch",
              "short_description": "omksnww",
              "directory": "Software",
              "payment_status": "success",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2tTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--55351eee59a557d7c2fc3623a6e64d48d67b8f95/765d591d698594d818f581647748e563.jpg"
            },
            {
              "id": 17,
              "name": "rk",
              "short_description": "omksnww",
              "directory": "Software",
              "payment_status": "success",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb29TIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a8a4979b893eeaa2621b05219e54a851ffee0b42/765d591d698594d818f581647748e563.jpg"
            },
            {
              "id": 18,
              "name": "pankaj",
              "short_description": "omksnww",
              "directory": "Software",
              "payment_status": "pending",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb3NTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--304ed80b63e02b49716fa38831ef4a2ad48dcfe5/765d591d698594d818f581647748e563.jpg"
            }
          ],
          "categories": [
            {
              "id": 1,
              "name": "deepak",
              "directory": "Software",
              "sub_categories": [
                {
                  "id": 1,
                  "name": "jain",
                  "category_id": 1
                },
                {
                  "id": 2,
                  "name": "seth",
                  "category_id": 1
                }
              ],
              "count": 3
            },
            {
              "id": 2,
              "name": "demo",
              "directory": "Software",
              "sub_categories": [
                {
                  "id": 3,
                  "name": "demo sub_cate",
                  "category_id": 2
                },
                {
                  "id": 4,
                  "name": "demo1",
                  "category_id": 2
                }
              ],
              "count": 1
            }
          ],
          "count": {
            "catalogue_count": 4,
            "categories_count": 4,
            "sub_categories_count": 5
          },
          "featured_catalogues": [{
            "id": 64,
            "directory":"Software",
            "name": "ACP - Acoustic Ceiling Products",
            "short_description": "ACP's beautiful and functional ceiling, backsplash and wall products add affordable elegance and practical solutions for residential and commercial use.",
            "logo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdE1CIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--73b7afc1051bdc1a175f9dfc8b9c832e883231dc/ss.png"
          }],
          "sub_categories": [
            {
              "id": 1,
              "name": "jain",
              "category_id": 1,
              "directory": "Software",
              "count": 2
            },
            {
              "id": 4,
              "name": "demo1",
              "category_id": 2,
              "directory": "Software",
              "count": 1
            },
            {
              "id": 2,
              "name": "seth",
              "category_id": 1,
              "directory": "Software",
              "count": 1
            }
          ]
        },
        {
          "directory": "Services",
          "catalogues": [
            {
              "id": 7,
              "name": "jainss",
              "short_description": "omksnww",
              "directory": "Services",
              "payment_status": "success",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdXdMIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0231bba4e11b190cf206f44cadeabfb57e88a411/vgbhnjk.png"
            },
            {
              "id": 3,
              "name": "bmw",
              "short_description": "omksnww",
              "directory": "Services",
              "payment_status": "pending",
              "logo": null
            },
            {
              "id": 27,
              "name": "dev",
              "short_description": "omksnww",
              "directory": "Services",
              "payment_status": "success",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcFFTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a28c2b2c722409faeffbbbe69c2fe5ceb572740/765d591d698594d818f581647748e563.jpg"
            }
          ],
          "categories": [
            {
              "id": 6,
              "name": "services",
              "directory": "Services",
              "sub_categories": null,
              "count": 1
            }
          ],
          "count": {
            "catalogue_count": 3,
            "categories_count": 4,
            "sub_categories_count": 5
          },
          "featured_catalogues": [],
          "sub_categories": [
            {
              "id": 6,
              "name": "Sub_services",
              "category_id": 5,
              "directory": "Services",
              "count": 1
            }
          ]
        },
        {
          "directory": "Events",
          "catalogues": [
            {
              "id": 8,
              "name": "oaky",
              "short_description": "omksnww",
              "directory": "Events",
              "payment_status": "success",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb0VTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cc7f0a52c0519a131f92d6154d1c24ea2d62f6ad/765d591d698594d818f581647748e563.jpg"
            },
            {
              "id": 12,
              "name": "deepak",
              "short_description": "omksnww",
              "directory": "Events",
              "payment_status": "pending",
              "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1VTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c45af816b6a5a35360b634196f31f6bb6fe3eb5/765d591d698594d818f581647748e563.jpg"
            },
            {
              "id": 2,
              "name": "below",
              "short_description": "omksnww",
              "directory": "Events",
              "payment_status": "success",
              "logo": null
            }
          ],
          "categories": [
            {
              "id": 3,
              "name": "Event",
              "directory": "Events",
              "sub_categories": [
                {
                  "id": 5,
                  "name": "eventsub",
                  "category_id": 3
                }
              ],
              "count": 1
            }
          ],
          "count": {
            "catalogue_count": 3,
            "categories_count": 4,
            "sub_categories_count": 5
          },
          "featured_catalogues": [],
          "sub_categories": [
            {
              "id": 5,
              "name": "eventsub",
              "category_id": 3,
              "directory": "Events",
              "count": 1
            }
          ]
        }
      ],
      searchDirectoryData:[]
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({
      listOfCategories:["Beverage & Bar Equipment (210)", "Beverage (150)"],
      listOfProductgroups:["Beverage & Bar Equipment (210)", "Beverage (150)"]
    })
    const storedData = await getStorageData("userdetails");
    const accountDetails = JSON.parse(storedData);
    this.setState({ userData: accountDetails });
    this.getAdvancedSearchData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } 
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
      runEngine.debugLog("API Message Recived", message);

      

      this.handleApiResponse(responseJson,apiRequestCallId)
      if(apiRequestCallId==this.advancedsearchDataApiCallId){
        this.handleSearchResponse(responseJson)
      }
      if(apiRequestCallId==this.getSearchResultDataReqId){
        this.handleDirectorySearch(responseJson)
        this.setState({loading:false})
      }
    } 
    
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      const data = message.getData(
        getName(MessageEnum.AdvancedSearchTermData)
      );
      this.setState({value:data && data.directory?data.directory:"All",searchTermProp:data && data.searchText},()=>this.getAdvancedSearchData());
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClick=(e:any)=>{
    this.setState({screenName:"catalogue"})
  }

  handleChange = (event: any, newValue: string) => {
    this.setState({ value: newValue },()=> this.getAdvancedSearchData());
  };

  handleSearchInput = (event:any) =>{  
    this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
    if(!event.target.value) this.handleDirectoryPopupClose();
  }
  handleDirectoryPopupClose=()=>{
    this.setState({showDirectoryPopup:!this.state.showDirectoryPopup})
  }

  handleApiResponse (responseJson:any, apiRequestCallId:string){
    if (responseJson && responseJson.accounts) {
      if (typeof responseJson.accounts === "string") {
        alert(responseJson.accounts);
      } else {
        this.setState({ advancedsearchList: responseJson.accounts.data });
      }
    }
  }

 
  goToLoginPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
  goToVenderaccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
  handleSignoutClick = async () => {
    await removeStorageData("loginToken")
    this.goToHomePage()
  }


  

  handleHeaderNavigation = async () => {
    const usertype = await getStorageData("user_type")
    if (usertype == "Buyer") {
      this.goToBuyeraccount()
    } else if (usertype == "vendor" || "Vendor") {
      this.goToVenderaccount()
    }
  }

  goToBuyeraccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }


  getListingUrl = (directory: any, id: any, payment_status: string) => {
    if (payment_status == "pending") {
      if (directory == "Suppliers") {
        return "/basiclistingsupplier/" + id
      }
      else if (directory == "Software") {
        return "/basiclisting/" + id
      }
      else if (directory == "Services") {
        return "/basiclistingservices/" + id
      }
      else if (directory == "Events") {
        return "/premiumlistingevents/" + id
      }

    } else {


      if (directory == "Suppliers") {
        return "/premiumlistingsupplier/" + id
      }
      else if (directory == "Software") {
        return "/premiumlisting/" + id
      }
      else if (directory == "Services") {
        return "/premiumlistingservices/" + id
      }
      else if (directory == "Events") {
        return "/premiumlistingevents/" + id
      }
    }
    


  }


  getAdvancedSearchData= () => {
      this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchDataApiCallId = requestMessage.messageId;

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AdvancedSearchApiEndPoint}?query=${this.state.searchTermProp}&directory_name=${this.state.value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchResponse=(responseJson:any)=>{
    if (responseJson && !responseJson.errors) {
      this.setState({loading:false,directoryWiseData:responseJson.data})
    }
  }
  handleCategoryRedirection =  () => {
    setStorageData('categoryType', "advanced");
    
  }

  showAllCategoriesToggle=(directory:string)=>{
    this.state.showAllCategoriesData.includes(directory)?this.setState({showAllCategoriesData:this.state.showAllCategoriesData.filter((item:any)=>item!=directory)})
    : this.setState({showAllCategoriesData:[...this.state.showAllCategoriesData,directory]})

  }

  showAllCategoryExist=(directory:string)=>{
    return this.state.showAllCategoriesData.includes(directory)
  }

  showAllSubCategoryExist=(directory:string)=>{
    return this.state.showAllSubCategoriesData.includes(directory)
  }

  showAllCatalogueExist=(directory:string)=>{
    return this.state.showAllCatalogueData.includes(directory)
  }

  showAllSubCategoriesToggle=(directory:string)=>{
    this.state.showAllSubCategoriesData.includes(directory)?this.setState({showAllSubCategoriesData:this.state.showAllSubCategoriesData.filter((item:any)=>item!=directory)})
    :this.setState({showAllSubCategoriesData:[...this.state.showAllSubCategoriesData,directory]})

   
  }

  showAllCatalogueToggle=(directory:string)=>{
    this.state.showAllCatalogueData.includes(directory)?this.setState({showAllCatalogueData:this.state.showAllCatalogueData.filter((item:any)=>item!=directory)})
    :this.setState({showAllCatalogueData:[...this.state.showAllCatalogueData,directory]})
  }

  getSearchResultDirectory = () => {
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };

  const searchdataRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

    this.getSearchResultDataReqId = searchdataRequestMessage.messageId;
      searchdataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
      );
      searchdataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);

  searchdataRequestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.httpGetMethod
  );

  runEngine.sendMessage(searchdataRequestMessage.id, searchdataRequestMessage);


  }

  handleDirectorySearch=(responseJson:any)=>{
    if(responseJson && responseJson.response){
      this.setState({loading:false,searchDirectoryData:responseJson.response})
    }
  }
  handleAdvancedSearch = (directory:string) => {
    this.setState({showDirectoryPopup:false})
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToAdvancedSearch)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
    this.send(msg);

  }

  handleSubmit=(event:any)=>{
    event.preventDefault();
    this.handleAdvancedSearch("All")
  }


  goToCatalogue = (directory:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { directory,searchText:this.state.searchTermProp });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
  }


  // Customizable Area End
}

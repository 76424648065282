import React from "react";

import {
    Typography,
    // Customizable Area Start
    withStyles,
    Theme,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Box,
    Tabs,
    Tab,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";


export const styles: any = (theme: Theme) => ({
    review: {
        color: "#262424", fontFamily: "Poppins",
        fontSize: "20px",fontWeight: 500,
        lineHeight: "14px", marginBottom: "34px",
        marginTop: "26px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "20px",
        },
    },
    container: {
        maxHeight: 780,
        borderRadius: "0 0 16px 16px",
        paddingTop: "18px",
        boxShadow:"none",
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        "&::-webkit-scrollbar": {
            width: "0",
            display: "none",
        },
    },
    table: {
        borderRadius: "16px",
    },
    tableHeader: {
        "& th": {
            color: "#fff",
            backgroundColor: "#B300ED",
            fontWeight: 500,
            fontSize: "18px",
        },
        "& th:first-child": {
            borderBottomLeftRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& th:last-child": {
            borderBottomRightRadius: "16px",
            borderTopRightRadius: "16px",
        },
    },
    head: {
        height: "58px",
        flexShrink: 0,
        borderRadius: "16px",
        '& .MuiTableCell-head': {
            fontFamily: "Poppins",
            color: "#FFF", fontWeight: 500,
            lineHeight: "14px", fontSize: "18px",
        },
    },
    row: {
        height: "88px",
    },
    body: {
    },
    logo_head: {
        width: "44px",
        padding: "0 95px 0 64px",
        borderBottom:"none",
    },
    listingName_head: {
        width: "120px",
        paddingRight: "95px",
        borderBottom:"none",
    },
    review_head: {
        width: "79px",
        paddingRight: "244px",
        borderBottom:"none",
    },
    date_head: {
        marginLeft: "auto", width: "58px",
        paddingRight: "84px",borderBottom:"none",
    },
    logo_row: {
        // display: "flex",
        alignContent: "center" as const,
        justifyContent: "center" as const,
    },
    listinName_Row: {
        color: "#3B3B3B", fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500, lineHeight: "14px",
    },
    reviewNameRow: {
        color: "#3B3B3B", fontFamily: "Poppins",
        fontSize: "12px", fontWeight: 500,
        lineHeight: "15px",
    },
    dateRow: {
        color: "#3B3B3B", fontFamily: "Poppins",
        fontSize: "14px", fontWeight: 500,
        lineHeight: "14px",// paddingLeft: "58px",
        marginLeft: "auto"
    },
    tabheader: {
        "& .MuiTab-wrapper": {
            textTransform: "none", color: "#2A0066",
            fontWeight: 700,ineHeight: "24px",
            fontSize: "16px", fontFamily: "Poppins",
        },
        "& .MuiTabs-flexContainer": {
            gap: "71px", backgroundColor: "#FFFFFF",
            padding: "25px 10px 0 58px", borderRadius: "16px 16px 0 0",

            [theme.breakpoints.down('md')]: {
                padding: "25px 20px 0 20px", 
                width: "100%", backgroundColor: "#FFFFFF",

            }

        },
        "& .MuiTab-root": {
            padding: "0",
            minWidth: "0", height: "24px", 
          },
        "& .MuiTabs-indicator": {
            backgroundColor: "#B300ED",
            height: "3px", width: "93px",

        },

        [theme.breakpoints.down('sm')]: {
            '&  > div': {
                overflowX: 'scroll !important',
                display: 'flex',
            },
            '&  > div::-webkit-scrollbar': { display: "none",
            },
            // margin: "0 10px",
            backgroundColor: "#FFFFFF",
        }
    },
    tab: {
        "& .MuiTab-root": {
            height: "24px",

        }
    },
    logoImg: {
        width: "75px",
        height: "75px",
        marginRight:"25px"
    },
    listImageNologo:{
        fontSize:"14px", fontWeight:400,
        fontFamily:"Poppins",
        color:"#000000",
        paddingRight:"20px"
    },
    descWid:{
        maxWidth:"75%",
    }

})
// Customizable Area End

import BookmarkController, {
    Props
} from "./BookmarkController.web";

class Bookmark extends BookmarkController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const {bookmarksData}= this.state

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Typography className={classes.review}>Bookmarks</Typography>
                <Box >
                    <Tabs
                        value={this.state.tabVal}
                        onChange={this.handleChangeTab}
                        data-testid={"tab"}
                        aria-label="secondary tabs example"
                        className={classes.tabheader}
                    >
                        <Tab value={"Suppliers"} className={classes.tab} label="Supplier" />
                        <Tab value={"Software"} className={classes.tab} label="Software" />
                        <Tab value={"Services"} className={classes.tab} label="Services" />
                        <Tab value="Events" className={classes.tab} label="Events" />

                    </Tabs>
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader className={classes.table} aria-label="caption table">
                            <TableHead className={classes.head}>
                                <TableRow className={classes.tableHeader}>
                                    <TableCell align="center" className={classes.logo_head}>Logo</TableCell>
                                    <TableCell align="left" className={classes.listingName_head}>Name</TableCell>
                                    <TableCell align="left" className={classes.review_head}>Description</TableCell>
                                    <TableCell align="left" className={classes.date_head}>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.body}>
                                {bookmarksData && bookmarksData.length > 0 ?
                                    bookmarksData.map((item: any, index: any) => {
                                        return (
                                            <TableRow key={index} className={classes.row}>
                                                <>
                                                    <TableCell component="th" scope="row" align="center" className={classes.logo_row} >
                                                        {item.logo_url ?
                                                          <img className={classes.logoImg} alt={item.catalogue_name} src={`${item.logo_url}`} />
                                                        :
                                                        <Box>
                                                          <p className={classes.listImageNologo}>No Logo</p>
                                                        </Box>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.listinName_Row}>{item.catalogue_name}</TableCell>
                                                    <TableCell align="left" className={classes.reviewNameRow}><p className={classes.descWid}>{item.short_description && `${item.short_description?.substring(0, 90)}...`}</p></TableCell>
                                                <TableCell align="left" className={classes.dateRow}>{moment(item.created_at).format("D-MMM-YY")}</TableCell>
                                                </>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPageReview}
                    page={this.state.pageReview}
                    onPageChange={this.handleChangePageReview}
                    data-test-id="paginationChange"
                />
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export { Bookmark }
export default withStyles(styles)(Bookmark)

// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.catalogueAPiEndPoint = "bx_block_categories/get_directories";
exports.catalogueAPiFilter = "?directory_name=";
exports.catalogueAPiFilterByRegionEndPoint = "&region_id=";
exports.countryAPiEndPoint = "bx_block_categories/get_regions";
exports.regionAPiEndPoint = "bx_block_categories/get_regions_or_location?";
exports.noResultText="No results found"





exports.AdvancedSearchApiEndPoint = 'bx_block_catalogue/catalogue_search'
exports.filterListEndpoint = "bx_block_catalogue/content_managments";


exports.getSearchResultEndPoint="/bx_block_catalogue/search_listing"

exports.catalogueUpdatedEndPoint="/bx_block_categories/get_directories_with_catalogues"
exports.categoriesUpdatedEndpoint="bx_block_categories/get_directories_with_category"


// Customizable Area End
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    isOpenNewPassModal: boolean;
    newPassword: string;
    confirmNewPassword: string;
    showSucessMessage: boolean;
    isOpenoSucessModal: boolean;
    passToken: string;
    isPassError: boolean;
    passErrMsg: string;
    isConfirmPassErr: boolean;
    confirmPassErrText: string;
    isConfirmPassSuccess: boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    NewPasswordAPICallId: string = "";
    pswdspaceReg: RegExp;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            isOpenNewPassModal: true,
            newPassword: "",
            confirmNewPassword: "",
            isOpenoSucessModal: true,
            showSucessMessage: false,
            passToken: "",
            isPassError: false,
            passErrMsg: "",
            isConfirmPassErr: false,
            confirmPassErrText: "",
            isConfirmPassSuccess: false
            // Customizable Area End
        };

        // Customizable Area Start
        this.pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const currentUrl = window.location.href;
        const urlParts = currentUrl.split('?');
        if (urlParts.length > 1) {
            const paramParts = urlParts[1].split('=');
            if (paramParts[0] === 'token') {
                this.setState({ passToken: paramParts[1] })
            }
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.NewPasswordAPICallId) {
                    const apiresponse = responseJson?.data
                    if (apiresponse) {
                        this.setState({
                            isOpenNewPassModal: false,
                            showSucessMessage: true
                        })
                    }
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Web Events

    handleChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 0) {
            this.setState({
                isPassError: true,
                passErrMsg: configJSON.requiPass,
            });
        } else if (!this.pswdspaceReg.test(event.target.value)) {
            this.setState({
                isPassError: true,
                passErrMsg: configJSON.spaceErrMsg,
            });
        }
        this.setState({ newPassword: event.target.value.trim() });

    }

    handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === this.state.newPassword) {
            this.setState({
                confirmNewPassword: event.target.value,
                isConfirmPassSuccess: true,
                confirmPassErrText: configJSON.passmatch
            })
        } else {
            this.setState({
                confirmNewPassword: event.target.value,
                isConfirmPassErr: true,
                isConfirmPassSuccess: false,
                confirmPassErrText: configJSON.passdidnmatch
            })
        }
    }

    goToLoginPage = () => {
        this.props.navigation.navigate("Home2", { type: "EmailAccountLoginBlock" })
    }

    handleCloseModal = () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationHomeScreenMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    newPasswordAPI = () => {
        const header = {
            "Content-Type": "application/json",
        };

        const data = {
            token: this.state.passToken,
            new_password: this.state.newPassword,
            new_password_confirm: this.state.confirmNewPassword
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.NewPasswordAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPassAPIEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.forgotPassAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
export interface EventsData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    event: {
                        boothNumber: string
                    };
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleOpenSaveModal: () => void;
    openSaveModal: boolean;
    handleDataChanged: () => void;
    handleDataSaved: () => void;
    handleBack: () => void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    eventData: { boothNumber: string };
    updateEventData: { boothNumber: string };
    isSucess: boolean;
    isFeedback: boolean;
    getEvent: any;
    selectedEvent: any;
    feedbackEmail: string;
    feedbackMessage: string;
    isValidEmail: { valid: boolean, message: string };
    isLoadVideoData: boolean;
    progressPercentage: number;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EventsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getEventListAPICalledId: string = "";
    updateEventListingDataAPICallId: string = "";
    getEventId: string = "";
    feedbackEventID: string = "";
    emailReg: RegExp;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            eventData: { boothNumber: '' },
            updateEventData: { boothNumber: '' },
            isSucess: false,
            isFeedback: false,
            getEvent: [],
            selectedEvent: [
                {
                    "id": "",
                    "name": "",
                    "boothNumber": "",
                },
            ],
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            isLoadVideoData: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getEventsList();
        this.getEvent()
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getEventListAPICalledId:
                        this.handleEventsLitsResponse(responseJson);
                        break;
                    case this.getEventId:
                        this.setState({ getEvent: responseJson.events })
                        break;
                    case this.updateEventListingDataAPICallId:
                        this.handleUpdateEventListResponse(responseJson);
                        break;
                    case this.feedbackEventID:
                        this.setState({ isFeedback: false });
                        break;
                }
            }


        }
    }

    // Web Events

    handleAddMore = () => {
        this.setState((prevState) => ({
            selectedEvent: [
                ...prevState.selectedEvent,
                {
                    "id": "",
                    "name": "",
                    "boothNumber": "",
                },
            ],
        }));
    };

    goToVendorAccount = () => {
        if (this.props.openSaveModal) {
            this.props.handleOpenSaveModal()
        }
        else {
            this.props.handleBack();
        }

    }

    handleCloseSuccessDialog = () => {
        this.setState({ isSucess: false });
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    getEvent = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEventId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.catlougeListAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getEventsList = async () => {
        this.setState({ isLoadVideoData: true })
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEventListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateEventsListingData = async () => {
        this.props.handleDataSaved()
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        this.state.selectedEvent?.forEach((item: any, index: number) => {
            formdata.append(`catalogue_events_attributes[][event_id]`, item.id)
            if (item.boothNumber) {
                formdata.append(`catalogue_events_attributes[][booth_number]`, item.boothNumber)
            }
        });

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateEventListingDataAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSubmitFeedbackEvent = async (event: any) => {

        let formdata = new FormData();


        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailEvent(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackEventID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeCategory = (value: number) => {
        let updatedCategory = this.state.selectedEvent.filter((item: any, index: any) => {
            return index !== value
        })
        this.setState({ selectedEvent: updatedCategory })
    }

    handleEventChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
        const toBeUpdate = this.state.getEvent?.find((item: any) => item.id === event.target.value);

        if (toBeUpdate) {
            const updatedData = this.state.selectedEvent.map((item: any, currentIndex: number) => {
                return currentIndex === index ? { ...item, id: toBeUpdate.id, name: toBeUpdate.name, boothNumber: item?.boothNumber } : item;
            });

            this.setState({ selectedEvent: updatedData });
        }

        this.props.handleDataChanged()
    }

    handleEventChangeBoothNumber = (event: React.ChangeEvent<{ value: unknown }>, id: any) => {
        this.props.handleDataChanged()
        const updatedData = this.state.selectedEvent.map((item: any) => {
            return item.id === id ? { ...item, boothNumber: event.target.value } : item;
        });

        this.setState({ selectedEvent: updatedData });

    }

    handleEventsLitsResponse = (responseJson: any) => {
        const apiresponse = responseJson?.data?.catalogue?.data?.attributes?.event?.data
        if (responseJson) {
            this.setState({ isLoadVideoData: false })
        }
        if (apiresponse) {
            const extractedData = apiresponse.map((event: any) => ({
                id: event.id,
                name: event.name,
                boothNumber: event.attributes.booth_number
            }));

            this.setState({ selectedEvent: extractedData })
        }

        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }


    handleUpdateEventListResponse = (responseJson: []) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucess: true })
            this.getEventsList()
        }
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailEvent = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }
    // Customizable Area End
}
// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import Footer from "../../components/src/Footer.web";


import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import { AdvancedSearchPage } from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import JoinUsSubscription from "../../blocks/landingpage/src/JoinUsSubscription.web";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Videoembeddingyoutube from "../../blocks/videoembeddingyoutube/src/Videoembeddingyoutube";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Customform4 from "../../blocks/customform4/src/Customform4";
import ServicesCustomForm from "../../blocks/customform4/src/ServicesCustomForm.web";
import SupplierCustomForm from "../../blocks/customform4/src/SupplierCustomForm.web"
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import VendorLandingPage from "../../blocks/landingpage/src/VendorLandingPage.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import {
  createMuiTheme,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import Survey from "../../blocks/email-account-login/src/Survey.web"
import VendorAccountDetails from "../../blocks/customisableusersubscriptions/src/VendorAccountDetails.web";
import OrderDetail from "../../blocks/customisableusersubscriptions/src/OrderDetail.web"
import Profile from "../../blocks/settings2/src/Profile.web";
import PremiumListing from "../../blocks/customform4/src/PremiumListing.web";
import JoinUsLandingPage from "../../blocks/landingpage/src/JoinUsLanding.web";
import SearchListing from "../../blocks/landingpage/src/SearchListing.web";
import ListingCheckOutPage from "../../blocks/landingpage/src/ListingCheckOutPage.web";
import ClaimListingPage from "../../blocks/landingpage/src/ClaimListingPage.web"
import BasicListing from "../../blocks/customform4/src/BasicListing.web";
import PlanDetail from "../../blocks/customisableusersubscriptions/src/PlanDetail.web"
import PlanSelection from "../../blocks/customisableusersubscriptions/src/PlanSelection.web"
import PremiumListingSupplier from '../../blocks/customform4/src/PremiumListingSupplier.web'
import BasicListingSupplier from '../../blocks/customform4/src/BasicListingSupplier.web'

import PremiumListingService from '../../blocks/customform4/src/PremiumListingService.web'

import PremiumListingEvents from '../../blocks/customform4/src/PremiumListingEvents.web'

import BasicListingService from '../../blocks/customform4/src/BasicListingService.web';
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import {SocialItemPage } from '../../blocks/advancedsearch/src/SocialLinks.web.tsx'

import TopNav from '../../blocks/landingpage/src/TopNav.web.tsx'

const routeMap = {
  OrderDetail: {
    component: OrderDetail,
    path: "/OrderDetail"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  AdvancedSearchPage: {
    component: AdvancedSearchPage,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: "/RecommendationEngine"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  UserStatus: {
    component: UserStatus,
    path: "/UserStatus"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  JoinUsSubscription: {
    component: JoinUsSubscription,
    path: "/JoinUsSubscription"
  },

  JoinUsSubscription2: {
    component: JoinUsSubscription,
    path: "/JoinUsSubscription2/:type"
  },

  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue/:type"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Videoembeddingyoutube: {
    component: Videoembeddingyoutube,
    path: "/Videoembeddingyoutube"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "*/EmailAccountLoginBlock",
    modal: true,
  },
  Survey: {
    component: Survey,
    path: "*/Survey",
    modal: true
  },

  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "*/EmailAccountRegistration",
    modal: true
  },

  ForgotPassword: {
    component: ForgotPassword,
    path: "*/ForgotPassword",
    modal: true
  },

  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "*/NewPassword",
    modal: true
  },
  Customform4: {
    component: Customform4,
    path: "/Customform4"
  },
  ServicesCustomForm: {
    component: ServicesCustomForm,
    path: "/ServicesCustomForm"
  },
  SupplierCustomForm: {
    component: SupplierCustomForm,
    path: "/SupplierCustomForm"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  VendorLandingPage: {
    component: VendorLandingPage,
    path: "/VendorLandingPage",
  },

  LandingPage: {
    component: LandingPage,
    path: "/LandingPage",
  },

  JoinUsLandingPage: {
    component: JoinUsLandingPage,
    path: "/joinus"
  },

  JoinUsLandingPage2: {
    component: JoinUsLandingPage,
    path: "/joinus2/:type"
  },

  SearchListing: {
    component: SearchListing,
    path: "/SearchListing"
  },

  SearchListing2: {
    component: SearchListing,
    path: "/SearchListing2/:type"
  },

  ListingCheckOutPage: {
    component: ListingCheckOutPage,
    path: "/ListingCheckOutPage"
  },

  ClaimListingPage: {
    component: ClaimListingPage,
    path: "/ClaimListingPage"
  },

  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },

  VendorAccountDetails: {
    component: VendorAccountDetails,
    path: "/VendorAccountDetails",
  },

  BuyerAccountDetails: {
    component: Profile,
    path: "/BuyerAccountDetails"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  Home2: {
    component: LandingPage,
    path: '/Home2/:type'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  PremiumListing: {
    component: PremiumListing,
    path: "/premiumlisting/:id"
  },
  BasicListing: {
    component: BasicListing,
    path: "/basiclisting/:id"
  },
  PlanDetail: {
    component: PlanDetail,
    path: "/PlanDetail"
  },
  PlanSelection: {
    component: PlanSelection,
    path: "/PlanSelection"
  },
  PremiumListingSupplier: {
    component: PremiumListingSupplier,
    path: "/premiumlistingsupplier/:id"
  },

  BasicListingSupplier: {
    component: BasicListingSupplier,
    path: "/basiclistingsupplier/:id"
  },

  BasicListingService: {
    component: BasicListingService,
    path: "/basiclistingservices/:id"
  },


  PremiumListingService: {
    component: PremiumListingService,
    path: "/premiumlistingservices/:id"
  },

  PremiumListingEvents: {
    component: PremiumListingEvents,
    path: "/premiumlistingevents/:id"
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }
  async componentDidMount() {
    const storedData = await getStorageData("loginToken");
    const userType = await getStorageData("user_type");
    const storeedTimeStamp = await getStorageData("timestamp")

    if (storedData && Date.now() - storeedTimeStamp > 24 * 60 * 60 * 1000) {
      await removeStorageData('loginToken');
      await removeStorageData('timestamp');
    }
    const allowedUrls = [
      '/',
      "/Home",
      '/VendorLandingPage',
      '/JoinUsLandingPage',
      "/Catalogue",
      "/PremiumListingService",
      "/PremiumListing",
      "/BasicListing",
      "/PremiumListingSupplier",
      "/BasicListingSupplier",
      "/PremiumListingEvents",
      "/BasicListingService"
    ];
 
    if (!storedData && !allowedUrls.some(route => window.location.pathname.startsWith(route))) {
      window.location.href = "/";
      return;
    }
    const buyerRoutesUrls = [
      "/VendorAccountDetails",
      "/joinus",
      '/JoinUsSubscription',
      '/SearchListing',
      "/ListingCheckOutPage",
      "/PlanDetail",
      "/PlanSelection",
    ];
    if(storedData && userType === "Buyer" && buyerRoutesUrls.some(route => window.location.pathname.startsWith(route))){
      window.location.href = "/BuyerAccountDetails";
      return;
    }
    this.setState({ isLoggedIn: true });
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    // Global themening
    const globalTheme = createMuiTheme({
      palette: {
        primary: { main: '#B300ED' },
        secondary: { main: '#000000' },
        third: { main: '#454545' },
        background: {
          default: '#B300ED',
        },
        typography: {
          fontFamily: "Poppins"
        }
      }
    });

    return (
      <MuiThemeProvider theme={globalTheme}>
        <View style={{ height: '100vh', }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <SocialItemPage />
        </View>
      </MuiThemeProvider>
    );
  }
}

export default App;

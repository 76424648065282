//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import React from "react";
export const locationMockData = ["1", "2-10", "11-50", "50+"]
import { setStorageData } from "../../../framework/src/Utilities";


// Customizable Area End


export const configJSON = require("./config");


export interface UserRole {
    id: number,
    name: string,
}
export interface TypeOfBusinessData {
    id: string
    type: string
    attributes: {
        name: string
    }

}

export interface PasswordI {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

export interface AccountDetailsI {
    full_name: string
    email: string
    user_type: string
    no_of_location: string
    annual_revenue: string
    country: string
    city: string
    company_name: string
    activated: boolean
    role_name: number
    department: number
    type_of_business: number
    full_phone_number: string
    role: string
    depart_ment: string
    typeOfbusiness: string
    profile_image: string
    department_name:string
    business_name:string
}


export interface UpDetailI {
    name: string,
    email: string,
    phonenumber: string,
    homelocation: string,
    roleLevel: string,
    department: string,
    companyName: string,
    typeOfBusiness: string,
    numberOfLocation: string,
    city: string | null
    country: string | null
    countryCode: string | null
}

export interface ShowProfileI {
    id: string
    type: string
    attributes: ShowProfileAttributesI
}

export interface ShowProfileAttributesI {
    full_name: string | null
    email: string | null
    user_type: string | null
    no_of_location: any | null
    annual_revenue: any | null
    country: any | null
    city: any | null
    company_name: any | null
    activated: boolean | null
    role_name: any | null
    department: any | null
    type_of_business: any | null
    profile_image: any | null
    full_phone_number: string | null
}

export interface Props {
    navigation: any;
    id: string;
    classes: any
}

export interface OldPasswordI {
    valid: false,
    msg: string,
}



export interface S {
    // Customizable Area Start
    openDetailsModal: boolean;
    annualRevenue: number,
    successUpdated: boolean,
    profileData: ShowProfileAttributesI,
    role: number | null | string,
    department: string | null,
    userRole: UserRole[];
    userDepartment: any;
    businessData: TypeOfBusinessData[],
    counrtyCodeList: any;
    resetPassword: boolean;
    successResetPassword: boolean,
    openCountryCodeList: any;
    updateDetails: UpDetailI;
    nameError: boolean;
    nameErrorText: string,
    emailErr: boolean;
    emailErrMsg: string;
    accountDetails: AccountDetailsI;
    passwordReset: PasswordI;
    oldpswdError: boolean;
    oldpswdErrorText: string;
    pswdError: boolean;
    pswdErrorText: string;
    confirmError: boolean;
    confirmErrorText: string;
    isValidPassword: OldPasswordI;
    isNewPassword: OldPasswordI;
    isConfirmPassword: OldPasswordI;
    editProfilePic: any,
    imagePreview: any
    isLoader: boolean;
    newError: string;
    disbaleUpdateBtn:boolean;
    // Customizable Area End
}


export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class AccountController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    showProfileId: string = "";
    getUserRoleAPICalledId: string = "";
    getUserDepartmentAPICalledId: string = "";
    apiTypeOfBusiness: string = "";
    getCountryCodeListAPICalledId: string = "";
    apiSurveyFormID: string = "";
    resetPasswordId: string = "";
    uploadProfileId: string = "";

    passwordReg: RegExp;
    passwordRegex: RegExp;
    reg: RegExp;



    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            openDetailsModal: false,
            annualRevenue: 0,
            successUpdated: false,
            resetPassword: false,
            successResetPassword: false,
            profileData: {},
            role: "",
            department: "",
            userRole: [],
            userDepartment: [],
            businessData: [],
            counrtyCodeList: [],
            openCountryCodeList: null,
            updateDetails: {
                name: "",
                email: "",
                phonenumber: "",
                homelocation: "",
                roleLevel: "",
                department: "",
                companyName: "",
                typeOfBusiness: "",
                numberOfLocation: "",
                city: "",
                country: "",
                countryCode: "+44",
            },
            nameError: false,
            nameErrorText: "",
            emailErr: false,
            emailErrMsg: "",
            accountDetails: {
                full_name: "",
                email: "",
                user_type: "",
                no_of_location: "",
                annual_revenue: "",
                country: "",
                city: "Null",
                company_name: "",
                activated: false,
                role_name: 0,
                department: 0,
                type_of_business: 0,
                full_phone_number: "",
                role: "",
                depart_ment: "Null",
                typeOfbusiness: "",
                profile_image: "",
            },
            passwordReset: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            },
            oldpswdError: false,
            oldpswdErrorText: "",
            pswdError: false,
            pswdErrorText: "",
            confirmError: false,
            confirmErrorText: "",
            isValidPassword: {
                valid: false,
                msg: "",
            },
            isNewPassword: {
                valid: false,
                msg: "",
            },
            isConfirmPassword: {
                valid: false,
                msg: "",
            },
            editProfilePic: "",
            imagePreview: "",
            isLoader: true,
            newError:"",
            disbaleUpdateBtn:false
            // Customizable Area End
        };


        // Customizable Area Start
        this.passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
        this.reg = new RegExp(/^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }


    // Customizable Area Start


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );


            let response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.setResponseStates(apiRequestId, response)

        }

        // Customizable Area End
    }


    setResponseStates = (apiRequestId, response) => {
        this.setState({ isLoader: false })
        let responseData

        switch (apiRequestId) {
            case this.getUserRoleAPICalledId:
                this.setState({ userRole: response });
                break;

            case this.getUserDepartmentAPICalledId:
                responseData = response ? response.data : [];
                this.setState({ userDepartment: responseData });
                break;

            case this.apiTypeOfBusiness:
                responseData = response ? response.data : [];
                let responseDataId = response ? response.data?.[0].id : "";
                this.setState({ businessData: responseData, businessTab: responseDataId });
                break;

            case this.showProfileId:
                this.handleAccountDetailsResponse(response);
                break;

            case this.getCountryCodeListAPICalledId:
                this.setState({ counrtyCodeList: response?.country_codes });
                break;

            case this.resetPasswordId:
                if (response.data) {
                    this.setState({ oldpswdError: false, oldpswdErrorText: "",disbaleUpdateBtn:false })
                    this.handleCloseResetPassword()
                } else if (response.errors) {
                    this.handleErrorMsg(response)
                    this.setState({disbaleUpdateBtn:false})
                }
                break;

            case this.apiSurveyFormID:
                this.handleProfileUpdate(response);

                break;

            case this.uploadProfileId:
                this.handleProfileUpdate(response);

                break;
            default:
                break;
        }
    }


    onProfileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                editProfilePic: file,
                imagePreview: reader.result
            });
        }
        reader.readAsDataURL(file);
    };

    handleProfileUpload = () => {
        let formdata = new FormData();

        formdata.append("[data][attributes][profile_image]", this.state.editProfilePic, this.state.editProfilePic?.name);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadProfileId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateProfileUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    getShowProfile = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.showProfileId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.showProfile
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUserRole = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserRoleAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.roleAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUserDepartment = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserDepartmentAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.departmentAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getTypeBusiness = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiTypeOfBusiness = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.typeOfBusiness
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCountryCodeList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCountryCodeListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryListAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    resetPassword = () => {
        if (this.customValidation() && !this.state.oldpswdError && !this.state.pswdError) {
            this.setState({disbaleUpdateBtn:true})
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("loginToken"),
            };

            const attrs = {
                "old_password": this.state.passwordReset.oldPassword,
                "new_password": this.state.passwordReset.newPassword,
                "new_password_confirm": this.state.passwordReset.confirmPassword,
            }

            const httpBody = {
                data: attrs,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.resetPasswordId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.resetPassword
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postApiMethod
            );


            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    updateSurveyForm = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("loginToken"),
        };

        const attrs = {
            "full_name": this.state.updateDetails.name,
            "email": this.state.updateDetails.email,
            "full_phone_number": this.state.updateDetails.countryCode? this.state.updateDetails.phonenumber:"",
            "account_block_departments_id": this.state.updateDetails.department,
            "no_of_location": this.state.updateDetails.numberOfLocation,
            "type_of_businesses_id": this.state.updateDetails.typeOfBusiness,
            "annual_revenue": this.state.annualRevenue,
            "country": this.state.country,
            "city": this.state.updateDetails.city,
            "role_id": this.state.updateDetails.roleLevel,
            "company_name": this.state.updateDetails.companyName,
            "country_code": this.state.updateDetails.countryCode,
            "type": "email_account"
        }

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSurveyFormID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateDetailsAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);

    };


    async componentDidMount(): any {
        super.componentDidMount();
        this.getShowProfile()
        this.getUserRole()
        this.getUserDepartment()
        this.getTypeBusiness()
        this.getCountryCodeList()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.editProfilePic !== this.state.editProfilePic) {
            this.handleProfileUpload()
        }
        if(this.state.userDepartment && this.state.userDepartment!==prevState.userDepartment){
            const departmentname = this.state.userDepartment.find((item: any) => item.id == this.state.accountDetails.department)
            this.setState({
                accountDetails: {
                  ...this.state.accountDetails,
                    depart_ment: departmentname?.attributes?.name,
                }
            })
        }
    }

    handleChangeOldPassword = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        if (value.length == 0) {
            this.setState({
                oldpswdError: true,
                oldpswdErrorText: "This field is required.",
            });
        }
        else if (!this.passwordRegex.test(value)) {
            this.setState({
                oldpswdError: true,
                oldpswdErrorText: configJSON.oldPassErrText,
            });
        }
        else {
            this.setState({ oldpswdError: false, oldpswdErrorText: "" });
        }
        this.setState({ passwordReset: { ...this.state.passwordReset, oldPassword: event.target.value.trim()},newError:""  });

    }


    handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        if (value.length == 0) {
            this.setState({
                pswdError: true,
                pswdErrorText: "This field is required.",
            });
        }
        else if (!this.passwordRegex.test(value)) {
            this.setState({
                pswdError: true,
                pswdErrorText: configJSON.passformate,
            });
        }
        else {
            this.setState({ pswdError: false, pswdErrorText: "" });
        }
        this.setState({ passwordReset: { ...this.state.passwordReset, newPassword: event.target.value.trim() },newError:"" });
    };


    handleChangePasswordConfirm = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        if (value.length == 0) {
            this.setState({
                confirmError: true,
                confirmErrorText: "This field is required.",
            });
        }
        else if (this.state.passwordReset.newPassword !== value) {
            this.setState({
                confirmError: true,
                confirmErrorText: "The passwords you entered does not match",
            });
        }
        else {
            this.setState({ confirmError: false, confirmErrorText: "" });
        }
        this.setState({ passwordReset: { ...this.state.passwordReset, confirmPassword: event.target.value.trim() } });
    };

    handleOpenDetails = () => {
        const countryCode = this.state.accountDetails?.full_phone_number.split('-')[0]
        const phoneNumber = this.state.accountDetails?.full_phone_number.split('-')[1]
        this.setState({
            openDetailsModal: true,
            updateDetails: {
                name: this.state.accountDetails?.full_name,
                email: this.state.accountDetails?.email,
                phonenumber: phoneNumber,
                roleLevel: this.state.accountDetails?.role_name,
                department: this.state.accountDetails?.department,
                companyName: this.state.accountDetails?.company_name,
                typeOfBusiness: this.state.accountDetails?.type_of_business,
                numberOfLocation: this.state.accountDetails?.no_of_location,
                city: this.state.accountDetails?.city,
                country: countryCode,
                countryCode: countryCode,
            },
            annualRevenue: this.state.accountDetails?.annual_revenue
        })
    }

    handleCloseDetailsModal = () => {
        this.setState({ openDetailsModal: false })
    }

    handleOpenSuccessUpdated = () => {
        if (this.state.nameError) {
            this.setState({
                nameError: true,
                nameErrorText: "required",
            });
        } else if (this.state.emailErr) {
            this.setState({
                emailErr: true,
                emailErrMsg: "required",
            });
        } else {
            this.updateSurveyForm()
            this.handleCloseDetailsModal()
            this.setState({ successUpdated: true })
        }
    }

    handleCloseSuccessUpdated = () => {
        this.setState({ successUpdated: false })
    }

    handleOpenResetPassword = () => {
        this.handleCloseDetailsModal()
        this.setState({ resetPassword: true })
    }

    handleCloseResetPassword = () => {
        this.setState({ passwordReset: { oldPassword: "", newPassword: "", confirmPassword: "" } })
        this.setState({ resetPassword: false })
        this.setState({ successResetPassword: true })
    }


    handleCloseResetPasswordDialog = () => {
        this.handleReset()
        this.setState({ resetPassword: false })
    }

    handleReset = () => {
        this.setState({
            passwordReset: { oldPassword: "", newPassword: "", confirmPassword: "" },
            oldpswdError: false,
            oldpswdErrorText: "",
            pswdError: false,
            pswdErrorText: "",
            confirmError: false,
            confirmErrorText: "",
            newError:"",
        })
    }
    customValidation = () => {
        const { oldPassword, newPassword, confirmPassword } = this.state.passwordReset
        let isValid = true;
        if (!oldPassword) {
            this.setState({
                oldpswdError: true,
                oldpswdErrorText: "This field is required.",
            });
            isValid = false
        }
        if (!newPassword) {
            this.setState({
                pswdError: true,
                pswdErrorText: "This field is required.",
            });
            isValid = false
        }
        if (!confirmPassword) {
            this.setState({
                confirmError: true,
                confirmErrorText: "This field is required."
            });
            isValid = false
        }
        return isValid;
    }

    handleCloseSuccessResetPassword = () => {
        this.setState({ successResetPassword: false })
    }

    onChangeAnnualRevenue = (event: any, value: any) => {
        this.setState({ annualRevenue: value })
    }

    handleOnChangeDetail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        const rawValue = event.target.value;
        if (name === "phonenumber") {
            const cleanedValue = rawValue.replace(/\D/g, "");
            this.setState((prevState) => ({
                updateDetails: {
                    ...prevState.updateDetails,
                    [name]: cleanedValue,
                },
            }));
        } else {
            this.setState({
                updateDetails: {
                    ...this.state.updateDetails,
                    [name]: value
                }
            })
        }
    }

    handleUpdateDetailName = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        if (value.length == 0) {
            this.setState({
                nameError: true,
                nameErrorText: "required",
            });
        }
        else {
            this.setState({ nameError: false, nameErrorText: "" });
        }
        this.setState({
            updateDetails: {
                ...this.state.updateDetails,
                name: value
            }
        })
    }


    handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        if (value.length === 0) {
            this.setState({
                emailErr: true,
                emailErrMsg: "Email is required",
            });
        } else if (!this.reg.test(event.target.value)) {
            this.setState({
                emailErr: true,
                emailErrMsg: "Please enter correct email address",
            });
        } else {
            this.setState({ emailErr: false, emailErrMsg: "" });
        }
        this.setState({
            updateDetails: {
                ...this.state.updateDetails,
                email: value
            }
        })
    };


    handleNumberOfLocation = (value) => {
        this.setState({
            updateDetails: {
                ...this.state.updateDetails,
                numberOfLocation: value
            }
        })
    }

    onClosePopOver = () => {
        this.setState({
            openCountryCodeList: null,
        });
    }

    handleOpenPopover = (event: any) => {
        this.setState({ openCountryCodeList: event.currentTarget });
    };

    handleCountryCodeChange = (code: any) => {
        this.setState({ updateDetails: { ...this.state.updateDetails, countryCode: code } }, () => {
            this.setState({ openCountryCodeList: null })
        })
    }

    //Api Response
    handleAccountDetailsResponse = (json: any) => {
        const apiresponse = json?.data?.attributes
        const departmentname = this.state.userDepartment?.find((item: any) => item.id == apiresponse?.department)
        const typeOfBusines = this.state.businessData?.find((item: any) => item.id == apiresponse?.type_of_business)

        if (apiresponse) {
            setStorageData("userdetails", JSON.stringify(apiresponse))
            this.setState({
                accountDetails: {
                    ...apiresponse,
                    depart_ment: departmentname?.attributes?.name,
                    typeOfbusiness: typeOfBusines?.attributes?.name,
                    profile_image: apiresponse.profile_image ? apiresponse.profile_image : ""
                }
            })
        }
    }

    handleProfileUpdate = (json: any) => {
        const apiresponse = json
        if (apiresponse) {
            this.getShowProfile()
        }
    }

    handleCloseIcon = () => {
        localStorage.clear();
        this.props.navigation.navigate("Home2", { type: "EmailAccountLoginBlock" })
    }
    handleErrorMsg = (response: any) => {
        if (response.errors[0].message == "Your password doesn't match" || response.errors[0].password == "New password is invalid") {
            this.setState({ oldpswdError: true, oldpswdErrorText: response.errors[0].message || response.errors[0].password })
        }else{
            this.setState({newError:response.errors[0].message})
        }
    }
    handelDepart=(depTrue:undefined | string, depFalse:undefined | string)=>{
        return this.state.updateDetails?.roleLevel ? depTrue : depFalse
    }
    handelDepart=(depTrue: undefined | string, deepFalsse:undefined | string)=>{
        return this.state.updateDetails?.department ? depTrue : deepFalsse
    }
    handleBusinessRole =(roleTrue:undefined | string, roleFalse:undefined | string)=>{
        return !this.state.updateDetails?.typeOfBusiness ? roleTrue : roleFalse
    }


    // Customizable Area End
}

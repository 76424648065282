import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;

    // Customizable Area Start
    openFeedback: boolean;
    closeFeedback: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userEmail: string;
    feedbackMessage: string;
    emailErr: boolean;
    emailErrMsg: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class FeedbackFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    submitFeedbackAPICallId: string = "";
    reg: RegExp;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area Start

            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userEmail: "",
            feedbackMessage: "",
            emailErr: false,
            emailErrMsg: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.reg = new RegExp(/^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.submitFeedbackAPICallId) {
                    const apiresponse = responseJson
                    if (apiresponse) {
                        this.props.closeFeedback()
                    }
                }
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleChangeFormEmail = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        if (value.length === 0) {
            this.setState({
                emailErr: true,
                emailErrMsg: "Email Address is Required",
            });
        } else if (!this.reg.test(event.target.value)) {
            this.setState({
                emailErr: true,
                emailErrMsg: "Enter Valid Email Address",
            });
        } else {
            this.setState({ emailErr: false, emailErrMsg: "" });
        }
        this.setState({ userEmail: event.target.value.trim() });
    };

    handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ feedbackMessage: event.target.value })
    }

    submitFeedback = async () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };
        let formdata = new FormData();
        formdata.append("email", this.state.userEmail);
        formdata.append("message", this.state.feedbackMessage);

    

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitFeedbackAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.feedbackAPI
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
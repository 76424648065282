import React from "react";

import {
    Typography,
    Box,
    // Customizable Area Start
    withStyles,
    Theme,
    Button,
    TextField,
    TextareaAutosize,
    Chip,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { CloseICon, deleteIcon_1 } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";


import AboutController, {
    Props
} from "./AboutController";
export const configJSON = require("./config");
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomToast from "../../../components/src/CustomToast.web";

export const styles: any = (theme: Theme) => ({
    container: {
        width: "1087px",
        margin: "0 auto",
        fontFamily: "Poppins",
        paddingBottom: "50px",

        [theme.breakpoints.up('md')]: {
            width: "1087px",
        },

        [theme.breakpoints.down('md')]: {
            width: "90%",
        },

        [theme.breakpoints.down('sm')]: {
            width: "90%",
        },
    },
    description: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        marginTop: "49px",
        marginBottom: "20px",
    },
    longdescription: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        marginTop: "23px",
        marginBottom: "20px",
    },

    para: {
        color: "#262424",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        marginBottom: "40px",
    },
    textarea: {
        maxHeight: "200px",
        height: "162px",
        overflowY: "auto",
        borderRadius: "8px",
        border: " 1px solid #CBD5E1",
        background: "#FAF5FF",
        whiteSpace: "normal",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        padding: "8px 9px 10px 8px",
        marginBottom: "17px",
        fontFamily: "Poppins",
        '&::placeholder': {
            fontFamily: "Poppins",
            color: "#828589",

        },

    },
    textarealongdesc: {
        marginBottom: "30px",
    },
    mediaWrapper: {
        display: "flex",
        justifyContent: "end",
        marginBottom: "17px",
    },
    addMedia: {
        color: "#B300ED",
        textTransform: "none",
    },
    mediatypo: {
        marginLeft: "6px",
    },
    productUrl: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "18px",
    },
    urldesc: {
        color: "#262424",
        fontSize: "18px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
        marginBottom: "29px",
    },
    domainAbout: {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "normal",
        color: "#828589",
        width: "587px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        background: "#FAF5FF",
        border: "1px solid #CBD5E1",
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none',
        },
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
        }
    },
    saveBtnWrapper: {
        marginTop: "59px",
        display: 'flex',
        justifyContent: "space-between",

        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            gap: "20px",
        },
    },
    saveBtn: {
        height: "56px",
        width: "241px",
        padding: "16px 71px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        textTransform: "initial",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        cursor: "pointer",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
        textWrap: "nowrap",
        fontSize: "16px",

        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },

    },
    uploadMedialDialog: {
        width: "624px",
        height: "552px",
        background: "red",

    },
    uploadMedia: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: 'hidden' as const,
        overflowY: 'scroll' as const,
        scrollbarColor: "#D6D6D6 transparent",

        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },

        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },


        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 6,
            background: "#B300ED",
            minWidth: 14,
            minHeight: 24,
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar": {
            height: 6,
            width: 4,
            backgroundColor: "#D6D6D6",
            padding: '30px',
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
    },
    top: {
        display: "flex",
        justifyContent: "end",
        padding: "16px 16px 0",
        alignSelf: "end",
    },
    updateName: {
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "28px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.14px",
        marginBottom: "29px",
        textAlign: "center",
    },
    avatarBox: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "74px",
    },
    innerAvatarBox: {
        width: "156px",
        height: "156px",
        borderRadius: "50%",
        background: "#000000BF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        position: "relative",
    },
    label: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "4px",
        cursor: "pointer",
    },
    imageBlur: {
        position: "absolute",
        width: "156px",
        height: "156px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    editPicture: {
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    editNameTextfield: {
        color: "#828589",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "normal",
        border: "1px solid #CBD5E1",
        borderRadius: "6px",
        background: "#FAF5FF",
        width: "440px",
    },
    saveUpload: {
        height: "56px",
        padding: "16px 151px",
        justifyContent: "center",
        alignItems: "center",
        gap: '8px',
        width: "342px",
        borderRadius: "8px",
        background: "#B300ED",
        marginTop: "33px",
        color: "white",
        textTransform: "initial",
        cursor: "pointer",
        '&:hover': {
            background: "#B300ED",
        },
    },
    successboxAbout: {
        width: "529px",
        height: "329px",
        borderRadius: "8px 8px 32px 8px",
        background: "#FFF",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },
    successMediaDialog: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: "20px",
    },
    detailSuccessTypo: {
        color: "#0F172A",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        margin: "20px 0 51px"
    },
    backToAccountBtn: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',

        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        cursor: "pointer",
        fontFamily: 'Poppins',
    },
    fontStyle5: {
        fontSize: '16px',
        color: '#262424',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    marketheader: {
        marginTop: "30px",
        color: "#262424",
        fontFamily: 'Poppins',
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "14px",
    },
    autocompleteAbout: {
        marginBottom: "42px",
        borderRadius: '8px',
        width: "587px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        border: "1px solid  #CBD5E1",
        backgroundColor: '#FAF5FF',
        padding: "6px 0",
        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },
        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent",
        },
        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
            minHeight: "40px",
        },
        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        },

    },
    chipbrandAbout: {
        margin: "0 3px",
        border: "none",
        fontSize: "16px",
    },
    myCustomListAbout: {
        maxwidth: "590px",
        maxHeight: "331px",
        overflow: 'auto',
        background: "#FFF",
        margin: "0",
        borderRadius: "8px",
        border: "1px solid #E2E8F0",
        padding: "4px 0",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "&::-webkit-scrollbar": {
            borderRadius: "30px",
            width: "6px",
            height: "23.34px",
            backgroundColor: "#A09B9B33",
        },
        "&::-webkit-scrollbar-thumb": {
            height: "23.34px",
            backgroundColor: "#B300ED",
            width: "6px",
            borderRadius: "30px",

        }
    },

    icon_style: {
        borderRadius: 6,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '1px solid #64748B',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon_style: {
        backgroundColor: '#8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },
    flagLabel: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "14px",
        fontFamily: "Poppins",
        marginLeft: 15
    },
})

// Customizable Area End

class About extends AboutController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.container}>
                <Typography className={classes.description} data-testid={"title"}>Short Description </Typography>
                <Typography className={classes.para}>
                    Enter a short description of your company or product. It will be shown in search results and lists. The short description should not be longer than 250 characters. There is a long description field in the Features section that will be shown in the detail page of your listing.
                </Typography>
                <textarea
                    className={classes.textarea}
                    value={this.state.short_description}
                    onChange={this.handleChangeShortDescription}
                    data-test-id="describe"
                    placeholder="Short Description"
                   
                />

                <Typography className={classes.longdescription}>Long Description </Typography>
                <Typography className={classes.para}>
                    Enter a long description of your company or product. It will be shown on your listing page.
                </Typography>
                <textarea
                    className={`${classes.textarea} ${classes.textarealongdesc}`}
                    value={this.state.long_description}
                    onChange={this.handleChangeLongDescription}
                    data-test-id="longDescribe"
                    placeholder="Long Description"
                   
                />

                <Typography className={classes.productUrl}>Product URL</Typography>
                <Typography className={classes.urldesc}>
                    Enter a url for the product page. There is a separate field for the Vendor website in the Vendor section below.
                </Typography>

                <Box>
                    <TextField
                        variant="outlined"
                        className={classes.domainAbout}
                        placeholder="Domain"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={this.state.user_domain}
                        data-test-id="domain"
                    />
                </Box>

                <Box>
                    < Typography className={classes.marketheader}>Markets</Typography>

                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={this.state.allcountriesdata}
                        getOptionLabel={(option) => option.country_name}
                        data-test-id={`autocomplete`}
                        value={this.state.marketsData}
                        onChange={this.onAutoCompleteChange}
                        disableClearable={true}

                        // freeSolo
                        ListboxProps={{
                            className: classes.myCustomListAbout
                        }}
                        className={classes.autocompleteAbout}
                        renderOption={(option, { selected }) => (
                            <div style={{ display: "flex", alignItems: "center" }} data-test-id={`checkboxexist`}>
                                <img alt="flag" height={16} width={25} src={option.flag} />
                                <Typography className={classes.flagLabel}>{option.country_name}</Typography>

                            </div>
                        )}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: any, index: number) => (
                                <Chip key={index}
                                    variant="outlined" label={option.country_name}
                                    {...getTagProps({ index })}
                                    className={classes.chipbrandAbout}
                                    style={{ fontSize: '14px', fontFamily: "Poppins" }}
                                    deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />}
                                />
                            ))

                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                data-test-id={`autocompletetextfield`}
                                variant="standard"
                                placeholder={this.state.marketsData.length > 0 ? "" : " Select Markets"}
                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                            />
                        )}
                    />
                </Box>

                <Box className={classes.saveBtnWrapper}>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                    </Box>
                    <Button variant="contained" className={classes.saveBtn} onClick={this.handleSubmitAbout} data-test-id="saveBtn">Save & Publish</Button>
                </Box>

                <CustomToast data-testid={"openSuccessDilog"} open={this.state.isSuccessDialog} onClose={this.handleCloseSuccessDialog} />
                <CustomFormFeedback
                    data-test-id="closefeedback"
                    isFeedback={this.state.isFeedback}
                    handleCloseFeedbackModal={this.handleCloseFeedbackModal}
                    handleSubmitFeedback={this.handleSubmitFeedbackAboutCustom}
                    handleFeedbackEmailChange={this.handleFeedbackEmailChange}
                    handleFeedbackMessageChange={this.handleFeedbackMessageChange}
                    closeIcon={CloseICon} isValidEmail={this.state.isValidEmail}
                />
            </Box>
            // Customizable Area End
        )
    }
}


// Customizable Area Start
export { About }
export default withStyles(styles)(About)
// Customizable Area End
import React from "react";

// Customizable Area Start
import {
    Grid, Typography, Divider, Table, TableBody, TableCell, TextField, TableContainer, TableHead, TableRow, Button, Paper, Box, Dialog
} from "@material-ui/core";
import { styled, withStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import TablePagination from '@material-ui/core/TablePagination';
// Customizable Area End

// Customizable Area Start
import { closeIcon } from './assets';
import PaymentMethodController, {
    Props, configJSON
} from "./PaymentMethodController";
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    ElementsConsumer
} from '@stripe/react-stripe-js';
// Customizable Area End

// Customizable Area Start
const newTextFont: any = {
    color: "#828589",
    fontWeight: 400,
    backgroudColor:"#FAF5FF",
    fontFamily: 'Poppins',
    fontSize: '14px',lineHeight:"21px",
    fontSmoothing: 'antialiased',
}
const stripePromise = loadStripe('pk_test_51Nmze6IePQ7KZBtF4T1TWd9AuveLbIuDY8is1lYOT3plAU7GTLlNOmJYQtZbVHq5tcfvoM7Js8MuBoCqBNCvURI700hqKvZoKX');
// Customizable Area End

export class PaymentMethod extends PaymentMethodController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const { cardNumberErrorText, cardNameErrorText, cvvErrorText, expNumberErrorText, toastErrMsg } = this.state;
        const { passnotmatch, passmatch } = configJSON;
        const errorMappings: { [key: string]: { stateProp: string; toastCheck: string | null } } = {
            cardNumberErrMessage: { stateProp: cardNumberErrorText, toastCheck: passnotmatch },
            cardNameErrMessage: { stateProp: cardNameErrorText, toastCheck: passmatch },
            cvvErrMessage: { stateProp: cvvErrorText, toastCheck: passmatch },
            expNumberErrMessage: { stateProp: expNumberErrorText, toastCheck: null },
        };

        const message: { [key: string]: any }={};
        for (const key in errorMappings) { const { stateProp, toastCheck } = errorMappings[key];
            message[key] = stateProp || (toastCheck && toastErrMsg === configJSON[toastCheck]);
        }
        // Customizable Area End
        return (
            // Customizable Area Start

            <>
                <Grid item xs={12} className={classes.viewListContainer}>
                    <Box className={classes.boxStyle4}>
                        <Typography className={classes.titleListView}>Payment Method</Typography>
                        <Button onClick={() => this.setState({ isOpen: true })} className={classes.addPaymentBtn} data-testid={"add-payment"}>Add New Payment Method <AddCircleOutlineOutlinedIcon className={classes.addPayIcon} /> </Button>                    </Box>

                    <TableContainer component={Paper} className={classes.containerVPM}>
                        <Table stickyHeader className={classes.tableVPM} aria-label="responsive table">
                            <TableHead>
                                <TableRow className={classes.ListTableHeader}>
                                <TableCell align="center" className={classes.tabelHeaderCell}></TableCell>
                                    <TableCell  className={classes.tabelHeaderCell}>Payment Method</TableCell>
                                    <TableCell align="left" className={classes.tabelHeaderCell}>Expires</TableCell>
                                    <TableCell align="center" className={classes.tabelHeaderCell}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.listingBody}>
                                {this.state.paymentMethodData && this.state.paymentMethodData.map((item:any, index:any) => {
                                    return( <TableRow className={classes.Listingrows} key={index}>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="left" component="th" scope="row" className={classes.listinNameRow} >{`${item.brand} ending in ${item.last4}`}</TableCell>
                                            <TableCell align="left" className={classes.listinNameRow}>{`${item.exp_month}/${item.exp_year}`}</TableCell>
                                            <TableCell align="center"  ><DeleteButton className={classes.actionRow} data-testid={"deleteid"} onClick={()=>this.handleDelete(item)} >Delete</DeleteButton></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={this.state.counts}
                        rowsPerPage={5}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Grid>

                <Dialog open={this.state.isOpen} data-test-id="add-payment-modal"
                    PaperProps={{ className: classes.dialogImports }}>
                    <Box style={{ width: '100%' }}>
                        <Box className={classes.ImportTopsHeader}>
                            <Typography className={classes.ImportFontsStyle}>Add Payment Method</Typography>
                            <img
                                src={closeIcon}
                                className={classes.ImportCloseIcon}
                                onClick={this.handleCloseCards}
                                data-testid="closeIcon"
                            />
                        </Box>
                        <Divider
                            style={{ backgroundColor: '#D9D9D9', marginTop: '5px ', marginBottom: '5px' }}
                        />
                        <Elements stripe={stripePromise}  >
                            <ElementsConsumer >
                                {({ elements, stripe }: any) => (
                                    <form onSubmit={(e) => this.handleAddCardToken(e, elements, stripe)} className={classes.formDiv}   >
                                        <>
                                            <Box style={{ padding: '10px 35px 35px 35px' }}>
                                                <Box>
                                                    <LandingPageStyle>
                                                        <CardNumberElement options={{ style: { base: newTextFont }, placeholder: "Card Number" }} />
                                                    </LandingPageStyle>
                                                    <PMtextFieldStyle
                                                        variant="outlined"
                                                        type="text"
                                                        data-testid={"text-cardName"}
                                                        fullWidth
                                                        placeholder={configJSON.textCardName}
                                                        value={this.state.cardName}
                                                        onChange={this.handleChangeCardName}
                                                        className={classes.nameTextfeild}
                                                        error={message.cardNameErrMessage}
                                                        helperText={
                                                            (message.cardNameErrMessage) &&
                                                            <Box className={classes.errBoxStyle}>
                                                                {<ErrorOutlineOutlinedIcon className={classes.errIconStyle} color="error" />}
                                                                <Typography style={{
                                                                    color: "#D53333",
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    fontWeight: 400,
                                                                }}>
                                                                    {message.cardNameErrMessage}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: { input: classes["editNameStyle"] },
                                                        }}
                                                    />
                                                    <Box className={classes.boxStyle4}>
                                                        <LandingPageStyle style={{ width: "209px", height:"50px"}}>
                                                            <CardCvcElement options={{ style: { base: newTextFont } , placeholder: "CVV"}} />
                                                        </LandingPageStyle>
                                                        <LandingPageStyle style={{ width: "209px",height:"50px"}}>
                                                            <CardExpiryElement options={{ style: { base: newTextFont }, placeholder: "Expiry" }} />
                                                        </LandingPageStyle>

                                                    </Box>
                                                    <Box className={classes.boxStyle5}>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.updatesButton}
                                                            data-testid="updateButton"
                                                            type="submit"
                                                        >
                                                            Add Card
                                                        </Button>

                                                        <Button
                                                            variant="contained"
                                                            className={classes.canceledButton}
                                                            data-testid="cancelButton"
                                                            onClick={this.handleCancelCard}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    </form>
                                )}
                            </ElementsConsumer>
                        </Elements>
                    </Box>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const userStyles = () => ({
    ListCompletedCell: {
        color: "#00874D",
        fontSize: "16px",
        fontWeight: 500
    },
    ListPendingCell: {
        color: "#B08800",
        fontSize: "16px",
        fontWeight: 500
    },
    listinNameRow:{fontSize: "16px",fontWeight: 500,color: "#3B3B3B",lineHeight:"14px",fontFamily:"Poppins",},
    actionRow: {
        borderRadius: "8px",fontSize: "14px",fontWeight: 400,fontFamily:"Poppins",
        border: "1px solid #DC2626",height:"35px",width:"95px",
        background: "#FFF",
        color: "#DC2626",
        boxShadow: "none",
        "& :hover": {
            background: "#FFF",
            boxShadow:"none"
        },
        '& .MuiButton-root:hover' :{
            backgroundColor:"none"
        }
    },
    errBoxStyle: {
        display: 'flex',
        marginTop: '2px',
    },
    errIconStyle: {
        fontSize: '18px',
        margin: '3px 10px 0px -10px',
    },
    listingLogoRow: {
        textAlign: "center" as const,
    },
    addPaymentBtn: {
        color: "#B300ED",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        display: "flex",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        background: "#FFF",
        height:"44px",
        width:"303px",
        borderRadius:"8px",
        textTransform:"capitalize" as "capitalize",
        whiteSpace:"nowrap" as "nowrap",
        '&:hover': {
            backgroundColor: "#B300ED",
            color: "#FFF",
        },
        "@media(max-width: 480px)": {
            width: "49%",
            padding: "0",
            fontSize: "14px",
            lineHeight: "18px",          
        }
    },
    addPayIcon:{height:"20px",width:"20px",marginLeft:"5px"},
    ListTableCell: {
        color: "#3B3B3B",
        fontSize: "16px",
        fontWeight: 500,
    },
    ListTableCell1: {
        borderBottom: 0,
        width: 10,
        padding: "16px 0"
    },
    ListTableCellBtn: {
        borderRadius: "8px",
        border: "1px solid #6200EA",
        background: "#FFF",
        textTransform: "capitalize" as const,
        color: "var(--primary-purple-600, #6200EA)",
        fontSize: "14px",
        fontWeight: 400
    },
    ListContainer: {
        background: "#F8F2FF",
    },
    ListTableContainer: {
        margin: "30px",
        width: "auto",
    },
    nameTextfeild: {
        width: "100%",
        borderRadius: "8px",
        backgroundColor: '#FAF5FF',
        marginTop: '15px',fontFamily:"Poppins"
    },
    nameTextfeild2: {
        width: "49%",
        borderRadius: 8,
        backgroundColor: '#FAF5FF',
        marginTop: '15px'
    },
    titleListView: {
        color: "#262424",
        fontSize: "24px",
        fontWeight: 500,
        fontFamily: "Poppins",
        marginBottom:"2.1rem",
        marginLeft:"3rem",
        "@media(max-width: 480px)": {
            width: "49%",
            padding: "0",
            fontSize: "14px",
            lineHeight: "18px",          
        },
    },
    ListTableHeader: {
        height:"58px",
		"& th:first-child": {
			borderTopLeftRadius: "16px",
			borderBottomLeftRadius: "16px",
		},
		"& th:last-child": {
			borderTopRightRadius: "16px",
			borderBottomRightRadius: "16px",
		},
		"& th": {
			backgroundColor: "#B300ED",
			color: "#fff",
			fontSize: "18px",
			fontWeight: 500,
		},
	},
    tabelHeaderCell:{borderBottom:"none",fontSize:"18px",lineHeight:"14px",fontFamily:"Poppins",color:"#FFFFFF"},
    containerVPM: {
        boxShadow:"none",
        borderTopLeftRadius: "16px",
		borderBottomLeftRadius: "16px",
        borderTopRightRadius: "16px",
		borderBottomRightRadius: "16px",
        borderRadius: "0 0 16px 16px",
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        maxHeight: 780,
        marginTop:"15px",
        "&::-webkit-scrollbar": {
            width: "0",
            display: "none",
        },
        "@media(max-width: 480px)": {
            width: "95vw",
            margin: "0 2.5vw",
            marginTop: "2%",
        }
    },
    tableVPM: {
        borderRadius: "16px",
    },
    headVPM: {
        height: "58px",
        flexShrink: 0,
        background: "#B300ED",
        borderRadius: "16px",
        '& .MuiTableCell-head': {
            fontFamily: "Poppins",
            color: "#FFF",
            fontWeight: 500,
            lineHeight: "14px",
            fontSize: "18px",
        },
    },
    dialogImports: {
        overflow: 'hidden',
        width: '500px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
    },
    ImportTopsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight:"20px",
    },
    ImportFontsStyle: {
        fontSize: '22px',
        fontWeight: 600,
        color: '#000000',
        fontFamily: 'Poppins',
        lineHeight:"80.96px"
    },
    ImportCloseIcon: {
        height: '25px',
        width: '25px',
        cursor: 'pointer'
    },
    updatesButton: {
        fontSize: '18px' as const,
        textTransform: 'initial' as const,
        marginTop: '3vw',
        color: '#FFFFFF',
        fontWeight: 400,
        width: '225px',height:"56px",
        backgroundColor: '#B300ED',
        borderRadius: '8px',padding:"16px 71px",whiteSpace:"nowrap"as "nowrap",
        fontFamily: 'Poppins',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    canceledButton: {
        fontSize: '18px' as const,
        width: '200px',height:"55px",
        marginTop: '3vw',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Poppins',
        textTransform: 'initial' as const,
        fontWeight: 400,
        color: '#B300ED',
        border: '1px solid #B300ED',
        borderRadius: '6px',whiteSpace:"nowrap" as "nowrap",
        '&:hover': {
            backgroundColor: "#FFFFFF",
        }
    },
    boxStyle4: {
        display: 'flex',
        justifyContent: 'space-between',
         alignItems: 'center' ,
        gap:"24px",
        "@media(max-width: 480px)": {
            width: "95%",
            padding: "0",
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 2.5%",
        }
    },
    boxStyle5:{
        display:"flex",
        gap:"15px",
    }
});

export const PMtextFieldStyle = withStyles({
    root: {
        fontSize: "14px",
        fontWeight:400,
        "& .MuiInputBase-root":{
            fontFamily:"Poppins",
            color: "#828589",borderRadius: "8px",
            fontSize: "14px",
            fontWeight:400,
        },
        "& .MuiInputBase-input-283:focus": {
            backgroundColor: "transparent",
            outline: 'none',
        },
        "& input::placeholder": {
            fontWeight: 400,
            fontFamily: "Poppins",
            fontSize: "14px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: "#FFFFFF",
            margin: 0,
            paddingLeft: 10,
        },
        "& .MuiOutlinedInput-input": {
            padding: '14px'
        }
    },
})((props: any) => <TextField {...props} />);

export const LandingPageStyle = styled(Box)({
    outline: "none",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "14px",
    color: "#DDDDDD",fontFamily:"Poppins",
    fontWeight: 400,backgroundColor:"#FAF5FF",
    marginTop: "15px",
    '&:hover': {
        border: "1px solid #B300ED",
    },
    '&:active': {
        border: "1px solid #B300ED",
    },
    '&:focus': {
        border: "1px solid #B300ED",
    }

})
export const DeleteButton = styled(Button)({
    '&:hover' : {background:"none"}});


// Customizable Area End

export default withStyles(userStyles)(PaymentMethod);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}
export interface Review {
  id: string
  type: string
  attributes: ReviewAttributes
}

export interface ReviewAttributes {
  id: number
  comment: string
  rating: number
  created_at: string
  updated_at: string
  listing_name: string
  logo_url: string
  name?: string;
  [key: string]: any;
}
export interface SubscriptionFeature {
  name: string;
  active: boolean;
}

export interface SubscriptionAttributes {
  name: string;
  price: string;
  description: string;
  category_name: string;
  subscription_features: SubscriptionFeature[];
}

export interface SubscriptionData {
  id: string;
  type: string;
  attributes: SubscriptionAttributes;
}

export interface ApiResponse {
  data: SubscriptionData[];
}

export interface SelectedPlanProps {
  data: SubscriptionData;
}
export interface UserName {
  full_name: string
  email: string
  user_type: string
  no_of_location: string
  annual_revenue: string
  country: string
  city: string
  company_name: string
  activated: boolean
  role_name: number
  department: number
  type_of_business: number
  profile_image: any
  full_phone_number: string
}

interface S {
  // Customizable Area Start
  userToken: string;
  listingData: any;
  isYearlyPlanSelected: boolean;
  data: any;
  selectedOption: string;
  isOpen: boolean;
  selectedTab: string;
  planDetails: SelectedPlanProps[]
  Username: UserName,
  showDirectoryPopup:boolean,
  searchDirectoryData:any,
  searchTermProp:string,
  isModalOpen: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PlanSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  planSelectionAPICallId: string = "";
  getPlanSelectionId: string = "";
  getResultApiId: string ="";
  planSwitchAPIId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      selectedOption: "import",
      userToken: "",
      isYearlyPlanSelected: false,
      isOpen: false,
      data: null,
      selectedTab: "Supplier",
      planDetails: [],
      listingData: null,
      Username: {
        email: "",
        full_name: "",
        annual_revenue: "",
        user_type: "",
        country: "",
        no_of_location: "",
        city: "",
        activated: false,
        role_name: 0,
        company_name: "",
        department: 0,
        type_of_business: 0,
        profile_image: undefined,
        full_phone_number: "",
       
      },
      showDirectoryPopup:false,
      searchDirectoryData:[],
      searchTermProp:"",
      isModalOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTokenUser();
    this.planSelection()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getTokenUser();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }


  getTokenUser = () => {
    const msgNew: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msgNew);
  };

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestId === this.planSelectionAPICallId) {
        let apiData = responseJson
        if (apiData) {
          const subscriptionPropsArray: SelectedPlanProps[] = apiData.map(
            (subscriptionData: ApiResponse) => ({ data: subscriptionData })
          );
          this.setState({ planDetails: subscriptionPropsArray });
        }
      }
      else if(apiRequestId === this.getResultApiId){
        this.handleDirectorySearchResponse(responseJson)
      }
      else if(apiRequestId===this.planSwitchAPIId){
        if(responseJson && responseJson.success){
          removeStorageData("user_listing_id")
          removeStorageData("listTab")
          this.setState({
            isModalOpen: true
          });
        }
      }


    }
    const listing = await getStorageData("currentListing");
    const listingData = JSON.parse(listing);
    this.setState({ listingData: listingData })
    const currentData = await getStorageData("userdetails");
    const accDetails = JSON.parse(currentData);
    this.setState({ Username: accDetails });
    const myDataImg = await getStorageData("userdetails");
    const newAccData = JSON.parse(myDataImg);
    if (this.state.Username.profile_image !== newAccData.profile_image || this.state.Username.full_name !== newAccData.full_name) {
      this.setState({ Username: newAccData });
    }
  }

  handlePlanChange = (plan: any) => {
    this.setState(prev => ({
      ...prev,
      isYearlyPlanSelected: !prev.isYearlyPlanSelected
    }))
  }


  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.listingData !== this.state.listingData) {
      if (this.state.listingData.selectedTab === "Suppliers") {
        this.setState({ selectedTab: "Supplier" })
      } else if (this.state.listingData.selectedTab === "Software") {
        this.setState({ selectedTab: "Softwares" })
      } else if (this.state.listingData.selectedTab === "Services") {
        this.setState({ selectedTab: "Services" })
      }
    }
  }
  handleTabChange = (tab: string) => {
    this.setState({ selectedTab: tab })
  }
  handleSetDefaultTab = ()=>{
    this.setState({ selectedTab: "Supplier" })
  }

  planSelection = () => {
    const head = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.planSelectionAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.planSelectionAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(head)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSelectPlan = (plan: any, selectedType: any) => {
    const data = {
      ...plan,
      "selectedType": selectedType
    }
  if(plan?.name !== 'BASIC'){
    setStorageData('selectedPlan', JSON.stringify(data));
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PlanDetail");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: data });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }else{
    this.handlePlanSwitch(data)
  }
}
  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  navigatevendorAccount = () => {
    const currentMsg = new Message(getName(MessageEnum.NavigationMessage));
    currentMsg.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    currentMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(currentMsg)
  };

  navigateToHomeScreen = () => {
    const currmes = new Message(getName(MessageEnum.NavigationMessage));
    currmes.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    currmes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(currmes)
  }

  handleUserSignout = async () => {
    await removeStorageData("loginToken")
    this.navigateToHomeScreen()
  }
  

  navigateToVendor = async () => {
    const UserType = await getStorageData("user_type")
    if (UserType == "vendor" || "Vendor") {
      this.navigatevendorAccount()
    }
  }


  handleSubmitSearch=(event:any)=>{
    event.preventDefault();
    this.handleAdvancedSearchNavigation("All")
  }

  handleDirectorySearchResponse=(responseJson:any)=>{
    if(responseJson && responseJson.response){
      this.setState({searchDirectoryData:responseJson.response})
    }
  }


  handleSearchInput = (event:any) =>{  
    this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
    if(!event.target.value) this.setState({showDirectoryPopup:false});
  }



  handleAdvancedSearchNavigation = (directory:string) => {
    this.setState({showDirectoryPopup:false})
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToAdvancedSearch)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
    this.send(msg);

  }

  getSearchResultDirectory = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

  const searchRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

    this.getResultApiId = searchRequestMessage.messageId;
    searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
      );
      searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);

      searchRequestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPIMethod
  );

  runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);


  }
  handleDirectoryPopupClose=()=>{
    this.setState({showDirectoryPopup:false})
  }

  handlePlanSwitch=async(data:any)=>{
    const token=await getStorageData("loginToken")
    const head = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const listTab=await getStorageData("listTab")
    const paymentable_id=await getStorageData("category_id")

    const body={
      data:{
        attributes:{
          subscription_id:data.id,
          "paymentable_type":listTab=="Listings"?"BxBlockCatalogue::Catalogue":"BxBlockCatalogue::UserListing",
          paymentable_id
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.planSwitchAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.planSwitchAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(head)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );



    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  

 

 
  // Customizable Area End
}

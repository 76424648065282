import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

export interface IntegrationData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    integrations: {
                        link: string
                    };
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    handleDataSaved:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    integrationListingData: { link: string };
    updatedIntegrationdata: { link: string };
    isSucess: boolean;
    isContsctUs: boolean;
    isFeedback: boolean;
    integrationList: any;
    selectedIntegratinList: any;
    email: string;
    message: string;
    contactuslink: string;
    feedbackEmail: string;
    feedbackMessage: string;
    isValidEmail: { valid: boolean, message: string };
    uploadFiles: any[];
    isLoadIntegrationData: boolean;
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class IntegrationsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    getIntegrationListAPICalledId: string = "";
    updateIntegrationDataAPICallId: string = "";
    // Customizable Area Start
    getListofIntegrationId: string = "";
    feedbackIntegrationId: string = "";
    ContactIntegrationId: string = "";
    emailReg: RegExp;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            integrationListingData: { link: '' },
            updatedIntegrationdata: { link: '' },
            isSucess: false,
            isContsctUs: false,
            isFeedback: false,
            integrationList: [],
            selectedIntegratinList: [],
            email: "",
            message: "",
            contactuslink: "",
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            uploadFiles: [],
            isLoadIntegrationData: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getIntegrationList();
        this.getListofIntegration()

    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getIntegrationListAPICalledId:
                        this.handleIntegrationLitsResponse(responseJson);
                        break;
                    case this.getListofIntegrationId:
                        const updateddata = responseJson.integrations.map((item: any) => ({ "id": item.id, "name": item.name }))
                        this.setState({ integrationList: updateddata })
                        break;
                    case this.updateIntegrationDataAPICallId:
                        this.handleUpdateIntegrationListResponse(responseJson);
                        break;
                    case this.feedbackIntegrationId:
                        this.setState({ isFeedback: false })
                        break;
                    case this.ContactIntegrationId:
                        this.setState({ isContsctUs: false })
                        break;
                }
            }
        }
    }


    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
             this.props.handleBack()
        }
    }


    handleChangeLink = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedIntegrationdata: { ...this.state.updatedIntegrationdata, link: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: event.target.value })
    }

    handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ message: event.target.value })
    }

    handleChangeContactuslink = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ contactuslink: event.target.value })
    }

    onFileUploadIntegration = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ uploadFiles: [file] });
            };
            reader.readAsDataURL(file);
        }
    };

    handleContactUsModal = () => {
        this.setState({ isContsctUs: true })
    }

    handleCloseContactModal = () => {
        this.setState({ isContsctUs: false })
    }

    handleFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleCloseSuccess = () => {
        this.setState({ isSucess: false })
    }

    getIntegrationList = async () => {
        this.setState({ isLoadIntegrationData: true })
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getIntegrationListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    getListofIntegration = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getListofIntegrationId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.integrationapi}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    updateIntegrationListingData = async () => {
        this.props.handleDataSaved();
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();

        if (this.state.selectedIntegratinList) {
            this.state.selectedIntegratinList.forEach((item: any) => {
                formdata.append("integration_ids[]", item.id);

            })
        }

        formdata.append("integration_link", this.state.updatedIntegrationdata?.link);


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateIntegrationDataAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSubmitFeedbackIntegration = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailIntegration(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackIntegrationId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleSubmitContactUsIntegration = async (event: any) => {
        let formdata = new FormData();
        if (!this.state.email) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailIntegration(this.state.email)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.email);
        formdata.append("message", this.state.message);
        formdata.append("link", this.state.contactuslink);

        this.state.uploadFiles.forEach((item) => {
            formdata.append("files", item, item.name);
        })

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ContactIntegrationId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.contactusapi}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // API Response
    handleIntegrationLitsResponse = (responseJson: any) => {
        const integrationautocompletedata = responseJson.data.catalogue.data.attributes.integrations.data
        if (responseJson) {
            this.setState({ isLoadIntegrationData: false })
        }
        if (integrationautocompletedata) {
            const updateddata = integrationautocompletedata?.map((item: any) => ({ "id": item.attributes.id, "name": item.attributes.name }))
            this.setState({ selectedIntegratinList: updateddata })
        }
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
        const link = responseJson.data.catalogue.data.attributes.integration_link
        if (link) {
            this.setState({ updatedIntegrationdata: { link: link } })
        }
    }

    handleUpdateIntegrationListResponse = (responseJson: []) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucess: true })
            this.getIntegrationList()
        }
    }

    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ selectedIntegratinList: newValue })
        this.props.handleDataChanged()
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailIntegration = (email: string) => {

        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }
    // Customizable Area End
}

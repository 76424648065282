import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";

import { getStorageData } from "../../../framework/src/Utilities";

export interface SupplierBrandData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    progress_percentage: number,
                    is_it_brand: boolean,
                    brands: {
                        data: []
                    }
                };
            };
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    openSaveModal:boolean;
    handleBack:()=>void;
    
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    selectedOption: string;
    brandListData: any[];
    selectedBrandList: any[];
    selectedBrandListValue: any[];
    updatedSuccessDialog: boolean;
    isFeedback: boolean,
    feedbackEmail: string,
    feedbackMessage: string,
    forbrandToggle: boolean,
    forinitialRenderData: any,
    isValidEmail: { valid: boolean, message: string },
    loading: boolean,
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierBrandsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBrandId: string = "";
    formSubmitBrandid: string = "";
    getCustomFormDetailBrandid: string = "";
    feedbackSupbrandId: string = "";
    emailReg: RegExp;


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            selectedOption: 'yes',
            brandListData: [],
            selectedBrandList: [],
            selectedBrandListValue: [],
            updatedSuccessDialog: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",

            forbrandToggle: false,
            forinitialRenderData: [],
            isValidEmail: { valid: false, message: "" },
            loading: false,
            progressPercentage: 0

            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestBrandId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responsebrand = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestBrandId === this.getBrandId) {
                const newdata = responsebrand.yes.concat(responsebrand.No)
                if (newdata) {
                    let updateddata = newdata?.map((item: any) => {
                        return {
                            item: item.id, name: item.name, is_it_brand: item.is_it_brand
                        }
                    })
                    this.setState({ brandListData: updateddata })
                    this.handleOptionFilterLogic(this.state.selectedOption)
                }
            }

            if (apiRequestBrandId === this.formSubmitBrandid) {
                this.setState({ updatedSuccessDialog: true })
            }

            if (apiRequestBrandId === this.getCustomFormDetailBrandid) {
                this.handleBarndDataResponse(responsebrand)
            }

            if (apiRequestBrandId === this.feedbackSupbrandId) {
                this.setState({ isFeedback: false })
            }
        }

        // Customizable Area End
    }

    // Web Events


    handleSubmit = async () => {
        this.props.handleDataSaved()
        const categoryID = await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const is_it_brand_boolean = this.state.selectedOption == "yes" ? "true" : "false";
        formdata.append("is_it_brand", is_it_brand_boolean);

        if(this.state.selectedBrandListValue.length){
            this.state.selectedBrandListValue.forEach((item) => {
                formdata.append("brand_ids[]", item.item);
            })
        }
        else{
            formdata.append("brand_ids[]","");
        }
        

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmitBrandid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSubmitFeedbackBrand = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailBrand(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackSupbrandId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFormDetailBrand = async () => {
        this.setState({ loading: true })
        const categoryid = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailBrandid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getBrandList()
        this.getFormDetailBrand()
    }

    handleBrandsUpdate = () => {
      const fromapiresponseID = Array.isArray(this.state.forinitialRenderData)
            ? this.state.forinitialRenderData.map((item: any) => +item.id)
            : [];
    
        if (fromapiresponseID.length > 0) {
            const filteredData = Array.isArray(this.state.selectedBrandList)
                ? this.state.selectedBrandList.filter(item => fromapiresponseID.includes(item.item))
                : [];
    
            this.setState({ selectedBrandListValue: filteredData, loading: false });
        }
    };
    

    getBrandList = async () => {
        this.setState({ loading: true })

        const categoryid = await getStorageData("category_id");

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBrandId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryid}/brand_list`

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleRadioChange = (value: string) => {
        this.setState({
            selectedOption: value,
        });
        this.handleOptionFilterLogic(value)
    };

    handleOptionFilterLogic = (value: string) => {
        let updatedData;
      if (value === "yes") {
            updatedData = this.state.brandListData
                .filter((item: any) => item.hasOwnProperty('is_it_brand') && item.is_it_brand === true) // Ensure only branded items are included
                .reduce((uniqueItems: any[], item: any) => {
                   if (!uniqueItems.some(existingItem => existingItem.item === item.item)) {
                        uniqueItems.push(item);
                    }
                    return uniqueItems;
                }, []);
        } else {
            updatedData = this.state.brandListData
                .filter((item: any) => !item.hasOwnProperty('is_it_brand') || item.is_it_brand === false) // Filter for non-branded or undefined
                .reduce((uniqueItems: any[], item: any) => {
                   if (!uniqueItems.some(existingItem => existingItem.item === item.item)) {
                        uniqueItems.push(item);
                    }
                    return uniqueItems;
                }, []);
        }
    
     this.setState({ selectedBrandList: updatedData });
    }
    
    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ selectedBrandListValue: newValue })
        this.props.handleDataChanged()
    }

    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleFeedbackEmailChangeBrand = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChangeBrand = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailBrand = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }

    handleBarndDataResponse = (responseJson: SupplierBrandData) => {
        const isitBrand = responseJson.data.catalogue.data.attributes.is_it_brand;
        const is_it_brand_boolean = isitBrand === true ? "yes" : "no";
        this.handleRadioChange(is_it_brand_boolean);
        let branddata = responseJson.data.catalogue.data.attributes.brands
        this.setState({ loading: false, forbrandToggle: true, forinitialRenderData: branddata }, () => this.handleBrandsUpdate())
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }

    gotoVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else this.props.handleBack();
    }

    // Customizable Area End
}
import React, { Component } from "react";
import { Avatar, Box, Button, Dialog, OutlinedInput, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { closeIcon } from "../../blocks/landingpage/src/assets";
import CustomSaveButton from "./CustomSaveButton.web"

type MyProps = { goToVendorAccount?: () => void; onClose?:()=>void, classes: Record<string, string>; icon: string; opemModal: boolean; buttonLabel: string };

export class CustomSucessDialogBox extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, icon, opemModal, goToVendorAccount,onClose, buttonLabel }: MyProps = this.props;
        return (
            <Dialog
                open={opemModal}
                onClose={onClose}
                PaperProps={{ className: classes.dialogStyle }}
                data-testId={"openModal"}
                maxWidth="md"
            >
                <Box className={classes.dialogBoxStyle_3}>
                    <img
                        src={icon}
                        className={classes.rightTickIcon_3}
                    />
                    <Typography className={classes.welcomeFontStyle_3}>{"Details Updated Successfully"}</Typography>
                    <Box className={classes.buttonBoxStyle}>
                        <Button
                            variant="contained"
                            className={classes.acceptBtn}
                            data-testid={"save_button"}
                            fullWidth
                            onClick={goToVendorAccount}
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
    }
}

export const userStyles = () => ({
    dialogStyle: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '500px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        marginTop: '5%',
        padding: '10px',
    },

    dialogBoxStyle_3: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        paddingTop: '30px'
    },

    rightTickIcon_3: {
        height: '65px',
        width: '65px',
    },

    buttonBoxStyle: {
        width: '70%',
        marginBottom: '30px'
    },

    welcomeFontStyle_3: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    acceptBtn: {
        backgroundColor: "#B300ED",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        padding: '10px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

});

export default withStyles(userStyles)(CustomSucessDialogBox)
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.paymentHistoryTitle = "Payment History"
exports.detailsAPIEndPoint = "/account_block/accounts/show_profile";
exports.roleAPIEndPoint = "bx_block_roles_permissions/account_details/:account_detail_id/view_role";
exports.departmentAPIEndPoint = "/account_block/departments";
exports.companyAPIEndPoint = "/account_block/type_of_business";
exports.updateDetailsAPIEndPoint = "/update_survey";
exports.countryListAPIEndPoint = "/account_block/accounts/:account_id/show_country";
exports.changePasswordAPIEndPoint = "/bx_block_forgot_password/change_password";
exports.updateProfileUrl = "account_block/accounts/update_profile";
exports.planSelectionAPIEndPoint = "bx_block_custom_user_subs/subscriptions";
exports.couponCodeAPIEndPoint = "/bx_block_coupon_cg/coupon_code_amount";
exports.taxValueAPIEndPoint = "/bx_block_coupon_cg/taxes";
exports.putMethod = "PUT";
exports.getAPIMethod = "GET";
exports.postMethod = "POST";
exports.welcomeText = "Welcome Back";
exports.nameText = "Tony";
exports.welcomeName = "Welcome Tony";
exports.vendorAccount = "Vendor account";
exports.uploadPicture = "Upload new picture";
exports.personalDetails = "Personal Details";
exports.resetPass = "Reset Password";
exports.updateDetails = "Update Details";
exports.roleLevel = "Role Level";
exports.department = "Department";
exports.buisnessType = "Type of Business";
exports.noLocations = "Number of Locations";
exports.back = "Back to accounts";
exports.sucessMessage = "Your account details have been successfully updated";
exports.updateBtn = "Update";
exports.cancelBtn = "Cancel";
exports.nameLable = 'Name';
exports.phoneNumber = "Telephone Number";
exports.userRole = "Role Level";
exports.company = "Company";
exports.locations = "Number Locations";
exports.emailLable = "Email";
exports.homelocation = "Home Location";
exports.departments = "Department";
exports.revenue = "Average annual revenue per property";
exports.number = "Phone Number";
exports.companyName = "Company Name";
exports.oldPass = "Old Password";
exports.newPass = "New Password";
exports.reEnterNewPass = "Re-enter new password";
exports.passnotmatch = "Your password doesn't match";
exports.passformate = "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character";
exports.passRequire = "Password is required";
exports.passmatch = "Password matched";
exports.validSpacePass = "Your password can't start or end with a blank space";
exports.passmatch =  "Password matched";
exports.getReviewSubmitted =  "bx_block_catalogue/reviews";
exports.updateReviewSubmitted = "bx_block_catalogue/review_replies";
exports.textCardNumber = "Card Number";
exports.textCardName = "Name on Card";
exports.textCvv = "CVV";
exports.textExpiry = "Expiry";
exports.getListVendor =  "bx_block_catalogue/user_listings";
exports.postCreatecard =  "bx_block_stripe_integration/payment_methods/create_customer_card";
exports.getCardDetail =  "bx_block_stripe_integration/cardinformations";
exports.postPayment= "bx_block_stripe_integration/payment_methods/create_payments";
exports.addCards= "bx_block_stripe_integration/cardinformations";
exports.downloadCSV= "bx_block_catalogue/download_cat_excel";
exports.bulkUpload= "bx_block_catalogue/import_suppliers";
exports.getClaimListingApi =  "bx_block_catalogue/show_claim_listing";
exports.postCardApi= "account_block/add_payment_method";
exports.paymentHistory= "bx_block_stripe_integration/payment_history"
exports.paymentContentManagement= "bx_block_content_management/add_on_data"
exports.httpDeleteMethod = "DELETE";
exports.cadrDeleteEndpoint = "bx_block_stripe_integration/cardinformations/";
exports.venderOldErrText = "password is incorrect";




exports.getSearchResultEndPoint="/bx_block_catalogue/search_listing"
exports.planSwitchAPIEndPoint="/bx_block_stripe_integration/downgrade_basic_plain"
exports.putAPIMethod="PUT"


// Customizable Area End

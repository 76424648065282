//@ts-nocheck
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleBack:()=>void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleDataSaved:()=>void;
    handleBack:()=>void;
    handleOpenSaveModal:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    isFeedback: boolean;
    videos: any[],
    videoType: string;
    url: string;
    updatedSuccessDialog: boolean;
    feedbackEmail: string;
    feedbackMessage: string;
    isValidEmail: { valid: boolean, message: string };
    isLoadVideoData: boolean;
    progressPercentage: number;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VideosPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getVideosDataAPICalledId: string = "";
    formSubmit_id: string = "";
    getCustomform_DetailId: string = "";
    feedbackVideosId: string = "";
    emailReg: RegExp;
    deleteVideoApiId :string ="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIRequestMessage),

        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            isFeedback: false,
            videos: [{ video_type: 'Youtube', url: "" }],
            videoType: 'Youtube',
            url: "",
            updatedSuccessDialog: false,
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            isLoadVideoData: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestId === this.getCustomform_DetailId) {
                let videos = response.data.catalogue.data.attributes.videos
                if (response) {
                    this.setState({ isLoadVideoData: false })
                }

                this.setState({ videos })
                if (response.data.catalogue.data.attributes.progress_percentage) {
                    this.setState({ progressPercentage: response.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
                }
            }

            if (apiRequestId === this.formSubmit_id) {
                this.setState({ updatedSuccessDialog: true })
            }

            if (apiRequestId === this.feedbackVideosId) {
                this.setState({ isFeedback: false })
            }
            if(apiRequestId===this.deleteVideoApiId){
                this.setState({ isLoadVideoData: false })
            }
        }

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getCustomForm()
    }

    getCustomForm = async () => {
        this.setState({ isLoadVideoData: true })
        const categoryID = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomform_DetailId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleSubmit = async () => {
        this.props.handleDataSaved()
        const categoryID = await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        this.state.videos.forEach((item,index) => {
            if (item.video_type == "youtube") {
                formdata.append(`videos_attributes[${index}][youtube]`, item.url);
            } else {
                formdata.append(`videos_attributes[${index}][vimeo]`, item.url);
            }
        })


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmit_id = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    handleSubmitFeedbackVideo = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailPage(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackVideosId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
          this.props.handleBack();
        }
    }
    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }


    addVideo = () => {
        this.setState({ videos: [...this.state.videos, { video_type: 'youtube', url: "" }] })
    }

    removeItem = (value: number,item:any) => {
        let updatedCategory = this.state.videos.filter((item, index) => {
            return index !== value
        })
        this.setState({ videos: updatedCategory })
        if(item.id){
            this.handleVideosDeleteApiCall(item.id)
        }
    }

    handleSelectDynamic = (event: any, index: number) => {
        let updated = this.state.videos.map((item, itemIndex) => {
            if (itemIndex == index) {
                return { ...item, video_type: event.target.value }
            }
            return item
        })
        this.setState({ videos: updated })
        this.props.handleDataChanged()
    }

    handleChangeUrlDynamic = (event: any, index: number) => {
        let updatedData = this.state.videos.map((item, itemIndex) => {
            if (itemIndex == index) {
                return { ...item, url: event.target.value }
            }
            return item
        })
        this.setState({ videos: updatedData })
        this.props.handleDataChanged();
    }

    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailPage = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }

    handleVideosDeleteApiCall = async (video_id:number) => {
        this.setState({ isLoadVideoData: false })
        const categoryid = await getStorageData("category_id");
        const header = {
            // "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const requestMessageDeleteApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const formdata=new FormData()
        formdata.append("id",categoryid)
        formdata.append("video_id",video_id)

        this.deleteVideoApiId = requestMessageDeleteApi.messageId;

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.videoDeleteApiEndPoint
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );

        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
        return true;
    };

    // Customizable Area End
}
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { accountIcon, listIcon, payment, paymentIcon, reviewsIcon, signoutIcon } from './assets';

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export const drawerWidth = 265;

export const mockListData = [
    { id: 1, text: "Account Details", icon: accountIcon },
    { id: 2, text: "Listings", icon: listIcon },
    { id: 3, text: "Payment History", icon: payment },
    { id: 4, text: "Payment Method", icon: paymentIcon },
    { id: 5, text: "Reviews Submitted", icon: reviewsIcon },
    { id: 6, text: "Sign out", icon: signoutIcon },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    anchorEl: any,
    Username: any,
    mobileMoreAnchorEl: any,
    selectedOption: string | null;
    vendorAccountDetails: any;
    mobileOpen: boolean,
    isLoader: boolean;
    selectedId:number;
    searchDirectoryData:any,
    showDirectoryPopup:boolean,
    searchTermProp:string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VendorAccountDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAccountDetailsAPIId: string = "";
    getDirectoryResultApiId: string= "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            anchorEl: null,
            mobileMoreAnchorEl: null,
            selectedOption: mockListData[0].text,
            vendorAccountDetails: null,
            Username: null,
            selectedId:1,
            mobileOpen: false,
            isLoader: true,
            searchDirectoryData:[],
            showDirectoryPopup:false,
            searchTermProp:""
            // Customizable Area End
        };

        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestId === this.getAccountDetailsAPIId) {
                if(responseJson && responseJson.data){
                    this.setState({ vendorAccountDetails: responseJson?.data?.attributes });
                }
                else{
                    this.handleUserSignout();
                    
                    
                }
               
            }
            else if(apiRequestId === this.getDirectoryResultApiId){
                this.handleDirectorySearchResponse(responseJson)
              }
        }
        const storedData = await getStorageData("userdetails");
        const userType = await getStorageData("user_type");
        const buyerRoutesUrls = [
            "/VendorAccountDetails",
            "/joinus",
            '/JoinUsSubscription',
            '/SearchListing',
            "/ListingCheckOutPage",
            "/PlanDetail",
            "/PlanSelection",
        ];
        if (storedData && userType === "Buyer" && buyerRoutesUrls.some(route => window.location.pathname.startsWith(route))) {
            window.location.href = "/BuyerAccountDetails";
        }
        const accountDetails = JSON.parse(storedData);
        this.setState({ Username: accountDetails });
        setTimeout(() => {
            this.handleLoader()
        }, 1000);
        const newData = await getStorageData("userdetails");
        const newAccountData = JSON.parse(newData);
        if (this.state.Username.profile_image !== newAccountData.profile_image || this.state.Username.full_name !== newAccountData.full_name) {
            this.setState({ Username: newAccountData });
        }
    
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getAccountDetails();
    }

    getAccountDetails = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAccountDetailsAPIId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.detailsAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Web Events

    handleOptionSelect = (text: string) => {
        if (text === "Sign out") {
            localStorage.clear();
            sessionStorage.clear();
            const loginMessage = new Message(getName(MessageEnum.NavigationMessage));
            loginMessage.addData(getName(MessageEnum.NavigationTargetMessage), 'Home');
            loginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
            raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: null });
            loginMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(loginMessage);
        }
        this.setState({ selectedOption: text });

        if (this.state.mobileOpen) {
            this.handleDrawerToggle()
        }
    };

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    };

    navigatevendorAccount = () => {
        const newMessage = new Message(getName(MessageEnum.NavigationMessage));
        newMessage.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        newMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(newMessage)
    }

    navigateToHomeScreen = () => {
        const newMessage = new Message(getName(MessageEnum.NavigationMessage));
        newMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        newMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(newMessage)
    }

    navigateToVendor = async () => {
        const curtype = await getStorageData("user_type")
        if (curtype == "vendor" || "Vendor") {
            this.navigatevendorAccount()
        }
    }

    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
    }

    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.navigateToHomeScreen()
    }

    handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
    }
    handleLoader = () => {
        this.setState({ isLoader: false })
    }
    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
    }

      getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getDirectoryResultApiId = searchRequestMessage.messageId;
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
      );
    
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
    
    
      }  

    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }  
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
      handleClick = (id:any) => {
        this.setState({ selectedId: id });
      };
    // Customizable Area End
}

import React from "react";

// Customizable Area Start
import {
    Box,
    Avatar,
    Grid,
    Button,
    Typography,
    Divider,
    TextField,
    Dialog,
    Slider,
    Tooltip,
    MenuItem,
    FormControl,
    Select,
    Popover,
    InputAdornment,
    InputLabel,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { personImage1, editIcon, rightCheck, closeIcon } from './assets';
import ReactCountryFlag from 'react-country-flag';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

// Customizable Area Start
import VenderProfileController, {
    Props, UserRole, configJSON, locationMockData, Department
} from "./VenderProfileController";

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FAF5FF",
        color: 'black',
        display: "flex",
        justifyContent: "center",
        alignItems: "center", fontSize: "14px", lineHeight: "21px", fontWeight: 400, fontFamily: "Poppins", height: "48px", maxWidth: "81px",
        [theme.breakpoints.down('md')]: {
            backgroundColor: "#FAF5FF",
        },
    },
}))(Tooltip);

export const ValueLabelComponent = (props: any) => {
    const { children, open, value } = props;
    const formattedValue = value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    });

    return (
        <CustomTooltip open={open} interactive enterTouchDelay={0} title={formattedValue} placement="top">
            {children}
        </CustomTooltip>
    );
};

// Customizable Area End
export class VenderProfile extends VenderProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { pswdErrorText, toastErrMsg, oldPassErrorText, confirmPassSucessText } = this.state;
        const { passnotmatch, passmatch } = configJSON;
        const fullPhoneNumber = this.state.accountDetails?.full_phone_number;
        const formattedPhoneNumber = `+${fullPhoneNumber}`;
        const formattedValue=Number(this.state.accountDetails?.annual_revenue)?.toLocaleString();
        const annualRevenuereadonly = this.state.accountDetails?.annual_revenue ? `$${formattedValue}` : "";
        const errorMappings: { [key: string]: { stateProp: string; toastCheck: string | null } } = {
            passErrMessage: { stateProp: pswdErrorText, toastCheck: passnotmatch },
            oldPassMessage: { stateProp: oldPassErrorText, toastCheck: passnotmatch },
            confirmPasswordMsg: { stateProp: confirmPassSucessText, toastCheck: passmatch },
            passworMessage: { stateProp: pswdErrorText, toastCheck: null },
        };

        const message: { [key: string]: any } = {};
        for (const key in errorMappings) {
            const { stateProp, toastCheck } = errorMappings[key];
            message[key] = stateProp || (toastCheck && toastErrMsg === configJSON[toastCheck]);
        }

        const getValueOrDefault = (property: string, defaultValue = '') => this.state.accountDetails?.[property] || defaultValue;
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                {this.state.isLoader ?
                    <Backdrop className={classes.backdrop} open={this.state.isLoader}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Grid container className={classes.containerStyle}>
                        <Grid item xs={12} md={4} lg={4} >
                            <Box className={classes.sidebarTop}>
                                <Avatar
                                    alt="Profile Picture"
                                    src={getValueOrDefault('profile_image', personImage1)}
                                    // src={this.  .accountDetails?.profile_image ? this.state.accountDetails?.profile_image : personImage1}
                                    className={classes.sidebarAvatar}
                                />
                                <Box>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        onChange={this.onProfileChange}
                                        data-testid={"inputref"}
                                        id="upload-photo"
                                    />
                                    <label className={classes.uploadButton} data-testid={"uploadbtn"} style={{textAlign:'center'}} htmlFor="upload-photo">{configJSON.uploadPicture}</label>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={8} lg={8} className={classes.divgrid}>
                            <Box className={classes.detailsBox}>
                                <Box className={classes.boxStyle_main}>
                                    <Typography className={classes.personalDetailsText}>{configJSON.personalDetails}</Typography>
                                    <img src={editIcon} className={classes.editicon_img} onClick={this.handleOpenDetails} data-testid={"editIcon"} />
                                </Box>
                                <Divider style={{ backgroundColor: '#D9D9D9' }} />
                                <Box className={classes.boxStyle2} >
                                    <Box className={classes.boxStyle2Content}>
                                        <Box>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.nameLable}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('full_name')}</Typography>
                                        </Box>

                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.phoneNumber}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{this.handleFormatNum(formattedPhoneNumber)}</Typography>
                                        </Box>
                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.userRole}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('role')}</Typography>
                                        </Box>
                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.company}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('company_name')}</Typography>
                                        </Box>

                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.locations}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('no_of_location')}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.boxStyle2Content}>
                                        <Box>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.emailLable}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('email')}</Typography>
                                        </Box>

                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.homelocation}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('city')}</Typography>
                                        </Box>
                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.departments}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('department_name')}</Typography>
                                        </Box>
                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.buisnessType}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>{getValueOrDefault('business_name')}</Typography>
                                            
                                        </Box>
                                        <Box className={classes.labelBox}>
                                            <InputLabel className={`${classes.labelTextStyle} custom-label`}>{configJSON.revenue}</InputLabel>
                                            <Typography className={classes.labelTypoStyle}>
                                            {annualRevenuereadonly}
                                            </Typography>
                                        </Box>
                                    </Box>

                                </Box>
                            </Box>
                            <Button variant="contained" className={classes.resetButton} data-testid={"resetBtnPassword"} onClick={this.handleResetPassword}>{configJSON.resetPass}</Button>
                        </Grid>
                    </Grid >
                }

                <Dialog
                    open={this.state.openDetailsModal}
                    onClose={this.handleCloseDetailsModal}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyle }}
                    data-testid={"openmodal"}
                >
                    <Box className={classes.BoxScrollbar}>
                        <Box className={classes.IconDiv} >
                            <Typography className={classes.textStyle}>{configJSON.updateDetails}</Typography>
                            <CloseIcon className={classes.icon} onClick={this.handleCloseDetailsModal} />
                        </Box>


                        <Box className={classes.boxStyle3}>
                            <Box className={classes.boxstyle3Textfield}>
                                <StyledTextField
                                    variant="outlined"
                                    placeholder={configJSON.name}
                                    data-testid={"changename"}
                                    fullWidth
                                    value={this.state.userDetails?.full_name}
                                    onChange={this.handleChangeName}
                                    className={classes.nameTextfeild}
                                />
                                <StyledTextField
                                    variant="outlined"
                                    data-testid={"changeEmail"}
                                    placeholder={configJSON.email}
                                    fullWidth
                                    value={this.state.userDetails?.email}
                                    onChange={this.handleChangeEmail}
                                    className={classes.nameTextfeild}
                                />
                                <Box>
                                    <StyledTextField
                                        variant="outlined"
                                        data-testid={"chaneNumber"}
                                        placeholder={configJSON.number}
                                        fullWidth
                                        value={this.state.userDetails?.fullNumber}
                                        onChange={this.handleChangePhoneNumber}
                                        className={classes.nameTextfeild}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" onClick={this.handleOpenPopover}>
                                                    <Box className={classes.inputPropsStyle}>
                                                        {this.state.userDetails?.countryCode}<ExpandMoreIcon className={classes.expandIcon} />
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Popover
                                        open={Boolean(this.state.openCountryCodeList)}
                                        anchorEl={this.state.openCountryCodeList}
                                        data-testid="popover-content"
                                        onClose={this.onClosePopOver}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}
                                        style={{ left: '6%', top: '25px' }}
                                    >
                                        <Box className={classes.ContentWithPadding}>
                                            <Box className={classes.CountryCodeScrollbar}>
                                                {this.state.counrtyCodeList.map((countryCode: any, index: number) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={() => this.handleCountryCodeChange(countryCode?.country_code)}
                                                        className={classes.countryFontStyle}
                                                        data-testid={`menu-item-${index}`}
                                                    >
                                                        <ReactCountryFlag svg
                                                            countryCode={countryCode?.short_country_name}
                                                            style={{ marginRight: '15px', height: '16px', width: '25px' }}
                                                        />
                                                        {`${countryCode?.country_name}  (+${countryCode?.country_code})`}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Box>

                                    </Popover>
                                </Box>
                                <StyledTextField
                                    variant="outlined"
                                    data-testid={"changeHomeLocation"}
                                    placeholder={configJSON.homelocation}
                                    fullWidth
                                    value={this.state.userDetails?.city}
                                    onChange={this.handleChangeHomeLocation}
                                    className={classes.nameTextfeild}
                                />
                                <Typography className={classes.labelText}> {configJSON.roleLevel}</Typography>

                                <FormControl variant="outlined" className={classes.formcontrol}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.userDetails?.role_name ?? "none"}
                                        onChange={this.handleChangeSelectRole}
                                        data-testid={"changeRole"}
                                        fullWidth
                                        className={this.handleDepartVen(classes.placeTextH, classes.blackTextH)}
                                    >
                                        <MenuItem value="none" style={{ display: "none" }}>Select</MenuItem>
                                        {
                                            this.state.userRole?.map((item: UserRole) => (
                                                <MenuItem value={item.id} key={item.id} className={classes.selectText}>{item.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>

                                <Typography className={classes.labelText}>
                                    {configJSON.department}
                                </Typography>
                                <FormControl variant="outlined" className={classes.formcontrol}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.userDetails?.department ?? "none"}
                                        onChange={this.handleChangeDepartment}
                                        data-testid={"changeDepartment"}
                                        fullWidth
                                        className={this.handleSelectClass(classes.placeTextH, classes.blackTextH)}
                                    >
                                        <MenuItem value="none" style={{ display: "none" }}>Select</MenuItem>
                                        {
                                            this.state.userDepartment?.map((item: any) => (
                                                <MenuItem value={item.id} key={item.id} className={classes.selectText}>{item?.attributes?.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>

                                <Box className={classes.buttonBoxStyleDesktop}>
                                    <Button variant="contained"
                                        className={classes.updateButton}
                                        onClick={this.updateAccountDetails}
                                        data-testid={"updateButton"}
                                    >{configJSON.updateBtn}</Button>

                                    <Button variant="contained"
                                        className={classes.cancelButton}
                                        onClick={this.handleCancelButton}
                                        data-testid={"cancelButton"}
                                    >{configJSON.cancelBtn}</Button>
                                </Box>
                            </Box>

                            <Box className={classes.boxstyle3Textfield}>
                                <StyledTextField
                                    variant="outlined"
                                    data-testid={"changeCompany"}
                                    placeholder={configJSON.companyName}
                                    fullWidth
                                    value={this.state.userDetails?.company_name}
                                    onChange={this.handleChangeCompanyName}
                                    className={classes.nameTextfeild}
                                />

                                <Typography className={classes.labelText}>
                                    {configJSON.buisnessType}
                                </Typography>
                                <FormControl variant="outlined" className={classes.formcontrol}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.userDetails?.type_of_business ?? "none"}
                                        onChange={this.handleChangeBusinessType}
                                        data-testid={"changeBuisness"}
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                              style: {
                                                maxHeight: 200,
                                                width: 250,
                                              },
                                            },
                                          }}
                                        className={this.handleBusinessType(classes.placeTextH, classes.blackTextH)}
                                    >
                                        <MenuItem value="none" style={{ display: "none" }}>Select</MenuItem>
                                        {
                                            this.state.companyDetails?.map((item: any) => (
                                                <MenuItem value={item.id} key={item.id} className={classes.selectText}>{item?.attributes?.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>

                                <Typography className={classes.labelText}>
                                    {configJSON.noLocations}
                                </Typography>
                                <Box className={classes.roleButtonGroup}>
                                    {locationMockData.map((item, index: number) => {
                                        return <Button key={index}
                                            // className={`${classes.roleButton} ${this.state.userDetails?.no_of_location === item && classes.selected}`}
                                            className={this.handleNumberLocationButton(classes.roleButton, classes.selected, item)}
                                            data-testid={"noOflocation"}
                                            onClick={() => this.handleLocation(item)}
                                        >
                                            <Typography className={classes.roleButtonText}>{item}</Typography>
                                        </Button>
                                    })}
                                </Box>

                                <Slider
                                    data-testid={"annual-revenue"}
                                    className={classes.slider}
                                    aria-label="Always visible"
                                    min={0}
                                    max={20000000}
                                    step={2000}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '$0'
                                        },
                                        {
                                            value: 20000000,
                                            label: '$20,000,000'
                                        },
                                    ]}
                                    ValueLabelComponent={ValueLabelComponent}
                                    value={this.state.userDetails?.annual_revenue}
                                    onChange={this.onChangeAnnualRevenue}
                                    valueLabelDisplay="on"
                                />

                                <Box className={classes.buttonBoxStyleMobile}>
                                    <Button variant="contained"
                                        className={classes.updateButton}
                                        onClick={this.updateAccountDetails}
                                        data-testid={"updateButton"}
                                    >{configJSON.updateBtn}</Button>

                                    <Button variant="contained"
                                        className={classes.cancelButton}
                                        onClick={this.handleCancelButton}
                                        data-testid={"cancelButton"}
                                    >{configJSON.cancelBtn}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Dialog >

                <Dialog
                    open={this.state.openUpdateSucessModal}
                    data-testid={"openSucessModal"}
                    PaperProps={{ className: classes.dialogStyle2 }}
                >
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} className={classes.closeIconGridStyle}>
                            <img src={closeIcon}
                                className={classes.closeIconStyle}
                                onClick={this.handleCloseIcon}
                                data-testid={"closeIcon"}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.textGridStyle}>
                            <img src={rightCheck} className={classes.imageStyle} />
                        </Grid>

                        <Grid item xs={12} className={classes.textGridStyle}>
                            <Typography className={classes.textFontStyle}>{configJSON.sucessMessage}</Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.textGridStyle}>
                            <Button
                                fullWidth variant="contained"
                                className={classes.buttonBackground}
                                onClick={this.handleCloseIcon}
                                data-testid={"navigateToLogin"} >{"Back to accounts"}</Button>
                        </Grid>
                    </Grid>

                </Dialog>

                <Dialog
                    open={this.state.isResetPassword}
                    data-testid={"openSucessModal"}
                    // onClose={() => this.setState({ isResetPassword: false })}
                    PaperProps={{ className: classes.dialogStyle2 }}
                >

                    <Box style={{ width: '100%' }}>
                        <Box className={classes.resetPassStyle}>
                            <Typography className={classes.fontStyle}>{configJSON.resetPass}</Typography>
                            <img src={closeIcon}
                                className={classes.closeIconStyle2}
                                onClick={this.handleCloseResetPassword}
                                data-testid={"closeIcon"}
                            />
                        </Box>
                        <Divider style={{ backgroundColor: '#D9D9D9', marginTop: '5px ', marginBottom: '5px' }} />

                        <Box style={{ margin: '10px 45px 45px 45px' }}>
                            <TextFeildStyle
                                variant="outlined"
                                type="password"
                                autoComplete="off"
                                data-testid={"old-password"}
                                fullWidth
                                placeholder={configJSON.oldPass}
                                value={this.state.oldPassword}
                                onChange={this.handleOldPasswordChange}
                                className={classes.nameTextfeild}
                                error={message.oldPassMessage}
                                helperText={
                                    (message.oldPassMessage) &&
                                    <Box className={classes.errBoxStyle}>
                                        {<ErrorOutlineOutlinedIcon className={classes.errIconStyle} color="error" />}
                                        <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}>
                                            {message.oldPassMessage}
                                        </Typography>
                                    </Box>
                                }
                            />

                            <TextFeildStyle
                                variant="outlined"
                                type="password"
                                data-testid={"new-passowrd"}
                                fullWidth
                                autoComplete="off"
                                placeholder={configJSON.newPass}
                                value={this.state.newPassword}
                                onChange={this.handleNewPasswordChange}
                                className={classes.nameTextfeild}
                                error={message.passErrMessage}
                                helperText={
                                    (message.passErrMessage) &&
                                    <Box className={classes.errBoxStyle}>
                                        {<ErrorOutlineOutlinedIcon className={classes.errIconStyle} color="error" />}
                                        <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}>
                                            {message.passworMessage}
                                        </Typography>
                                    </Box>
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { input: classes["editNameStyle"] },
                                }}
                            />

                            <TextFeildStyle
                                variant="outlined"
                                type="password"
                                data-testid={"new-confirmpassword"}
                                fullWidth
                                autoComplete="off"
                                placeholder={configJSON.reEnterNewPass}
                                value={this.state.reEnterNewPassword}
                                onChange={this.handlereEnterNewPasswordChange}
                                className={classes.nameTextfeild}
                                error={message.confirmPasswordMsg}
                                helperText={
                                    (message.confirmPasswordMsg) &&
                                    <Box className={classes.errBoxStyle}>
                                        {this.state.confirmPassSucess ?
                                            <>
                                                <CheckCircleOutlineOutlinedIcon className={classes.sucessIconStyle} />
                                                <Typography className={classes.errorTextColor} >
                                                    {this.state.confirmPassSucessText}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <ErrorOutlineOutlinedIcon className={classes.errIconStyle} color="error" />
                                                <Typography className={classes.successTextColor} >
                                                    {this.state.confirmPassSucessText}
                                                </Typography>
                                            </>}
                                    </Box>
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { input: classes["editNameStyle"] },
                                }}
                            />
                            {this.state.newError && <Typography className={classes.successTextColor} >
                                {this.state.newError}
                            </Typography>}

                            <Box className={classes.boxStyle4}>
                                <Button variant="contained"
                                    className={classes.updateButton}
                                    data-testid={"updateButton"}
                                    onClick={this.changePasswordAPI}
                                    disabled={this.state.disbaleUpdateBtn}
                                >{configJSON.updateBtn}</Button>

                                <Button variant="contained"
                                    className={classes.cancelButton}
                                    data-testid={"resetCancelButton"}
                                    onClick={this.handleCloseResetPassword}
                                >{configJSON.cancelBtn}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Dialog >
                <Dialog
                    open={this.state.isResetModal}
                    data-testid={"openSucessModal"}
                    PaperProps={{ className: classes.dialogStyle2 }}
                >
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} className={classes.closeIconGridStyle}>
                            <img src={closeIcon}
                                className={classes.closeIconStyle}
                                onClick={this.goBackLoginPage}
                                data-testid={"closeIcon"}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.textGridStyle}>
                            <img src={rightCheck} className={classes.imageStyle} />
                        </Grid>

                        <Grid item xs={12} className={classes.textGridStyle}>
                            <Typography className={classes.textFontStyle}>{"Your account password has been successfully updated"}</Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.textGridStyle}>
                            <Button
                                fullWidth variant="contained"
                                className={classes.buttonBackground}
                                onClick={this.goBackLoginPage}
                                data-testid={"navigateToLogin"} >{"Login again"}</Button>
                        </Grid>
                    </Grid>

                </Dialog>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export const StyledTextField = withStyles({
    root: {
        fontSize: "14",
        borderRadius: 8,
        fontFamily: 'Poppins',
        backgroundColor: "#FAF5FF",
        "& input::placeholder": {
            fontSize: "14",
            fontFamily: "Poppins",
            fontWeight: 400,
        },
        "& .MuiInputBase-input-283:focus": {
            outline: 'none',
            backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
            paddingLeft: 10,
            backgroundColor: "#FFFFFF",
            margin: 0,
        },
    },
})((props: any) => <TextField {...props} />);

export const TextFeildStyle = withStyles({
    root: {
        fontSize: "14",
        borderRadius: 8,
        "& input::placeholder": {
            fontSize: "14",
            fontFamily: "Poppins",
            fontWeight: 400,
        },
        "& .MuiInputBase-input-283:focus": {
            outline: 'none',
            backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
            paddingLeft: 10,
            backgroundColor: "#FFFFFF",
            margin: 0,
        },
        "& .MuiOutlinedInput-input": {
            padding: '14px'
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        }
    },
})((props: any) => <TextField {...props} />);

export const userStyles = (theme: any) => ({
    dialogStyle: {
        overflow: 'hidden',
        width: '90vw',
        borderRadius: '8px',
        backgroundColor: ' #FFFFFF',

    },
    dialogStyle2: {
        overflow: 'hidden',
        width: '500px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
    },

    gridPadding: {
        padding: '0px 35px 0px 35px'
    },

    labelBox: { marginTop: '25px' },

    labelTextStyle: {
        color: '#707070',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        '&.custom-label': {
            color: '#707070',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
        },
    },

    labelTypoStyle: {
        color: '#000000',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 500,
        marginTop: '10px',
        whiteSpace: "nowrap" as "nowrap",
        height: "14px"
    },

    countryFontStyle: {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    },

    fontStyle: {
        fontSize: '22px',
        fontWeight: 600,
        color: '#000000',
        fontFamily: 'Poppins'
    },
    resetPassStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px'
    },

    containerStyle: {
        height:"100%",
        backgroundColor: '#FFFFFF',
        padding: '0px 40px 40px 40px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#FFFFFF',
            height: "100%",
            padding: '20px',
            paddingTop: "0px"
        },
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',

    },
    boxStyle4: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', gap: "24px",
    },

    detailsBox1: {
        marginLeft: '20px'
    },

    inputPropsStyle: {
        display: 'flex',
        alignItems: 'center'
    },

    expandIcon: {
        color: "#000000",
        cursor: 'pointer'
    },

    detailsBox2: {
        padding: '10px'
    },

    paperStyle: {
        height: '272px',
        width: '257px'
    },

    imageStyle: {
        height: '93px',
        width: '93px',
    },
    closeIconStyle: {
        height: '33px',
        width: '33px',
        padding: '10px',
        cursor: 'pointer'
    },
    closeIconStyle2: {
        height: '25px',
        width: '25px',
        cursor: 'pointer'
    },
    adornmentText: {
        fontSize: '18px',
        color: '#000000'
    },
    selected: {
        border: "1px solid #B300ED",
        color: "#B300ED",
    },

    buttonBoxStyleDesktop: {
        display: 'flex' as "flex",
        flexDirection: "row" as "row",
        alignItems: 'center' as "center",
        gap: "30px",
        [theme.breakpoints.down('sm')]: {
            display: 'none' as 'none'
        },
    },
    buttonBoxStyleMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        "@media(max-width: 480px)": {
            padding: "30px",
        }
    },
    textGridStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    closeIconGridStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 10px 0px 0px'
    },


    textFontStyle: {
        color: "#0F172A",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Poppins",
        textAlign: 'center' as const,
        padding: '0px 42px 30px 42px'
    },

    textStyle: {
        color: "#000000",
        fontSize: "22px",
        fontWeight: 600,
        fontFamily: "Poppins",
        marginLeft: '17px'
    },
    buttonBackground: {
        backgroundColor: "#B300ED",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        margin: '0px 42px 60px 42px',
        textTransform: "initial" as const,
        "&:hover": {
            backgroundColor: "#B300ED"
        }
    },

    boxStyle_main: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    boxStyle2: {
        display: 'flex',
        // gap: "80px",
        justifyContent: "space-between",
        padding: '40px',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '30px', whiteSpace: "nowrap" as "nowrap",
        [theme.breakpoints.down('sm')]: {
            display: "unset",
        },
        "@media(min-width:960px) and (max-width:1300px)": {
            paddingLeft: "20px",
            gap: "15px",
        },
    },

    boxStyle2Content: {
        maxwidth: "100%",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "25px",
        },
    },

    boxStyle3: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            display: "unset",
        },
    },

    boxstyle3Textfield: {
        width: "45%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },

    labelText: {
        color: '#454545',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        whiteSpace: 'nowrap' as const,
        marginTop: '25px',
        marginBottom: '10px'
    },
    nameTextfeild: {
        width: "100%",
        borderRadius: 8,
        backgroundColor: '#FAF5FF',
        marginTop: '15px',
        '& .MuiOutlinedInput-root': {
            borderRadius: "8px",
            fontFamily: 'Poppins',
        }
    },
    formcontrol: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "8px",
            fontFamily: 'Poppins',
        }
    },
    blackTextH: {
        color: "#000000"
    },
    placeTextH: {
        color: "#828589"
    },
    selectText: {
        fontSize: "14px", fontWeight: 400, fontFamily: "Poppins",
        color: "#0F172A"
    },
    roleButtonGroup: {
        display: "flex",
        gap: "1.125rem",
        flexWrap: "wrap" as const,
        marginTop: '15px',
        [theme.breakpoints.down("md")]: {
            gap: "1rem"
        }
    },
    roleButton: {
        width: "max-content",
        height: '50px',
        padding: "2px",
        borderRadius: "8px",
        backgroundColor: "#FAF5FF",
        cursor: "pointer",
    },
    roleButtonText: {
        color: '#454545',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
    },

    labelTextfeild: {
        width: "100%",
        borderRadius: '1px',
        backgroundColor: '#FAF5FF',
        marginTop: '25px'
    },

    sidebarTop: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        padding: '20px',
        gap: "30px"
    },
    sidebarAvatar: {
        width: '140px',
        height: '140px',
        borderRadius: "50%",
        border: "4px solid #F0E5FF",
        background: "#FFF",
        "@media(max-width: 480px)": {
            width: '90px',
            height: '90px',
        }
    },


    uploadButton: {
        display:"block",
        // display:"block",
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        borderRadius: '8px',
        padding: "16px",
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: 'initial' as const,
        whiteSpace: "nowrap" as "nowrap",
        width:"234px",
       
       
  justifyContent: "center",
        
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            padding: "16px 35px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "16px 30px",

        },
        "@media(max-width: 480px)": {
            padding: "16px 35px",
        }
    },
    updateButton: {
        marginTop: '3vw',
        fontSize: '16px' as const,
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        width: '201px',
        height: "56px",
        padding: "16px 71px",
        textTrasnform: "capitalize" as "capitalize",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

    cancelButton: {
        marginTop: '3vw',
        fontSize: '18px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#B300ED',
        backgroundColor: '#FFFFFF',
        textTransform: 'initial' as const,
        border: '1px solid #B300ED',
        borderRadius: '6px',
        width: '207px',
        height: "56px",
        lineHeight: "50.96px",
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

    resetButton: {
        marginTop: '2vw',
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#6200EA',
        backgroundColor: '#F0E5FF',
        textTransform: 'capitalize' as const,
        borderRadius: '8px',
        lineHeight: "24px",
        height: "44px",
        width: "157px",
        padding: "10px 16px",
        whiteSpace: "nowrap" as "nowrap",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "#F0E5FF",
            boxShadow: "none"
        }
    },
    divgrid: {
       
        paddingTop:"27px"
    },

    detailsBox: {
        border: '1px solid #D5D5D5',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        maxWidth: "100%"
    },
    editicon_img: {
        height: '24px',
        width: '24px',
        padding: '2px',
        marginRight: '15px',
        cursor: 'pointer'
    },
    personalDetailsText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#707070',
        padding: '10px',
        paddingLeft: "40px",
        "@media(min-width:960px) and (max-width:1300px)": {
            paddingLeft: "20px"
        },
        "@media(min-width:414px) and (max-width:960px)": {
            paddingLeft: "25px"
        }
    },
    textField: {
        border: 'none',
        borderBottom: 'none',
    },

    TextFieldStyle: {
        marginTop: '15px'
    },

    label: {
        color: '#707070',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
    },

    valueText: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 500,
        color: '#000000'
    },

    BoxScrollbar: {
        height: '100%',
        overflowY: 'scroll' as const,
        overflowX: 'hidden' as const,
        padding: '40px',
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
        },
        scrollbarColor: "#D6D6D6 transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            backgroundColor: "#D6D6D6",
            padding: '30px',
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            borderRadius: 6,
            minWidth: 14,
            minHeight: 24
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
    },
    IconDiv: {
        display: "flex", flexDirection: "row" as "row", justifyContent: "space-between"
    }, icon: { height: "24px", width: "24px" },

    CountryCodeScrollbar: {
        height: '272px',
        width: '252px',
        overflowY: 'scroll' as const,
        overflowX: 'hidden' as const,
        scrollbarColor: "#D6D6D6 transparent",

        "&::-webkit-scrollbar": {
            width: 8,
            height: 6,
            backgroundColor: "#D6D6D6",
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            borderRadius: 8,
            minWidth: 14,
            minHeight: 24
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
    },

    FlagIcon: {
        fontSize: '1.5em',
        marginRight: '10px',
    },

    ContentWithPadding: {
        padding: 15,
    },
    errBoxStyle: {
        display: 'flex',
        marginTop: '2px',

        '& .MuiFormHelperText-root.Mui-error': {
            backgroundColor: "white",
            margin: 0,
            padding: "0 14px"
        }
    },
    errorTextColor: {
        color: "#1AA663",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins"
    },
    successTextColor: {
        color: "#D53333",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins"
    },

    helperText: {
        color: "#D53333",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins"
    },
    errIconStyle: {
        fontSize: '18px',
        margin: '3px 10px 0px -10px',
    },

    sucessIconStyle: {
        fontSize: '18px',
        margin: '3px 10px 0px -10px',
        color: '#1AA663'
    },

    errorTextMsgStyle: {
        color: '#D533330',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
    },
    sucessTextMsgStyle: {
        color: '#1AA663',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
    },
    sucesshelperText: {
        color: "#1AA663",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins"
    },
    slider: {
        color: '#B300ED',
        maxWidth: '39rem',
        display: 'flex',
        alignItems: 'center',
        marginTop: '10vw',
        '& .MuiSlider-track': {
            height: '8px',
            border: 'none',
            borderRadius: '1px',
        },
        '& .MuiSlider-rail': {
            height: '8px',
            border: 'none',
            borderRadius: 0,
        },
        '& .MuiSlider-thumb': {
            height: '30px',
            width: '30px',
            backgroundColor: '#B300ED',
            border: '5px solid white',
            marginTop: '0',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            top: '50%',
            transform: 'translateY(50%)',
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
        },
        '& .MuiSlider-markLabel': {
            top: '36px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '21px',
            color: 'black',
            [theme.breakpoints.down("md")]: {
                transform: 'translateX(-100%)'
            }
        },
    },

});


export default withStyles(userStyles)(VenderProfile)
// Customizable Area End
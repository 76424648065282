import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const mockPath = [
    { name: "Home", path: "LandingPage" },
    { name: "Join Us", path: "JoinUsLandingPage" },
    { name: "Subscriptions", path: "JoinUsSubscription" },
]

export const mockTabs = ["Suppliers", "Softwares", "Services", "Events"]

export interface SubscriptionFeature {
    name: string;
    active: boolean;
}

export interface SubscriptionEvent {
    name: string;
    description: string;
}
export interface SearchDir{
    directory_id: number,
    directory_name:string,
    count: number
}


export interface Subscription {
    id?: number;
    name?: string;
    price?: string;
    description?: string;
    subscription_features?: SubscriptionFeature[];
    subscription_events?: SubscriptionEvent[];
}

export interface Directory {
    directory_id?: string;
    directory_name?: string;
    subscriptions?: Subscription[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    route: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    selectedTab: string | undefined;
    planTabs: string[];
    isYearlyPlanSelected: boolean;
    selectedPlanIndex: number;
    subscriptionType: { proKey: string };
    Username: any;
    planDetails: Directory[];
    showSelectedPlan: Directory,
    showDirectoryPopup:boolean,
    searchDirectoryData:SearchDir[],
    searchTermProp:string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class JoinUsSubscriptionController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    planDetailsAPICallId: string = "";
    getDataResultApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            selectedTab: "",
            isYearlyPlanSelected: false,
            selectedPlanIndex: -1,
            subscriptionType: { proKey: '' },
            Username: null,
            planTabs: [],
            planDetails: [],
            showSelectedPlan: {},
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchTermProp:""
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ Username: accountDetails });
        this.planDetailsAPI()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const propData = message.getData(
                getName(MessageEnum.SessionResponseData)
            );
            await setStorageData('durectory_name', JSON.stringify(propData));
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.planDetailsAPICallId) {
                    const apiResponse = responseJson
                    if (apiResponse) {
                        const subscriptionPropsArray: Directory[] = apiResponse
                        const directoryItems = apiResponse.map(
                            (item: Directory) => item?.directory_name
                        );
                        this.setState({
                            planTabs: [...directoryItems, "Events"],
                            planDetails: subscriptionPropsArray
                        }, () => { this.updateSelectedTab() })
                    }
                }
                else if (apiRequestCallId===this.getDataResultApiId){
                    this.handleDirectorySearchResponse(responseJson);
                }
            }
        }
    }

    // Web Events
    goToLoginPage = () => {
        this.props.navigation.navigate("JoinUsSubscription2", { type: "EmailAccountLoginBlock" })
    }

    goToMockPath = (path: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), path);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }


    goTovenderaccount = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    goTohomePage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)

    }

    handleheaderNavigation = async () => {
        const usertype = await getStorageData("user_type")
        if (usertype == "vendor" || "Vendor") {
            this.goTovenderaccount()
        }
    }

    handleuserSignout = async () => {
        this.goTohomePage()
        await removeStorageData("loginToken")
    }

    updateSelectedTab = async () => {
        const storedSubscriptionType = await getStorageData('durectory_name');
        if (storedSubscriptionType) {
            const parsedSubscriptionType = JSON.parse(storedSubscriptionType);
            const { proKey } = parsedSubscriptionType;
            this.setState({ selectedTab: proKey }, () => {
                this.handleTabChange(proKey)
            });
        }
    }
    getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
          const searchDataRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
    
        this.getDataResultApiId = searchDataRequestMessage.messageId;
        searchDataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchDataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
      );
    
      runEngine.sendMessage(searchDataRequestMessage.id, searchDataRequestMessage);
    
    
      }

    handleTabChange = (index: string) => {
        const selectedTabDetails = this.state.planDetails?.find((item: Directory) => (
            item?.directory_name == index
        ))
        this.setState({
            selectedTab: index,
            showSelectedPlan: selectedTabDetails ?? {}
        }, async () => {
            await setStorageData('directory_id', this.state.showSelectedPlan?.directory_id)
        });
    };

    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
      }


    handlePlanChange = () => {
        this.setState(prev => ({
            ...prev,
            isYearlyPlanSelected: !prev.isYearlyPlanSelected
        }))
    }
    handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
    }

    handlePlanClick = async (planIndex: number, plan: Subscription) => {
        this.setState({ selectedPlanIndex: planIndex });
        let selectPlan = { selectedPlan: { plan, directory_Name: this.state.showSelectedPlan?.directory_name, directory_id: this.state.showSelectedPlan?.directory_id } }
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SearchListing");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), selectPlan);
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message)
        await setStorageData('selectedPlan', JSON.stringify(selectPlan));
    };
    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }


    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
    }
    planDetailsAPI = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.planDetailsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.planDetailsAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
    // Customizable Area End
}

import React from "react";
// Customizable Area Start
import "./index.css";

import TopNavController, {
  Props
} from "./TopNavController";


import {  withStyles } from "@material-ui/core/styles";
import {  Box, Link } from "@material-ui/core";
import MegaMenuWeb from "../../../components/src/MegaMenu.web";
// Customizable Area End



export class TopNav extends TopNavController {
  
  
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   
// Customizable Area Start
  
// Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
        <Box className={classes.topHeader}>
        <Box className={classes.container}>
          <Box className={classes.row}>
            <ul className={classes.topHeaderUl}>
              <li className={classes.topHeaderLi}>
                <MegaMenuWeb data={this.state.directoryMenuList}/>
              </li>
              <li className={`${classes.topHeaderLi} ${classes.topHeaderLiSecond} `}>
                <Link className={`${classes.navLink} ${location.pathname === "/" && classes.active}`}
                  href="/"
                >
                  For Buyers
                </Link>
              </li>
              <li className={classes.topHeaderLi}>
                <Link className={`${classes.navLink} ${location.pathname === "/VendorLandingPage" && classes.active}`}
                  href="/VendorLandingPage"
                > 
                  For Vendors
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

export const styles= ()=> ({
    topHeader: {
      display: 'flex',
      justifyContent: 'end',
      padding: '12px 0',
      backgroundColor: '#E9E5FF',
      gap: '60px',
      fontFamily: 'Poppins, sans-serif',
      width: '100%',
      height: '25px',
      alignItems: 'center',
      boxSizing:"unset" as 'unset',
      position: "fixed" as const,
      zIndex: 1000,
      top: 0,
      left: 0,
    },
    row: {
      display: 'flex',
      justifyContent: 'end'
    },
    topHeaderUl: {
      margin: '0',
      display: 'flex',
      alignItems: 'center',
      "@media(max-width: 1440px)": {
        marginRight: '40px',
      },
      "@media(max-width: 960px)": {
        marginRight: '25px',
      },
    },
    topHeaderLi: {
      display: 'inline'
    },
    topHeaderLiSecond: {
      margin: '0 60px',
      "@media(max-width: 520px)": {
        margin: '0 40px',
      },
      "@media(max-width: 480px)": {
        margin: '0 20px',
      }
    },
    navLink: {
      color: '#262424',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
      textDecoration: 'none',
      cursor: 'pointer',
      letterSpacing:"0.01",
      "@media(max-width: 480px)": {
        fontSize: '12px',
      }
    },
    container: {
      maxWidth: "1440px",
      margin: '0 auto',
      width: '100%',
      "@media(max-width: 992px)": {
        margin: '0 15px'
      }
    },
    active:{
      fontWeight:600
    }
  });
export default withStyles(styles)(TopNav);
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";

export interface MockAPIResponse {
  data: {
    catalogue: {
      data: {
        attributes: {
          name: string,
          logo: string
        }
      }
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes: any;

  // Customizable Area End
}

interface S {
  Username: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  Describe: string;
  Domain: string;
  children?: React.ReactNode;
  index: any;
  value: any;
  open: boolean;
  validURL: boolean;
  title: string;
  imagePreview: string;
  progressVal: number;
  isOpenEditModal: boolean;
  editProfilePic: any;
  categoryIdCustomform: string;
  isLoading: boolean;
  isSuccessDialog: boolean;
  showDirectoryPopup:boolean,
  searchDirectoryData:any,
  searchTermProp:string,
  formDataChanged:boolean,
  openSaveModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Customform4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomFormid: string = "";
  form_Submitid: string = "";
  getSearchResultDataReqId: string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [

      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),


      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      Username: null,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open: false,
      value: 0,
      index: 1,
      Describe: "",
      Domain: "",
      validURL: true,
      title: "",
      imagePreview: "",
      progressVal: 0,
      isOpenEditModal: false,
      editProfilePic: "",
      categoryIdCustomform: "",
      isLoading: false,
      isSuccessDialog: false,
      showDirectoryPopup:false,
      searchDirectoryData:[],
      searchTermProp:"",
      formDataChanged:false,
      openSaveModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const propData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const idToSet = propData?.data?.catalogue_id;
      if (idToSet !== undefined) {
        this.setState({ categoryIdCustomform: JSON.stringify(idToSet) })
        await setStorageData('category_id', JSON.stringify(idToSet));
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestId == this.getCustomFormid) {
        this.handleAPIResponse(response)
      }
      if (apiRequestId === this.form_Submitid) {
        if (response) {
          this.setState({isSuccessDialog: true})
          this.handleCloseOpenEditModal()
        }
      }
      if(apiRequestId==this.getSearchResultDataReqId){
        this.handleDirectorySearch(response)
        this.setState({isLoading:false})
      }
    }
    // Customizable Area End
  }



  componentDidMount = async () => {
    const storedData = await getStorageData("userdetails");
    const accountDetails = JSON.parse(storedData);
    this.setState({ Username: accountDetails });
    this.getCustomForm()
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.categoryIdCustomform !== this.state.categoryIdCustomform) {
      this.getCustomForm()
    }
  }

  // Customizable Area Start
  txtinputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtnnputMobileProps = {
    ...this.txtinputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtinputProps = this.isPlatformWeb()
    ? this.txtinputWebProps
    : this.txtnnputMobileProps;

  btnshowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtinputProps.secureTextEntry = !this.state.enableField;
      this.btnShowhideImageProps.source = this.txtinputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowhideImageProps = {
    source: this.txtinputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleprops = {
    onPress: () => this.dobuttonPressed(),
  };

  dobuttonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleAPIResponse = (response: MockAPIResponse) => {
    if (response) {
      this.setState({ isLoading: false })
    }
    if (response.data.catalogue.data.attributes.name) {
      this.setState({ title: response.data.catalogue.data.attributes.name })
    }
    if (response.data.catalogue.data.attributes.logo) {
      this.setState({ imagePreview: response.data.catalogue.data.attributes.logo })
    }
  }

  handleChange = (event: any, newValue: number) => {
    if(this.state.formDataChanged) this.setState({openSaveModal:true})
    else this.setState({ value: newValue });
  };

  a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  vendorAccountNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  homePageNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  headerNavigation = async () => {
    const usertype = await getStorageData("user_type")
    if (usertype == "vendor" || "Vendor") {
      this.vendorAccountNavigation()
    }
  }

  signoutUser = async () => {
    await removeStorageData("loginToken")
    this.homePageNavigation()
  }

  handleSubmit = async () => {
    const categoryID = this.state.categoryIdCustomform || await getStorageData("category_id");
    let formdata = new FormData();

    const header = {
      token: localStorage.getItem("loginToken"),
    };

    formdata.append("name", this.state.title);

    if (this.state.editProfilePic) {
      formdata.append("logo", this.state.editProfilePic);
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.form_Submitid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomForm}/${categoryID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  getCustomForm = async () => {
    this.setState({ isLoading: true })
    const category_ID = this.state.categoryIdCustomform || await getStorageData("category_id");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomFormid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.catalogueAPiEndPoint}/${category_ID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  incrementProgress = (value: number) => {
    this.setState({ progressVal: value })
  }

  handleOpenEditModal = () => {
    this.setState({ isOpenEditModal: true })
  }

  handleCloseOpenEditModal = () => {
    this.setState({ isOpenEditModal: false })
  }

  handleCloseSuccessDialog = () =>{
    this.setState({isSuccessDialog: false})
  }

  handleChangeTitle = (event: any) => {
    this.setState({ title: event.target.value })
  }

  onScreenshotUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader: any = new FileReader();

    const files = e.target.files;

    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];

    reader.onloadend = () => {
      this.setState({
        editProfilePic: file,
        imagePreview: reader.result
      });

    }
    reader.readAsDataURL(file);
  };

  handleAdvancedSearch = (directory:string) => {
    this.setState({showDirectoryPopup:false})
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToAdvancedSearch)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
    this.send(msg);

  }
  handleSubmitSearch=(event:any) =>{
    event.preventDefault();
    this.handleAdvancedSearch("All");
  }

  handleSearchInput = (event:any) =>{   
    this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
     if(!event.target.value) this.setState({ showDirectoryPopup:false }); 
 }

 handleDirectorySearch=(responseJson:any)=>{
   if(responseJson && responseJson.response){ 
     this.setState({isLoading:false,searchDirectoryData:responseJson.response}) 
   } 
 }

 handleDirectoryPopupClose=()=> { 
   this.setState({showDirectoryPopup:false})
 }

  
  getSearchResultDirectory = () => {
    this.setState({
      isLoading:true
    }) 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
 
  const searchRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  ); 
 
    this.getSearchResultDataReqId =  searchRequestMessage.messageId;  
    searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
      );
      searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);

      searchRequestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage), 
  configJSON.validationApiMethodType
  ); 

  runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage); 
  }

  handleDataChanged=()=>{
    this.setState({formDataChanged:true})
  }
  handleCloseSaveModal=()=>{
    this.setState({openSaveModal:false})
  }
  handleDataSaved=()=>{
    this.setState({formDataChanged:false})
  }
  handleOpenSaveModal=()=>{
    this.setState({openSaveModal:true})
  }

  handleBack=()=>{
    this.setState({value:this.state.value-1})
  }


  

  // Customizable Area End
}
import React from "react";

// Customizable Area Start
import {
    Typography,
    Button,
    Grid,
    Checkbox,
    Box,
    Dialog,
    Theme,
    Paper,
    CircularProgress,
    Backdrop,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
// Customizable Area End

// Customizable Area Start
import SupplierProductPageController, {
    Props,
    configJSON,
} from "./SupplierProductPageController";
import { deleteIcon, AddIConWhite, CloseICon, CheckIcon } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';


// Customizable Area End

export class SupplierProductPage extends SupplierProductPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            <Box>

                <Grid container className={classes.product_mainContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid item container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.product_font_1}>{configJSON.productDescription}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid item container className={classes.product_screenshotheader}>
                            <Grid item xs={6} md={6}><Typography className={classes.product_font_2}>{configJSON.fileName}</Typography></Grid>
                            <Grid item xs={5} md={5}><Typography className={classes.product_font_2}>{configJSON.size}</Typography></Grid>
                        </Grid>
                        <Grid container className={classes.product_screenshotBody} >
                            {this.state.documentArrayProduct.map((item, index) => {
                                return (
                                    <Grid item container key={index}>
                                        <Grid item xs={6} md={6} className={classes.screenshotitemProduct}><Typography className={classes.files_fontStyle_2}>{decodeURIComponent(item.name)}</Typography></Grid>
                                        <Grid item xs={3} md={3}><Typography className={classes.files_fontStyle_2}>{item?.id ? item.size : this.formatFileSizeProduct(item.size)}</Typography></Grid>
                                        <Grid xs={2} md={2} item>{item?.url && <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}>Download</a>}</Grid>
                                        <Grid item xs={1} md={1} style={{ textAlign: "end" }}><div style={{ cursor: "pointer" }} data-testid="remove-1" onClick={() => this.removeDocumentProduct(item)}><img src={deleteIcon} alt="deleteicon" /></div></Grid>
                                    </Grid>
                                )
                            })}

                        </Grid>
                        <Grid container className={classes.product_ButtonBox} >
                            <Grid container justifyContent="flex-end" item md={12}>
                                <Box className={classes.imagefileBtnWrapperProduct}>
                                    <label htmlFor="upload-document" className={classes.addimagebtnScreenshotProduct}><img src={AddIConWhite} alt="AddIConWhite" /><Typography className={classes.add}>{configJSON.addFile}</Typography></label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        accept=".csv"
                                        onChange={this.onDocumentUploadProduct}
                                        data-testid={"inputrefdocument"}
                                        id="upload-document"
                                    />
                                </Box>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Checkbox
                                    checked={this.state.isChecked}
                                    onChange={this.handleCheckboxChange}
                                    checkedIcon={<span className={clsx(classes.product_iconStyle_1, classes.product_checked_Icon)} />}
                                    icon={<span className={classes.product_iconStyle_1} />}
                                    inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    data-testid={"checbox_1"}
                                />
                                <label className={classes.product_labelFont}>{configJSON.selectCheckLable}</label>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <a download href="https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbGdDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5ace1dd5094a319266a2f655bee74212b8e5ecdb/Restaurant-BC%20-%20sample%20csv(3)%20(2).csv" className={classes.product_fontCSVStyle}>{configJSON.downloadCSV}</a>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Box className={classes.saveBtn_Wrapper_Product}>
                        <Box className={classes.buttonBox}>
                            <Typography className={classes.needHelp}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.fontStyle4_product}>{configJSON.no}</Typography>
                            <Typography className={classes.fontStyle4_product} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                        </Box>

                        <Box className={classes.buttonBoxStyle_4}>

                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"saveBtn"} handleClick={this.handleSubmitSupplierProductPage} />

                        </Box>
                    </Box>


                </Grid>

                <Dialog
                    open={this.state.error}
                    onClose={this.handleCloseErrorDialogProduct}
                    PaperComponent={props => (
                        <Paper {...props} className={classes.successboxSupplierProduct} />
                    )}
                >
                    <Box className={classes.successMedia_product}>

                        <Typography className={classes.detailSuccessText_product}>Can't upload file greater than 30mb</Typography>
                        <Button variant="contained" className={classes.backToAccountButton_product} onClick={this.handleCloseErrorDialogProduct} data-test-id="backtoaccounterror">Back to Accounts</Button>
                    </Box>
                </Dialog>
                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <CustomToast open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />

                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackProduct} handleFeedbackEmailChange={this.handleFeedbackEmailChangeProduct} handleFeedbackMessageChange={this.handleFeedbackMessageChangeProduct} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Box>

        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    product_mainContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },

    product_font_1: {
        fontSize: '16px',
        fontWeight: 400,
        color: '#000000',
        fontFamily: 'Poppins'
    },

    product_fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    product_downFontStyle: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#B300ED',
        fontFamily: 'Inter'
    },

    product_screenshotheader: {
        padding: "10px",
        borderBottom: "1px solid #CBD5E1",
    },

    product_screenshotBody: {
        padding: "10px 7px 10px 10px",
    },

    product_ButtonBox: {
        marginTop: '5%',
    },

    product_cancelIcon: {
        cursor: 'pointer',
        textAlign: "end" as const,
    },

    product_font_2: {
        color: "#475569",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },

    product_fontCSVStyle: {
        display: 'none',
        color: "#006FFF",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        cursor: "poniter",
        textDecoration: "none"
    },
    product_addMoreBtn: {
        backgroundColor: "#B300ED",
        borderRadius: '10px',
        width: '12%',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    product_BtnTextStyle: {
        textTransform: 'initial' as const,
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        fontWeight: 400,
    },

    product_font_5: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins'
    },

    product_feedBack_Text: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '20%',
        marginBottom: '20%'
    },

    product_iconStyle_1: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        border: '1px solid #64748B',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    product_checked_Icon: {
        backgroundColor: '#B300ED',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },

    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
    product_labelFont: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        fontFamily: 'Poppins',
        lineHeight: '20px'
    },

    imagefileBtnWrapperProduct: {
        display: "flex",
        justifyContent: "center",
        // marginTop: "50px",
    },

    addimagebtnScreenshotProduct: {
        width: "119px",
        height: "44px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        alignItems: "center",
        borderRadius: "8px",
        padding: "0px 16px",
        color: "white",
        textTransform: "initial",
        cursor: "pointer",
        background: "#B300ED",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
    },
    saveBtn_Wrapper_Product: {
        display: 'flex',
        marginTop: "59px",
        alignItems: "center",
        justifyContent: "space-between",

        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",

        },
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    downloadtxt: {
        color: "#B300ED",
         fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        cursor: "pointer",
        textDecoration: "none",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight:"26px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,

        },

    },
    needHelp: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    fontStyle4_product: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },
    declineBtn_7: {
        fontSize: "18px",
        color: "#B300ED",
        textAlign: "center",
        padding: "2px 81px",
        fontFamily: "Poppins",
        fontWeight: 400,
        textTransform: "initial",
        borderRadius: "6px",
        border: "1px solid #B300ED",
        lineHeight: "50.956px",
        background: "#FFF",
        height: 55,
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    buttonBoxStyle_4: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginTop: '3%',
        },
    },
    files_fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    acceptBtn_7_product: {
        height: "55px",
        width: "241px",
        fontSize: "16px",
        gap: "8px",
        justifyContent: "center",
        lineHeight: "24px",
        alignItems: "center",
        padding: "16px 71px",
        color: "#FFF",
        fontFamily: "Poppins",
        fontWeight: 400,
        textTransform: "initial",
        background: "#B300ED",
        borderRadius: "8px",
        marginLeft: "10px",
        textWrap: "nowrap",
        '&:hover': {
            backgroundColor: "#B300ED",
        },

        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
            marginLeft: "unset",

        },
    },
    successMedia_product: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "20px",
        alignItems: "center",
        height: "100%",
    },
    detailSuccessText_product: {
        margin: "20px 0 51px",
        textAlign: "center",
        fontWeight: 700,
        color: "#0F172A",
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    backToAccountButton_product: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        textTransform: "initial",
        color: "#FFF",
        gap: "8px",
        borderRadius: "8px",
        fontSize: "18px",
        background: "#B300ED",
        lineHeight: "80.956px",
        fontFamily: "Poppins",
        '&:hover': {
            background: "#B300ED",
        },
    },
    successboxSupplierProduct: {
        width: "529px",
        borderRadius: "8px 8px 32px 8px",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        height: "329px",
        background: "#FFF",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },
    successMediaDialogProduct: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: "20px",
    },

    detailSuccessTypoProduct: {
        color: "#0F172A",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        margin: "20px 0 51px"
    },
    backToAccountBtnProduct: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    screenshotitemProduct: {
        marginBottom: "12px",
    },
    add: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        fontWeight: 400,
        textWrap: "noWrap"
    },
});

export default withStyles(userStyles)(SupplierProductPage)
// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { ChangeEvent } from "react";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

interface ApiResponse {
    category_id: string;
    category_name: string;
    sub_categories: {
        sub_category_id: string;
        sub_category_name: string;
    }[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleOpenSaveModal:()=>void;
    handleDataChanged:()=>void;
    handleDataSaved:()=>void;
    handleBack:()=>void;


    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    value: number;
    progressVal: number;
    isSucessModalOpen: boolean;
    subCategory: any[];
    isFeedbackFormOpen: boolean;
    inputValue: string;

    categoryListData: any[],
    categoryListName: any[],
    categoryListNew: any[],
    progressPercentage: number;
    anchorEl: null | HTMLElement;

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ServicesServicePageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoryListAPICallId: string = "";
    updateCategoryListDataAPIcallId: string = "";
    getCustomFormDetailServiceId: string = "";
    deleteCategoryAPIId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            value: 0,
            progressVal: 0,
            isSucessModalOpen: false,
            subCategory: [],
            isFeedbackFormOpen: false,
            inputValue: "",
            categoryListData: [],
            categoryListName: [],
            categoryListNew: [{ category: { category_name: "", category_id: 0 }, feature: [], option: [] }],
            progressPercentage: 0,
            anchorEl: null
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getCategoryListAPICallId:
                        this.handleCategoryListAPIResponse(responseJson);
                        break;
                    case this.getCustomFormDetailServiceId:
                        this.setInitialData(responseJson.data);
                        break;
                    case this.updateCategoryListDataAPIcallId:
                        this.handleUpdateCategoryAPIResponse(responseJson);
                        break;
                }
            }
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getCategoryList()
        this.getCustomFormDetailService()
    }

    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        } 
        else{
                this.props.handleBack()
        }
    }

    handleOpenFeedbackFrom = () => {
        this.setState({ isFeedbackFormOpen: true })
    }

    handleCloseFeedbackForm = () => {
        this.setState({ isFeedbackFormOpen: false })
    }

    handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };




    // API

    getCustomFormDetailService = async () => {
        const categoryID = await getStorageData("service_category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailServiceId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCategoryList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCategoryListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.categoryList}?directory_name=${"Services"}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateCategoryListData = async () => {
        this.props.handleDataSaved()
        const categoryId = await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        this.state.categoryListNew.forEach((item) => {
            let subCategory = item.feature
            formdata.append("category_ids[]", item.category.category_id);
            if (subCategory && subCategory.length) {
                subCategory.forEach((item: any) => {
                    formdata.append("sub_category_ids[]", item.sub_category_id);
                })
            } else {
                formdata.append("sub_category_ids[]", "");
            }
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateCategoryListDataAPIcallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleCategoryListAPIResponse = (responseJson: any) => {
        let categoriesName = responseJson?.map((item: any) => ({
            category_id: item.category_id,
            category_name: item.category_name
        }));

        this.setState({ categoryListData: responseJson })
        this.setState({ categoryListName: categoriesName })

    }

    setInitialData = (data: any) => {

        if (data) {
            if (data.catalogue.data.attributes?.categories) {
                const allcategories = data.catalogue.data.attributes.categories
                const output1 = allcategories.map((category: any) => ({
                    category: {
                        category_name: category.category_name,
                        category_id: category.category_id
                    },
                    feature: category.sub_categories.map((subCategory: any) => ({
                        sub_category_id: subCategory.id,
                        sub_category_name: subCategory.name
                    })),
                    option: category.options.map((subCategory: any) => ({
                        sub_category_id: subCategory.id,
                        sub_category_name: subCategory.name
                    }))
                }));
                this.setState({ categoryListNew: output1 })

            }

            if (data.catalogue.data.attributes.progress_percentage) {
                this.setState({ progressPercentage: data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
            }
        }
    }


    selectCategoryDynamic = (event: any, index: any) => {
        let categoryName = event.target.value;
        let categoryId = this.state.categoryListName.find(
            (item) => item.category_name === categoryName
        )?.category_id;

        let options = this.state.categoryListData
            .find((item) => item.category_id === categoryId)
            ?.sub_categories



        let updatedCategory = this.state.categoryListNew.map((item, currentIndex) => {
            if (currentIndex === index) {
                return {
                    ...item,
                    category: { category_name: categoryName, category_id: categoryId },
                    option: options || [],
                };
            } else {
                return item;
            }
        });

        this.setState({ categoryListNew: updatedCategory })
        this.props.handleDataChanged()

    };


    onAutoCompleteChange = (event: any, newValue: any, index: number) => {
        let updatedCategory = this.state.categoryListNew.map((item, listindex) => {
            if (listindex == index) {
                return { ...item, feature: newValue }
            } else {
                return item
            }
        })
        this.setState({ categoryListNew: updatedCategory })
        this.props.handleDataChanged()

    }

    addCategory = () => {
        this.setState({ categoryListNew: [...this.state.categoryListNew, { category: { category_name: "", category_id: 0, }, feature: [], option: [] }] })
    }

    removeCategoryNew = (value: number) => {
        let updatedCategory = this.state.categoryListNew.filter((item, index) => {
            return index !== value
        })
        if(value==0){
            this.handleRemoveCategory()
        }
        this.setState({ categoryListNew: updatedCategory })
        this.props.handleDataChanged();
    }


    handleUpdateCategoryAPIResponse = (responseJson: {}) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucessModalOpen: true })
        }
    }

    handleCloseSuccess = () => {
        this.setState({ isSucessModalOpen: false })
    }

    handleRemoveCategory=async ()=>{
        const category_id=this.state.categoryListNew[0].category.category_id
     if(this.state.categoryListNew.length && category_id){
       
        const categlogueId = await getStorageData("service_category_id");
       
        const requestMessageDeleteRes = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };

        this.deleteCategoryAPIId = requestMessageDeleteRes.messageId;
        

        requestMessageDeleteRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.categoryDeleteEndpoint}?id=${categlogueId}&category_id=${category_id}`
        );
        requestMessageDeleteRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDeleteRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );
        runEngine.sendMessage(requestMessageDeleteRes.id, requestMessageDeleteRes);
     }
    }
    // Customizable Area End
}
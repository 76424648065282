import React from "react";

import {
    Typography,
    Box,
    // Customizable Area Start
    withStyles,
    Theme,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Chip,
    TextField,
    RadioGroup,
    Radio,
    styled,
    Grid,
    Dialog,
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    Paper,
    Backdrop,
    CircularProgress,
    Popper,
    FormControl,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from "@material-ui/icons/Menu";
import { deleteIcon, AddIConWhite, deleteIcon_1, CloseICon } from "./assets";
import clsx from 'clsx';
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';


export const BpIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#f5f8fa',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: "transparent",
    },

}));
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#8833FF',
    backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#8833FF',
    },

});

const BpIconCheckBox = styled('span')(() => ({
    width: 20,
    height: 20,
    border: "1px solid #64748B",
    borderRadius: "6px",
}));

const BpCheckedIconCheckBox = styled(BpIcon)({
    backgroundColor: '#8833FF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    width: 20,
    height: 20,
    borderRadius: "6px",

    '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        borderRadius: "6px",
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#8833FF',
    },
});


import FeatureController, {
    Props, configJSON, drawerWidth
} from "./FeatureController";
import CustomToast from "../../../components/src/CustomToast.web";

export const styles: any = (theme: Theme) => ({
    featureContainer: {
        display: "flex",
        padding: "1.7rem",
        paddingLeft: "42px",
        gap: "15px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0",
            width: "100%",
            display: "unset",
        },

    },
    sidebar: {
        width: "265px",
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },
    fontStyle5: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    button: {
        textTransform: "initial",
        padding: "8px 12px",
        marginBottom: "8px",
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        width: "100%",
        justifyContent: "start",
        '&:hover': {
            backgroundColor: "#f6f0ff",
        },
    },
    activeTab: {
        backgroundColor: "#f6f0ff",
    },
    item: {
        padding: "8px 12px",
        marginBottom: "8px",
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    mainContent: {
        borderRadius: "6px",
        border: '1px solid #C8C9CA',
        background: "#FFF",
        height: "674px",
        flex: "1",
    },
    reasturantTab: {
        padding: "28px 0 42px 57px",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        },
    },
    reasturantType: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "28.34px"
    },
    resturantBox: {
        height: "500px",
        overflowY: "auto",
        scrollbarColor: "#D6D6D6 transparent",
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar": {
            width: 4,
            padding: '30px',
            height: 6,
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            minHeight: 24,
            minWidth: 14,
            borderRadius: 6,
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
    },
    checkbox: {
        color: "#B300ED",
        padding: "0",
        borderRadius: "8px",
        '&:hover': {
            color: "#B300ED",
        },
        '&.Mui-checked': {
            color: "#B300ED"
        },
    },
    checked: {
        color: "white",
    },

    icon_style_1: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '1px solid #B300ED',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon_style_1: {
        backgroundColor: '#B300ED',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },

    icon_style_2: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '1px solid #8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon_style_2: {
        backgroundColor: '#8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },

    label: {
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    formControlLabel: {
        marginLeft: "0",
        gap: "8px",
    },
    subCategoryCheckbox: {
        marginLeft: "28px",
        display: "flex",
        flexDirection: "column",
    },
    actions: {
        display: "flex",
        gap: "10px",
        justifyContent: "end",
        paddingRight: "15px",
        [theme.breakpoints.down('md')]: {
            paddingRight: "0",
            justifyContent: "unset",

        },
    },

    backBtn: {
        height: 55,
        padding: "2px 81px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#B300ED",
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "50.956px",
        borderColor: "#B300ED",
        textTransform: "initial",
        '& .MuiButton-outlined': {
            borderColor: "#B300ED",
        },

        '&:hover': {
            background: "transparent",
        },

        [theme.breakpoints.down('md')]: {
            width: "50%",
            padding: "0",
        },
    },
    saveBtn: {
        borderRadius: "8px",
        background: "#B300ED",
        padding: "15.5px 71px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textTransform: "initial",
        height: 55,
        textWrap: "nowrap",
        width: "241px",

        '&:hover': {
            background: "#B300ED",
        },

        [theme.breakpoints.down('md')]: {
            width: "50%",
            padding: "0",
        },
    },
    categoryContainer: {
        padding: "28px 0 42px 30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        },
    },
    categoryBox: {
        overflowX: "hidden",
        height: "400px",
        paddingRight: '30px',
        overflowY: "auto",
        [theme.breakpoints.down('md')]: {
            paddingRight: "0",
            borderBottom: "0"
        },
        "&::-webkit-scrollbar": {
            padding: '30px',
            height: 6,
            width: 4,
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb": {
            minWidth: 14,
            background: "#B300ED",
            minHeight: 24,
            borderRadius: 6,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
    },
    actionsCatgoryButtons: {
        display: "flex",
        gap: "10px",
        justifyContent: "end",
        marginTop: "auto",
        [theme.breakpoints.down('md')]: {
            paddingRight: "0",
        },

    },
    actionsCatgory: {
        display: "flex",
        gap: "10px",
        justifyContent: "end",
        marginTop: "auto",
    },
    categoryTypo: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "24.3px"
    },
    categorylabelContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "12px",
    },
    categorylabel: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
    },
    categoryRemoveBtn: {
        all: "unset",
        cursor: "pointer",
    },
    categorySelect: {
        backgroundColor: "#f6f0ff",
        borderRadius: "6px",
        border: "1px solid  #CBD5E1",
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        padding: "10px 8px",
        width: "100%",
        '&:before': {
            borderBottom: "1px solid  #CBD5E1",
        },
        '&:after': {
            borderBottom: 'none',
            border: "1px solid  #CBD5E1",
        },
        '& .MuiSelect-icon': {
            marginRight: "10px",
        }
    },
    adddListIcon: {
        height: "24px",
        width: "24px",
        marginRight: "5px"
    },

    icon: {
        marginRight: "10px",
    },
    featureTypo: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        marginBottom: "2px",
        marginTop: "28px",
    },
    para: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        marginBottom: "6px",

    },
    autocomplete: {
        backgroundColor: "#f6f0ff",
        padding: "6px 0",
        borderRadius: '8px',
        border: "1px solid  #CBD5E1",
        marginBottom: "42px",

        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent"
        },

        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },


        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
        },

        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        }

    },
    chip: {
        margin: "0 3px",
        border: "none",
    },
    endCloseIcon: {
        right: "10px",
    },
    categoryBtnWrap: {
        display: "flex",
        justifyContent: "end",
        marginTop: "39px",
    },
    addCategoryBtn: {
        height: "44px",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        textTransform: "none",

        "&:hover": {
            background: "#B300ED",
        }
    },
    dataContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        padding: "28px 103px 42px 57px",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        },
    },
    ownershiptypo: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "23px",
    },
    radio: {
        color: "green",
    },
    descriptionContainer: {
        padding: "39px 51px 42px 57px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
    },
    desctypo: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "12px",
    },
    descriptiontypo: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "27px",
    },
    textarea: {
        borderRadius: "8px",
        border: " 1px solid #CBD5E1",
        background: "#FAF5FF",
        width: "100%",
        overflow: "hidden",
        color: "#828589",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        padding: "8px 9px 10px 8px",
        marginBottom: "17px",
    },

    actionsCatgoryDescription: {
        display: "flex",
        gap: "32px",
        justifyContent: "end",
        marginTop: "auto",
        marginRight: "67px",
    },
    targetContainer: {
        padding: "39px 103px 42px 57px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        },
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '100%',

        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    targettypo: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "28px",
    },
    checkboxTarget: {
        color: "#B300ED",
        width: "20px",
        height: "20px",
        borderRadius: "6px",
        border: "1px solid #64748B",
        padding: "0",
        '&:hover': {
            color: "#B300ED",
        },
        '&.Mui-checked': {
            color: "#B300ED"
        },
    },
    labelTarget: {
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    platformContainer: {
        padding: "39px 103px 42px 57px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        },
    },
    platfomtypo: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "12px",
    },
    platfomrdesctypo: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "26px",
    },
    screenShotContainer: {
        padding: "39px 36px 0px 59px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        },
    },
    screenshottypo: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "21px",
    },
    screenshottypodesc: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "20px",
    },
    screenshotheader: {
        padding: "12px",
        color: "#475569",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        borderBottom: "1px solid #CBD5E1",
    },
    screenshotBody: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        padding: "12px 7px 12px 12px"
    },
    screenshotitem: {
        marginBottom: "12px",
    },
    fileitem: {
        marginBottom: "12px",
    },
    pdfitem: {
        marginBottom: "12px",
    },
    imageBtnWrapper: {
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
    },
    imagefileBtnWrapper: {
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
    },
    imagepdfBtnWrapper: {
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
    },
    addimagebtnScreenshot: {
        height: "44px",
        padding: "0px 16px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        alignItems: "center",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        textTransform: "initial",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
    },
    addimagebtn: {
        height: "44px",
        padding: "10px 16px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        alignItems: "center",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        textTransform: "initial",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
    },
    add: {
        marginLeft: "8px",
    },
    maxSizeContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "67px",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",
            marginRight: "0",
        },
    },
    maxSizeFileContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "67px",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",
            marginRight: "0",
        },

    },
    maxSizePdfContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "67px"
    },
    maxSize: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "20px",
    },
    maxSizeFile: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "20px",
    },
    maxSizePdf: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "20px",
    }
    , root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon1: {
        width: 20,
        height: 20,
        border: "1px solid #64748B",
        borderRadius: "6px",
    },
    checkedIcon: {

        backgroundColor: '#8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        width: 20,
        height: 20,
        borderRadius: "6px",

        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            borderRadius: "6px",
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#8833FF',
        },
    },
    successboxFeature: {
        width: "529px",
        borderRadius: "8px 8px 32px 8px",
        height: "329px",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        background: "#FFF",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },

    successMedia: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: "20px",
        alignItems: "center",
    },
    detailSuccessText: {
        fontSize: "24px",
        textAlign: "center",
        fontWeight: 700,
        color: "#0F172A",
        lineHeight: "32px",
        margin: "20px 0 51px",
        letterSpacing: "-0.12px",
    },
    backToAccountButton: {
        display: "flex",
        height: "56px",
        width: "367px",
        gap: "8px",
        alignItems: "center",
        textTransform: "initial",
        color: "#FFF",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontSize: "18px",
        background: "#B300ED",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    rootDrawer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            gap: "20px",
            width: "100%",

        },
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
    appBar: {
        position: "absolute",
        top: "unset",
        boxShadow: "none",
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            height: "0",
            margin: "0",
        },
        [theme.breakpoints.down("sm")]: {
            backgroundColor: "transparent",
        },
    },
    menuButton: {
        color: "black",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },

    drawerPaper: {
        width: drawerWidth,
        position: "unset",
        border: "none",
    },
    drawerPaperDesktop: {
        width: drawerWidth,
        position: "unset",
        border: "none",
    },
    content: {
        flexGrow: 2,
        [theme.breakpoints.down("sm")]: {
            marginTop: "55px",
        },
    },
    toolbar: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    }
})



// Customizable Area End


class Feature extends FeatureController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props


        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.featureContainer}>
                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={classes.rootDrawer}>
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <Drawer
                                variant="temporary"
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                {["Restaurant Type", "Features", "Data Ownership", "Target Users", "Platform", "Screenshots", "Files"].map((item, index) => {
                                    return <Button key={index} variant="text"
                                        data-test-id={`sidebarItem-${index + 1}`}
                                        className={`${classes.button} ${this.state.tab == index + 1 ? classes.activeTab : ""} `}
                                        onClick={() => this.toggleTab(index + 1)} >{item}</Button>
                                })}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaperDesktop,
                                }}
                                variant="permanent"
                                open
                            >
                                {["Restaurant Type", "Features", "Data Ownership", "Target Users", "Platform", "Screenshots", "Files"].map((item, index) => {
                                    return <Button key={index} variant="text" data-test-id={`sidebarItem-device${index + 1}`} className={`${classes.button} ${this.state.tab == index + 1 ? classes.activeTab : ""} `} onClick={() => this.toggleTab(index + 1)} >{item}</Button>
                                })}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <Box className={classes.mainContent}>
                            {this.state.tab == 1 &&
                                <Box className={classes.reasturantTab}>
                                    <Typography className={classes.reasturantType}>Restaurant Type</Typography>
                                    <FormGroup>

                                        <Box className={classes.resturantBox}>

                                            {this.state.dummyresturantTypeData?.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <FormControlLabel
                                                            data-test-id={`resturant-${index + 1}`}
                                                            checked={item.attributes.restauran_sub_type.some((subItem: any) => this.state.dummycheckedResturant.includes(subItem.id))}
                                                            onChange={(event) => this.checkAllSubcategory(event, item.attributes.restauran_sub_type)}
                                                            key={index}
                                                            control={<Checkbox name={item.attributes.name}
                                                                checkedIcon={<span className={clsx(classes.icon_style_1, classes.checkedIcon_style_1)} />}
                                                                icon={<span className={classes.icon_style_1} />}
                                                            />}
                                                            label={item.attributes.name}
                                                            classes={{ label: classes.label, root: classes.formControlLabel }}
                                                            style={{ display: "block" }}
                                                        />
                                                        {item.attributes.restauran_sub_type.map((item: any, index: number) => {
                                                            return (
                                                                <Box className={classes.subCategoryCheckbox} key={index}>
                                                                    <FormControlLabel
                                                                        data-test-id={`sub-resturant-${index + 1}`}
                                                                        checked={this.state.dummycheckedResturant.includes(item.id)}
                                                                        onChange={(event) => this.dummyhandleChangeResturant(event, item.id)}
                                                                        control={<Checkbox name={item.name}
                                                                            checkedIcon={<span className={clsx(classes.icon_style_1, classes.checkedIcon_style_1)} />}
                                                                            icon={<span className={classes.icon_style_1} />} />}
                                                                        label={item.name}
                                                                        classes={{ label: classes.label, root: classes.formControlLabel }}
                                                                        style={{ display: "block" }}
                                                                    />
                                                                </Box>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </Box>
                                    </FormGroup>

                                    <Box className={classes.actions}>

                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-test-id={"saveBtn"} handleClick={this.handleSubmit} />

                                    </Box>
                                </Box>
                            }

                            {this.state.tab == 2 &&
                                <Box className={classes.dataContainer}>
                                    <Box className={`${this.state.categoryList.length > 1 && classes.categoryBox} `}>
                                        <Typography className={classes.categoryTypo}>Categories</Typography>
                                        {this.state.categoryList.map((item: any, index: number) => {

                                            return (
                                                <div key={index}>
                                                    <Box className={classes.categorylabelContainer}>
                                                        <Typography className={classes.categorylabel}>Category {index + 1}</Typography>
                                                        <Button disableRipple
                                                            className={classes.categoryRemoveBtn}
                                                            onClick={() => this.removeCategory(index)}
                                                            data-test-id={`removeCategory-${index + 1}`}>
                                                            <img src={deleteIcon} alt="deleteicon" />
                                                        </Button>
                                                    </Box>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            aria-placeholder="select Category"
                                                            id="demo-simple-select"
                                                            key={index}
                                                            data-test-id={`selectCategory-${index + 1}`}
                                                            className={classes.categorySelect}
                                                            placeholder={"select category"}
                                                            value={item.category.category_name}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            disableUnderline
                                                            onChange={(event) => this.selectCategoryDynamic(event, index)}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: '300px', width: '250px',

                                                                    }
                                                                },
                                                                getContentAnchorEl: null,
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "left",
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem disabled value={""}><span style={{ color: "#737373ad" }}>Select</span></MenuItem>
                                                            {this.state.categoryListName.map((item) => {
                                                                return <MenuItem key={item.category_id} value={item.category_name}>{item.category_name}</MenuItem>
                                                            })}

                                                        </Select>
                                                    </FormControl>
                                                    <Typography className={classes.featureTypo}>Feature</Typography>
                                                    <Typography className={classes.para}>Add features to the box below by clicking in the white area. You can select more than one feature at the same time. You can also type characters to reduce the list. Please select one or more categories first.</Typography>


                                                    <Autocomplete
                                                        multiple
                                                        id="tags-filled"
                                                        options={item.option}
                                                        getOptionLabel={(option) => option.sub_category_name}
                                                        data-test-id={`autocomplete-${index + 1}`}
                                                        value={item.feature}
                                                        disableClearable={true}
                                                        onChange={(event, newValue) => this.onAutoCompleteChange(event, newValue, index)}
                                                        className={classes.autocomplete}
                                                        renderOption={(option, { selected }) => (
                                                            <React.Fragment>
                                                                <Checkbox
                                                                    checkedIcon={<span className={clsx(classes.icon_style_2, classes.checkedIcon_style_2)} />}
                                                                    icon={<span className={classes.icon_style_2} />}
                                                                    style={{
                                                                        color: selected ? "#B300ED" : '#64748B',
                                                                        marginRight: 8
                                                                    }}
                                                                    checked={selected}
                                                                />
                                                                <span className={classes.label}>{option.sub_category_name}</span>

                                                            </React.Fragment>
                                                        )}
                                                        renderTags={(value: readonly string[], getTagProps) =>
                                                            value.map((option: any, index: number) => (
                                                                <Chip variant="outlined" key={index}
                                                                    label={option.sub_category_name} {...getTagProps({ index })}
                                                                    className={classes.chip}
                                                                    style={{ fontSize: '14px', fontFamily: "Poppins" }}
                                                                    deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />}
                                                                />
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                placeholder={item.feature.length > 0 ? "" : "Features"}
                                                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                                                            />
                                                        )}


                                                    />

                                                </div>
                                            )
                                        })}

                                        <Box className={classes.categoryBtnWrap}>
                                            <Button className={classes.addCategoryBtn} onClick={this.addCategory} data-test-id="add-category"><AddCircleOutlineOutlinedIcon className={classes.adddListIcon} />Add more Category</Button>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box className={classes.actionsCatgoryButtons}>
                                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} handleClick={this.handleSubmit} />

                                            
                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {this.state.tab == 3 &&
                                <Box className={classes.dataContainer}>
                                    <Box>
                                        <Typography className={classes.ownershiptypo}>Data Ownership</Typography>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            data-test-id="dataownership"
                                            name="radio-buttons-group"
                                            value={this.state.dataOwnership}
                                            onChange={this.handleChangeDataOwnerShip}
                                        >
                                            {this.state.dataOwnerShipData && this.state.dataOwnerShipData.map((item, index) => {
                                                return <FormControlLabel
                                                    key={index}
                                                    value={item.id}
                                                    control={<Radio checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />} />}
                                                    label={item.attributes.data_ownership_type}
                                                />
                                            })}


                                        </RadioGroup>
                                    </Box>
                                    <Box>
                                        <Box className={classes.actionsCatgory}>
                                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn}  handleClick={this.handleSubmit} />

                                           
                                        </Box>
                                    </Box>
                                </Box>

                            }

                            {this.state.tab == 4 &&
                                <Box className={classes.targetContainer}>
                                    <Box>
                                        <Typography className={classes.targettypo}>Target Users</Typography>
                                        <FormGroup>
                                            {this.state.targetUserArray.map((item, index) => {
                                                { console.log(item, 'targetuser==>') }
                                                return <FormControlLabel
                                                    key={index}
                                                    data-test-id={`target-${index + 1}`}
                                                    checked={this.state.targetuser.includes(item.attributes.id)}
                                                    onChange={(e) => this.handleTargetUser(e, item.attributes.id)}

                                                    control={
                                                        <Checkbox
                                                            data-test-id={`target-${index + 1}`}
                                                            checkedIcon={<BpCheckedIconCheckBox />}
                                                            icon={<BpIconCheckBox />}
                                                            name={item.attributes.target_user_type}
                                                        />
                                                    }
                                                    label={item.attributes.target_user_type}
                                                    classes={{ label: classes.labelTarget }}
                                                />
                                            })}

                                        </FormGroup>

                                    </Box>
                                    <Box>
                                        <Box className={classes.actionsCatgory}>
                                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn}  handleClick={this.handleSubmit} />

                                           
                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {this.state.tab == 5 &&
                                <Box className={classes.platformContainer}>
                                    <Box>
                                        <Typography className={classes.platfomtypo}>Platform</Typography>
                                        <Typography className={classes.platfomrdesctypo}>Enter the platform your product(s) run on</Typography>


                                        <Grid container>
                                            <Grid item md={5}>
                                                <FormGroup>
                                                    {this.state.platformDataOne.map((item, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            data-test-id={`platform-${index + 1}`}
                                                            checked={this.state.platform.includes(item.attributes.id)}
                                                            onChange={(e) => this.handleChangePlatform(e, item.attributes.id)}
                                                            control={
                                                                <Checkbox
                                                                    data-test-id={`platform-${index + 1}`}
                                                                    checkedIcon={<BpCheckedIconCheckBox />}
                                                                    icon={<BpIconCheckBox />}
                                                                    name={item.attributes.platform_type}
                                                                />
                                                            }
                                                            label={item.attributes.platform_type}
                                                            classes={{ label: classes.labelTarget }}
                                                        />
                                                    })}

                                                </FormGroup>


                                            </Grid>
                                            <Grid item md={6}>
                                                <FormGroup>
                                                    {this.state.platfomrDataTwo.map((item, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    checkedIcon={<BpCheckedIconCheckBox />}
                                                                    icon={<BpIconCheckBox />}
                                                                    checked={this.state.platform.includes(item.attributes.id)}
                                                                    onChange={(e) => this.handleChangePlatform(e, item.attributes.id)}
                                                                    name={item.attributes.platform_type}
                                                                />
                                                            }
                                                            label={item.attributes.platform_type}
                                                            classes={{ label: classes.labelTarget }}
                                                        />
                                                    })}

                                                </FormGroup>
                                            </Grid>
                                        </Grid>


                                    </Box>
                                    <Box>
                                        <Box className={classes.actionsCatgory}>
                                           
                                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn}  handleClick={this.handleSubmit} />

                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {this.state.tab == 6 &&
                                <Box className={classes.screenShotContainer}>
                                    <Box>
                                        <Typography data-testid={"screenshots"} className={classes.screenshottypo}>Screenshots</Typography>
                                        <Grid container className={classes.screenshotheader}>
                                            <Grid item xs={6} sm={6} md={6}><Typography>File Name</Typography></Grid>
                                            <Grid item xs={5} sm={5} md={5}><Typography>Size</Typography></Grid>
                                        </Grid>
                                        <Grid container className={classes.screenshotBody} >

                                            {this.state.screenShotArray.map((item, index) => {
                                                return (
                                                    <Grid item container key={index} data-testid={"screenshotgrid"}>
                                                        <Grid item xs={6} sm={6} md={6} className={classes.screenshotitem}><Typography>{decodeURIComponent(item.name)}</Typography></Grid>
                                                        <Grid item xs={5} sm={5} md={5}><Typography>{item.id ? item.size : this.formatFileSize(item.size)}</Typography></Grid>
                                                        <Grid item xs={1} sm={1} md={1} style={{ textAlign: "end" }}>
                                                            <div style={{ cursor: "pointer" }}
                                                                data-testid={"deleteScreenshot"}
                                                                onClick={() => this.removeScreensHot(item)}>
                                                                <img src={deleteIcon} alt="deleteicon" />
                                                            </div></Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        <Box className={classes.imageBtnWrapper}>
                                            <label htmlFor="upload-screenshot" className={classes.addimagebtnScreenshot}><img src={AddIConWhite} alt="AddIConWhite" /><Typography className={classes.add}>Add Image</Typography></label>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                accept="image/*"
                                                onChange={this.onScreenshotUpload}
                                                data-testid={"inputref"}
                                                id="upload-screenshot"
                                            />
                                        </Box>
                                    </Box>

                                    <Box className={classes.maxSizeContainer}>
                                        <Typography className={classes.maxSize}>Max file size 30 Mb</Typography>
                                        <Box className={classes.actionsCatgory}>
                                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn}  handleClick={this.handleSubmit} />

                                        </Box>
                                    </Box>

                                </Box>
                            }

                            {this.state.tab == 7 &&
                                <Box className={classes.screenShotContainer}>
                                    <Box>
                                        <Typography className={classes.screenshottypo}>Files</Typography>
                                        <Typography className={classes.screenshottypodesc}>Add manuals, datasheets or other documents here</Typography>
                                        <Grid container className={classes.screenshotheader}>
                                            <Grid item xs={6} sm={6} md={6}><Typography>File Name</Typography></Grid>
                                            <Grid item xs={5} sm={5} md={5}><Typography>Size</Typography></Grid>
                                        </Grid>
                                        <Grid container className={classes.screenshotBody} >

                                            {this.state.documentArray.map((item, index) => {
                                                return (
                                                    <Grid item container key={index}>
                                                        <Grid item xs={6} sm={6} md={6} className={classes.screenshotitem}><Typography>{decodeURIComponent(item.name)}</Typography></Grid>
                                                        <Grid item xs={5} sm={5} md={5}><Typography>{item.id ? item.size : this.formatFileSize(item.size)}</Typography></Grid>
                                                        <Grid item xs={1} sm={1} md={1} style={{ textAlign: "end" }}>
                                                            <div style={{ cursor: "pointer" }}
                                                                data-testid={"pdf_document"}
                                                                onClick={() => this.removeDocument(item)}>
                                                                <img src={deleteIcon} alt="deleteicon" />
                                                            </div></Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        <Box className={classes.imagefileBtnWrapper}>
                                            <label htmlFor="upload-document" className={classes.addimagebtnScreenshot}><img src={AddIConWhite} alt="AddIConWhite" /><Typography className={classes.add}>Add Files</Typography></label>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                accept=".pdf,.doc,.docx,.xlsx"
                                                onChange={this.onDocumentUpload}
                                                data-testid={"inputrefdocument"}
                                                id="upload-document"
                                            />
                                        </Box>

                                    </Box>

                                    <Box className={classes.maxSizeFileContainer}>
                                        <Typography className={classes.maxSizeFile}>Max file size 30 Mb</Typography>
                                        <Box className={classes.actionsCatgory}>
                                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.handleSubmit} />

                                           
                                        </Box>
                                    </Box>
                                    <Box className={classes.buttonBox}>
                                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                                        <Typography className={classes.fontStyle4} onClick={this.handleOpenFeedbackModal}
                                            data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                                    </Box>
                                    <CustomFormFeedback
                                        data-test-id="closefeedback"
                                        isFeedback={this.state.isFeedback}
                                        handleCloseFeedbackModal={this.handleCloseFeedbackModal}
                                        handleSubmitFeedback={this.handleSubmitFeedbackAboutCustom}
                                        handleFeedbackEmailChange={this.handleFeedbackEmailChange}
                                        handleFeedbackMessageChange={this.handleFeedbackMessageChange}
                                        closeIcon={CloseICon} isValidEmail={this.state.isValidEmail}
                                    />
                                </Box>
                            }

                        </Box>
                    </main>
                </div>

                <CustomToast open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />
                <Dialog
                    open={this.state.error}
                    onClose={this.handleCloseErrorDialog}
                    PaperProps={{ style: { width: "529px", height: "240px", borderRadius: "8px 8px 32px 8px", background: "#FFF", boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)" } }}
                >
                    <Box className={classes.successMedia}>

                        <Typography className={classes.detailSuccessText}>Can't upload file greater than 30mb</Typography>
                        <Button variant="contained" className={classes.backToAccountButton} onClick={this.handleCloseErrorDialog} >Back to Accounts</Button>
                    </Box>
                </Dialog>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export { Feature }
export default withStyles(styles)(Feature)

// Customizable Area End

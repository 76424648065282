import React from "react";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails, userStyles } from "./PremiumListing.web";
// Customizable Area End

// Customizable Area Start
import BasicListingServiceController, {
    Props,
} from "./BasicListingServiceController";
import { Backdrop, Box, CircularProgress, Tab, Tabs, Typography,Link } from "@material-ui/core";
import { logoImage, searchIcon, downArrow } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Reviews from "../../../components/src/Reviews.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import Slider from "react-slick";
import AppBarPage from "../../../components/src/AppBar.web";
import  CustomErrorToast from "../../../components/src/CustomErrorToast.web";

// Customizable Area End

// Customizable Area Start
export class BasicListingService extends BasicListingServiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderItemSimilar = (classes: any) => {
        const settings = {
            speed: 500,
            dots: false,
            infinite: true,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      infinite: true,
                      dots: true
                    }
                  },
               
              ]
            

        };
        return (<div>
            <Box>
                <Slider className={classes.basicSlider} {...settings}>
                    {this.state.similar_listings && this.state.similar_listings.map((list: any) => {
                        return (
                            <div key={list.id+"item"}>
                                 <Link color="inherit" href={`/basiclistingservices/${list.id}`} underline="none">
                               
                                <img className={classes.basicImg} src={list.logo}/>
                                <Typography className={`${classes.marginTop10} ${classes.color666} ${classes.heading12}`}>by {list.name}</Typography>
                                <Typography className={` ${classes.marginTop10} ${classes.heading12} ${classes.ellipsisMultiline}`}>{list.short_description}</Typography>
                            </Link>
                            </div>
                        

                        )
                    })}

                </Slider>

            </Box>
        </div>)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start

        const { isLoading, logo,
            description,
            basicReview,
            name,

        }: any = this.state
        const { classes }: any = this.props
        const tabsArray = ["About", "Reviews"]

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                 handleClick={this.goToLoginPage}
                 dummyicon={""}
                    logoImg={logoImage}
                    downarrow={downArrow}
                    handleUserSignout={this.handleBasicSignout}
                    username={this.state.userdetails}
                    serachIcon={searchIcon}
                    data-testId={"signInBtn"}
                    goToHomePage={()=>this.handleNavigation("Home")}
                    handleHeaderNavigation={()=>this.navigateToAccount(this.state.userdetails?.user_type)}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                   
                />
                <Box className={classes.root}>
                    <Box className={`${classes.container}`}>
                        <Box className={classes.headerContainer}>
                            <ListingHeader userdetails={this.state.userdetails} handleBookmarkDelete={this.handleBookmarkDelete} bookmarked={this.state.bookmarked} domain_url={this.state.domain_url} data-testid="bookmark" handleBookmarkCall={this.handleBookmark} name={name} logo={logo} />

                            <Box className={classes.navBar}>
                                <Tabs
                                    data-testid="tabs"
                                    value={this.state.tabIndex}
                                    onChange={this.handleChange}
                                    centered
                                    classes={{ centered: classes.supplierTabContainer }}
                                    TabIndicatorProps={{ style: { height: '3px', backgroundColor: "#B300ED" } }}
                                >
                                    {tabsArray && tabsArray.map((item: any, index: any) => (
                                        <Tab value={index} data-testid={item} label={item} style={{ color: this.colorDetect(index) }} />

                                    ))}

                                </Tabs>
                            </Box>
                        </Box>

                        <Box id="0-tab" className={classes.accordionContainer}>
                            <Accordion data-testid="aboutDataOpen" onChange={() => this.handleAccordionToggle('aboutDataOpen')} expanded={this.state.aboutDataOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header-test"
                                >
                                    <Typography className={classes.accordionHeading}>About</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={classes.integrationContainer}>
                                        <Box>
                                            <Typography className={`${classes.heading400}`}>
                                                {description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Reviews id="1-tab" hideRatingForm={this.state.hideRatingForm}
                            handleRating={this.handleUserRate}
                            handleClose={this.handleClose}
                            ratingVal={this.state.ratingValue}

                            anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            isEdit={this.state.editReview}
                            data-testid="reviewAccordion" classes={classes}
                            reviewData={basicReview}
                            handleAccordionToggle={this.handleAccordionToggle}
                            expanded={this.state.reviewDataOpen}

                            handleReviewSubmit={this.handleReviewSubmit}

                            loadmore={this.state.loadmore}
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            reviewComment={this.state.reviewText}
                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadBtn}
                            accordionState="reviewDataOpen"
                            filterValue={this.state.filterValue}
                            handleReviewComment={this.handleReviewText}

                            handleEditBtn={this.handleEditBtn}
                            handleReviewUpdate={this.handleReviewUpdate}
                        />

                        <Box className={`${classes.accordionContainer} ${classes.headerContainer}`}>
                            <Box style={{ padding: "30px 40px 0px 40px" }}>
                                <Typography className={classes.similarHeading}>Similar Listings</Typography>

                            </Box>
                            <Box style={{ padding: "0px 30px" }}>
                                {this.renderItemSimilar(classes)}
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <CustomErrorToast data-testid= "customToast" open={this.state.openToast} onClose={this.handleCloseToast}/>

            </div>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
export default withStyles(userStyles)(BasicListingService)
// Customizable Area End

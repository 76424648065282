import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { ChangeEvent } from "react";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleDataSaved:()=>void;
    handleOpenSaveModal:()=>void;
    handleBack:()=>void;


    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    uploadFiles: any[];
    error: boolean;
    isSucessModalOpen: boolean;
    isFeedbackFormOpen: boolean;
    loading: boolean;
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ServicesFilePageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    uploadFilesAPICallId: string = "";
    getserviceFormDetailId: any;
    deleteServicesFilesApiId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            uploadFiles: [],
            error: false,
            isSucessModalOpen: false,
            isFeedbackFormOpen: false,
            loading: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.uploadFilesAPICallId) {
                    const apiresponse = responseJson
                    if (apiresponse) {
                        this.setState({ isSucessModalOpen: true })
                    }
                }
            }

            if (apiRequestCallId == this.getserviceFormDetailId) {
                this.setFilesData(responseJson.data);
            }
            else if (apiRequestCallId === this.deleteServicesFilesApiId) {
                this.setState({ loading: false })
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getServiceFormData();
    }

    // Web Events
    goToVendorAccount = () => {
        if(!this.props.openSaveModal){
           this.props.handleBack()
           
        }
        else  this.props.handleOpenSaveModal()
    }
    handleOpenFeedbackFrom = () => {
        this.setState({ isFeedbackFormOpen: true })
    }

    handleCloseFeedbackForm = () => {
        this.setState({ isFeedbackFormOpen: false })
    }

    onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
       
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const fileName=file.name
                if (file.size > +3e+7 || !fileName.includes(".pdf")) {
                    this.setState({ error: true });
                    return;
                }
                
                this.setState({ uploadFiles: [...this.state.uploadFiles, file] });
            };
            reader.readAsDataURL(file);
        }
    };

    removeDocument = (file: any) => {
        const updateData = this.state.uploadFiles.filter((item) => item.name !== file.name)
        this.setState({ uploadFiles: updateData })

        if (file.id) {
            this.handleServiceFileDelete(file.id)
        }
    }

    formatFileSize = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
    };

    // API
    uploadFilesOnSubmit = async () => {
        this.props.handleDataSaved();
        const categoryId = await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        this.state.uploadFiles.forEach((item) => {
            if (!item?.id) {
                formdata.append("features_pdf[]", item, item.name);
            }

        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadFilesAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleCloseSuccess = () => {
        this.setState({ isSucessModalOpen: false })
    }
    getServiceFormData = async () => {
        const category_Id = await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getserviceFormDetailId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${category_Id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    setFilesData = (data: any) => {
        if (data.catalogue.data.attributes.files) {
            this.setState({ uploadFiles: data.catalogue.data.attributes.features_pdf })
        }

        if (data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }
    handleServiceFileDelete = async (attachment_id: any) => {
        this.setState({ loading: true })
        const category_Id = await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const requestMessageDeleteApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteServicesFilesApiId = requestMessageDeleteApi.messageId;

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.attachmentDeleteEndpoint + `?id=${category_Id}&attachment_id=${attachment_id}&attachment_name=features_pdf`
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );

        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
        return true;
    };

    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Switch
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

// Customizable Area Start
import JoinUsSubscriptionController, {
    Props,
    configJSON,
    mockPath,
    Subscription,
    SubscriptionFeature,
    SubscriptionEvent
} from "./JoinUsSubscriptionController";
import { downArrow, dummyIcon, logoImage, searchIcon } from "./assets";
import AppBarPage from "../../../components/src/AppBar.web";

const plansColors = [{ main: "#6CC9D1", secondary: "#E4FDFF" }, { main: "#F62B8D", secondary: "#FFEEF6" }, { main: "#5C15C2", secondary: "#F6F0FF" }]

// Customizable Area End
export class JoinUsSubscription extends JoinUsSubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderPath = (classes: any) => {
        return (
            <Box className={classes.pathBlock}>
                {mockPath.map((path, index) => {
                    const isPathLast = index === mockPath.length - 1;
                    return (
                        <React.Fragment key={path.name}>
                            <Typography data-testid={"mockpath"} onClick={() => this.goToMockPath(path.path)} className={classes.paths} style={{ color: isPathLast ? "#0F172A" : "#475569" }}>{path.name}</Typography>
                            {!isPathLast && <Typography className={classes.paths} style={{ margin: "0 10px" }}>{">"}</Typography>}
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    }

    renderTabs = (classes: any) => {
        return (
            <Box className={classes.tabsBlock} data-testid={"planTitle"}>
                {this.state.planTabs?.map((tab: string) => (
                    
                    <Typography
                        className={classes.tabItem}
                        style={
                            tab === configJSON.events ?
                                {
                                    color: "#94A3B8",
                                }
                                :
                                {
                                    background: this.state.selectedTab === tab ? "#B300ED" : "none",
                                    color: this.state.selectedTab === tab ? "#FFF" : "#2A0066",
                                    cursor: "pointer",
                                }
                        }
                        onClick={() => this.handleTabChange(tab)}
                        data-testId={`tab${tab}`}
                        key={tab}
                    >
                        {tab}
                    </Typography>
                ))}
            </Box>
        )
    }

    renderPlans = (classes: any) => {
        const subscriptionType = this.state.isYearlyPlanSelected ? "YEARLY" : "MONTHLY";
        const monthlyPlan = this.state.showSelectedPlan?.subscriptions?.filter((planType: any) => !this.state.isYearlyPlanSelected && planType?.type_of === "monthly")
        const yearlyPlan = this.state.showSelectedPlan?.subscriptions?.filter((planType: any) => this.state.isYearlyPlanSelected && planType?.type_of === "yearly")
        const planType = this.state.isYearlyPlanSelected ? yearlyPlan : monthlyPlan
        return (
            <Box className={classes.plansBlock}>
                {
                    planType?.map((planItem: Subscription, index: any) => {
                        const isPlanSelected = this.state.selectedPlanIndex === index;
                        return (
                            <Box
                                key={planItem?.id}
                                className={classes.planBoxStyle}
                                style={{
                                    border: isPlanSelected ? '6px solid #5577FF' : '6px solid transparent',
                                    borderRadius: isPlanSelected ? '12px' : '0',
                                }}
                                onClick={() => this.handlePlanClick(index, planItem)}
                                data-testid="planClick"
                            >
                                <Box className={classes.planHeader}
                                    style={{ background: plansColors[index].main }}>
                                    <Typography variant="h5"
                                        className={classes.planTitle}>
                                        {planItem?.name}
                                    </Typography>
                                    <Typography className={classes.planDescription}>
                                        {planItem?.description}
                                    </Typography>
                                </Box>

                                <Box className={classes.priceBlock} style={{ color: plansColors[index].main }}>
                                    <Typography className={classes.planType}>
                                        {(planItem?.price !== "0.0") ? `$${planItem?.price}` : "FREE"}
                                    </Typography>
                                    <Typography className={classes.planPrice}>
                                        {(planItem?.price !== "0.0") ? subscriptionType : "ALWAYS"}
                                    </Typography>
                                </Box>

                                <Box className={classes.planBody}>
                                    <Box className={classes.featureBlock}>
                                        {planItem?.subscription_features?.map((feature: SubscriptionFeature) => (
                                            <Box className={classes.featureItem} key={feature?.name}>
                                                {feature?.active ? <CheckIcon htmlColor="#059669" /> : <CloseIcon htmlColor="#DC2626" />}
                                                <Typography className={classes.featureNameStyle}>{feature?.name}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box>
                                        {planItem?.subscription_events?.map((item: SubscriptionEvent) => (
                                            <Box className={classes.extraBlock}
                                                style={{ background: plansColors[index].secondary }}
                                                key={item.name}>
                                                <Typography className={classes.fontStyle1}>{item.name}</Typography>
                                                <Typography className={classes.fontStyle2}>{item.description}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box className={classes.buttonBlock}>
                                        <Button className={classes.selectButton} style={{ background: plansColors[index].main }}>{configJSON.Select}</Button>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }

            </Box>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.rootStyle}>
                <AppBarPage
                    handleClick={this.goToLoginPage}
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    username={this.state.Username}
                    dummyicon={dummyIcon}
                    downarrow={downArrow}
                    goToHomePage={this.goTohomePage}
                    handleHeaderNavigation={this.handleheaderNavigation}
                    handleUserSignout={this.handleuserSignout}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleSearchInput={this.handleSearchInput}   
                    handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                    handleSubmit={this.handleSubmitSearch}
                    data-testId={"signInBtn"}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                {this.renderPath(classes)}
                <Box className={classes.targetBox}>
                    <Typography className={classes.fonstStyle1}>{configJSON.title1}</Typography>
                    <Typography className={classes.fonstStyle2}>{configJSON.title2}</Typography>
                </Box>
                {this.renderTabs(classes)}
                <Box className={classes.descriptionBox}>
                    <Typography align="center" className={classes.descriptionFont}>{configJSON.subsDescription}</Typography>
                </Box>

                <Box className={classes.switchBlock}>
                    <Typography className={classes.switchTextStyle}>{configJSON.monthly}</Typography>
                    <StyledSwitch
                        checked={this.state.isYearlyPlanSelected}
                        onChange={this.handlePlanChange}
                        data-testid="switchPlan"
                    />
                    <Typography className={classes.switchTextStyle}>{configJSON.yearly}</Typography>
                </Box>
                {this.renderPlans(classes)}
            </Box>
        );
        // Customizable Area End
    }

}
// Customizable Area Start

export const StyledSwitch = withStyles(() => ({
    root: {
        '& .MuiSwitch-thumb': {
            backgroundColor: '#FFFFFF',
            width: '22px',
            height: '22px',
            borderColor: '#8833FF',
            borderWidth: '2px',
            borderStyle: 'solid'
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#8833FF',
            width: '60px',
            height: '21px',
            borderRadius: '40px'
        },
        '& .MuiSwitch-track': {
            backgroundColor: '#8833FF',
            width: '60px',
            height: '21px',
            borderRadius: '40px'
        }
    }
}))((props: any) => <Switch {...props} />);

export const userStyles = () => ({
    rootStyle: {
        flexGrow: 1,
        backgroundColor: '#f8f2ff',
    },

    planBoxStyle: {
        cursor: 'pointer',
        width: '500px',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '365px',

        }
    },

    targetBox: {
        display: "grid",
        backgroundColor: '#FFFFFF',
        margin: "35px 6vw",
        padding: "25px 30px 30px",
        background: "#FFF",
        borderRadius: 6,
        gap: '20px',
    },

    fonstStyle1: {
        fontSize: '24px',
        fontWeight: 500,
        color: "#262424",
        fontFamily: "Poppins",
        lineHeight: '24px'
    },

    fonstStyle2: {
        fontSize: '18px',
        fontWeight: 500,
        color: "#262424",
        fontFamily: "Poppins",
        lineHeight: '14px'
    },

    descriptionBox: {
        margin: "0 21vw",
    },

    descriptionFont: {
        fontSize: '18px',
        fontWeight: 400,
        color: "#262424",
        fontFamily: "Poppins",
    },

    pathBlock: {
        display: "flex",
        margin: "0 0 0 6vw",
        marginTop:"9%",
        "@media(max-width: 1100px)": {
            marginTop:"16%",
        },
        "@media(max-width: 1000px)": {
            marginTop:"14%",
        },
        "@media(max-width: 760px)": {
            marginTop:"18%",
        },
        "@media(max-width: 620px)": {
            marginTop:"22%",
        },
        "@media(max-width: 480px)": {
            marginTop:"30%",
        },
    },

    paths: {
        fontSize: '14px',
        fontWeight: 400,
        color: "#475569",
        cursor: "pointer",
        fontFamily: "Poppins"
    },

    tabsBlock: {
        display: "flex",
        margin: "35px 16vw 25px",
        background: "#EAD8FE",
        borderRadius: 12,
        height: "4rem",
    },

    tabItem: {
        display: "flex",
        width: "17vw",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 16,
        fontWeight: 700,
        margin: "auto 0",
        borderRadius: 12,
        fontFamily: "Poppins",

        "@media (min-width: 300px) and (max-width: 950px)": {
            fontSize: '14px',
            fontWeight: 400,
        }
    },

    plansBlock: {
        display: "flex",
        margin: "0 5vw",
        justifyContent: "center",
        gap: '20px',
        marginBottom: '50px',

        "@media (min-width: 300px) and (max-width: 950px)": {
            display: "flex",
            flexDirection: 'column'
        }
    },

    planHeader: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        color: "#FFFFFF",
        padding: "0 50px",
        borderRadius: "12px 12px 0px 0px",
        textAlign: 'center' as const
    },

    planTitle: {
        fontWeight: 600,
        paddingTop: '20px',
        color: '#FFFFFF',
        fontSize: '28px',
        fontFamily: 'Poppins',
        lineHeight: '80px'
    },

    planDescription: {
        paddingBottom: 90,
        fontWeight: 400,
        color: '#FFFFFF',
        fontSize: '16px',
        fontFamily: 'Poppins',
        lineHeight: '33px' as const
    },

    planType: {
        fontWeight: 600,
        fontSize: '28px',
        fontFamily: 'Poppins',
    },

    planPrice: {
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: 'Poppins',
    },

    featureNameStyle: {
        fontWeight: 400,
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#3A3A3A'
    },

    fontStyle1: {
        fontWeight: 400,
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#000000'
    },

    fontStyle2: {
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: 'Poppins',
        color: '#000000'
    },

    priceBlock: {
        borderRadius: "50%",
        height: 158,
        width: 158,
        background: "#FFF",
        position: "relative" as const,
        bottom: 50,
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "center"
    },
    planBody: {
        boxShadow: "0px 4px 4px 0px #00000040",
        borderRadius: '0px 0px 12px 12px'
    },

    featureBlock: {
        background: "#FFF",
        marginTop: "-140px",
        padding: "80px 75px 15px",
    },
    featureItem: {
        display: "flex",
        padding: "0.5rem 0",
        gap: "1rem"
    },
    extraBlock: {
        padding: "15px 0 15px 110px",
    },
    buttonBlock: {
        padding: "60px 0px 55px 0px",
        backgroundColor: "#FFFFFF",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectButton: {
        width: "168px",
        height: '48px',
        borderRadius: '8px',
        color: "#FFFFFF",
        textTransform: "none" as const,
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: '16px',
        padding: '16px 71px 16px 71px'
    },

    switchBlock: {
        display: "flex",
        margin: "50px 0",
        justifyContent: "center",
        alignItems: 'center',
        gap: '15px'
    },

    switchTextStyle: {
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: '18px',
        color: '#262424'
    },

    switchRoot: {
        colorSecondary: {
            '&$checked': {
                color: 'red',
            },
            '&$checked + $track': {
                backgroundColor: 'purple',
            },
        },

    },

});

export default withStyles(userStyles)(JoinUsSubscription)
// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    openSaveModal:boolean;
    handleOpenSaveModal:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    contactData: {
        useraddress: string;
        userfburl: string;
        userinstaurl: string;
        userlinkdlnurl: string;
        userphonenumber: string;
        usertwitterurl: string;
        usercontactus: string;
        useryoutubeurl: string;
    };
    updatedContactData: {
        useraddress: string;
        userfburl: string;
        userinstaurl: string;
        userlinkdlnurl: string;
        userphonenumber: string;
        usertwitterurl: string;
        usercontactus: string;
        useryoutubeurl: string;
    };
    isSucess: boolean;
    isFeedback: boolean;
    feedbackEmail: string,
    feedbackMessage: string,
    isValidEmail: { valid: boolean, message: string },
    progressPercentage: number,

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierContactPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getContactListDataAPICalledContactId: string = "";
    updateContactListAPICallIdContactId: string = "";
    feedbackSupContactId: string = "";
    phoneNumber_Reg: RegExp
    emailReg: RegExp;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            contactData: {
                useraddress: '',
                userfburl: '',
                userinstaurl: '',
                userlinkdlnurl: '',
                userphonenumber: '',
                usertwitterurl: '',
                usercontactus: '',
                useryoutubeurl: '',
            },
            updatedContactData: {
                useraddress: '',
                userfburl: '',
                userinstaurl: '',
                userlinkdlnurl: '',
                userphonenumber: '',
                usertwitterurl: '',
                usercontactus: '',
                useryoutubeurl: '',
            },
            isSucess: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        this.phoneNumber_Reg = new RegExp(/^\d*$/)

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getContactListDataSupContact();
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallSupplierContactId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJsonContact = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallSupplierContactId && responseJsonContact) {
                switch (apiRequestCallSupplierContactId) {
                    case this.getContactListDataAPICalledContactId:
                        this.handleContactLitsResponseSupContact(responseJsonContact);
                        break;
                    case this.updateContactListAPICallIdContactId:
                        this.handleUpdateContactListResponseSupContact(responseJsonContact);
                        break;
                    case this.feedbackSupContactId:
                        this.setState({ isFeedback: false })
                        break;
                }
            }
        }
    }

    // Web Events
    goToVendorAccount = () => {
       
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
       
    }

    handleUpdatedContactSupplierData = () => {
        this.setState({ updatedContactData: { ...this.state.contactData } });
    }

    handleChangeAddressSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, useraddress: event.target.value }
        })
    }

    handleChangeFbUrlSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, userfburl: event.target.value }
        })
    }

    handleChangeInstaUrlSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, userinstaurl: event.target.value }
        })
    }

    handleChangeLinkdlnUrlSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, userlinkdlnurl: event.target.value }
        })
    }

    handleChangePhoneNumberSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        const phoneNumber = event.target.value;

        if (this.phoneNumber_Reg.test(phoneNumber) && phoneNumber.length<=15) {
            this.setState({
                updatedContactData: { ...this.state.updatedContactData, userphonenumber: phoneNumber },
            });
        }
    }


    handleChangeTwitterUrlSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, usertwitterurl: event.target.value }
        })
    }

    handleChangeContactUsSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, usercontactus: event.target.value }
        })
    }

    handleChangeYouTubeUrlSupContact = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        this.setState({
            updatedContactData: { ...this.state.updatedContactData, useryoutubeurl: event.target.value }
        })
    }


    getContactListDataSupContact = async () => {
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getContactListDataAPICalledContactId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    updateContactListDataSupContact = async () => {
        this.props.handleDataSaved();
        const categoryID = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        formdata.append("[contact][address]", this.isValidValue( this.state.updatedContactData?.useraddress));
        formdata.append("[contact][linkdlnurl]", this.isValidValue(this.state.updatedContactData?.userlinkdlnurl))
        formdata.append("[contact][phonenumber]",this.isValidValue(this.state.updatedContactData?.userphonenumber))
        formdata.append("[contact][instaurl]", this.isValidValue(this.state.updatedContactData?.userinstaurl))
        formdata.append("[contact][youtubeurl]", this.isValidValue(this.state.updatedContactData?.useryoutubeurl))
        formdata.append("[contact][twitterurl]", this.isValidValue(this.state.updatedContactData?.usertwitterurl))
        formdata.append("[contact][contactus]", this.isValidValue(this.state.updatedContactData?.usercontactus))
        formdata.append("[contact][fburl]", this.isValidValue(this.state.updatedContactData?.userfburl))

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateContactListAPICallIdContactId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    isValidValue=(value:any)=>{
        if(value){
            return value;
        }
        else return "";
    }

    handleSubmitFeedbackContact = async (event: any) => {
        let formdata = new FormData();
        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailSupContact(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })
        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackSupContactId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // API Response
    handleContactLitsResponseSupContact = (responseJson: any) => {
        const apiresponse = responseJson?.data?.catalogue?.data?.attributes?.contact
        if (apiresponse) {
            this.setState({
                contactData: {
                    ...this.state.contactData,
                    useraddress: apiresponse?.address,
                    userfburl: apiresponse?.fburl,
                    userinstaurl: apiresponse?.instaurl,
                    userlinkdlnurl: apiresponse?.linkdlnurl,
                    userphonenumber: apiresponse?.phonenumber,
                    usertwitterurl: apiresponse?.twitterurl,
                    usercontactus: apiresponse?.contactus,
                    useryoutubeurl: apiresponse?.youtubeurl
                }
            },
                () => this.handleUpdatedContactSupplierData()
            )
        }
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }


    handleUpdateContactListResponseSupContact = (responseJson: []) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucess: true })
            this.getContactListDataSupContact()
        }
    }

    handleCloseSuccessDialog = () => {
        this.setState({ isSucess: false });
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleFeedbackEmailChangeContact = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChangeContact = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailSupContact = (email: string) => {
        const emailRegex = this.emailReg;

        return emailRegex.test(email);
    }
    goBack=()=>{
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
        this.props.handleBack();
        }
    }
    // Customizable Area End
}
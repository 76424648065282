import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const mockPaths = [{
  name: "Home",
  path: "LandingPage"
},

{
  name: "Join Us",
  path: "JoinUsLandingPage"
}
]
export interface JoinUsDataAttributes {
  id: number;
  title: string;
  subtitle: string;
  main_title: string | null;
  text: string;
  user_type: string;
}

export interface JoinUsDataItem {
  id: string;
  type: string;
  attributes: JoinUsDataAttributes;
}

export interface JoinUsApiResponse {
  data: JoinUsDataItem[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  getContents: JoinUsApiResponse | null;
  Username: any;
  popoverAnchorEl: HTMLElement | null;
  loginToken: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class JoinUsLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  joinUsContentAPICallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      getContents: null,
      Username: null,
      popoverAnchorEl: null,
      loginToken: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.joinUsContentAPICallId) {
          const apiresponse = responseJson
          if (apiresponse) {
            this.setState({
              getContents: apiresponse,
            })
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.JoinUsContentAPI()

    const storedData = await getStorageData("userdetails");
    const userType = await getStorageData("user_type");
    const accountDetails = JSON.parse(storedData);
    this.setState({ Username: accountDetails });

    const logintoken = await getStorageData("loginToken");
    this.setState({ loginToken: logintoken });
    const buyerRoutesUrls = [
      "/VendorAccountDetails",
      "/joinus",
      '/JoinUsSubscription',
      '/SearchListing',
      "/ListingCheckOutPage",
      "/PlanDetail",
      "/PlanSelection",
    ];
    if(storedData && userType === "Buyer" && buyerRoutesUrls.some(route => window.location.pathname.startsWith(route))){
      window.location.href = "/BuyerAccountDetails";
    }
  }

  goToLoginPage = () => {
    this.props.navigation.navigate("JoinUsLandingPage2", { type: "EmailAccountLoginBlock" })
  }

  goToSupplier = (category: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "JoinUsSubscription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { proKey: category });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  goToMockPath = (path: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToVendor = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  navigateToHome = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleHeaderNavigation = async () => {
    const usertype = await getStorageData("user_type")
    if (usertype == "vendor" || "Vendor") {
      this.navigateToVendor()
    }
  }

  handleUserSignout = async () => {
    await removeStorageData("loginToken")
    this.navigateToHome()
  }

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ popoverAnchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchorEl: null });
  };

  JoinUsContentAPI = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.joinUsContentAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.joinusConteAPIEndPOint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export const configJSON = require("./config");

export const drawerWidth = 265;


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleDataSaved:()=>void;
    handleOpenSaveModal:()=>void;
    handleBack:()=>void;
    // Customizable Area End

}

export interface S {
    // Customizable Area Start
    openDetailsModal: boolean;
    tab: number,
    resturantTypeData: any[],
    dummyresturantTypeData: any[],
    dummycheckedResturant: any[],
    categoryListData: any[],
    categoryListName: any[],
    categoryList: any[],
    dataOwnership: string,
    targetUserArray: any[],
    platformData: any[],
    platformDataOne: any[],
    platfomrDataTwo: any[],
    targetuser: any[],
    platform: string[],
    dataOwnerShipData: any[],
    screenShotArray: any[],
    editProfilePic: any,
    imagePreview: any,
    documentArray: any[],
    updatedSuccessDialog: boolean,
    error: boolean,
    mobileOpen: boolean,
    isLoading: boolean,
    progressPercentage: null;
    isFeedback: boolean;
    isValidEmail: { isValid: boolean, message: string };
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class FeatureController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRestaurantTypeId: string = "";
    getCategoryId: string = "";
    formSubmitid: string = "";
    getCustomFormDetailId: string = "";
    getplatformId: string = "";
    getdataownershipId: string = "";
    getTargetUserId: string = "";
    deleteFilesAPICallId: string = "";
    feedbackFormAPICallId: string = "";
    deleteCategoryAPICallId: string="";    
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            openDetailsModal: false,
            tab: 3,
            resturantTypeData: [],
            dummyresturantTypeData: [],
            dummycheckedResturant: [],
            categoryListData: [],
            categoryListName: [],
            categoryList: [{ category: { category_name: "", category_id: 0 }, feature: [], option: [] }],
            dataOwnership: "",
            targetUserArray: [],
            platformData: [],
            platformDataOne: [],
            platfomrDataTwo: [],
            targetuser: [],
            platform: [],
            dataOwnerShipData: [],
            screenShotArray: [],
            editProfilePic: "",
            imagePreview: "",
            documentArray: [],
            updatedSuccessDialog: false,
            error: false,
            mobileOpen: false,
            isLoading: false,
            progressPercentage: null,
            isFeedback: false,
            isValidEmail: { isValid: false, message: "" },
            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }


    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.formSubmitid:
                        this.setState({ isLoading: false, updatedSuccessDialog: true })
                        break;
                    case this.getCategoryId:
                        const categoriesName = responseJson?.map((item) => ({
                            category_id: item.category_id,
                            category_name: item.category_name
                        }));
                        this.setState({ categoryListData: responseJson, categoryListName: categoriesName })
                        break;
                    case this.getRestaurantTypeId:
                        this.setState({ dummyresturantTypeData: responseJson.data })
                        this.ResturantApiResponse(responseJson.data)
                        break;
                    case this.getplatformId:
                        this.setState({ platformData: responseJson.data })
                        this.PlatformApiResponse(responseJson.data)
                        break;
                    case this.getdataownershipId:
                        this.setState({ dataOwnerShipData: responseJson.data });
                        break;
                    case this.getTargetUserId:
                        this.setState({ targetUserArray: responseJson.data });
                        break
                    case this.getCustomFormDetailId:
                        this.handleSetInitidata(responseJson);
                        break;
                    case this.deleteFilesAPICallId:
                        this.setState({ isLoading: false });
                        break;
                    case this.feedbackFormAPICallId:
                        this.setState({ isFeedback: false });
                        break; 
                    default:
                        break;
                }
            }
        }
        // Customizable Area End
    }

    ResturantApiResponse = (data) => {
        let result = data?.flatMap(item => {
            if (item.attributes.restauran_sub_type) {
                return item.attributes.restauran_sub_type
                    .filter(subType => subType.active)
                    .map(subType => subType.id);
            }
            return [];
        });
        this.setState({ dummycheckedResturant: result })
    }

    PlatformApiResponse = (data) => {
        if (data) {
            const totalCount = data.length;
            const leftCount = Math.ceil(totalCount / 2);
            const leftArray = data.slice(0, leftCount);
            const rightArray = data.slice(leftCount, leftCount + (totalCount - leftCount));

            this.setState({ platformDataOne: leftArray })
            this.setState({ platfomrDataTwo: rightArray })
        }
    }

    componentDidMount(): any {
        this.dummygetResturantTypeOne();
        this.getCategoryList()
        this.getTargetUsers()
        this.getPlatforms()
        this.getDataOwnerShip()
        this.getCustomFormDetail()

    }

    handleSetInitidata = (response: any) => {
        if (response) {
            this.setState({ isLoading: false })
        }
        this.setInitialData(response?.data)
        this.props.incrementProgress(this.state.progressPercentage)
    }

    setInitialData = (data: any) => {
        if (data.catalogue.data.attributes?.target_user) {
            let target = data.catalogue.data.attributes.target_user
            let updatedtarget = target.map((item) => item.id)
            this.setState({ targetuser: updatedtarget })
        }

        if (data.catalogue.data.attributes?.platform) {
            let platform = data.catalogue.data.attributes.platform
            let updatedplatform = platform.map((item) => item.id)
            this.setState({ platform: updatedplatform })
        }

        if (data.catalogue.data.attributes?.data_ownership) {
            let updateddata = data.catalogue.data.attributes.data_ownership
            const activedata = updateddata.map((item) => item.id)

            if (activedata.length > 0) {
                this.setState({ dataOwnership: `${activedata[0]}` })
            }
        }
        if (data.catalogue.data.attributes?.categories) {
            const allcategories = data.catalogue.data.attributes.categories

            const output1 = allcategories.map(category => ({
                category: {
                    category_name: category.category_name,
                    category_id: category.category_id
                },
                feature: category.sub_categories.map(subCategory => ({
                    sub_category_id: subCategory.id,
                    sub_category_name: subCategory.name
                })),
                option: category.options.map(subCategory => ({
                    sub_category_id: subCategory.id,
                    sub_category_name: subCategory.name
                }))
            }));

            this.setState({ categoryList: output1 })
        }

        if (data.catalogue.data.attributes?.restaurant_type?.data) {
            this.ResturantApiResponse(data.catalogue.data.attributes?.restaurant_type?.data)
        }

        if (data.catalogue.data.attributes?.features_pdf) {
            this.setState({
                documentArray: data.catalogue.data.attributes?.features_pdf
            })
        }
        if (data.catalogue.data.attributes?.features_documents) {
            this.setState({
                screenShotArray: data.catalogue.data.attributes?.features_documents
            })
        }
        if (data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: data.catalogue.data.attributes.progress_percentage })
        }
    }

    handleSubmit = async () => {
        this.props.handleDataSaved()
        this.setState({ isLoading: true })
        const categoryID = await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: await getStorageData("loginToken"),
        };

        formdata.append("restaurant_type", JSON.stringify(this.state.dummycheckedResturant));
        if (this.state.dataOwnership) {
            formdata.append("data_ownership_ids[]", ([this.state.dataOwnership]));
        }
        if (this.state.targetuser.length > 0) {
            this.state.targetuser.forEach((item) => {
                formdata.append("target_user_ids[]", item);
            })
        }
        else{
            formdata.append("target_user_ids[]","");

        }
        if (this.state.platform.length > 0) {
            this.state.platform.forEach((item) => {
                formdata.append("platform_ids[]", item);
            })
        }
        else{
            formdata.append("platform_ids[]","");
        }

        this.state.categoryList.forEach((item) => {
            let subCategory = item.feature
            formdata.append("category_ids[]", item.category.category_id);
            if (subCategory && subCategory.length) {
                subCategory.forEach((item) => {
                    formdata.append("sub_category_ids[]", item.sub_category_id);
                })
            }else{
                formdata.append("sub_category_ids[]", "");
            }
        })
        

        this.state.screenShotArray.forEach((item) => {
            !item?.id && formdata.append("features_documents[]", item, item.name);
        })

        this.state.documentArray.forEach((item) => {
            !item?.id && formdata.append("features_pdf[]", item, item.name);
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmitid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    getCustomFormDetail = async () => {
        this.setState({ isLoading: true })
        const categoryID = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    dummygetResturantTypeOne = async (event: any) => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getRestaurantTypeId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.restaurantTypes
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCategoryList = async (event: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCategoryId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.categoryList}?directory_name=${"Software"}` 
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPlatforms = async (event: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getplatformId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.platforms
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDataOwnerShip = async (event: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getdataownershipId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dataOwnership
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTargetUsers = async (event: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTargetUserId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.targetusers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDeleteFiles = async (attachment_id: number, type: string) => {
        const documentType = type == "screenshot" ? "features_documents" : "features_pdf"
        this.setState({ isLoading: true })
        const categoryid = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const requestMessageDeleteApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteFilesAPICallId = requestMessageDeleteApi.messageId;

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.attachmentDeleteEndpoint + `?id=${categoryid}&attachment_id=${attachment_id}&attachment_name=${documentType}`
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );
        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
    };

    toggleTab = (value: number) => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
            if (this.state.mobileOpen) {
                this.handleDrawerToggle()
            }
            this.setState({ tab: value })
        }
       
    }

    addCategory = () => {
        this.setState({ categoryList: [...this.state.categoryList, { category: { category_name: "", category_id: 0, }, feature: [], option: [] }] })
    }

    removeCategory = (value: number) => {
        let updatedCategory = this.state.categoryList.filter((item, index) => {
            return index !== value
        })
        if(value==0){
            this.handleRemoveCategoryAPICall()
        }
        this.setState({ categoryList: updatedCategory })
       
    }

    selectCategoryDynamic = (event: any, index: any) => {
        let categoryName = event.target.value;
        let categoryId = this.state.categoryListName.find(
            (item) => item.category_name === categoryName
        )?.category_id;

        let options = this.state.categoryListData
            .find((item) => item.category_id === categoryId)
            ?.sub_categories

        let updatedCategory = this.state.categoryList.map((item, currentIndex) => {
            if (currentIndex === index) {
                return {
                    ...item,
                    category: { category_name: categoryName, category_id: categoryId },
                    option: options || [],
                };
            } else {
                return item;
            }
        });

        this.setState({ categoryList: updatedCategory })

    };

    onAutoCompleteChange = (event: any, newValue: any, index: number) => {
        let updatedCategory = this.state.categoryList.map((item, listindex) => {
            if (listindex == index) {
                return { ...item, feature: newValue }
            } else {
                return item
            }
        })
        this.setState({ categoryList: updatedCategory })
        this.props.handleDataChanged()
    }

    handleChangeDataOwnerShip = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ dataOwnership: event.target.value })
        this.props.handleDataChanged()
    };

    handleTargetUser = (event, id) => {
        const { checked } = event.target;
        const { targetuser } = this.state;

        const updatedItems = checked
            ? [...targetuser, id]
            : targetuser.filter(item => item !== id);

        this.setState({
            targetuser: updatedItems,
        });
        this.props.handleDataChanged()
    };

    handleChangePlatform = (event, id) => {
        const { checked } = event.target;
        const { platform } = this.state;

        const updatedItems = checked
            ? [...platform, id]
            : platform.filter(item => item !== id);

        this.setState({
            platform: updatedItems,
        });
        this.props.handleDataChanged()
    };

    dummyhandleChangeResturant = (event, id) => {
        const { checked } = event.target;
        const { dummycheckedResturant } = this.state;

        const updatedItems = checked
            ? [...dummycheckedResturant, id]
            : dummycheckedResturant.filter(item => item !== id);

        this.setState({
            dummycheckedResturant: updatedItems,
        });
        this.props.handleDataChanged()
    };

    checkAllSubcategory = (event, subcategories) => {
        this.props.handleDataChanged()
        const { checked } = event.target;

        if (checked) {
            const allSubcategoryIds = subcategories.map(subItem => subItem.id);
            this.setState({
                dummycheckedResturant: [...this.state.dummycheckedResturant, ...allSubcategoryIds],
            });
        } else {
            const subcategoryIdsToRemove = subcategories.map(subItem => subItem.id);
            const updatedItems = this.state.dummycheckedResturant.filter(item => !subcategoryIdsToRemove.includes(item));
            this.setState({
                dummycheckedResturant: updatedItems,
            });
        }
    };

    onScreenshotUpload = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                editProfilePic: file,
                imagePreview: reader.result
            });

            if (file.size > +3e+7) {
                this.setState({ error: true })
                return
            }

            this.setState({ screenShotArray: [...this.state.screenShotArray, file] })
        }
        reader.readAsDataURL(file);
    };

    formatFileSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        if (bytes === 0) return '0 Byte';

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

        return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
    }

    removeScreensHot = (screenshotFiles) => {
        const updateData = this.state.screenShotArray.filter((item) => item.name !== screenshotFiles.name)
        
        this.setState({ screenShotArray: updateData })
        if (screenshotFiles.id) {
            this.handleDeleteFiles(screenshotFiles.id, 'screenshot')
        }
    }

    onDocumentUpload = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.handleDataChanged()
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {

            if (file.size > +3e+7) {
                this.setState({ error: true })
                return
            }

            this.setState({ documentArray: [...this.state.documentArray, file] })
        }
        reader.readAsDataURL(file);
    };

    removeDocument = (documentname) => {
        const updateData = this.state.documentArray.filter((item) =>  item.name !== documentname.name)
        this.setState({ documentArray: updateData })
        if (documentname.id) {
            this.handleDeleteFiles(documentname.id, 'document')
        }
    }


    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleCloseErrorDialog = () => {
        this.setState({ error: false });
    }
    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
            this.props.handleBack()
        }
       
    }


    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    };

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { isValid: false, message: "" } })
    }

    handleSubmitFeedbackAboutCustom = async () => {
        let formdata = new FormData();
        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { isValid: true, message: "Email is required" } })
            return
        }
        else if (!this.validateEmailAbout(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { isValid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { isValid: false, message: "" } })
        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackFormAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }
    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }
    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    handleRemoveCategoryAPICall=async ()=>{
        const category_id=this.state.categoryList[0].category.category_id
     if(this.state.categoryList.length && category_id){
        const categlogueId = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const requestMessageDeleteApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteCategoryAPICallId = requestMessageDeleteApi.messageId;

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.categoryDeleteEndpoint}?id=${categlogueId}&category_id=${category_id}`
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );
        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
     }
    }
    // Customizable Area End
}

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Icon,
  // Customizable Area Start
  Switch,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { logoImage } from "../../customisableusersubscriptions/src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    fontFamily: "Poppins",
    fontSize: 18
  },
  overrides: {
    MuiSwitch: {
      root: {
        padding: 6
      },
      colorSecondary: {
        "&$checked": {
          color: "#FFF"
        }
      },
      track: {
        borderRadius: 40,
        backgroundColor: "#8833FF",
        opacity: 1,
        "$checked$checked + &": {
          backgroundColor: "#8833FF",
          opacity: 1,
        }
      }
    }
  }
});

const advancedPlan = {
  description: "Showcase your business with rich media and drive traffic to your website",
  price: 29.99,
  features: [
    {name: "Logo", included: true},
    {name: "Short Description", included: true},
    {name: "Contact Info", included: true},
    {name: "Similar Listings", included: false},
    {name: "Integrations", included: true},
    {name: "Distributors/Brands Linking", included: true},
    {name: "Events I'm Attenging", included: true},
    {name: "Photos", included: true},
    {name: "Videos", included: true},
    {name: "Support Links", included: true},
    {name: "Call to action button", included: true},
    {name: "Verified Check", included: true},
    {name: "Upload reviews from Google", included: false},
    {name: "Respond to Reviews", included: true},
    {name: "Analytic Dashboard", included: true},
    {name: "Catalog Upload (required for item specific searching, Suppliers directory only)", included: true},
    {name: "In Good Company Badges", included: true},
    {name: "PR Marketing Deck", included: true},
  ],
  extra:[
    {name: "Featured Listing", value: "Extra $$"},
    {name: "Hosting Events",value: "Extra $$"}
  ]
}

const mockPath = ["Home", "Join Us", "Subscriptions"]
const mockTabs = ["Suppliers", "Softwares", "Services", "Events"]
const mockPlans: any = [
  {
    name: "BASIC",
    description: "Everything you need to get started to showcase your product to restaurants",
    features: [
      {name: "Logo", included: true},
      {name: "Short Description", included: true},
      {name: "Contact Info", included: true},
      {name: "Similar Listings", included: true},
      {name: "Integrations", included: true},
      {name: "Distributors/Brands Linking", included: true},
      {name: "Events I'm Attenging", included: true},
      {name: "Photos", included: true},
      {name: "Videos", included: false},
      {name: "Support Links", included: false},
      {name: "Call to action button", included: false},
      {name: "Verified Check", included: false},
      {name: "Upload reviews from Google", included: false},
      {name: "Respond to Reviews", included: false},
      {name: "Analytic Dashboard", included: false},
      {name: "Catalog Upload (required for item specific searching, Suppliers directory only)", included: false},
      {name: "In Good Company Badges", included: false},
      {name: "PR Marketing Deck", included: false},
    ],
    extra:[
      {name: "Featured Listing", value: "Not available"},
      {name: "Hosting Events",value: "Extra $$"}
    ]
  },
  {
    name: "ENHANCED",
    ...advancedPlan
  },
  {
    name: "GLOBAL",
    ...advancedPlan
  },
]

const plansColors = [{main: "#6CC9D1", secondary: "#E4FDFF"}, {main: "#F62B8D", secondary: "#FFEEF6"}, {main: "#5C15C2", secondary: "#F6F0FF"}]
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.billingBlock}>
          <Box sx={webStyle.toolBar}>
            <img src={logoImage} style={webStyle.logoStyle}/>
            <Input
              data-test-id={"txtInput"}
              type="text"
              placeholder={configJSON.txtInputPlaceholder}
              fullWidth={true}
              disableUnderline={true}
              value={this.state.txtInputValue}
              onChange={(e) => this.setInputValue(e.target.value)}
              style={webStyle.inputStyle}
              startAdornment={
                <InputAdornment position="start" style={{padding: "0 0 0 10px"}}>
                      <SearchIcon />
                </InputAdornment>
              }
            />
            <Button style={{...webStyle.buttonStyle, textTransform: "none"}}>{configJSON.btnSignIn}</Button>
          </Box>
          <Box style={webStyle.pathBlock}>
            {mockPath.map((path, index) => {
              const isPathLast = index === mockPath.length - 1
              return (
              <>
                <Typography style={{...webStyle.paths, color: isPathLast ? "#0F172A" : "#475569"}} key={path}>{path}</Typography>
                {!isPathLast && <Typography style={{...webStyle.paths, margin: "0 10px"}}>{">"}</Typography>}
              </>
            )})}
          </Box>
          <Box sx={webStyle.titleBlock}>
            <Typography variant="h5" style={webStyle.title}>
              Reach Your Exact Target Audience
            </Typography>
            <Typography variant="h6" style={webStyle.title}>
              The best marketing investment for restaurant suppliers at the right price
            </Typography>
          </Box>
          <Box style={webStyle.tabsBlock}>
            {mockTabs.map(tab => {
              const isTabSelected = this.state.selectedTab === tab
              return (
              <Typography 
                style={{...webStyle.tabItem, background: isTabSelected ? "#B300ED" : "none", color: isTabSelected ? "#FFF" : "#2A0066"}}
                onClick={() => this.handleTabChange(tab)}
                data-test-id={`tab${tab}`}
                key={tab}
              >
                {tab}
              </Typography>
            )})}
          </Box>
          <Box style={webStyle.description}>
            <Typography align="center">
              Whether you're in the business of food, beverage, table linens, 
              restaurant cleaning supplies or even merchandise, we're your one 
              stop connection to the industry folks doing the ordering and planning. 
              Our supplier directories and listings are where the rubber meets the 
              road for the food and beverage community. Claim your listing today.
            </Typography>
          </Box>
          <Box style={webStyle.switchBlock}>
            <Typography style={{paddingTop: 6}}>Monthly</Typography>
            <Switch checked={this.state.isYearlyPlanSelected} onChange={this.handlePlanChange}/>
            <Typography style={{paddingTop: 6}}>Yearly</Typography>
          </Box>
          <Box style={webStyle.plansBlock}>
            {mockPlans.map((plan: any, planIndex: number) => (
              <Box key={plan.name}>
                <Box style={{...webStyle.planHeader, background: plansColors[planIndex].main}}>
                  <Typography variant="h5" style={webStyle.planTitle}>{plan.name}</Typography>
                  <Typography style={webStyle.planDescription}>{plan.description}</Typography>
                </Box>
                <Box style={{...webStyle.priceBlock, color: plansColors[planIndex].main}}>
                    <Typography variant="h5" style={{fontWeight: 600}}>{plan.price ? `$${plan.price}` : "FREE"}</Typography>
                    <Typography>{plan.price ? this.state.isYearlyPlanSelected ? "YEARLY" : "MONTHLY" : "ALWAYS"}</Typography>
                </Box>
                <Box style={webStyle.planBody}>
                  <Box style={webStyle.featureBlock}>
                  {plan.features.map((feature: any) => (
                    <Box style={webStyle.featureItem} key={feature.name}>
                      {feature.included ? <CheckIcon htmlColor="#059669"/> : <CloseIcon htmlColor="#DC2626"/>}
                      <Typography>{feature.name}</Typography>
                    </Box>
                  ))}
                  </Box>
                  <Box>
                    {plan.extra.map((item: any) => (
                      <Box 
                        style={{...webStyle.extraBlock, background: plansColors[planIndex].secondary}}
                        key={item.name}
                      >
                        <Typography>{item.name}</Typography>
                        <Typography style={{fontSize: 14}}>{item.value}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box style={webStyle.buttonBlock}>
                    <Button style={{...webStyle.selectButton, background: plansColors[planIndex].main}}>Select</Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  billingBlock: {
    background: "#F8F2FF",
    fontFamily: "Poppins",
    paddingBottom: 60
  },
  toolBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "25px 0",
    background: "linear-gradient(90deg, rgb(123, 53, 196), rgb(143, 109, 243),rgb(194, 134, 241))",
  },
  logoStyle: {
    height: '30px',
    width: '130px',
    margin: "auto 6vw"
  },
  inputStyle: {
    border: "1px solid #CBD5E1",
    borderRadius: 8,
    width: "100%",
    height: 56,
    background: "#FFF"
  },
  buttonStyle: {
    width: 90,
    height: 44,
    border: "none",
    borderRadius: 8,
    color: "#6200EA",
    background: "#FFF",
    margin: "auto 3vw auto 9vw",
    fontWeight: 700,
    fontSize: 16,
    flexShrink: 0,
    cursor: "pointer"
  },
  pathBlock: {
    display: "flex",
    margin: "15px 0 0 6vw",
  },
  paths: {
    fontSize: 14,
    color: "#475569",
    cursor: "pointer"
  },
  titleBlock: {
    display: "grid",
    margin: "35px 6vw",
    padding: "35px 40px 40px",
    background: "#FFF",
    borderRadius: 6,
    gap: 20,
  },
  title: {
    fontWeight: 500
  },
  tabsBlock: {
    display: "flex",
    margin: "35px 16vw 25px",
    background: "#EAD8FE",
    borderRadius: 12,
    height: "4rem"
  },
  tabItem: {
    display: "flex",
    width: "17vw",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 700,
    margin: "auto 0",
    borderRadius: 12,
    cursor: "pointer"
  },
  description: {
    margin: "0 20vw",
    color: "#262424"
  },
  switchBlock: {
    display: "flex",
    margin: "50px 0",
    justifyContent: "center",
    gap: 20
  },
  plansBlock: {
    display: "flex",
    margin: "0 6vw",
    justifyContent: "space-between",
    gap: 30,
  },
  planHeader: {
    color: "#FFF",
    padding: "0 50px",
    textAlign: "center" as "center",
    borderRadius: "12px 12px 0px 0px"
  },
  planTitle: {
    fontWeight: 600,
    lineHeight: "4.5rem",
    paddingTop: 20
  },
  planDescription: {
    lineHeight: "2rem",
    paddingBottom: 90,
    fontSize: 16
  },
  priceBlock: {
    borderRadius: "50%",
    height: 158,
    width: 158,
    background: "#FFF",
    position: "relative" as "relative",
    bottom: 50,
    left: 120,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center"
  },
  planBody: {
    boxShadow: "0px 4px 4px 0px #00000040"
  },
  featureBlock: {
    background: "#FFF",
    marginTop: "-140px",
    padding: "80px 50px 15px",
  },
  featureItem: {
    display: "flex",
    padding: "0.5rem 0",
    gap: "1rem"
  },
  extraBlock: {
    padding: "15px 0 15px 110px",
  },
  buttonBlock: {
    padding: "60px 120px",
  },
  selectButton: {
    width: "100%",
    color: "#FFF",
    textTransform: "none" as "none",
    fontWeight: 400
  }
};
// Customizable Area End

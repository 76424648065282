import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Dialog,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { closeIcon, groupEmail, log_image } from "./assets";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

// Customizable Area End

// Customizable Area Start
import ForgotPasswordController, {
  Props, configJSON,
} from "./ForgotPasswordController";

// Customizable Area End
export class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes }: any = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Dialog
          open={this.state.isOpenModal}
          maxWidth="md"
          PaperProps={{ className: classes.dialogStyle }}
          onClose={this.handleCloseModal}
        >
          
          <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ padding: '65px' }}>
          <Grid xs={12} className={classes.closeIconGridStyle}>
            <img src={closeIcon}
              className={classes.closeIconStyle2}
              onClick={this.handleCloseModal}
            />
          </Grid>
            <Grid item xs={12} className={classes.emailIconGridStyle}>
              <img src={log_image} className={classes.logoImageStyle} />
            </Grid>

            <Grid item xs={12} >
              <Typography className={classes.fontStyle1}>{configJSON.noworries}</Typography>
              <Typography className={classes.fontStyle1}>{configJSON.resetpass}</Typography>
            </Grid>

            <Grid item xs={12} >
              <Typography className={classes.fontStyle2}>{configJSON.enteroldemail}</Typography>
            </Grid>

            <Grid item xs={12} >
              <StyledTextField
                variant="outlined"
                placeholder={configJSON.placeHolderEmail}
                fullWidth
                data-testid={"emailchange"}
                name="email"
                value={this.state.email}
                onChange={this.handleChangeEmail}
                error={this.state.isEmailError}
                helperText={
                  this.state.isEmailError &&
                  <Box className={classes.errorBoxStyle}>
                    {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                    <Typography style={{
                      color: "#D53333",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}>
                      {this.state.emailErrorMsg || this.state.failedErrorMsg}
                    </Typography>
                  </Box>
                }
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.nameTextfeild}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained"
                type="submit"
                onClick={this.forgotPasswordAPI}
                className={classes.buttonBackground}
                data-testid={"login-button"} >{configJSON.continuwBtn}</Button>
            </Grid>
          </Grid>
        </Dialog>

        {this.state.showMessageSent &&
          <Dialog
            open={true}
            maxWidth="md"
            PaperProps={{ className: classes.dialogStyle }}
            onClose={this.handleCloseModal}
            data-testid={"closemodal"}
          >
            <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ padding: '30px' }}>
              <Grid item xs={12} className={classes.cancelGridStyle}>
                <img
                  data-testid={"closeinstruction"}
                  onClick={this.handleCloseModal}
                  src={closeIcon}
                  className={classes.closeIconStyle}
                />
              </Grid>
              <Grid item xs={12} className={classes.emailIconGridStyle}>
                <img src={groupEmail} className={classes.imageStyle} />
              </Grid>

              <Grid item xs={12} className={classes.textGridStyle}>
                <Typography className={classes.recoverFontStyle}>{configJSON.recover}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.textGridStyle}>
                <Typography className={classes.insFontStyle}>{this.state.sucessMessage}</Typography>
              </Grid>
            </Grid>
          </Dialog>
        }
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export const StyledTextField = withStyles({
  root: {
    fontSize: "14",
    "& input::placeholder": {
      fontSize: "14",
      fontWeight: 400,
      fontFamily: "Poppins"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD",
    },
    "& .MuiInputBase-input-283:focus": {
      backgroundColor: "transparent",
      outline: 'none'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF",
    },
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#FFFFFF",
      margin: 0,
      paddingLeft: 10
    },
  },
})((props: any) => <TextField {...props} />);

export const userStyles = (theme: any) => ({
  dialogStyle: {
    overflow: 'hidden',
    justifyContent: 'center',
    width: '510px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ' #FFFFFF',
  },
  imageStyle: {
    height: '105px',
    width: '105px',
  },

  errorIconStyle: {
    fontSize: '18px',
    margin: '3px 10px 0px -10px',
  },

  errorBoxStyle: {
    display: 'flex',
    marginTop: '2px',
  },

  cancelGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  logoImageStyle: {
    height: '56px',
    width: '243px',
  },

  emailIconGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  closeIconStyle: {
    height: '28px',
    width: "28px",
    cursor: "pointer"
  },

  textGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  recoverFontStyle: {
    color: "#454545",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },

  insFontStyle: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: 'center' as const,
    marginBottom: '50px'
  },

  nameTextfeild: {
    width: "100%",
    borderRadius: '1px',
    backgroundColor: '#FAF5FF',
  },

  fontStyle1: {
    color: "#454545",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },

  fontStyle2: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 400
  },

  buttonBackground: {
    fontSize: "18px",
    fontWeight: 400,
    backgroundColor: "#B300ED",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  helperText: {
    color: "#D53333",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },
  closeIconGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeIconStyle2: {
    height: '25px',
    width: '25px',
    cursor: 'pointer'
  },
})

export default withStyles(userStyles)(ForgotPassword)
// Customizable Area End

export const topheaderlogo = require("../assets/logo.svg");
export const headerbanner = require("../assets/landing-banner.svg");
export const search = require("../assets/search.svg");
export const illustration = require("../assets/illustration.svg");

export const supplier = require("../assets/supplier.svg");
export const services = require("../assets/services.png");
export const software = require("../assets/software.svg");
export const events = require("../assets/events.svg");

export const illustration1 = require("../assets/illustration1.svg");
export const illustration2 = require("../assets/illustration2.svg");
export const goodcopany = require("../assets/good_company.svg");
export const footer = require("../assets/footer.svg");
export const fb = require("../assets/fb.svg");
export const insta = require("../assets/insta.svg");
export const linkdin = require("../assets/linkdin.svg");
export const twitter = require("../assets/xlogo.svg");

export const arrow = require("../assets/arrow.svg");
export const marketingoption = require("../assets/marketing-option.svg");
export const marketingsolution = require("../assets/marketing-solution.svg");
export const diversity_inclusion = require("../assets/image_rate.svg");
export const charity = require("../assets/charity.svg");
export const eco = require("../assets/eco.svg");
export const privacy = require("../assets/privacy.svg");
export const ethics = require("../assets/ethics.svg");
export const tick = require("../assets/tick.svg");
export const joinUsIllustration = require("../assets/joinusIllus.svg");
export const joinUsSuppliers = require("../assets/joinUsSuppliers.svg");
export const joinUsSupplierBtnImg = require("../assets/joinUsSupplierBtnImg.svg");
export const joinUsSoftwareImg = require("../assets/joinUsSoftwareImg.svg");
export const joinUsServiceImg = require("../assets/joinUsServiceImg.svg");
export const joinUsEventsImg = require("../assets/joinUsEventsImg.svg");
export const searchIconEvents = require("../assets/searchIconEvents.svg");
export const searchIconSuppliers = require("../assets/searchIconSuppliers.svg");
export const searchIconServices = require("../assets/searchIconServices.svg");
export const searchIconSoftwares = require("../assets/searchIconSoftwares.svg");
export const logoImage = require("../assets/image_logo_top.png");
export const searchIcon = require("../assets/image.png");
export const groupIcon = require("../assets/Group 342787558.png");
export const closeIcon = require("../assets/group_close.png");
export const uploadIcon = require("../assets/upload.png");
export const dummyIcon = require("../assets/group_avatar.png");
export const downArrow = require("../assets/image_vect.png");
export const cardIcon = require("../assets/group_master.png");
export const cardIcon2 = require("../assets/image_vector.png");
export const rightTickIcon = require("../assets/group_check.png");
export const progressIcon = require("../assets/Ellipse 126 (Stroke).png");
export const deleteImage = require("../assets/group_delete.png");
export const sucessIcon = require("../assets/group_icon.png");
export const dummyImage = require("../assets/dummyImage.png");
export const dummyImage1 = require("../assets/image_image.png")
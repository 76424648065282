import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import StorageProvider from "../../framework/src/StorageProvider.web";
import {  setStorageData } from "../../framework/src/Utilities";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";

// Customizable Area End


export interface Props {
  data: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MegaMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      anchorEl: null
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

  }
  // Customizable Area End

  // Customizable Area Start
  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  };

  handleCategoryRedirection =  () => {
   setStorageData('categoryType', "advanced");
   

  }
 
  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}
export interface Review {
  id: string
  type: string
  attributes: ReviewAttributes
}

export interface ReviewAttributes {
  id: number
  comment: string
  rating: number
  created_at: string
  updated_at: string
  listing_name: string
  logo_url: string
  name?: string;
  [key: string]: any;
}

export interface UserData {
  full_name: string
  email: string
  user_type: string
  no_of_location: string
  annual_revenue: string
  country: string
  city: string
  company_name: string
  activated: boolean
  role_name: number
  department: number
  type_of_business: number
  profile_image: any
  full_phone_number: string
}
interface S {
  // Customizable Area Start
  token: string;
  plan: any;
  isModalOpen: boolean;
  data: any;
  isPaymentStatus: boolean;
  isOpen: boolean;
  selectedPlan: any;
  cardNumber: string;
  cardName: string;
  cvvNumber: string;
  expNumber: string;
  toastErrMsg: string;
  cardNumberErrorText: string;
  cardNameErrorText: string;
  cvvErrorText: string;
  expNumberErrorText: string;
  cardNumberError: boolean,
  cardNameError: boolean,
  cvvError: boolean,
  expNumberError: boolean,
  isCardCheck: string,
  listingData: any,
  Username: UserData,
  isAddons: boolean,
  isFeature: boolean,
  addonsData: any,
  grandTotal: any,
  counts: number | any,
  paymentMethodData: any;
  isCouponCode: boolean;
  couponText: string;
  errorCouponCode: string;
  currentDiscount: number;
  couponValue: number;
  taxValue: number;
  showDirectoryPopup:boolean,
  searchDirectoryData:any,
  searchTermProp:string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PlanDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getPlanSelectCallId: string = "";
  card: any | null = null;
  paymentAPICallId: string = "";
  postPaymentIDCard: string = "";
  getAddonsId: string = ""
  getPaymentMethodId: string = "";
  postCouponCodeID: string = "";
  getTaxValuApiAPICallId: string = "";
  getResultApiDataId: string ="";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      plan: "",
      isModalOpen: false,
      data: null,
      isPaymentStatus: false,
      isOpen: false,
      selectedPlan: null,
      cardNumber: "",
      cardName: "",
      cvvNumber: "",
      expNumber: "",
      toastErrMsg: "",
      cardNumberErrorText: "",
      cardNameErrorText: "",
      cvvErrorText: "",
      expNumberErrorText: "",
      cardNumberError: false,
      cardNameError: false,
      cvvError: false,
      expNumberError: false,
      isCardCheck: "",
      listingData: null,
      addonsData: null,
      grandTotal: null,
      Username: {
        full_name: "",
        email: "",
        user_type: "",
        no_of_location: "",
        annual_revenue: "",
        country: "",
        city: "",
        company_name: "",
        activated: false,
        role_name: 0,
        department: 0,
        type_of_business: 0,
        profile_image: undefined,
        full_phone_number: ""
      },
      isAddons: false,
      isFeature: false,
      paymentMethodData: null,
      counts: 0,
      isCouponCode: false,
      couponValue: 0,
      couponText: "",
      currentDiscount: 0,
      taxValue: 0,
      errorCouponCode: "",
      showDirectoryPopup:false,
      searchDirectoryData:[],
      searchTermProp:"",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestId === this.getAddonsId) {
        this.setState({ addonsData: responseJson.add_on, isAddons: true })
        this.handleTotalAmount(responseJson.add_on)
      }
      if (apiRequestId === this.getPaymentMethodId) {
        this.setState({ paymentMethodData: responseJson.cardinformation });
        this.setState({ counts: responseJson.meta.total_count })
      }
      if (apiRequestId === this.postCouponCodeID) {
        this.handleCouponCodeResponse(responseJson)
      }
      if (apiRequestId === this.getTaxValuApiAPICallId) {
        this.setState({
          taxValue: responseJson.data[0].attributes.tax,
        })
        this.handleTotalAmount(this.state.selectedPlan)
      }
      if(apiRequestId === this.getResultApiDataId){
        this.handleDirectorySearchResponse(responseJson)
      }

    }
    const listing = await getStorageData("currentListing");
    const dataList = JSON.parse(listing);
    this.setState({ listingData: dataList })
    const storeDataUser = await getStorageData("userdetails");
    const currAccountDetails = JSON.parse(storeDataUser);
    this.setState({ Username: currAccountDetails });
    const DataNew = await getStorageData("userdetails");
    const newAccData = JSON.parse(DataNew);
    if (this.state.Username.profile_image !== newAccData.profile_image || this.state.Username.full_name !== newAccData.full_name) {
      this.setState({ Username: newAccData });
    }

    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const localStorageData = await getStorageData('selectedPlan');
    const addons = await getStorageData("addons");
    const parsedData = JSON.parse(localStorageData);
    this.setState({ selectedPlan: parsedData });
    if (addons) {
      this.getHandleAddons()
    }
    this.handleTotalAmount(parsedData)
    this.getPaymentMethod()
    this.getTaxValuApi()
    // Customizable Area End
  }

  getToken = () => {
    const msgCurr: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msgCurr);
  };

  // Customizable Area Start

  handleSelectPlan = (plan: any) => {
    this.setState({ plan: plan })
  }

  handleClickPaymentButton = async (e: any, elements: any, stripe: any) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
    const { paymentMethod }: any = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    const { token } = await stripe.createToken(cardElement);
    this.handleAddnewCard(paymentMethod)
    this.handlePaymentApiCall(token, paymentMethod)
  };

  ChangeCardName = (event: ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value.trim();
    if (name.length > 0) {
      if (name.length <= 2) {
        this.setState({
          cardNameError: true,
          cardNameErrorText: 'Please enter a valid card name',
        });
      }
      else {
        this.setState({ cardNameError: false, cardNameErrorText: "" });
      }
    }
    this.setState({ cardName: name })
  }

  handlePaymentApiCall = (token: any, paymentMethod: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };
    const attrs = {
      subscription_id: this.state.selectedPlan?.id || this.state.listingData?.subscription_id,
      amount: this.state.grandTotal?.total,
      paymentable_id: this.state.listingData?.id,
      paymentable_type: this.state.listingData?.listngState === "Listings" ? "BxBlockCatalogue::Catalogue" : "BxBlockCatalogue::UserListing",
      token: paymentMethod?.id,
      // cardinformation_id:1,
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPayment
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isModalOpen: true })
  }
  handleModalClose = () => {
    this.setState({ isModalOpen: false },()=>this.navigateToVendor())
  }

  handleGoBack = () => {
    this.setState({ isModalOpen: false })
    removeStorageData("addons")
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
  handleCardCheck = (value: string) => {
    this.setState({ isCardCheck: value })
  }
  handleAddnewCard = (paymentMethod: any) => {
    const header = {
      token: localStorage.getItem("loginToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      "payment_method_id": paymentMethod.id
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postPaymentIDCard = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCardApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigatevendorAccount = () => {
    const messCur = new Message(getName(MessageEnum.NavigationMessage));
    messCur.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    messCur.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messCur)
  }

  navigateToHomeScreen = () => {
    const messCur = new Message(getName(MessageEnum.NavigationMessage));
    messCur.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    messCur.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messCur)
  }

  navigateToVendor = async () => {
    removeStorageData("addons")
    removeStorageData("selectedPlan")
    const UserTypes = await getStorageData("user_type")
    if (UserTypes == "vendor" || "Vendor") {
      this.navigatevendorAccount()
    }
  }

  handleUserSignout = async () => {
    await removeStorageData("loginToken")
    this.navigateToHomeScreen()
  }
  handleCancelAddon = () => {
    this.setState({ isAddons: false })
    removeStorageData("addons")
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleSelectAddons = () => {
    this.setState({ isAddons: false, isFeature: true })
  }
  getHandleAddons = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddonsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentContentManagement}?directory_id=${this.state.listingData?.directoryID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTotalAmount = (parsedData: any) => {
    const subTotal = parseFloat(parsedData?.price || +this.state.addonsData?.amount || 0);
    const { taxValue, currentDiscount } = this.state
    const total = subTotal - currentDiscount;
    const finalTotal = total + taxValue
    let myTotal = {
      tax: taxValue,
      total: finalTotal
    }
    this.setState({ grandTotal: myTotal })
  }
  getPaymentMethod = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPaymentMethodId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCardDetail}?page=1&per_page=5`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAddonsStateManage = () => {
    this.setState({ isAddons: true })
  }
  handleRemoveCoupon = () => {
    this.setState({
      couponValue: 0,
      isCouponCode: false,
      currentDiscount: 0,
      couponText: ""
    }, () => {
      this.handleTotalAmount(this.state.selectedPlan);
    });
  }

  handleCouponCodeResponse = (responseJson: any) => {
    const couponCode = responseJson
    if (couponCode.message) {
      this.setState({ isCouponCode: true, currentDiscount: couponCode?.amount }, () => {
        this.handleTotalAmount(this.state.selectedPlan);
      })
    } else {
      this.setState({ errorCouponCode: couponCode?.error })
    }
  }
  handleChangeCouponCode = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ couponText: event.target.value, errorCouponCode: "" })
  }
  handleApplyCoupon = () => {
    if (this.state.couponText) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("loginToken"),
      };
      const requestMsgs = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postCouponCodeID = requestMsgs.messageId;

      requestMsgs.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.couponCodeAPIEndPoint}?code=${this.state.couponText}&amount=${this.state.selectedPlan?.selectedPlan?.plan?.price}`
      );
      requestMsgs.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMsgs.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );

      runEngine.sendMessage(requestMsgs.id, requestMsgs);
    }
  };

  getTaxValuApi = async () => {
    const header = {
      token: await getStorageData("loginToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaxValuApiAPICallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taxValueAPIEndPoint
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
  };
  handleDirectorySearchResponse=(responseJson:any)=>{
    if(responseJson && responseJson.response){
      this.setState({searchDirectoryData:responseJson.response})
    }
  }


  handleSearchInput = (event:any) =>{  
    this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
    if(!event.target.value) this.setState({showDirectoryPopup:false});
  }

  getSearchResultDirectory = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

  const searchRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

    this.getResultApiDataId = searchRequestMessage.messageId;
    searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
      );
      searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);

      searchRequestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPIMethod
  );

  runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);


  }

  handleAdvancedSearchNavigation = (directory:string) => {
    this.setState({showDirectoryPopup:false})
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToAdvancedSearch)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
    this.send(msg);

}
handleDirectoryPopupClose=()=>{
  this.setState({showDirectoryPopup:false})
}

  handleSubmitSearch=(event:any)=>{
    event.preventDefault();
    this.handleAdvancedSearchNavigation("All")
  }
  // Customizable Area End
}

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const logoImage = require("../assets/image_logo_top.png");
export const searchIcon = require("../assets/image.png");
export const personImage = require("../assets/image_person.png");
export const downArrow = require("../assets/image_vect.png");
export const personImage1 = require("../assets/group_avatar.png");
export const editIcon = require("../assets/group_edit.png");
export const line = require("../assets/view_rect.png");
export const profilePic = require("../assets/image_n.png");
export const callIcon = require("../assets/group_call.png");
export const emailIcon = require("../assets/group_email.png");
export const rightCheck = require("../assets/group_check.png");
export const closeIcon = require("../assets/group_close.png")
export const starIcon = require("../assets/starImg.png")
export const cardIcon2 = require("../assets/image_vector.png");
export const cardIcon = require("../assets/group_master.png");
export const accountIcon = require("../assets/account_details.svg");
export const listIcon = require("../assets/listing.svg");
export const payment = require("../assets/payment_history.svg");
export const paymentIcon = require("../assets/payment_method.svg");
export const reviewsIcon = require("../assets/review_submitted.svg");
export const signoutIcon = require("../assets/signout.svg");



import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  InputAdornment,
  Dialog,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { restaurant_logo, emailLogo, password_logo, closeModalIcon } from "./assets";
// Customizable Area End

// Customizable Area Start
import EmailAccountLoginController, {
  Props, configJSON,
} from "./EmailAccountLoginController";
// Customizable Area End
export class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes }: any = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <Dialog
        open={this.state.isLoginModal}
        data-testid={"closeModal"}
        onClose={this.handleCloseLoginModal}
        maxWidth="md"
        PaperProps={{ className: classes.dialogStyle }}
      >

        <Box className={classes.BoxScrollbar}>
          <Grid item xs={12} className={classes.closeIconGridStyle}>
            <img src={closeModalIcon}
              className={classes.closeIconStyle2}
              onClick={this.handleCloseLoginModal}
            />
          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={3} className={classes.containerStyle}>
            <Grid item xs={12} className={classes.logoGridStyle} style={{ marginTop: '10px' }}>
              <img src={restaurant_logo} className={classes.imageStyle} />
            </Grid>
            <Grid item xs={12} className={classes.credentialgridStyle} style={{ marginBottom: '1.5rem' }}>
              <Typography className={classes.welcomeFont}>{configJSON.welcome}</Typography>
              <Typography className={classes.credFontStyle}>{configJSON.credential}</Typography>
            </Grid>

            <Grid item xs={12}>
              <StyledTextField
                variant="outlined"
                placeholder={configJSON.placeHolderEmail}
                fullWidth
                data-testid={"email-test"}
                name="email"
                value={this.state.enterEmail}
                onChange={this.handleChangeEmail}
                InputProps={{
                  disableUnderline: true,
                  classes: { input: classes["inputStyle"] },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={emailLogo} />
                    </InputAdornment>
                  ),
                }}
                error={this.state.emailErrMsg || (this.state.toastErr === configJSON.emailnotmatch && this.state.toastErr)}
                helperText={
                  (this.state.emailErrMsg || (this.state.toastErr === configJSON.emailnotmatch && this.state.toastErr)) &&
                  <Box className={classes.errorBoxStyle}>
                    {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                    {<Typography style={{
                      color: "#D53333",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}>
                      {this.state.emailErrMsg || this.state.toastErr}
                    </Typography>}
                  </Box>
                }
                className={classes.nameTextfeild}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledTextField
                variant="outlined"
                type="password"
                data-testid={"password-test"}
                placeholder={configJSON.placeHolderPassword}
                fullWidth
                name="password"
                value={this.state.enterPassword}
                onChange={this.handleChangePassword}
                InputProps={{
                  disableUnderline: true,
                  classes: { input: classes["inputStyle"] },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={password_logo} />
                    </InputAdornment>
                  ),
                }}
                className={classes.nameTextfeild}
                error={this.state.passErrMsg || (this.state.toastErr === configJSON.incorrectpass && this.state.toastErr)}
                helperText={
                  (this.state.passErrMsg || (this.state.toastErr === configJSON.incorrectpass && this.state.toastErr)) &&
                  <Box className={classes.errorBoxStyle}>
                    {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                    <Typography style={{
                      color: "#D53333",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}>
                      {this.state.passErrMsg || this.state.toastErr}
                    </Typography>
                  </Box>
                }
              />
            </Grid>

            <Grid item xs={12} className={classes.forgetPasStyle}>
              <Typography data-testid={"gotoforgotpass"}
                className={classes.forgetTextColor}
                onClick={this.goToPassword}>{configJSON.forget}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridMargin}>
              <Button fullWidth variant="contained"
                type="submit"
                onClick={(e: any) => this.handleFormSubmit(e)}
                className={classes.buttonBackground}
                data-testid={"login-button"} >{configJSON.login}</Button>
            </Grid>

            <Grid item xs={12} className={classes.gridMargin}>
              <Typography
                className={classes.registerStyle}>{configJSON.donnthaveaccount} <span
                  data-testid={"gotoregister"}
                  onClick={this.goToRegister}
                  className={classes.textColor}>
                  {configJSON.register}</span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Dialog >
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export const StyledTextField = withStyles({
  root: {
    fontSize: "14",
    "& input::placeholder": {
      fontSize: "14",
      fontWeight: 400,
      fontFamily: "Poppins"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD",
    },
    "& .MuiInputBase-input-283:focus": {
      outline: 'none',
      backgroundColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF"
    },
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#FFFFFF",
      margin: 0,
      paddingLeft: 10
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF",
    },

  },
})((props: any) => <TextField {...props} />);

export const userStyles = () => ({
  dialogStyle: {
    overflow: 'hidden', height: "auto",
    justifyContent: 'center',
    width: '500px',
    borderRadius: '8px',
    backgroundColor: ' #FFFFFF',
    display: 'flex',
    alignItems: 'center'
  },

  containerStyle: {
    padding: '30px'
  },

  inputStyle: {
    fontFamily: "Poppins",
    "&::placeholder": {
      fontFamily: "Poppins",
    },
  },

  credentialgridStyle: {
    margin: "10px 0px 10px 0px"
  },

  errorBoxStyle: {
    display: 'flex',
    marginTop: '2px'
  },

  logoGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageStyle: {
    height: '56px',
    width: '243px',
    margin: '10px 0px 20px 0px'
  },

  errorIconStyle: {
    fontSize: '18px',
    margin: '3px 10px 0px -10px'
  },
  nameTextfeild: {
    width: "100%",
    borderRadius: '1px',
    backgroundColor: '#FAF5FF',
  },

  forgetPasStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  textColor: {
    cursor: "pointer",
    color: "#B300ED",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "Poppins"
  },

  forgetTextColor: {
    cursor: "pointer",
    color: "#B300ED",
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Poppins"
  },

  gridMargin: {
    marginTop: '30px'
  },

  buttonBackground: {
    fontSize: "18px",
    fontWeight: 400,
    backgroundColor: "#B300ED",
    fontFamily: "Poppins",
    color: "#FFFFFF",
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  welcomeFont: {
    color: "#000000",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins"
  },
  registerStyle: {
    textAlign: 'center' as const,
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Poppins",
    marginBottom: '30px'
  },
  credFontStyle: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },

  helperText: {
    color: "#D53333",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },

  BoxScrollbar: {
    height: '100%',
    overflowX: 'hidden' as const,
    overflowY: 'auto' as const,
    padding: '10px',
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D6D6D6",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb": {
      minWidth: 14,
      background: "#B300ED",
      minHeight: 24,
      borderRadius: 6,
    },
    "&::-webkit-scrollbar": {
      padding: '30px',
      height: 6,
      width: 4,
      backgroundColor: "#D6D6D6",
    },

  },
  closeIconGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px 10px 0px 0px'
  },
  closeIconStyle2: {
    height: '25px',
    width: '25px',
    cursor: 'pointer'
  },

})

export default withStyles(userStyles)(EmailAccountLoginBlock)
// Customizable Area End

// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import {Grid, Box, Typography, Link} from '@material-ui/core';
import {
  topheaderlogo,
  fb,
  insta,
  linkdin,
  twitter,
} from "../../blocks/landingpage/src/assets";
import { makeStyles } from "@material-ui/core/styles";

// Customizable Area End

const Footer = ({ history }) => {
  // Customizable Area Start
  const classes = useStyles();

  return (
    <Box className={classes.restaurentFooter}>
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Grid container className={classes.footerTopContainer}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Typography className={classes.restaurentFooterUlLiFirstChild} variant="h4">Information</Typography>
              <Typography variant="h5"> <Link href="#" className={classes.restaurentFooterUlLiA}>Suppliers</Link></Typography>
              <Typography variant="h5"><Link href="#" className={classes.restaurentFooterUlLiA}>Software</Link></Typography>
              <Typography variant="h5"><Link href="#" className={classes.restaurentFooterUlLiA}>Services</Link></Typography>
              <Typography variant="h5"><Link href="#" className={classes.restaurentFooterUlLiA}>Events</Link></Typography>
              <Typography variant="h5"><Link href="#" className={classes.restaurentFooterUlLiA}>Feedback</Link></Typography>
              
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12} className={classes.footerRightGrid}>
            <Typography className={classes.restaurentFooterUlLiFirstChild} variant="h4">About Us</Typography>
              <Typography variant="h5"> <Link href="#"  className={classes.restaurentFooterUlLiA}>Privacy Policy</Link></Typography>
              <Typography variant="h5"><Link href="#"  className={classes.restaurentFooterUlLiA}>Cookie Policy (EU)</Link></Typography>
              
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.restaurentFooterBottomFooter}>
        <Box className={`${classes.restaurentFooterBottomFooterContainer} ${classes.container}`}>
          <Box className={classes.restaurentFooterBottomFooterUlLeft}>
          <Link href="#"  className={`${classes.restaurentFooterBottomFooterUlLeftLiFirstA} ${classes.restaurentFooterBottomFooterUlLeftLiA} ${classes.restaurentFooterBottomFooterUlLiFirstA}`} >
                <img src={topheaderlogo} className={classes.restaurentFooterBottomFooterTopheaderlogo}  />
              </Link>
              <Box className={classes.fotterNavigationIcons}>
              <Box className={classes.footerTextsLeft}>
              <Typography className={classes.restaurentFooterBottomFooterUlLi}>
              <Link href="#" className={`${classes.restaurentFooterBottomFooterUlLeftLiA} ${ classes.footerClickUpText}`}>© 2023 ClickUp</Link>
              </Typography>
              <Typography className={classes.restaurentFooterBottomFooterUlLi}>
              <Link href="#" className={`${classes.restaurentFooterBottomFooterUlLeftLiA} ${ classes.footerSecurityText}`}>Security</Link>
              </Typography>
              </Box>
              <Box className={classes.footerTextsRight}>
              <Typography className={classes.restaurentFooterBottomFooterUlLi}>
              <Link href="#" className={`${classes.restaurentFooterBottomFooterUlLeftLiA} ${ classes.footerPrivacyText}`}>Your Privacy</Link>
              </Typography>
              <Typography className={classes.restaurentFooterBottomFooterUlLi}>
              <Link href="#" className={`${classes.restaurentFooterBottomFooterUlLeftLiA} ${classes.restaurentFooterBottomFooterUlLiLastA} ${ classes.footerTermsText}`}>Terms</Link>
              </Typography>
              </Box>
              </Box>
          </Box>
          
          <Box className={`${classes.restaurentFooterBottomFooterUl} ${classes.restaurentFooterBottomrightUL}`} >
            <Typography className={classes.restaurentFooterBottomFooterUlRightLi}><Link href="#"><img src={linkdin} /></Link> </Typography>
            <Typography className={classes.restaurentFooterBottomFooterUlRightLi}><Link href="#"> <img src={twitter}/></Link></Typography>
            <Typography className={classes.restaurentFooterBottomFooterUlRightLi}><Link href="#"> <img src={fb} /></Link></Typography>
            <Typography className={classes.restaurentFooterBottomFooterUlRightLi}><Link href="#"> <img src={insta} /></Link></Typography>

          </Box>
          
        </Box>
      </Box>
    </Box>
  );

  // Customizable Area End
};

// Customizable Area Start
const useStyles = makeStyles({
  
  row: {
    display: 'flex',
    justifyContent: 'end'
  },

  container: {
    maxWidth: "1200px",
    margin: '0 10px',
    width: '100%'
  },
  footerTopContainer:{
    padding:50,
    "@media(max-width:1250px)":{
      paddingLeft:"45px"
    },
    "@media(max-width:700px)":{
      paddingLeft:"20px"
    }
  },

    restaurentFooter: {
      backgroundImage: `url(${require('../../blocks/landingpage/assets/footer.png')})`,
      backgroundPosition: "top",
      backgroundSize: "110%",
      paddingTop: "35px",
      display: "block",
      fontFamily: 'Poppins, sans-serif',
      "@media(max-width: 992px)": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      },
      width:"100%",
    },
    restaurentFooterUlLiFirstChild: {
      color: "#000",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "18px",
      textTransform: "uppercase",
      marginBottom: "45px",
      listStyle:'none',
      fontFamily:"Poppins"
    },
    footerRightGrid:{
      "@media(max-width: 600px)": {
       marginTop:"30px"
      },
    },
    restaurentFooterUlLi: {
      listStyle: "none",
    },
    restaurentFooterUlLiA: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "300",
      lineHeight: "23.8px",
      textDecoration: "none",
      marginBottom: "25px",
      display: "block",
      fontFamily:"Poppins",
      "&:hover": {
        textDecoration:"none"
      }
      
    },
    restaurentFooterUl: {
      paddingBottom: "170px",
      "@media(max-width: 992px)": {
        paddingBottom: '0'
      }
    },
    restaurentFooterBottomFooter: {
      display: "flex",
      padding: "45px 20px 35px 45px",
      minHeight: "50px",
      alignItems: "center",
      borderTop: "1px solid #DBDAFF",
      "@media(max-width: 992px)": {
        padding: "15px 45px 55px 45px"
      },
      "@media(max-width: 600px)": {
        height: 'unset',
        padding: "15px"
      }
     
    },
    restaurentFooterBottomFooterUlLi: {
      display: "inline",
      margin: '0',
      "@media(max-width: 580px)": {
        marginTop:"10px"
       }
    },
    restaurentFooterBottomFooterTopheaderlogo: {
      width: "150px",
    },
    restaurentFooterBottomFooterUlLeft: {
      display: "inherit",
      padding: "0 !important",
      margin: "0px !important",
    },
    restaurentFooterBottomFooterUl: {
      padding: "0px !important",
      
    },
    restaurentFooterBottomFooterUlLeftLi: {
      margin: "0px !important",
    },
    restaurentFooterBottomFooterUlLeftLiA: {
      borderRight: "1px solid #fff",
      color: "#FFF",
      textDecoration: "none",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "14px",
      padding: "0 10px",
      margin: "0px !important",
      cursor: "pointer",
    },
    restaurentFooterBottomFooterUlLeftLiFirstA: {
      padding: "0px",
    },
    restaurentFooterBottomFooterUlLiFirstA: {
      border: "0px !important",
    },
    restaurentFooterBottomFooterUlLiLastA: {
      border: "0px !important",
    },
    restaurentFooterBottomFooterUlRightLi: {
      padding: "0 5px",
      cursor: "pointer",
      display:"flex",
      alignItems:"center"
    },
    restaurentFooterBottomFooterContainer: {
      display: "flex",
      justifyContent: "space-between",
      height: '50px',
      "@media(max-width: 992px)": {
        position:"relative",
        flexDirection: 'column'
      },
      "@media(max-width: 600px)": {
        height: 'unset',
      }
    },
    restaurentFooterBottomrightUL: {
      display: 'flex',
      listStyle: 'none',
      width:"0",
      marginRight:"-4%",
      "@media(max-width: 992px)": {
        marginTop:"10px"
      },
      "@media(max-width: 580px)": {
        position:"absolute",
        top:"40px"
       }
    },
    fotterNavigationIcons:{
      paddingTop:"10px",
      marginLeft:"10px",
      display:"flex",
      "@media(max-width: 640px)": {
        flexDirection:"column",
        marginLeft:"125px"
      },
      "@media(max-width: 682px)": {
        flexDirection:"column",
        marginLeft:"40px"
      },
      "@media(max-width: 600px)": {
        marginLeft:"20vw",
        paddingTop:"0px"
      },
      "@media(max-width: 390px)": {
        marginLeft:"10vw",
       
      }
    },
    footerTextsRight:{
      "@media(max-width: 640px)": {
      marginTop:"10px"
      },
      "@media(max-width: 580px)": {
        display: "flex",
        flexDirection:"column"
      }
    },
    footerTextsLeft:{
      "@media(max-width: 580px)": {
        display: "flex",
        flexDirection:"column"
      }
    },
    footerSecurityText:{
      "@media(max-width: 640px)": {
        border:"none",
        marginTop:"10px"
      },
      
    },
    footerClickUpText:{
      "@media(max-width: 580px)": {
       border:"none"
      }
    },
    footerPrivacyText:{
      "@media(max-width: 580px)": {
       border:"none"
      }
    },
    footerTermsText:{
      "@media(max-width: 580px)": {
       border:"none",
       marginTop:"10px"
      }
    }
  
  // Example usage: styles.restaurentFooter
  
});
export default withRouter(Footer);

// Customizable Area End
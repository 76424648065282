import React from "react";

// Customizable Area Start
import {
    Typography,
    OutlinedInput,
    Grid,
    Checkbox,
    Chip,
    TextField,
    Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import Autocomplete from '@material-ui/lab/Autocomplete';

import clsx from 'clsx';

// Customizable Area End

// Customizable Area Start
import ServicesAboutPageController, {
    Props,
    configJSON,
} from "./ServicesAboutPageController";
import FeedbackForm from "./FeedbackForm.web";
import CustomToast from "../../../components/src/CustomToast.web";
import { deleteIcon_1 } from "./assets";
// Customizable Area End

export class ServicesAboutPage extends ServicesAboutPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            <Grid container spacing={5} className={classes.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography data-testid={"description"} className={classes.description}>{configJSON.shortDescription}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.para}>{configJSON.longDescription}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OutlinedInput
                            style={{overflow: "auto"}}
                                id="outlined-adornment-weight"
                                data-testid={'change_shortDesp'}
                                value={this.state.updatedAboutDetails.short_description}
                                onChange={this.handleChangeShortDescription}
                                rows={4}
                                multiline
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.shortDescription,
                                    className: classes.placeholder,
                                }}
                                classes={{
                                    root: classes.outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.inputText
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.description}>{configJSON.longDescriptio}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.para}>{configJSON.longDescriptionText}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={'change_longDesp'}
                                value={this.state.updatedAboutDetails.long_description}
                                onChange={this.handleChangeLongDescription}
                                rows={6}
                                multiline
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.longDescriptio,
                                    className: classes.placeholder,
                                }}
                                classes={{
                                    root: classes.outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.inputText
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            < Typography className={classes.description}>{configJSON.servicesUrl}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.para}>{configJSON.serviceDescription}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                variant="outlined"
                                className={classes.domainServiceAbout}
                                placeholder="Domain"
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={this.state.aboutDetails.domain_url}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    < Typography className={classes.marketServices}>Markets</Typography>

                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={this.state.allcountriesdata}
                        getOptionLabel={(option) => option.country_name}
                        data-test-id={`autocomplete`}
                        value={this.state.selectedCountries}
                        onChange={this.onAutoCompleteChange}
                        disableClearable={true}
                        ListboxProps={{
                            className: classes.myCustomListServiceAbout
                        }}
                        className={classes.autocompleteServices}
                        renderOption={(option, { selected }) => (
                            <div style={{ display: "flex", alignItems: "center" }} data-test-id={`checkboxexist`}>
                                <img alt="flag" height={16} width={25} src={option.flag} />
                                <Typography className={classes.flagLabel}>{option.country_name}</Typography>

                            </div>
                        )}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: any, index: number) => (
                                <Chip key={index} variant="outlined"
                                    label={option.country_name}
                                    {...getTagProps({ index })}
                                    className={classes.chipbrandService}
                                    style={{ fontSize: '14px', fontFamily: "Poppins" }}
                                    deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                data-test-id={`autocompletetextfield`}
                                variant="standard"
                                placeholder={this.state.selectedCountries.length > 0 ? "" : "Select"}
                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container>
                        <Grid item xs={12} sm={9} md={9} lg={9} className={classes.feedText}>
                            <Typography className={classes.fontStyle2}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.font_4}>{configJSON.no}</Typography>
                            <Typography className={classes.font_4} onClick={this.handleOpenFeedbackFrom}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} lg={3} container className={classes.buttonBoxStyle}>
                            <CustomSaveButton
                                handleClick={this.updateAboutListingData}
                                data-testid={"save_button"}
                                label={configJSON.saveBtn} />
                        </Grid>
                    </Grid>
                </Grid>

                <CustomToast data-testid="gotoaccount" open={this.state.isSucessModalOpen} onClose={this.handleCloseSuccess} />

                <FeedbackForm openFeedback={this.state.isFeedbackFormOpen} closeFeedback={this.handleCloseFeedbackForm} navigation={this.props.navigation} id={""} />
            </Grid>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles = (theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },

    longDesBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '20px'
    },
    description: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    para: {
        color: "#262424",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Poppins",
    },

    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
        width: '100%'
    },
    focused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    placeholder: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },

    feedbackBox: {
        display: 'flex',
        marginTop: "15%",
        marginBottom: '0',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    buttonBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5%',
        justifyContent: 'end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            width: "100%",
        },
    },
    font_4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },

    feedText: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '5%'
    },

    fontStyle2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },
    flagLabel: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "14px",
        fontFamily: "Poppins",
        marginLeft: 15
    },

    marketServices: {
        fontFamily: 'Poppins',
        color: "#262424",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "14px",
        fontSize: "20px",
    },
    autocompleteServices: {
        padding: "6px",
        marginBottom: "42px",
        borderRadius: '8px',
        width: "587px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        border: "1px solid  #CBD5E1",
        backgroundColor: '#FAF5FF',
        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },
        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },

        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent",
        },
        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
            minHeight: "40px",
        },

        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },

    },
    chipbrandService: {
        fontSize: "16px",
        margin: "0 3px",
        border: "none",
    },
    myCustomListServiceAbout: {
        background: "#FFF",
        overflow: 'auto',
        borderRadius: "8px",
        maxHeight: "331px",
        padding: "4px 0",
        margin: "0",
        border: "1px solid #E2E8F0",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        maxwidth: "590px",
        "&::-webkit-scrollbar": {
            borderRadius: "30px",
            width: "6px",
            height: "23.34px",
            backgroundColor: "#A09B9B33",

        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#B300ED",
            width: "6px",
            height: "23.34px",
            borderRadius: "30px",

        }
    },
    domainServiceAbout: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        color: "#828589",
        borderRadius: "6px",
        background: "#FAF5FF",
        lineHeight: "normal",
        width: "587px",
        "& input": {
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Poppins",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },

        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        border: "1px solid #CBD5E1",
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none',
        },
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
        }

    },
    icon_style: {
        borderRadius: 6,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '1px solid #64748B',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon_style: {
        backgroundColor: '#8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },
});

export default withStyles(userStyles)(ServicesAboutPage)
// Customizable Area End
import React, { Component } from "react";
import {
    Box,
    Typography,
    Button
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { location, fbIcon, internet, linkedin, locationPin, phone, twitter, youtubeLogo, instaLogo } from "../../blocks/customform4/src/assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';


type MyProps = { contactData?: any, id?: any, handleAccordionToggle?: any, classes?: any, expanded?: boolean, accordionState?: string };

export class Contacts extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, contactData, expanded, accordionState }: any = this.props
        return (
            <>
                <Box className={classes.accordionContainer} id={this.props.id}>
                    <Accordion onChange={() => this.props.handleAccordionToggle(accordionState)} expanded={expanded} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.accordionHeading}>Contact</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={classes.contactContainer}>
                                <Box className={classes.itemWrapper}>
                                    <Box style={{display:'flex',flexDirection:'column', flex: 1,marginTop:'5px' }}>
                                        <Box className={classes.contactItem}>
                                            <img src={phone} className={`${classes.ml5} ${classes.mr10}`} />
                                            <Typography className={`${classes.contactItemtext}`}>
                                                {contactData?.phonenumber}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.contactItem}>
                                            <img src={locationPin} className={`${classes.ml5} ${classes.mr10}`} />
                                            <Typography className={`${classes.ml5} ${classes.contactItemtext}`}>
                                                {contactData?.address}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.contactItem} >
                                            <img src={internet} className={`${classes.ml5} ${classes.mr10}`} />
                                            <Typography style={{ color: "#2EA3F2" }} className={`${classes.ml5} ${classes.contactItemtext}`}>
                                                {contactData?.contactus}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.socialContainer}>
                                            {contactData?.fburl && <a target="_blank" href={contactData?.fburl}><img src={fbIcon} className={classes.mr5} /></a>
                                            }
                                            {contactData?.linkdlnurl && <a target="_blank" href={contactData?.linkdlnurl}><img src={linkedin} className={classes.mr5} /></a>
                                            }
                                            {
                                               contactData?.twitterurl &&  <a target="_blank" href={contactData?.twitterurl}><img  src={twitter} className={classes.mr5} /></a>
                                            }
                                            {
                                                contactData?.instaurl && <a target="_blank" href={contactData?.instaurl}><img src={instaLogo} className={classes.mr5} /></a>

                                            }
                                            {
                                                contactData?.youtubeurl &&    <a target="_blank" href={contactData?.youtubeurl}><img src={youtubeLogo} className={classes.mr5} /></a>

                                            }


                                        </Box>
                                    </Box>
                                    <Box style={{ flex: 1 }}>
                                        <img className={classes.location} src={location} alt="location" />
                                    </Box>
                                </Box>

                            </Box>
                        </AccordionDetails>
                    </Accordion >
                </Box >
            </>
        );
    }
}


export const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: 6,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    rounded:{
      '&:first-child': {
        borderTopLeftRadius: "6px",
      borderTopRightRadius:"6px"
        },
        '&:last-child': {
            borderTopLeftRadius: "6px",
          borderTopRightRadius:"6px"
            },
    },
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        paddingLeft: 56,
        borderRadius: 6,
        backgroundColor: '#F1E8FF',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
})(MuiAccordionSummary);



export const AccordionDetails = withStyles((theme: any) => ({
    root: {
        paddingLeft:56,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: 10
        },
    },
}))(MuiAccordionDetails);

export const userStyles: any = (theme: any) => ({
    accordionContainer: {
        margin: "10px 0px"

    },
    mr10: {
        marginRight: 10
    },
    contactItem: {
        display: "flex",
        marginBottom: 20
    },
    accordionHeading: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 700
    },
    contactContainer: {
        borderRadius: 6,
        border: "1px solid #C8C9CA",
        padding: 45,
        marginBottom: 10,
        width: 630,
        height: 450,
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            height: 'auto'
        },
    },
    ml5: {
        marginLeft: 5
    },
    mr5: {
        marginRight: 5
    },
    contactItemtext: {
        color: "#151515",
        fontFamily: "Open Sans, sans-serif",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500,
    },
    socialContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'flex-end',
      marginLeft:10
    },
    location: {
        height: "auto",
        [theme.breakpoints.down('sm')]: {
            height: 200,
        },
    },
    itemWrapper: {
        margin:'10px,20px',
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            display: "block"
        },
    }

})

export default withStyles(userStyles)(Contacts)
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

type MyProps = { handleClick?: () => void; label: string; classes: Record<string, string>; };

export class CustomSaveButton extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, label, handleClick }: MyProps = this.props;
        return (
            <Button
                variant="contained"
                className={classes.acceptBtn}
                data-testid={"save_button"}
                onClick={handleClick}
            >
                {label}
            </Button>
        );
    }
}

export const userStyles = () => ({
    acceptBtn: {
        backgroundColor: "#B300ED",
        width: '241px',
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        borderRadius: '8px',
        height: 55,
        marginLeft: 15,
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
});

export default withStyles(userStyles)(CustomSaveButton)
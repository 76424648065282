import React from "react";

// Customizable Area Start
import {
    Box,
    Avatar,
    Tabs,
    Tab,
    Grid,
    Typography,
    Dialog,
    OutlinedInput,
    Button,
    Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import SupplierCustomFormController, {
    Props, configJSON
} from "./SupplierCustomFormController";
import AppBarPage from "../../../components/src/AppBar.web"
import LinearProgressBar from "../../../components/src/LinearProgressBar.web";
import SupplierAboutPage from "./SupplierAboutPage.web";
import SupplierSuppliersPage from "./SupplierSuppliersPage.web"
import SupplierProductPage from "./SupplierProductPage.web";
import SupplierBrandsPage from "./SupplierBrandsPage.web";
import SupplierDistributorsPage from "./SupplierDistributorsPage.web";
import SupplierFilesPage from "./SupplierFilesPage.web";
import SupplierContactPage from "./SupplierContactPage.web"
import { edit_icon, camerIconEdit, logoImage, searchIcon, dummyIcon, downArrow, CloseICon, errorIcon } from "./assets";
// Customizable Area End

export class SupplierCustomForm extends SupplierCustomFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.supplier_form_root}>
                <AppBarPage
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    dummyicon={dummyIcon}
                    username={this.state.Username}
                    downarrow={downArrow}
                    goToHomePage={this.supplierHomePageNavigation}
                    handleHeaderNavigation={this.headerSupplierNavigation}
                    handleUserSignout={this.signoutSupplierUser}
                    data-testId={"signInBtn"}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                    handleSubmit={this.handleSubmitSearch}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                <Grid container className={classes.supplier_form_mainWapper}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.custom_subWapper}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LinearProgressBar value={this.state.progressVal} />
                        </Grid>
                        <Grid container className={classes.custom_subGridContainer}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box className={classes.custom_avatarBoxStyle}>
                                    <Avatar alt="A&J" src={this.state.imagePreview ? this.state.imagePreview : "/static/images/avatar/1.jpg"} className={classes.custom_avatarImage} />
                                    <Typography className={classes.custom_tabBarHeader}>{this.state.title}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} container justifyContent="flex-end">
                                <img data-testid={"editIcon"} onClick={this.handleOpenEditModal} src={edit_icon} className={classes.custom_editIconStyle} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.custom_tabGrid}>
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    data-test-id="tabs"
                                    TabIndicatorProps={{ style: { height: '2px', backgroundColor: "#B300ED" } }}
                                    className={classes.tabs}
                                >
                                    <Tab value={0} label="About" {...this.allProps(0)} className={classes.custom_tab} />
                                    <Tab value={1} label="Suppliers"  {...this.allProps(3)} className={classes.custom_tab} />
                                    <Tab value={2} label="Product" {...this.allProps(6)} className={classes.custom_tab} />
                                    <Tab value={3} label="Brands"  {...this.allProps(7)} className={classes.custom_tab} />
                                    <Tab value={4} label="Distributors"  {...this.allProps(7)} className={classes.custom_tab} />
                                    <Tab value={5} label="Files"  {...this.allProps(7)} className={classes.custom_tab} />
                                    <Tab value={6} label="Contact"  {...this.allProps(7)} className={classes.custom_tab} />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.custom_subWapper_2}>
                        {this.state.value == 0 && <SupplierAboutPage handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="about" incrementProgress={this.incrementProgress} categoryId={this.state.categoryId} />}
                        {this.state.value == 1 && <SupplierSuppliersPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="supplier" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 2 && <SupplierProductPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="product" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 3 && <SupplierBrandsPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="brands" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 4 && <SupplierDistributorsPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="distributors" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 5 && <SupplierFilesPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="files" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 6 && <SupplierContactPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="contact" incrementProgress={this.incrementProgress} />}
                    </Grid>

                </Grid>

                <Dialog
                    open={this.state.isOpenEditModal}
                    onClose={this.handleCloseOpenEditModal}
                    maxWidth="md"
                    PaperProps={{ className: classes.contactUsDialog }}
                    data-testid="open_modal"
                >
                    <Box className={classes.cancelGridStyleSupplier}>
                        <img
                            src={CloseICon}
                            className={classes.closeIconStyleSupplier}
                            onClick={this.handleCloseOpenEditModal}
                            data-test-id={'close_modal'}
                        />
                    </Box>
                    <Box className={classes.BoxScrollbar_1_supplier}>

                        <Typography className={classes.contactUsFont_Supplier}>{"Update Name and Picture"}</Typography>
                        <label htmlFor="upload-screenshot" className={classes.wrapper_supplier}>
                            <Avatar alt="Tony" src={this.state.imagePreview ? this.state.imagePreview : "/static/images/avatar/1.jpg"} className={classes.avatar} />
                            <img alt="Tony" src={camerIconEdit} className={classes.cameraEdit} />
                            <Typography className={classes.editText}>Edit Picture</Typography>

                        </label>

                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={this.onScreenshotUpload}
                            data-testid={"inputref"}
                            id="upload-screenshot"
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            classes={{
                                root: classes.outlinedInputSupplier,
                                focused: classes.focusedSupplier,
                                input: classes.inputTextSupplier,
                                notchedOutline: classes.notchedOutlineSupplier,
                            }}
                            inputProps={{
                                'aria-label': 'address',
                                className: classes.placeholderSupplier,
                                placeholder: 'Name',
                            }}
                            style={{ width: '80%' }}
                            value={this.state.title}
                            onChange={this.handleChangeTitle}
                            data-test-id={"title"}

                        />
                        <Box className={classes.buttonBoxStyle_Supplier}>
                            <Button
                                variant="contained"
                                className={classes.acceptBtn_Supplier}
                                onClick={this.handleSubmitSupplier}
                                data-test-id={"saveBtn"}
                                fullWidth={true}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

                <Dialog
                    open={this.state.openSaveModal}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyles }}
                    onClose={this.handleCloseSaveModal}
                    data-testId={"openErrorModal"}
                >
                    <Grid container>
                        <Grid item xs={12} className={classes.cancelGridStyleSupplier}>
                            <img
                                data-testid={"closeinstruction"}
                                src={CloseICon}
                                onClick={this.handleCloseSaveModal}
                                className={classes.closeIconStyle}
                            />
                        </Grid>
                    </Grid>

                    <Box className={classes.dialogBoxStyle_2}>
                        <img
                            src={errorIcon}
                            className={classes.rightTickIcon}
                        />
                        <Typography className={classes.welcomeFontStyle}>{configJSON.goBackText}</Typography>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.buttonStyle}
                            onClick={this.handleCloseSaveModal}
                        >
                            {configJSON.backText}
                        </Button>
                    </Box>
                </Dialog>
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles = (theme: Theme) => ({
    supplier_form_root: {
        flexGrow: 1,
        backgroundColor: '#f8f2ff',
    },

    supplier_form_mainWapper: {
        background: "#FFFFFF",
        // margin: '0 4%',
        // width: 'auto',
        // width: "1309px",
        margin: "0 auto",
        marginTop: "11%",
        marginBottom: "83px",
        borderRadius: '6px',
        // margin: '0 4%',
        // "@media (min-width: 300px) and (max-width: 950px)": {
        //     width: "400px",
        // },
        [theme.breakpoints.up('md')]: {
            width: "1309px",
        },

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },

        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    custom_subWapper: {
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column" as const,
        padding: '1% 4%',
    },

    custom_subGridContainer: {
        padding: '2% 2% 0',
        border: '1px solid  #D9D9D9',
        borderRadius: '6px'
    },

    custom_tabGrid: {
        marginTop: '3%'
    },

    custom_subWapper_2: {
        margin: '3% 4% 3%',
        padding: '1% 4%',
        border: '1px solid #C8C9CA',
        borderRadius: '6px'
    },

    custom_avatarBoxStyle: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        marginLeft: '4%'
    },

    custom_editIconStyle: {
        height: '24px',
        width: '24px',
        cursor: "pointer",
    },

    custom_tab: {
        textTransform: 'none' as const,
        margin: "0",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "26px",
        color: "#000000",
        fontWeight: 400,
        height: "50px",
        minWidth: "unset" as const,
        flex: "1",

        // "@media (min-width: 300px) and (max-width: 950px)": {
        //     width: "auto",
        //     fontWeight: 300,
        //     fontSize: "10px",
        // }
    },
    tabs: {
        margin: "0 35px",
        // width: "100%",
        justifyContent: "space-between",

        '& .MuiTabs-flexContainer': {
            justifyContent: "space-between",
        },
        '& .Mui-selected': {
            color: "#B300ED",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
        },


        [theme.breakpoints.down('md')]: {
            '&  > div': {
                display: 'flex',
                overflowX: 'scroll !important',
            },
            '&  > div::-webkit-scrollbar': {
                display: "none",
            },
            margin: "0 10px",


        }


    },
    custom_avatarImage: {
        display: "flex",
        width: "110px",
        height: "110px",
        justifyContent: "center" as const,
        alignItems: "center" as const,
        border: "4px solid #F0E5FF",
        '& img': {
            objectFit: "fill" as const
        }
    },
    custom_tabBarHeader: {
        color: "#000",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
    },
    contactUsDialog: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '500px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        marginTop: '5%',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },

    cancelGridStyleSupplier: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },

    closeIconStyleSupplier: {
        height: '32px',
        padding: '5px',
        width: '32px',
        cursor: 'pointer',
    },

    BoxScrollbar_1_supplier: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
        scrollbarColor: "#D6D6D6 transparent",
        overflowX: 'hidden' as const,
        overflowY: 'auto' as const,
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar": {
            height: 6,
            width: 4,
            padding: '30px',
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },


        "&::-webkit-scrollbar-thumb": {
            minHeight: 24,
            minWidth: 14,
            borderRadius: 6,
            background: "#B300ED",
        },
    },
    contactUsFont_Supplier: {
        fontFamily: "Poppins",
        fontSize: "28px",
        color: '#0F172A',
        textAlign: 'center' as const,
        fontWeight: 700,
    },
    wrapper_supplier: {
        position: 'relative' as const,
    },
    avatar: {
        height: '156px',
        width: '156px',
        filter: "blur(2px)"
    },
    outlinedInputSupplier: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    focusedSupplier: {},
    notchedOutlineSupplier: {
        borderWidth: '1px',
    },
    placeholderSupplier: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputTextSupplier: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },
    cameraEdit: {
        position: 'absolute' as const,
        top: "40%",
        left: "50%",
        transform: 'translate(-50%,-50%)',

    },
    editText: {
        position: 'absolute' as const,
        top: "50%",
        left: "24%",
        color: "#fff",
        textWrap: "noWrap",
        fontWeight: 700

    },
    buttonBoxStyle_Supplier: {
        marginBottom: '30px',
        width: '70%',
    },
    acceptBtn_Supplier: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        backgroundColor: "#B300ED",
        color: "#FFFFFF",
        borderRadius: '8px',
        padding: '10px 0px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    dialogBoxStyle_2: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        width: '70%'
    },

    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    rightTickIcon: {
        height: '75px',
        width: '75px'
    },
    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
});

export default withStyles(userStyles)(SupplierCustomForm)
// Customizable Area End
//@ts-nocheck
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start

// Customizable Area End


export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    classes: any
}

export interface S {
    // Customizable Area Start
    openDetailsModal: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class NotificationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    // public inputRef: RefObject<HTMLInputElement>;

    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            openDetailsModal: false,
            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }


    // Customizable Area Start

    // Customizable Area End
}

import React from "react";

import {
    Typography,
    Button,
    // Customizable Area Start
    withStyles,
    Theme,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Dialog,
    Box,
    Grid,
    TextareaAutosize,
    styled,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { closeIcon } from './assets';
import Rating from '@material-ui/lab/Rating';
import moment from "moment";
import TablePagination from '@material-ui/core/TablePagination';


export const ViewButton = styled(Button)({
    '&:hover':{
        boxShadow:"none",
        background:"none",
    }
});
export const styles: any = (theme: Theme) => ({
    review: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "14px",
      margin:"2rem",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "20px",
        },
    },
    container: {
        boxShadow:"none", minHeight: 512,
        maxHeight: 780,  borderRadius: "16px",
        "&::-webkit-scrollbar": {
            display: "none", width: "0",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",

    },
    table: {
        borderRadius: "16px",
    },
    tableHeader: {
        "& th:first-child": {
            borderTopLeftRadius: "16px", borderBottomLeftRadius: "16px",
        },
        "& th:last-child": {
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
        },
        "& th": {
            backgroundColor: "#B300ED",
            color: "#fff",
            fontSize: "18px",
            fontWeight: 500,
        },
    },
    head: {
        flexShrink: 0, height: "58px",
        borderRadius: "16px", '& .MuiTableCell-head': {
            color: "#FFF", fontFamily: "Poppins",
            fontSize: "18px", 
            fontWeight: 500, lineHeight: "14px",
        },
    },
    body: {
    },
    row: {
        height: "88px",
    },
    logoHead: {
        width: "20%",
        borderBottom:"none",
    },
    listingNameHead: {
        width: "15%",
        borderBottom:"none",
    },
    ReviewHead: {
        width: "25%",
        borderBottom:"none",
    },
    DateHead: {
        width: "15%",
        borderBottom:"none",
    },
    ActionsHead: {
        width: "20%",
        borderBottom:"none",
    },
    logoRow: {
    },
    listinNameRow: {
        color: "#3B3B3B",fontFamily: "Poppins",
        fontSize: "16px",fontWeight: 500,
        lineHeight: "14px",width:"17%"
    },
    reviewNameRow: {
        color: "#3B3B3B",fontFamily: "Poppins",
        fontSize: "12px",fontWeight: 500,
        lineHeight: "15px",
    },
    reviewNameRowPara:{
        maxWidth:"266px",wordBreak:"break-word"
    },
    dateRow: {
        color: "#3B3B3B",
        fontFamily: "Poppins", fontSize: "16px",
        fontWeight: 500, lineHeight: "14px",
    },
    actionRow: {
        borderRadius: "8px", border: "1px solid #6200EA",
        background: "#FFF", color: "#6200EA",
        fontFamily: "Poppins",fontSize: "14px",
        fontWeight: 400, lineHeight: "22px",
        textTransform: "initial",
        maxWidth:"113px", height:"35px",
        '&:hover': {
            background: "#FFF",
        }
    },
    dialogStyleEdit: {
        width: "996px",
        borderRadius: "8px 8px 32px 8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        display: "flex",flexDirection: "column",
        scrollbarColor: "#D6D6D6 transparent",
        overflowY: 'hidden', overflowX: 'hidden',
        padding: "22px 40px 40px", boxSizing: "border-box",
        "&::-webkit-scrollbar": {
            width: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: "12px",
        },
    },
    updateReview: {
        color: "var(--basic-black, #000)",
        fontFamily: "Poppins",
        fontSize: '18px', fontWeight: 500,
        lineHeight: "26px", marginBottom: "23px",
    },
    rating: {
        display: 'flex', flexDirection: 'row',
        gap: '40px', marginBottom: "56px",
        [theme.breakpoints.down('xs')]: {
            gap: '20px',
        },
    },
    fullWidthTextarea: {
        width: '100%',
        border: "1px solid #CBD5E1",  borderRadius: "8px",
        padding: "8px 9px 10px 8px",
        color: "#000",textOverflow: "ellipsis",
        fontSize: "16px",
        fontWeight: 400, lineHeight: "24px",
        minHeight: "144px",
        fontFamily: "Poppins", textWrap:"wrap",
        '&:hover': {
            borderColor: '#CBD5E1',
        },
        '&:focus': {
            borderColor: '#CBD5E1',
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "end", marginTop: "20px",
    },
    updateBtn: {
        display: "flex", width: '89px',
        height: "32px",
        padding: "6px 10px", justifyContent: "center",
        alignItems: "center",gap: "6px",
        flexShrink: 0,
        borderRadius: "8px", backgroundColor: "#B300ED",
        color: "white", textTransform: "initial",
        fontSize:"14px", fontWeight:400,
        lineHeight:"22px",fontFamily:"Poppins",
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    image: {
        width: "57px", maxWidth: "79px",
        objectFit: "cover"
    },
    listImageNologo:{
        fontSize:"14px",
        fontWeight:400,
        fontFamily:"Poppins",
        color:"#000000"
    },
    icon: {
        width: 32, // Set the width of the star icon
        height: 32, // Set the height of the star icon
    },
    showcase: {
        display: "flex", justifyContent: "end",
        gap: "10px",
        alignItems: "center",margin: "10px",
    },
    showcaseTypo: {
        color: "#262424",
        fontFamily: "Poppins", fontSize: "16px",
    },
    closeIconGridStyle: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'flex-end', padding: '10px 10px 0px 0px'
      },
      closeIconStyle2: {
        height: '25px',
        width: '25px', cursor: 'pointer'
      },

})
// Customizable Area End

import ReviewController, {
    Props
} from "./ReviewController.web";

class Review extends ReviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
           
                <Typography className={classes.review}>Reviews Submitted</Typography>
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="caption table">
                        <TableHead className={classes.head}>
                            <TableRow className={classes.tableHeader}>
                                <TableCell align="center" className={classes.logoHead}>
                                    Logo</TableCell>
                                <TableCell align="left" className={classes.listingNameHead}>
                                    Listing Name</TableCell>
                                <TableCell align="left" className={classes.ReviewHead}>
                                    Review</TableCell>
                                <TableCell align="left" className={classes.DateHead}>
                                    Date</TableCell>
                                <TableCell align="center" className={classes.ActionsHead}>
                                    Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.body}>
                            {this.state.reviewData?.map((item, index) => {return( <TableRow className={classes.row} key={index}>
                                        <TableCell component="th" scope="row" align="center" className={classes.logoRow}>
                                            {item.attributes.logo_url ?
                                              <img className={classes.image} src={item.attributes.logo_url} alt={item.attributes.listing_name} />
                                            :
                                            <Box>
                                                <p className={classes.listImageNologo}>No Logo</p>
                                            </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="left" className={classes.listinNameRow}>
                                            {item.attributes.listing_name}
                                        </TableCell>
                                        <TableCell align="left" className={classes.reviewNameRow}><p className={classes.reviewNameRowPara}>{item.attributes.comment}</p>
                                        </TableCell>
                                        <TableCell align="left" className={classes.dateRow}>
                                            {moment(item.attributes.created_at).format("D-MMM-YY")}
                                        </TableCell>
                                        <TableCell align="center" ><ViewButton variant="contained" className={classes.actionRow} data-testid={`editreview-${index}`} onClick={() => this.handleOpenEdit(item)}>View/Edit</ViewButton></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPageReview}
                    page={this.state.pageReview}
                    onPageChange={this.handleChangePageReview}
                    data-test-id="paginationChange"
                />

                <Dialog
                    open={this.state.edit}
                    onClose={this.handleCloseEdit}
                    maxWidth="md"
                    data-testid={"openmodaledit"}
                    PaperProps={{ className: classes.dialogStyleEdit }}
                >
                    <Box>
                        <Grid item xs={12} className={classes.closeIconGridStyle}>
                            <img src={closeIcon}
                                className={classes.closeIconStyle2}
                                onClick={this.handleCloseEdit}
                            />
                        </Grid>
                        <Typography className={classes.updateReview}>Update Review</Typography>
                        <Rating
                            className={classes.rating}
                            name="simple-controlled"
                            value={this.state.ratingVal}
                            data-testid={"starrating"}
                            onChange={(event, val) => this.handleRating(event, val)}
                            classes={{
                                iconFilled: classes.icon,
                                iconEmpty: classes.icon,
                            }}
                        />
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Empty"
                            className={classes.fullWidthTextarea}
                            value={this.state.editedData?.attributes.comment}
                            onChange={this.handleReviewCommentChange}
                            data-testid={"textarea"}
                        />
                        <Box className={classes.btnContainer}>
                            <Button className={classes.updateBtn} data-testid={"updateBtn"} onClick={this.handleUpdate}>Update</Button>
                        </Box>
                    </Box>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export { Review }
export default withStyles(styles)(Review)

// Customizable Area End

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const suppliersImg1 = require("../assets/cat_img_1.png");
export const ratings = require("../assets/ratings.svg");
export const addmoreIcon = require("../assets/image.png");
export const deleteIcon = require("../assets/group_delete.png");
export const addIcon = require("../assets/image1.png");
export const deleteIcon_1 = require("../assets/group_delete_1.png")
export const pdflogo = require("../assets/pdflogo.svg");
export const xlxslogo = require("../assets/xlxslogo.svg");
export const screenshot = require("../assets/screenshot.svg");
export const FolderIcon = require("../assets/folderIcon.svg");
export const fbIcon = require("../assets/fb.svg");
export const linkedin = require("../assets/linkedin.svg");
export const twitter = require("../assets/xlogo.svg");
export const location = require("../assets/location.svg");
export const phone = require("../assets/phone.svg");
export const internet = require("../assets/internet.svg");
export const locationPin = require("../assets/locationpin.svg");
export const leftarrow = require("../assets/group_d.png");

export const searchIconTwo = require("../assets/searchIcon.png");
export const AddICon = require("../assets/addIcon.svg");
export const CloseICon = require("../assets/close.png");
export const CameraICon = require("../assets/camera.svg");
export const CheckIcon = require("../assets/group_check.svg");
export const logoImage = require("../assets/image_logo_top.png");
export const searchIcon = require("../assets/image2.png");
export const dummyIcon = require("../assets/group_avatar.png");
export const downArrow = require("../assets/image_vect.png");
export const rightTickIcon = require("../assets/group_check.png");
export const AddIConWhite = require("../assets/whitePlusIcon.svg");
export const plusIcon = require("../assets/Vector.png");
export const cameraIcon = require("../assets/group_Avatar_edit_state.png");
export const edit_icon = require("../assets/group_edit.png")

export const similarImg = require("../assets/similarImg.svg");
export const csvLogo = require("../assets/csvLogo.svg");
export const camerIconEdit = require("../assets/group_camera_alt.png")
export const instaLogo = require("../assets/instaLogo.svg");
export const youtubeLogo = require("../assets/youtube18.svg");

export const chipDel=require("../assets/chipDel.svg");

export const closeIconRED = require("../assets/group_close.png");


export const errorIcon = require("../assets/errorIcon.svg");

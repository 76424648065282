import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { ChangeEvent } from "react";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export interface ServiceContactData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    contact: {
                        address: string;
                        fburl: string;
                        instaurl: string;
                        linkdlnurl: string;
                        phonenumber: string;
                        twitterurl: string;
                        contactus: string;
                        youtubeurl: string;
                    };
                    progress_percentage: number
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    serviceContactData: {
        address: string;
        fburl: string;
        instaurl: string;
        linkdlnurl: string;
        phonenumber: string;
        twitterurl: string;
        contactus: string;
        youtubeurl: string;
    };
    ServiceUpdatedContactData: {
        address: string;
        fburl: string;
        instaurl: string;
        linkdlnurl: string;
        phonenumber: string;
        twitterurl: string;
        contactus: string;
        youtubeurl: string;
    };
    isSucessModalOpen: boolean;
    isFeedbackFormOpen: boolean;
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ServicesContactPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getContactListAPICalledId: string = "";
    updateContactListDataAPICallId: string = "";
    phoneNumberReg: RegExp
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            serviceContactData: {
                address: "",
                fburl: "",
                instaurl: "",
                linkdlnurl: "",
                phonenumber: "",
                twitterurl: "",
                contactus: "",
                youtubeurl: "",
            },
            ServiceUpdatedContactData: {
                address: "",
                fburl: "",
                instaurl: "",
                linkdlnurl: "",
                phonenumber: "",
                twitterurl: "",
                contactus: "",
                youtubeurl: "",
            },
            isSucessModalOpen: false,
            isFeedbackFormOpen: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.phoneNumberReg = new RegExp(/^\d*$/);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getServiceContactListData();
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getContactListAPICalledId:
                        this.handleServiceContactLitsResponse(responseJson);
                        break;
                    case this.updateContactListDataAPICallId:
                        this.handleServiceUpdateContactListResponse(responseJson);
                        break;
                }
            }
        }
    }

    // Web Events
    goVendorAccount = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    goBack=()=>{
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal();
        }
        else{
           this.props.handleBack()
        }
    }
    handleOpenFeedbackFrom = () => {
        this.setState({ isFeedbackFormOpen: true })
    }

    handleCloseFeedbackForm = () => {
        this.setState({ isFeedbackFormOpen: false })
    }

    handleUpdatedContactData = () => {
        this.setState({ ServiceUpdatedContactData: { ...this.state.serviceContactData } });
    }

    handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, address: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeFbUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, fburl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeInstaUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, instaurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeLinkdlnUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, linkdlnurl: event.target.value }
        })
        this.props.handleDataChanged()
    }


    handleChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value;

        if (this.phoneNumberReg.test(phoneNumber) && phoneNumber.length<=15) {
            this.setState({
                ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, phonenumber: phoneNumber },
            });
        }
        this.props.handleDataChanged()
    }

    handleChangeTwitterUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, twitterurl: event.target.value }
        })
    }

    handleChangeContactUs = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, contactus: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeYouTubeUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ServiceUpdatedContactData: { ...this.state.ServiceUpdatedContactData, youtubeurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    getServiceContactListData = async () => {
        const category_Id = await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getContactListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${category_Id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    isValidValue=(value:any)=>{
        if(value){
            return value;
        }
        else return "";
    }

    updateServiceContactListData = async () => {
        this.props.handleDataSaved()
        const category_Id = await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        const {address,fburl,instaurl,linkdlnurl,contactus,youtubeurl,twitterurl,phonenumber}=this.state.ServiceUpdatedContactData
        formdata.append("[contact][address]", this.isValidValue(address));
        formdata.append("[contact][fburl]", this.isValidValue(fburl))
        formdata.append("[contact][instaurl]", this.isValidValue(instaurl))
        formdata.append("[contact][linkdlnurl]", this.isValidValue(linkdlnurl))
        formdata.append("[contact][contactus]", this.isValidValue(contactus))
        formdata.append("[contact][youtubeurl]",this.isValidValue( youtubeurl))
        formdata.append("[contact][twitterurl]", this.isValidValue(twitterurl))
        formdata.append("[contact][phonenumber]", this.isValidValue(phonenumber))

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateContactListDataAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${category_Id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleServiceContactLitsResponse = (responseJson: ServiceContactData) => {
        const apiresponse = responseJson.data.catalogue.data.attributes.contact
        if (apiresponse) {
            this.setState({
                serviceContactData: {
                    ...this.state.serviceContactData,
                    address: apiresponse.address,
                    fburl: apiresponse.fburl,
                    instaurl: apiresponse.instaurl,
                    linkdlnurl: apiresponse.linkdlnurl,
                    phonenumber: apiresponse.phonenumber,
                    twitterurl: apiresponse.twitterurl,
                    contactus: apiresponse.contactus,
                    youtubeurl: apiresponse.youtubeurl
                }
            },
                () => this.handleUpdatedContactData()
            )
        }
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }

    handleServiceUpdateContactListResponse = (responseJson: []) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucessModalOpen: true })
            this.getServiceContactListData()
        }
    }

    handleCloseSuccess = () => {
        this.setState({ isSucessModalOpen: false })
    }

    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import {
    Typography,
    Button,
    Grid,
    Box,
    Dialog,
    Theme,
    Paper,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import SupplierFilesPageController, {
    Props,
    configJSON,
} from "./SupplierFilesPageController";
import { deleteIcon, AddIConWhite, CloseICon, CheckIcon } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';


// Customizable Area End

export class SupplierFilesPage extends SupplierFilesPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            <Box>
                <Grid container className={classes.files_maincontainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.files_font_1}>{configJSON.files}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.files_fontStyle_2}>{configJSON.addFiles}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid item container className={classes.files_screenshotheader}>
                            <Grid item xs={6} md={6}><Typography className={classes.files_font_2}>{configJSON.fileName}</Typography></Grid>
                            <Grid item xs={5} md={5}><Typography className={classes.files_font_2}>{configJSON.size}</Typography></Grid>
                        </Grid>
                        <Grid container className={classes.files_screenshotBody} >
                            {this.state.documentArrayFile.map((item, index) => {
                                return (
                                    <Grid item container key={index}>
                                        <Grid item xs={6} md={6} className={classes.screenshotitemFile}><Typography className={classes.files_fontStyle_2} >{decodeURIComponent(item.name)}</Typography></Grid>
                                        <Grid item xs={3} md={3}><Typography className={classes.files_fontStyle_2}  >{item?.id ? item.size : this.formatFileSizeSupplierFile(item.size)}</Typography></Grid>
                                        <Grid xs={2} md={2} item>{item?.url && <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}>Download</a>
                                        }</Grid>
                                        <Grid item xs={1} md={1} style={{ textAlign: "end" }}><div style={{ cursor: "pointer" }} data-testid="remove-1" onClick={() => this.removeDocumentSupplierFile(item)}><img src={deleteIcon} alt="deleteicon" /></div></Grid>
                                    </Grid>
                                )
                            })}

                            <Grid item container>
                                <Grid item xs={5} md={5} >
                                </Grid>
                                <Grid item xs={7} md={7} >
                                    <label htmlFor="upload-document" className={classes.File}><img src={AddIConWhite} alt="AddIConWhite" /><Typography className={classes.add}>{configJSON.addFile}</Typography></label>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container className={classes.files_ButtonBox} >
                            <Grid md={6}><Typography className={classes.files_font_3}>{configJSON.maxSize}</Typography></Grid>
                            <Grid md={5}>
                                <Box className={classes.imagefileBtnWrapperFile}>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        accept=".pdf,.doc,.docx,.xlsx"
                                        onChange={this.onDocumentUploadSupplierFile}
                                        data-testid={"inputrefdocument"}
                                        id="upload-document"
                                    />
                                </Box>

                            </Grid>
                        </Grid>
                    </Grid>


                    <Box className={classes.saveBtn_Wrapper}>
                        <Box className={classes.buttonBox}>
                            <Typography className={classes.need_help}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.fontStyle4page}>{configJSON.no}</Typography>
                            <Typography className={classes.fontStyle4page} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>

                        </Box>

                        <Box className={classes.buttonBoxStyle_4}>
                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"saveBtn"} handleClick={this.handleSubmitSupplierFilePage} />


                        </Box>
                    </Box>
                </Grid>

                <Dialog
                    open={this.state.error}
                    onClose={this.handleCloseErrorDialog}
                    PaperProps={{ style: { width: "529px", height: "240px", borderRadius: "8px 8px 32px 8px", background: "#FFF", boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)" } }}
                >
                    <Box className={classes.successMediaFile}>

                        <Typography className={classes.detailSuccessTextFile}>Can't upload file greater than 30mb</Typography>
                        <Button variant="contained" className={classes.backToAccountButtonFile} onClick={this.handleCloseErrorDialog} data-test-id="backtoaccounterror">Back to Accounts</Button>
                    </Box>
                </Dialog>

                <CustomToast open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />

                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackFile} handleFeedbackEmailChange={this.handleFeedbackEmailChangeFile} handleFeedbackMessageChange={this.handleFeedbackMessageChangeFile} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Box>

        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({

    files_font_1: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    files_maincontainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },

    files_fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    files_screenshotheader: {
        padding: "10px",
        borderBottom: "1px solid #CBD5E1",
    },

    files_screenshotBody: {
        padding: "10px 7px 10px 10px",
    },

    files_ButtonBox: {
        marginTop: '5%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            gap: "10px",
        },
    },

    files_cancelIcon: {
        cursor: 'pointer',
        textAlign: "end" as const,
    },
    downloadtxt: {
        color: "#B300ED",
         fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        lineHeight:"26px",
        fontStyle: "normal",
        fontWeight: 400,
        cursor: "pointer",
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,
        },

    },

    files_font_2: {
        color: "#475569",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },

    files_font_3: {
        fontSize: '14px',
        fontWeight: 300,
        color: '#2D2E2F',
        fontFamily: 'Poppins',
        marginLeft: '5px',
    },
    files_addMoreBtn: {
        backgroundColor: "#B300ED",
        borderRadius: '10px',
        width: '33%',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    files_btnTextStyle: {
        textTransform: 'initial' as const,
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        fontWeight: 400,
    },
    add: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        fontWeight: 400,
        textWrap: "noWrap"
    },

    files_font_5: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins'
    },

    files_feedBack_Text: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '20%',
        marginBottom: '20%'
    },

    File: {
        height: "44px",
        width: "119px",
        padding: "0px 16px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        alignItems: "center",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        textTransform: "initial",
        cursor: "pointer",
        marginTop: 20,
        '&:hover': {
            backgroundColor: "#B300ED",
        },
    },
    saveBtn_Wrapper: {
        marginTop: "59px",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",

        "@media (min-width: 300px) and (max-width: 950px)": {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
        }
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: 'auto',
        }
    },

    buttonBoxStyle_4: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginTop: '3%',
        },
        [theme.breakpoints.down('md')]: {

            width: "100%",
        },
    },

    need_help: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    fontStyle4page: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },
    declineBtn_7_file: {
        fontSize: "18px",
        color: "#B300ED",
        textAlign: "center",
        padding: "2px 81px",
        fontFamily: "Poppins",
        fontWeight: 400,
        textTransform: "initial",
        borderRadius: "6px",
        border: "1px solid #B300ED",
        lineHeight: "50.956px",
        background: "#FFF",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },
    acceptBtn_7_file: {
        height: "55px",
        width: "241px",
        fontSize: "16px",
        padding: "16px 71px",
        justifyContent: "center",
        lineHeight: "24px",
        gap: "8px",
        background: "#B300ED",
        color: "#FFF",
        fontFamily: "Poppins",
        fontWeight: 400,
        alignItems: "center",
        textTransform: "initial",
        borderRadius: "8px",
        marginLeft: "30px",
        textWrap: "nowrap",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },
    successMediaFile: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "20px",
        alignItems: "center",
        height: "100%",
    },
    detailSuccessTextFile: {
        color: "#0F172A",
        textAlign: "center",
        fontWeight: 700,
        lineHeight: "32px",
        margin: "20px 0 51px",
        letterSpacing: "-0.12px",
        fontSize: "24px",
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
    backToAccountButtonFile: {
        fontFamily: "Poppins",
        display: "flex",
        height: "56px",
        width: "367px",
        alignItems: "center",
        textTransform: "initial",
        color: "#FFF",
        lineHeight: "80.956px",
        borderRadius: "8px",
        gap: "8px",
        fontSize: "18px",
        background: "#B300ED",
        '&:hover': {
            background: "#B300ED",
        },
    },

    imagefileBtnWrapperFile: {
        display: "flex",

    },
    successboxSupplierFiles: {
        borderRadius: "8px 8px 32px 8px",
        background: "#FFF",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        height: "329px",
        width: "529px",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },
    successMediaDialogFiles: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: "20px",
    },

    detailSuccessTypoFiles: {
        color: "#0F172A",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        margin: "20px 0 51px"
    },
    backToAccountBtnFiles: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    screenshotitemFile: {
        marginBottom: "12px",
    },
});

export default withStyles(userStyles)(SupplierFilesPage)
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;

  enterEmail: string,
  enterPassword: string,
  isInvalidEmail: boolean;
  isInvalidPass: boolean;
  emailErr: boolean;
  emailErrMsg: string;
  isInvalidPassword: boolean;
  isInvalidRepeat: boolean;
  passErr: boolean;
  passErrMsg: string;
  toastErr: string;
  isLoginModal: boolean;
  toastSucessMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  reg: RegExp;
  regPassword: RegExp;
  passwordReg: RegExp;
  pswdspaceReg: RegExp;
  loginAPICallId: string = "";
  getAccountDetailsAPICalled: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,

      enterEmail: "",
      enterPassword: "",
      isInvalidEmail: false,
      isInvalidPass: false,
      emailErr: false,
      emailErrMsg: "",
      isInvalidPassword: false,
      isInvalidRepeat: false,
      passErr: false,
      passErrMsg: "",
      toastErr: "",
      isLoginModal: true,
      toastSucessMsg: "",
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.reg = new RegExp(/^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i);
    this.regPassword = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
    this.passwordReg = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
    this.pswdspaceReg = new RegExp(/^\S$|^\S[\s\S]*\S$/);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // New Web Events and API integration

  handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        emailErr: true,
        emailErrMsg: configJSON.emailRequire,
      });
    } else if (!this.reg.test(event.target.value)) {
      this.setState({
        emailErr: true,
        emailErrMsg: configJSON.correctemailMsg,
      });
    } else {
      this.setState({ emailErr: false, emailErrMsg: "" });
    }
    this.setState({ enterEmail: event.target.value.trim() });
  };

  handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    if (event.target.value.length === 0) {
      this.setState({
        passErr: true,
        passErrMsg: configJSON.requiPass,
      });
    } else if (!this.pswdspaceReg.test(event.target.value)) {
      this.setState({
        passErr: true,
        passErrMsg: configJSON.spaceErrMsg,
      });
    } else if (value.length > 0) {
      if (!this.passwordReg.test(value.replace(" ", "@"))) {
        this.setState({
          passErr: true,
        });
      } else if (this.passwordReg.test(value.replace(" ", "@"))) {
        this.setState({
          passErr: false,
          passErrMsg: "",
        });
      } else {
        this.setState({ passErr: false, passErrMsg: "" });
      }
    }
    this.setState({ enterPassword: event.target.value.trim() });
  }

  handleFormSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    let isValid = true;
    if (!this.state.enterEmail) {
      this.setState({ isInvalidEmail: true });
      this.setState({
        isInvalidPass: true,
        emailErr: true,
        emailErrMsg: configJSON.emailRequire,
      });
      isValid = false;
    } else if (
      this.state.enterEmail.includes("@") &&
      !this.reg.test(this.state.enterEmail)
    ) {
      this.setState({ emailErr: true, emailErrMsg: configJSON.correctemailMsg });
      isValid = false;
    }
    if (!this.regPassword.test(this.state.enterPassword)) {
      isValid = false;
      this.setState({
        isInvalidPassword: true,
        isInvalidRepeat: false,
        passErr: true,
        passErrMsg: configJSON.requiPass,
      });
    }
    if (isValid) {
      this.loginApi(e);
    }
  };

  goToRegister = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToPassword = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleCloseLoginModal = () => {
    this.props.navigation.goBack()
  }

  goToVenderAccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToBuyerAccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToJoinUsPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "JoinUsLandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToJoinUsSubscription = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "JoinUsSubscription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToSearchListing = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SearchListing");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  landToHomeScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  userNavigation = async () => {
    const params = window.location.pathname;
    const usertype = await getStorageData("user_type");

    switch (params) {
      case "/Home2/EmailAccountLoginBlock":
        this.handleHomeNavigation(usertype);
        break;
      case "/joinus2/EmailAccountLoginBlock":
        this.handleJoinUsNavigation(usertype);
        break;
      case "/JoinUsSubscription2/EmailAccountLoginBlock":
        this.handleJoinUsSubscriptionNavigation(usertype);
        break;
      case "/SearchListing2/EmailAccountLoginBlock":
        this.handleSearchListingNavigation(usertype);
        break;
      default:
        if (params.includes("listing") || params.includes("Catalogue")) {
          this.hanldeListingNavigation(params);
        } else {
          this.landToHomeScreen();
        }
        break;
    }
  }
  
  handleHomeNavigation = (usertype: string) => {
    if (usertype === "Buyer") {
      this.goToBuyerAccount();
    } else if (usertype === "vendor" || usertype === "Vendor") {
      this.goToVenderAccount();
    }
  }

  handleJoinUsNavigation = (usertype: string) => {
    if (usertype === "vendor" || usertype === "Vendor") {
      this.goToJoinUsPage();
    } else {
      this.landToHomeScreen();
    }
  }

  handleJoinUsSubscriptionNavigation = (usertype: string) => {
    if (usertype === "vendor" || usertype === "Vendor") {
      this.goToJoinUsSubscription();
    } else {
      this.landToHomeScreen();
    }
  }

  handleSearchListingNavigation = (usertype: string) => {
    if (usertype === "vendor" || usertype === "Vendor") {
      this.goToSearchListing();
    } else {
      this.landToHomeScreen();
    }
  }

  hanldeListingNavigation = (params: any) => {
    if (params.startsWith("/premiumlisting") ||
      params.startsWith("/basiclisting") ||
      params.startsWith("/Catalogue")) {
      this.handleCloseLoginModal();
    }
  }

  // Login API
  loginApi = (event: ChangeEvent<HTMLFormElement>) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.enterEmail,
      password: this.state.enterPassword,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signinAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Account Details API
  getAccountDetailsAPI = (token: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountDetailsAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.detailsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Web API Response
  handleLoginAPIResponse = (json: any) => {
    if (!json.errors) {
      if (json?.meta?.token) {
        setStorageData("loginToken", json?.meta?.token);
        setStorageData("timestamp", Date.now())
        setStorageData("refresh_token", json?.meta?.refresh_token);
        setStorageData("id", json?.meta?.id);
        setStorageData("user_type", json?.meta?.user_type);
        this.getAccountDetailsAPI(json?.meta?.token)
      }
    } else {
      let key = Object.keys(json?.errors[0])[0];

      this.setState({
        toastErr: (json?.errors[0][key])
      });
    }

  }

  handleDetailsAPIResponse = (json: any) => {
    if (json?.data) {
      const apiresponse = json?.data?.attributes
      setStorageData("userdetails", JSON.stringify(apiresponse))
      this.userNavigation()
    }
  }

  private handleUserCredentials = (message: Message) => {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
    const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));
    if (!countryCode && userName && password) {
      this.setState({
        email: userName,
        password: password,
        checkedRememberMe: true,
      });

      //@ts-ignore
      this.txtInputEmailProps.value = userName;

      //@ts-ignore
      this.txtInputPasswordProps.value = password;

      this.CustomCheckBoxProps.isChecked = true;
    }
  }

  private handleApiResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!apiRequestCallId) return;

    if (apiRequestCallId === this.validationApiCallId && responseJson?.data?.length) {
      const regexData = responseJson.data[0];
      if (regexData?.email_validation_regexp) this.emailReg = new RegExp(regexData.email_validation_regexp);
    }

    if (apiRequestCallId === this.apiEmailLoginCallId) {
      const token = responseJson?.meta?.token || undefined;

      if (token) {
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        this.openInfoPage();
      } else {
        this.parseApiErrorResponse(responseJson);
        this.sendLoginFailMessage();
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const messageHandlers: Record<string, () => void> = {
      [getName(MessageEnum.ReciveUserCredentials)]: () => {
        this.handleUserCredentials(message);
      },
      [getName(MessageEnum.RestAPIResponceMessage)]: () => {
        this.handleApiResponse(message);
      },
    };
    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.loginAPICallId:
          this.handleLoginAPIResponse(responseJson);
          break;
        case this.getAccountDetailsAPICalled:
          this.handleDetailsAPIResponse(responseJson);
          break;
      }
    }
    const handler = messageHandlers[message.id];
    if (handler) {
      handler();
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}

import React, { Component } from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Link
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { bookmarBtnStyle, unclaimedLabelStyle, featureLabelStyle, favouriteIconStyle, websiteBtnStyle } from "../../blocks/customform4/src/PremiumListing.web";
import { ratings } from "../../blocks/customform4/src/assets";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite'

type GoodCompanies ={
    id: number,
    name: string,
    active: boolean,
    icon: string
}
type MyProps = { classes?: any, goodCompanies?: GoodCompanies[], handleBookmarkDelete?: any, domain_url?: any, userdetails?: any, toggleText?: () => void;
    isExpanded?:any, bookmarked?: boolean, markets?: any, name: string, logo: string, showContact?: boolean, showComapnyRating?: boolean, showFeatured?: boolean, showClaimed?: boolean, description?: string, handleBookmarkCall?: any, contactData?: any };

export class ListingHeader extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

   
   
    render() {
        const { classes, name, logo, description, showContact, showComapnyRating, showFeatured, showClaimed, handleBookmarkCall, contactData }: any = this.props
        const previewLength = 300; 
        const isTextLong = description?.length > previewLength;
        const displayedText = this.props.isExpanded || !isTextLong ?  description: description.slice(0, previewLength) + "...";
        return (
            <>
                <Box>
                    <Card className={classes.cards}>
                        <Box className={classes.cardHeader}>
                            <div className={classes.imgContainer}>
                                <Box className={classes.restroImg}>
                                    {logo ?
                                    <CardMedia
                                        component="img"
                                        alt="Logo"
                                        height="140"
                                        image={logo}
                                        title="Restaurant"
                                        style={{ borderColor: "#D9D9D9" }}
                                    />
                                    :
                                    
                                    <Typography className={classes.restoText} style={{ borderColor: "#D9D9D9" }}>No Logo</Typography>
                                    }
                                </Box>
                            </div>
                            <CardContent className={classes.cardContent}>

                                <Box className={classes.descBox}>
                                    <Box>
                                        <Typography variant="h6" component="h2" className={classes.cardHeading}>
                                            {name}
                                        </Typography>
                                    </Box>
                                    <Box className={`${classes.marginTop10}`}>
                                        {this.props.bookmarked ?
                                            <Button variant="contained"
                                                data-testid="bookmark"
                                                className={classes.cardBtnStyle}
                                                style={bookmarBtnStyle}
                                                onClick={this.props.handleBookmarkDelete}
                                            >
                                                <FavoriteIcon style={favouriteFilledIconStyle} />

                                                Unlike
                                            </Button> : ""}
                                        {!this.props.bookmarked ? <Button variant="contained"
                                            data-testid="bookmark"
                                            className={classes.cardBtnStyle}
                                            style={bookmarBtnStyle}
                                            onClick={this.props?.userdetails?.user_type == "Buyer" &&
                                                handleBookmarkCall}
                                        >
                                            <FavoriteBorderIcon style={favouriteIconStyle} />

                                            Bookmark
                                        </Button> : ""}

                                        <Button variant="contained"
                                            component="a"
                                            href={this.props.domain_url}
                                            target="_blank"
                                            className={classes.cardBtnStyle}
                                            style={websiteBtnStyle}>
                                            Website
                                        </Button>
                                        {showContact && <Button variant="contained"
                                            className={classes.cardBtnStyle}
                                            style={{
                                                backgroundColor: '#B300ED',
                                                color: '#FFF',
                                                height: 36,
                                                width: 130,
                                                borderRadius: "9px",
                                            }}>
                                            <Link color="inherit" href={contactData?.contacturl}> Contact</Link>



                                        </Button>}



                                    </Box>

                                    <CardContent style={{ padding: '0px' }}>

                                        <Typography className={`${classes.footerDescription} ${classes.width500}`}>
                                            {displayedText}
                                            {
                                                isTextLong && (
                                                    <span onClick={this.props.toggleText}>
                                                   <span style={{color:"purple",cursor:'pointer'}}> {this.props.isExpanded ? '...See Less' : 'See More'}</span>
                                                </span>
                                                )
                                            }
                                          
                                        </Typography>
                                    </CardContent>

                                    <Box className={classes.marginTop10}>
                                        {this.props.markets && <Typography className={`${classes.heading16} `}>
                                            Markets
                                        </Typography>}

                                        <Box style={{marginLeft:"12px"}}>
                                            <ul className={classes.marketUl}>
                                                {this.props.markets && this.props.markets.map((market: any) => (
                                                    <li key={market?.country_name} style={{
                                                        marginLeft:"12px",}}>
                                                        <Typography className={`${classes.heading400}`} style={{
                                                      maxWidth: "100%",
                                                     wordWrap: "break-word",
                                                     fontFamily:"Poppins"
                                                    }}>
                                                            {market?.country_name}
                                                        </Typography>
                                                    </li>
                                                ))}

                                            </ul>



                                        </Box>
                                    </Box>




                                </Box>
                                <Box>
                                    {showComapnyRating && <Box >
                                        { this.props.goodCompanies && this.props.goodCompanies?.length>0&& <Box className={classes.cardDescriptionContainer}>
                                            <Typography color="textSecondary" className={classes.cardDescriptionHead}>
                                                Good Company rating
                                            </Typography>
                                            <div>
                                                {this.props.goodCompanies.map((company: GoodCompanies) => <img alt={company.name} key={company.id} width={"24px"} height={"24px"} src={company.icon} className={classes.ml5} />)}

                                            </div>

                                        </Box>}


                                    </Box>}


                                    <CardActions className={classes.cardBtnContainer} >
                                        {showFeatured && <Button variant="contained"
                                            className={classes.cardBtnStyle}
                                            style={featureLabelStyle} >
                                            Featured
                                        </Button>}
                                        {showClaimed && <Button variant="contained"
                                            className={classes.cardBtnStyle}
                                            style={unclaimedLabelStyle}>
                                            Unclaimed
                                        </Button>}

                                    </CardActions>
                                </Box>







                            </CardContent>
                        </Box>

                    </Card>
                </Box>
            </>
        );
    }
}

export const userStyles: any = (theme: any) => ({

    cards: {
        backgroundColor: "#fff",
        padding: '25px',
        boxShadow: "none"
    },

    cardHeader: {
        display: 'flex',
        "@media(max-width:600px)": {
            flexDirection: 'column',
        },
    },
    imgContainer: {
        marginLeft: '15px',
    },
    restroImg: {
        width: '110px',
        '& img': {
            width: '110px',
            height: '110px',
            padding: '6px',
            border: '4px solid',
            borderRadius: '100%',
            objectFit: 'fill',
            [theme.breakpoints.down('sm')]: {
                width: '80px',
                height: '80px',
            },
        }
    },
    restoText:{
        width: '110px',
        height: '110px',
        padding: '6px',
        border: '4px solid',
        borderRadius: '100%',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontFamily:"Poppins"
    },
    cardBtnStyle: {
        padding: '1px 20px',
        boxShadow: 'none',
        '& span:nth-child(1)': {
            fontSize: '14px',
            fontWeight: '40end0',
            textTransform: 'initial',
            fontFamily: 'Poppins, sans-serif',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 10
        },
    },
    cardBtnContainer: {
        padding: '0px',
        // paddingRight: 20
    },
    marginTop10: {
        marginTop: 10
    },
    cardHeading: {
        fontFamily: 'Poppins, sans-serif',
        color: "#000",
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },


    },
    footerDescription: {
        fontSize: 14,
        fontWeight: 400,
        color: "#666",
        fontFamily: 'Poppins, sans-serif',
        marginTop: 30,
        overflowWrap: "anywhere",
        wordBreak: "break-word",
    },
    cardDescriptionContainer: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '15px',
        justifyContent: "end"
    },
    ml5: {
        marginLeft: 5
    },
    cardDescriptionHead: {
        color: "#A7A7A7",
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500
    },
    width500: {
        maxWidth: "100%",
        "@media(max-width: 430px)": {
           width:"auto"
          }
    },
    marketUl: {
       
        display: "flex",
        alignItems: "baseline",
        flexWrap: "wrap",
        paddingLeft: 0,
        marginTop: "5px",
        gap:"2rem",
        "& li": {
            whiteSpace:"nowrap"
        },
        [theme.breakpoints.down('sm')]: {
           display:"block"
         },
    },
    heading16: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 700
    },
    cardContent: {
        display: "flex", paddingLeft: 45, paddingRight: 45, width: "100%",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            padding: 0
        },
    },
    descBox:{
        width:"70%",
        "@media(min-width: 414px) and (max-width:960px)":{
         width:"100%"
        }
    }



})
export const favouriteFilledIconStyle = { height: 19, color: "red", marginRight: 5 }


export default withStyles(userStyles)(ListingHeader)
import React from "react";
// Customizable Area Start
import {
 
  Container,
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  Link,
  Backdrop,
  CircularProgress,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

import {topheaderlogo, search,} from "../../landingpage/src/assets";
import {downArrow} from "./assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppBarPage  from "../../../components/src/AppBar.web";

// Customizable Area End

// Customizable Area Start


// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const configJSON = require("./config");
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Container className={classes.advSrchContainer}>
        <AppBarPage
            logoImg={topheaderlogo}
            data-testId="searchInput"
            handleClick={this.goToLoginPage}
            serachIcon={search}
            downarrow={downArrow}
            goToHomePage={this.goToHomePage}
            handleHeaderNavigation={this.handleHeaderNavigation}
            username={this.state.userData}
            searchText={this.state.searchTermProp}
            handleUserSignout={this.handleSignoutClick}
            handleSearchInput={this.handleSearchInput}
            dummyicon={""}
            showDirectoryPopup={this.state.showDirectoryPopup}
            searchDirectoryData={this.state.searchDirectoryData}
            handleAdvancedSearch={this.handleAdvancedSearch}
            handleSubmit={this.handleSubmit}
            handleDirectoryPopupClose={this.handleDirectoryPopupClose}
            />
         <Backdrop className={classes.backdrop} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>   
            
            <Box className={classes.landingBannerContent}>
                <Container className={classes.container}>
                <Box className={classes.navBar}>
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    centered
                    classes={{ centered: classes.tabContainer }}
                    TabIndicatorProps={{ style: { height: '3px', backgroundColor: "B300ED", color:"B300ED"} }}
                    data-test-id="inputSearchText"
                  >
                    <Tab value={"All"} label="All" className={classes.tabItem}/>
                    <Tab value={"Suppliers"} label="Suppliers" className={classes.tabItem}/>
                    <Tab value={"Software"} label="Software"  className={classes.tabItem}/>
                    <Tab value={"Services"} label="Services"  className={classes.tabItem}/>
                    <Tab  value={"Events"} label="Events" className={classes.tabItem}/>
                  </Tabs>
            </Box>
            <Box>
      {
       this.state.directoryWiseData && this.state.directoryWiseData.map((directoryData:any) => {
          
          const {categories,directory,sub_categories,catalogues}=directoryData
          return (<Box key={directoryData.directory}>
            <Box className={classes.suppCaraoselWrapper}>
              <Typography className={classes.supplierListTitle}>{directoryData.directory}</Typography>
              <Slider className={classes.slickSlider} {...configJSON.settings}>
                {directoryData.featured_catalogues.map((item: any) => {
                  return (
                    <Link underline='none' href={this.getListingUrl(directoryData.directory, item.id, item.payment_status)} >
                      <img key={item.id} className={classes.sliderImg} src={item.logo}></img>
                    </Link>
                  )
                })}

              </Slider>

            </Box>

            <Paper className={classes.advanceSearchBodyContainer}>
              <Box className={classes.categorieswrapper}>
                <Typography className={classes.categoriesTitle}>Categories {`(${directoryData.count.categories_count})`}</Typography>
                {categories.slice(0,this.showAllCategoryExist(directory)?categories.length:4)

.map((item: any) => {

                  return (
                    <div>
                      {item &&
                        <Link data-testId="category" onClick={this.handleCategoryRedirection} href={`/Catalogue/${item.directory}?adv_category_id=${item.id}&search=${this.state.searchTermProp}`} underline='none'>
                          <Typography data-test-id="navigationClick" onClick={this.handleClick} key={item.category_slug} className={classes.categoriesItem}>{item.name} ({item.count})</Typography>
                        </Link>
                      }
                    </div>
                  )
                })}
                <Typography onClick={()=>this.showAllCategoriesToggle(directory)} data-test-id="navigationClick"   className={classes.showMoreText}>{this.showAllCategoryExist(directory)?"Show less":"Show more"}</Typography>

                     
                       

              </Box>
              <Box className={classes.productGroupwrapper}>
                <Box>
                <Typography className={`${classes.categoriesTitle} ${classes.productGroupTitle}`}>Productgroup {`(${directoryData.count.sub_categories_count})`}</Typography>
                {sub_categories.slice(0,this.showAllSubCategoryExist(directory)?sub_categories.length:4).map((item: any) => {

                  return (
                    <div>

                      {item &&
                        <Link data-testId="sub_category" onClick={this.handleCategoryRedirection} href={`/Catalogue/${item.directory}?adv_category_id=${item.category_id}&adv_sub_category_id=${item.id}&search=${this.state.searchTermProp}`} underline='none'>

                          <Typography key={item.sub_category_slug} className={classes.categoriesItem}>{item.name} ({item.count})</Typography>
                        </Link>
                      }
                    </div>
                  )
                })}
                   <Typography onClick={()=>this.showAllSubCategoriesToggle(directory)} data-testId="showmore_sub_category"   className={classes.showMoreText}>{this.showAllSubCategoryExist(directory)?"Show less":"Show more"}</Typography>
                </Box>
                <Box>

                <Typography className={`${classes.categoriesTitle} ${classes.productGroupTitle}`}>Listings {`(${directoryData.count.catalogue_count})`}</Typography>
                {catalogues.slice(0,this.showAllCatalogueExist(directory)?catalogues.length:4).map((listing: any, index: number) => {
                  const { directory, id, payment_status } = listing
                  
                  return (
                    <div>
                      {listing &&
                        <Link underline='none' href={this.getListingUrl(directory, id, payment_status)} >

                          <Box className={classes.listingWrapper} key={`${id}${index}`}>
                            <Box className={classes.listingImgContainer}>
                              {listing.logo ?
                              <img alt="logo" className={classes.listingImgItem} src={listing.logo} />
                              :
                              <Typography style={{color:"#005ACF"}} className={classes.listingImgItemText}>No Logo</Typography>
                              }
                            </Box>
                            <Box className={classes.listingTextContainer}>
                              <Typography className={classes.listingTitleText}>{listing.name}</Typography>
                              <Typography className={classes.listingDetailsText}>{listing.short_description && listing.short_description.length>320?listing.short_description.slice(0,320)+"....":listing.short_description}</Typography>

                            </Box>


                          </Box>
                        </Link>
                      }</div>
                  )
                })}
                <Typography style={{textAlign:"center"}} onClick={()=>this.showAllCatalogueToggle(directory)} data-testId="showmoreListing"   className={classes.showMoreText}>{this.showAllCatalogueExist(directory)?"Show less":"Show more"}</Typography>
                <Box style={{textAlign:"center",marginTop:20}}>
                <Button data-testid="goToCatalogue" onClick={()=>this.goToCatalogue(directory)} variant="contained"
                                            className={classes.advanceSearchBtn}
                                           >
                                            {directory} Advanced search
                                        </Button>
                </Box>
                

                </Box>


              </Box>
            </Paper>
          </Box>)
        })
      }
    </Box>


            
                </Container>
              </Box> 
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
 export const advancedSearchStyle = ()=>({
 
  
  advSrchContainer:{
    width:"100%",
    padding:"0",
    maxWidth:"100%"
  },
  landingHeader: {
    fontFamily: "Poppins",
    maxWidth:"100%",
    marginLeft: "auto",
    marginRight:"auto",
    backgroundImage: `url(${require('../../landingpage/assets/banner.png')})`,
    backgroundPosition: "top",
    backgroundSize: "cover",

  },
  landingHeaderRow: {
    
    justifyContent: "space-between",
    alignItems:"center",
    width: "100%",
    display: "flex"
  },
  headingGridContainer: {
    maxWidth: "1440px",
    marginLeft:"auto",
    marginRight:"auto",
    
    padding:"21px 0px",
    "@media(max-width: 1440px)": {
      padding:"21px 38px",
    },
  },
  
  signinBtnAdvSrc: {
    fontWeight: 500,
    lineHeight: "14px",
    border: 0,
    cursor: "pointer",
    fontFamily: "Poppins",
    height:"57px",
    width:"162px",
    borderRadius: "8px",
    background: "#FFF",
    color: "#9429C5",
    fontSize: "14px",
    fontStyle: "bold",
    
    textTransform:"none" as "none",
    '&:hover': {
      backgroundColor: 'white', 
    },
    "@media(max-width: 630px)": {
      height:"6.923vw",
      width:"20vw",
    },
    "@media(max-width: 430px)": {
      height:"6.923vw",
      width:"18vw",
      fontSize:"12px"
    }
  },
  landingBannerContent: {
    display: "block",
    backgroundColor:"F8F2FF",
    marginTop:"7%"
  },
  row: {
    width: "100%",
    display: "block",
    "@media(max-width: 480px)": {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
    }
  },
  topheaderlogo: {
    width:"130px",
    height:"30px",
   
    "@media(max-width: 520px)": {
      height:"20.7px",
      width:"90px",
    },
    "@media(max-width: 430px)": {
      height:"18px",
      width:"70px",
    }
  },
  container:{
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop:"17px",
    "@media(min-width: 1280px)": {
      maxWidth: "1520px"
    }
   
  },
  searchWrapInput: {
    borderRadius: "19px",
    background: "#FFF",
    // padding: "18px 24px",
    display: "block",
    width: "100%",
    paddingLeft: "70px",
    border: 0,
    color: "#000",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "Poppins",
    "& input":{
      padding:"21.5px 0px",
      "@media(max-width: 630px)": {
        padding:"1.7vw 0"
      },
      "@media(max-width: 430px)": {
        padding:"2vw 0"
      },
    },
    "@media(max-width: 680px)": {
      paddingLeft: "55px",
    },
    "@media(max-width: 630px)": {
      height:"6.923vw",
      paddingLeft: "40px",
      borderRadius:"10px",
      fontSize:"15px"
      
    },
    "@media(max-width: 500px)": {
      
      paddingLeft: "30px",
      fontSize:"12px"
    },
    "@media(max-width: 430px)": {
      
      paddingLeft: "25px",
      fontSize:"10px"
    }
  },

  formWrap: {
    width:"57.56vw",
    maxWidth:"829vw",
    margin:"0",
    "@media(max-width: 950px)": {
      width: "40vw",
    }
  },
  searchWrap: {
    position: "relative" as "relative",
    width: "100%",
    
    
  },
  searchIcon: {
    position: "absolute" as "absolute",
    top: "21px",
    left: "24px",
    width: "20.25px",
    display: "block",
    zIndex:10,
    "@media(max-width: 630px)": {
      left: "10px",
      top:"13px",
      width:"17px"
    },
    "@media(max-width: 500px)": {
      
      top:"10px",
     
    },
    "@media(max-width: 430px)": {
      
      top:"7px",
     
    },
  },
  searchIconimg: {
    width: "100%",
    "@media(max-width: 580px)": {
      width: "70%",
    }
  },

  navBar: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid #D6D6D6',
  },
  tabContainer: {
    padding: '5px 0px',
    '& button': {
      minWidth: '110px',
      "@media(max-width: 590px)": {
       minWidth:'unset'
      },
      

    },
    "& .Mui-selected":{
      color:"#B300ED",
      fontWeight:600
    },
  },
  tabItem:{
    fontFamily:"Poppins",
    fontSize:"18px",
    lineHeight:"14px",
    fontWeight:400,
    textTransform:"unset" as "unset",
    color:"#262424",
    "@media(max-width: 590px)": {
      fontSize:"14px",
     },
     "@media(max-width: 450px)": {
      fontSize:"10px",
      lineHeight:"12px",
     }
  },
  advanceSearchBodyContainer:{
    padding:"41px",
    marginBottom:"34px"
  },
  categoriesTitle:{
    fontFamily:"Poppins",
    fontSize:"18px",
    lineHeight:"14px",
    fontWeight:600,
    paddingBottom:"28px",
    borderBottom:"1px solid #D6D6D6",
  },
  productGroupTitle:{
    paddingTop:"41px"
  },
  categoriesItem:{
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"18px",
    lineHeight:"14px",
    color:"#005ACF",
    paddingTop:"28px",
    cursor:"pointer",
    "@media(max-width: 460px)": {
      lineHeight:"30px",
     },
    
  },
  listingWrapper:{
    display:"flex",
    justifyContent:"space-between",
    padding:"28px 28px 28px 35px",
    "@media(max-width: 570px)": {
      flexDirection: 'column',
      alignItems:'center'
     },

  },
  listingImgItem:{
    width: "142px",
    height: "142px",
    border: "1px solid #EAAFAF",
    borderRadius: "50%",
    objectFit:"contain" as const ,
    padding:"6px"
  },
  listingImgItemText:{
    width: "142px",
    height: "142px",
    border: "1px solid #EAAFAF",
    borderRadius: "50%",
    objectFit:"contain" as const ,
    padding:"6px",
    display:"flex",
    justifyContent:"center" as "center",
    alignItems:"center" as "center",
    fontFamily:"Poppins"
  },
  listingImgContainer:{
    height:"148px",
    maxWidth: "148px",
    flexBasis:"17%",
    borderRadius:"50%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "@media(max-width: 1150px)": {
      flexBasis:"25%",
     },
     "@media(max-width: 870px)": {
      flexBasis:"35%",
     },
     "@media(max-width: 690px)": {
      flexBasis:"45%",
     },
     "@media(max-width: 570px)": {
      flexBasis:"unset",
      width:"148px"
     },
  },
  listingTextContainer:{
    flexBasis:"83%",
    margin:"auto 0",
    "@media(max-width: 1150px)": {
      flexBasis:"75%",
     },
     "@media(max-width: 870px)": {
      flexBasis:"65%",
     },
     "@media(max-width: 690px)": {
      flexBasis:"55%",
     },
     "@media(max-width: 570px)": {
      flexBasis:"unset",
     },
  },
  listingTitleText:{
    fontFamily:"Poppins",
    fontSize:"22px",
    lineHeight:"14px",
    fontWeight:500,
    color:"#005ACF",
    marginBottom:"10px",
    cursor:"pointer",
    "@media(max-width: 690px)": {
      lineHeight:"25px",
     },
     "@media(max-width: 570px)": {
     textAlign:'center',
     marginTop:"15px"
     },
  },
  listingDetailsText:{
    paddingRight:"30px",
    fontFamily:"Poppins",
    fontSize:"18px",
    lineHeight:"33.5px",
    fontWeight:400,
    color:"#000",
    "@media(max-width: 570px)": {
      textAlign:'justify',
      paddingRight:"unset",
      lineHeight:"25px",
      },

  },
  supplierListTitle:{
    fontFamily:"Poppins",
    fontSize:"18px",
    lineHeight:"14px",
    fontWeight:600,
    paddingBottom:"28px",
  },
  suppCaraoselWrapper:{
    marginTop:"27px"
  },
  sliderImg:{
    height:110, 
    width:"128.29px !important",
    "@media(max-width: 1150px)": {
      
     
      width:"8.909vw !important",
     
    },
  },
  backdrop: {
    zIndex: 1200,
    color: '#fff',
  },
  slickSlider:{
    width:"92%",
    marginLeft:"auto",
    marginRight:"auto",
    marginBottom:"61.78px",
   
    "& .slick-list":{
      width:"90%",
      margin: "0 auto",
      "& .slick-track":{
        "& .slick-slide":{
          height:"inherit",
        }
      }
    },

    "& .slick-prev":{
      backgroundImage: `url(${require('../assets/sliderLeftImg.svg')})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    height:"45px",
    width:"45px",
    "@media(max-width: 550px)": {
      
     
      height:"30px",
    width:"30px",
     
    },
    },

    "& .slick-prev::before":{
      content: "unset"
    },
    "& .slick-next":{
      backgroundImage: `url(${require('../assets/sliderRightImg.svg')})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    height:"45px",
    width:"45px",
    "@media(max-width: 550px)": {
      
     
      height:"30px",
    width:"30px",
     
    },
    },
    "& .slick-next::before":{
      content: "unset"
    },
  },
  showMoreText:{
    color:"#9429C5",
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"18px",
    paddingTop:"28px",
    textDecoration:"underline",
    cursor:"pointer"
  },
  advanceSearchBtn:{
    backgroundColor: '#9429C5',
    color: '#FFF',
    height: 57,
    width: "auto",
    borderRadius: "9px",
    cursor:"pointer",
    fontFamily:"Poppins",
    fontWeight:500,
    fontSize:"18px",
    textTransform:"none" as const

    
  }
  
  // "& .slick-slide":{
  //   height:"inherit"
  // }
  
 });

 

 export const AdvancedSearchPage = withStyles(advancedSearchStyle)(AdvancedSearch);
 
// Customizable Area End

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

type MyProps = { handleClick?: () => void; label: string; classes: Record<string, string>; };

export class CustomBackButton extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, label, handleClick }: MyProps = this.props;
        return (
            <Button
                variant="outlined"
                className={classes.declineBtn_7}
                data-testid={"save_button"}
                fullWidth
                onClick={handleClick}
            >
                {label}
            </Button>
        );
    }
}

export const userStyles = () => ({
    declineBtn_7: {
        backgroundColor: "#FFFFFF",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#B300ED",
        borderColor: '#B300ED',
        borderRadius: '8px',
        width: '212px',
        height: 55,
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#FFFFFF",
        }
    },
});

export default withStyles(userStyles)(CustomBackButton)
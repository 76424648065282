Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

// API End Points
exports.joinusConteAPIEndPOint = "/bx_block_content_management/join_us";
exports.planDetailsAPIEndPoint = "/bx_block_custom_user_subs/subscriptions/join_us";
exports.listingDataAPIEndPoint = "/bx_block_catalogue/search?query=";
exports.claimListingAPIEndPoint = "/bx_block_catalogue/user_listings";
exports.submitQueryAPIEndPoint = "/bx_block_catalogue/user_listings";
exports.couponCodeAPIEndPoint = "bx_block_coupon_cg/coupon_code_amount";
exports.userRequestAPIEndPoint = "/bx_block_catalogue/user_request";
exports.getAPIMethod = "GET";
exports.patchMethod = "PATCH";

exports.btnSignin = "Sign In";
exports.headerTitle = "From software to seafood,"
exports.headerTitle2 = "we’ve got you covered."
exports.aboutDetail = "A one stop, easy-to-use search engine and digital community for the food and beverage industry. We've combed through and cataloged an endless amount of discoverable data for restaurants, from software to seafood and everything in between. As an industry knowledge hub, we’re connecting the restaurant community at the touch of their fingertips."
exports.aboutUs = "About Us"
exports.suppliers = "Suppliers"
exports.suppliersDirectory = "Suppliers Directory"
exports.software = "Software"
exports.softwareDirectory = "Software Directory"
exports.services = "Services"
exports.servicesDirectory = "Services Directory"
exports.events = "Events";
exports.pay = "Pay";
exports.Select = "Select";
exports.eventsDirectory = "Events Directory"
exports.restaurantSuppliers = "Restaurant Suppliers"
exports.reach1 = "Want to reach your exact target"
exports.reach2 = "audience?"
exports.supplierDetails = "We know there are a million different places you can invest in to market your business. With Restofy, you’re directly reaching users in your core audience market everyday."
exports.weekly = "The Weekly Chit"
exports.inbox = "RR in your inbox"
exports.newsletter = "Subscribe to The Weekly Chit to receive an industry newsletter dedicated to the fun, interesting and weird ass news from the restaurant biz. It’s like walking into a shift and all your mise en place is already diced, sliced and ready for you."
exports.subscribe = "Subscribe"
exports.company = "In Good Company"
exports.companyDetail = "We believe everyone has an opportunity to make a difference"
exports.ecosystem = "In today’s evolving restaurant ecosystem, we recognize and distinguish organizations that raise the integrity bar and are consciously committed to respecting and progressing the people, places and environments that make our restaurant world go round."
exports.selling = "Selling Restaurant Software?"
exports.buyers = "Reach more buyers."
exports.joinUs = "Join Us"
exports.resource = "Your future customers are researching their next Restaurant resource. "
exports.makeSure = "Make sure they find you"


// VENDOR LANDING PAGE CONFIG
exports.vendorHeader = "Join Our Vendor Network and Boost Your Sales"
exports.benefits = "Experience the benefits of joining our dedicated vendor community today."
exports.getStarted = "Get Started"
exports.vendorAbout = "A one stop, easy-to-use search engine and digital community for the food and beverage industry. Weve combed through and cataloged an endless amount of discoverable data for restaurants, from software to seafood and everything in between. As an industry knowledge hub, were connecting the restaurant community at the touch of their fingertips."
exports.marketing = "Too Many Marketing Options"
exports.solution = "One stop solution"
exports.vendorDetail = "A one stop, easy-to-use search engine and digital community for the food and beverage industry. Weve combed through and cataloged an endless amount of discoverable data for restaurants, from software to seafood and everything in between. As an industry knowledge hub, we're connecting the restaurant community at the touch of their fingertips."
exports.marketingSolution = "The One-Stop Marketing Solution"
exports.directory = "Directory wise products"
exports.restaurantResource = "A one stop, easy-to-use search engine and digital community for the food and beverage industry. We’ve combed through and cataloged an endless amount of discoverable data for restaurants, from software to seafood and everything in between. As an industry knowledge hub, we’re connecting the restaurant community at the touch of their fingertips."
exports.explore = "Explore now"
exports.marketingJourney = "MARKETING JOURNEY"
exports.industry = "How we'll attract restaurant industry people to view your listing content and take action."
exports.awareness = "AWARENESS"
exports.organic = "Organic Social"
exports.socialSubscriber = "Paid Social Subscriber Generation Campaigns Press Releases & Media Coverage Trade Partnership Marketing"
exports.interest = "INTEREST"
exports.decision = "DECISION"
exports.weeklyChitEmailCampaigns = "Weekly Chit Email Campaigns"
exports.automationCampaigns = "Automation Campaigns RR Solutions Hub Content SEM: Organic & Paid Search Campaigns"
exports.action = "ACTION"
exports.supplierDirectoryListing = "Supplier Directory Listing Content"
exports.inGoodCompanyRecognition = "In Good Company Recognition Enhanced Supplier Listing Blog Content SEM: Remarketing Campaigns"
exports.celebrating = "CELEBRATING DIFFERENCE MAKERS"
exports.goodCompany = "In Good Company"
exports.makerDetail = "At Restaurant Resource, we believe everyone has an opportunity to make a difference. In today's evolving food and beverage ecosystem, we recognize and distinguish organizations that raise the integrity bar and are consciously committed to respecting and progressing the people, places and environments that make our restaurant world go round. When you claim an Enhanced or Global listing, you can select the In Good Company badges that reflect your business practices."
exports.diversity = "Diversity & Inclusion"
exports.innerDetail = "We empower our people by respecting and appreciating what makes them unique, be it age, gender, ethnicity, religion, disability, sexual orientation, education, or nationality"
exports.privacy = "Privacy"
exports.charity = "Charity & Community"
exports.protectPrivacy = "We respect and protect people's fundamental online privacy and data rights"
exports.charityAim = "We aim to maximize employment, business, and economic opportunities and we support accredited charitable organizations"
exports.eco = "Eco"
exports.environment = "We protect the environment and seek ways to minimize our environmental footprint wherever we operate"
exports.ethics = "Ethics"
exports.standards = "We have high standards of ethics in all of our business practices, pay fair prices and work with suppliers that don't exploit labor"
exports.pricing = "LISTING SUBSCRIPTION PRICING"
exports.price = "The best marketing investment for restaurant suppliers at the right price"
exports.atRestaurantResource = " At Restaurant Resource, we believe everyone has an opportunity to make a difference. In todays evolving food and beverage ecosystem, we recognize and distinguish organizations that raise the integrity bar and are consciously committed to respecting and progressing the people, places and environments that make our restaurant world go round. When you claim an Enhanced or Global listing, you can select the In Good Company badges that reflect your business practices."
exports.btnSignin = "Sign In"

//JoinUs Page Configuratios
exports.joinUsHeaderTitle = "Join Us"
exports.joinUsSupplierBtnText = "Add or claim your supplier listing"
exports.joinUsSoftwareBtnText = "Add or claim your Software listing"
exports.joinUsServicesBtnText = "Add or claim your Services listing"
exports.joinUsEventsBtnText = "Add or claim your Events listing";
exports.subsDescription = "Whether you're in the business of food, beverage, table linens, restaurant cleaning supplies or even merchandise, we're your one stop connection to the industry folks doing the ordering and planning. Our supplier directories and listings are where the rubber meets the road for the food and beverage community. Claim your listing today."
exports.title1 = "Reach Your Exact Target Audience";
exports.title2 = "The best marketing investment for restaurant suppliers at the right price";
exports.monthly = "Monthly";
exports.yearly = "Yearly";
exports.welcomeText = "Welcome to the Add Listing Page";
exports.welcomeDescription = "Enter the name of your listing and the associated domain to see what results show for this specific database. If you find the relevant listing, you'll be able to claim it. If not, you can add a new listing."
exports.cantFind = "Cannot find what you are looking for?";
exports.addListing = "Add your Listing";
exports.claimListing = "Claim Listing";
exports.search = "Search";
exports.companyName = "Company Name";
exports.getBuyerDataEndPoint = "bx_block_content_management/landing_contents";
exports.getVendorDataEndPoint = "bx_block_content_management/vender_landing_content";
exports.nameErrorText = "Name should not contain numbers";

exports.subscriptions = [
  {
    title: 'BASIC',
    description: 'Everything you need to get started to showcase your product to restaurants.',
    price: 'FREE',
    time: 'ALWAYS',
    theme_color: '#6cc9d1',
    perks: [1, 2, 3, 4, 5]
  },
  {
    title: 'ENHANCED',
    description: 'Showcase your business with rich media and drive traffic to your website.',
    price: '$29.99',
    time: 'MONTHLY',
    theme_color: '#f72b8d',
    perks: [1, 2, 3, 4, 5]
  },
  {
    title: 'GLOBAL',
    description: 'Showcase your business with rich media and drive traffic to your website.',
    price: '$29.99',
    time: 'MONTHLY',
    theme_color: '#5c16c2',
    perks: [1, 2, 3, 4, 5]
  },
];
exports.heading1 = "Let's start with the basics";
exports.tankuText = "Thank you for joining our community! To. get started, please fill in the fields below. Don't worry; you'll have the chance to build your profile even further after checkout on the account page."
exports.paymentClaimingText = "Before proceeding to payment and claiming the domain, we need to verify your email address. Please enter your email address below. If it matches the domain, you'll be able to continue with the process."
exports.welcomeClaimText = "Welcome to our Domain Claim page.";
exports.welcomeClaimText2 = "We've made it easy for you to secure your domain effortlessly.";
exports.tobeginWithText = "To begin, simply enter your email address below:";
exports.emailText = "Email";
exports.validating = "Validating..";
exports.uploadImage = "Upload Logo";
exports.domain = "Domain";
exports.postCardApi = "account_block/add_payment_method";
exports.putMethod = "PUT";
exports.validate = "Validate";
exports.verifyEmailText = "Our system is verifying your email...";
exports.greatNews = "Great news!";
exports.emailMatchesText = "Your email matches the domain you're trying to claim.";
exports.proceed = "Proceed";
exports.emailNotMatchText = " It seems the email you entered doesn't match the domain you're trying to claim.";
exports.oopsText = "Oops !";
exports.noworries = "No worries!";
exports.providemoreinfor = "Please provide us with some details, so we can assist you further:";
exports.continue = "Continue";
exports.details = "Details";
exports.software = "Software";
exports.softwareEnhanced = "Software Enhanced - initial Post";
exports.mysoftware = "My Software";
exports.subTotal = "Sub-Total:";
exports.priceamount = "$2,570.09";
exports.tax = "Tax:";
exports.cuponvalue = "$305.69";
exports.cuponapplied = "Coupon Applied";
exports.remove = "Remove";
exports.cupondiscount = "Coupon Discount";
exports.cupontoatlvalue = "-$ 300";
exports.totalValue = "Total:";
exports.paymentOption = "4. Payment Option";
exports.masterCard = "MasterCard";
exports.cardNumber = "3250 XXXX XXXX 1536";
exports.entercvv = "Enter CVV";
exports.card = "Card";
exports.entername = "Enter Name";
exports.name = "Name";
exports.shortDescription = "Short Description";
exports.cardNumb = "Enter Card Number";
exports.productURLrequ = "Product URL is required";
exports.domainav = "Domain Available";
exports.domainunav = "Domain unavailable";
exports.expirayDate = "Enter Expiry Date";
exports.congratulation = "Congratulations !";
exports.addListingScuccess = "You have successfully added your listing";
exports.backtoaccounts = "Back to accounts";
exports.payTotalamount = "Pay $2,570.09";
exports.paymentFailed = "Payment Failed !";
exports.paymentFailedText = "Payment failed. Please contact your bank or choose another payment method";
exports.tryAgain = "Try Again";
exports.backtosearch = "Back to search";
exports.continue = "Continue";
exports.createListingAPIEndPoint = "bx_block_catalogue/create_listing";
exports.postMethod = "POST";
exports.domainListAPIEndPoint = "bx_block_catalogue/catalogues/:catalogue_id/domain_list";
exports.taxValueAPIEndPoint = "/bx_block_coupon_cg/taxes";
exports.postPayment = "bx_block_stripe_integration/payment_methods/create_payments";
exports.cuponCodeAPIEndPoint = "/bx_block_coupon_cg/coupon_code_generator"
exports.fileLogoErrorMessage = "Unsupported file type (Supported file type: jpg, png, jpeg)"
exports.getCategoriesAPIEndPoint="/bx_block_categories/get_categories"







exports.getSearchResultEndPoint="/bx_block_catalogue/search_listing"
// Customizable Area End
//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start

// Customizable Area End


export const configJSON = require("./config");

export interface Review {
    id: string
    type: string
    attributes: ReviewAttributes
}

export interface ReviewAttributes {
    id: number
    comment: string
    rating: number
    created_at: string
    updated_at: string
    listing_name: string
    logo_url: string
}

export interface Props {
    navigation: any;
    id: string;
    classes: any
}



export interface S {
    // Customizable Area Start
    selectedOption: string | null;
    edit: boolean;
    ratingVal: number | null;
    reviewData: Review[];
    editedData: Review | null;
    counts:number;
    rowsPerPageReview: number | any;
    pageReview: number | any;
    start_point: number | any;
    end_point: number | any;
    // Customizable Area End
}


export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class ReviewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getReviewId: string = "";
    updateReviewId: string = "";

    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            selectedOption: "",
            edit: false,
            ratingVal: null,
            reviewData: [],
            editedData: null,
            counts:0,
            rowsPerPageReview: 5,
            pageReview: 0,
            start_point: 0,
            end_point: 0,
            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }


    // Customizable Area Start


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestid = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );


            let response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestid === this.getReviewId) {
                const {total_count} = response?.meta
                this.setState({ reviewData: response?.Review?.data,  counts:total_count});
            
            }

            if (apiRequestid === this.updateReviewId) {
               this.setState({editedData:null,ratingVal:null})
               this.handleCloseEdit()
            }

        }

        // Customizable Area End
    }


    getReview = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReviewId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getReview}?page=${Number(this.state.pageReview) + 1}&per_page=${this.state.rowsPerPageReview}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleUpdateReview = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("loginToken"),
        };

        const httpBody = {
            "id": this.state.editedData.id,
            "rating": this.state.ratingVal,
            "comment": this.state.editedData?.attributes.comment
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateReviewId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateReview
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);

    };


    componentDidMount(): any {
        this.getReview()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
        if (this.state.pageReview !== prevState.pageReview) {
            this.getReview();
        }
    }

    handleOpenEdit = (item: Review) => {
        this.setState({ edit: true, editedData: item, ratingVal: item.attributes.rating })
    }


    handleCloseEdit = () => {
        this.setState({ edit: false })
    }

    handleRating = (event: any, val) => {
        this.setState({ ratingVal: val })
    }

    handleReviewCommentChange = (event: any) => {
        const { editedData } = this.state;

        editedData.attributes.comment = event.target.value;
        this.setState({
            editedData: editedData,
        });
    }

    handleUpdate = ()=>{
        this.handleUpdateReview()
    }

    handleChangePageReview = (event: unknown, newPage: number) => {
        this.setState({ pageReview: newPage });
    };


    // Customizable Area End
}

//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import { accDetails, revSumbit,sigOut,notification,bookMark} from './assets';
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";


export const drawerWidth = 265;

export const mockListData = [
    { id: 1, text: "Account Details", icon: accDetails },
    { id: 2, text: "Reviews Submitted", icon: revSumbit },
    { id: 3, text: "Bookmarks", icon: bookMark },
    { id: 4, text: "Notification Settings", icon: notification },
    { id: 5, text: "Sign out", icon: sigOut },
];

export interface AccountDetails {
    id: string
    type: string
    attributes: Attributes
  }
  
  export interface Attributes {
    full_name: string
    email: string
    user_type: string
    no_of_location: string
    annual_revenue: string
    country: string
    city: string
    company_name: string
    activated: boolean
    role_name: number
    department: number
    type_of_business: number
    profile_image: string
    full_phone_number: string
  }

  export interface SearchDir{
    directory_id: number,
    directory_name:string,
    count: number
} 
// Customizable Area End


export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    classes: any
}



export interface S {
    // Customizable Area Start
    selectedOption: string | null;
    accountDetails:AccountDetails;
    Username: any,
    mobileOpen: boolean,
    showDirectoryPopup:boolean,
    searchDirectoryData:SearchDir[],
    selectedIdBuyer:number

    // Customizable Area End
}


export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class ProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    showProfileid: string = "";


    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            Username: null,
            selectedOption: "Account Details",
            accountDetails:{
                id: "",
                type: "",
                attributes: {
                    full_name: "",
                    email: "",
                    user_type: "",
                    no_of_location: "",
                    annual_revenue: "",
                    country: "",
                    city: "",
                    company_name: "",
                    activated: false,
                    role_name: 0,
                    department: 0,
                    type_of_business: 0,
                    profile_image: "",
                    full_phone_0: "",
                }
            },
            mobileOpen: false,
            showDirectoryPopup:false,
            searchDirectoryData:[],
            selectedIdBuyer:1

            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }


    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestid = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );


            let response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestid === this.showProfileid) {
                if(response && response.data){
                    this.setState({ accountDetails: response.data });
                }
                else{
                    this.handleUserSignout()
                }
               
            }
            else if(apiRequestid===this.getDirectoryDatatApiCallId){
                this.handleDirectorySearchResponse(response)
            }

        }
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ Username: accountDetails });
        const newData = await getStorageData("userdetails");
        const newAccountData = JSON.parse(newData);
        setTimeout(() => {
            this.handleLoader()
        }, 1000);
        if (this.state.Username.profile_image !== newAccountData.profile_image || this.state.Username.full_name !== newAccountData.full_name ) {
            this.setState({ Username: newAccountData });
        }

        // Customizable Area End
    }



    async componentDidMount() {
        super.componentDidMount();
        this.getShowProfile()
    }


    getShowProfile = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.showProfileid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.showProfile
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };



    handleOptionSelect = (selectedOption: string) => {
        if(selectedOption !== "Sign out"){
            this.setState({ selectedOption });
        }
        if (selectedOption === "Sign out") {
            sessionStorage.clear();
            localStorage.clear();
            const logoutMessage = new Message(getName(MessageEnum.NavigationMessage));
            logoutMessage.addData(getName(MessageEnum.NavigationTargetMessage),'Home');
            logoutMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
            raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: null });
            logoutMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(logoutMessage);
        }
        if(this.state.mobileOpen){
            this.handleDrawerToggle()
        }

    };

    handleDrawerToggle = () => {
        this.setState({mobileOpen:!this.state.mobileOpen})
    };

    navigatevendorAccount = () => {
        const messagecurr = new Message(getName(MessageEnum.NavigationMessage));
        messagecurr.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        messagecurr.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messagecurr)
    }

    getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.apiContentType,
        };
    
          const searchDataRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
    
        this.getDirectoryDatatApiCallId = searchDataRequestMessage.messageId;
        searchDataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchDataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
      );
    
      runEngine.sendMessage(searchDataRequestMessage.id, searchDataRequestMessage);
    
    
    }

    navigateToHomeScreen = () => {
        const messagecurr = new Message(getName(MessageEnum.NavigationMessage));
        messagecurr.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        messagecurr.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messagecurr)
    }
    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
    }

    navigateToVendor = async () => {
        const userTypes = await getStorageData("user_type")
        if (userTypes == "vendor" || "Vendor") {
            this.navigatevendorAccount()
        }
    }
    handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
    }

    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.navigateToHomeScreen()
    }
    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }

    handleLoader = () => {
        this.setState({ isLoader: false })
    }
    handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }

    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
    }
    handleClickBuyer = (id:any) => {
        this.setState({ selectedIdBuyer: id });
      };
    // Customizable Area End
}

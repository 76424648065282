import React, { Component } from "react";
import { Avatar, Box, Dialog, OutlinedInput, Typography, Theme, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { closeIcon } from "../../blocks/landingpage/src/assets";
import CustomSaveButton from "./CustomSaveButton.web"

type MyProps = {
    handleCloseOpenEditModal?: () => void;
    updateData?: () => void;
    handleChangeName?: any;
    handleChangeLogo?: any;
    classes?: any;
    icon?: any;
    openModal?: any;
    name?: string;
    srcImage?: any
};

export class CustomDialogBox extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, srcImage, icon, openModal, updateData, handleCloseOpenEditModal, name, handleChangeName, handleChangeLogo }: MyProps = this.props;
        return (
            <Dialog
                open={openModal}
                onClose={handleCloseOpenEditModal}
                maxWidth="md"
                PaperProps={{ className: classes.contactUsDialog }}
            >

                <Box className={classes.cancelGridStyle}>
                    <img
                        src={closeIcon}
                        className={classes.closeIconStyle}
                        onClick={handleCloseOpenEditModal}
                        data-testid={'close_modal'}
                    />
                </Box>
                <Box className={classes.BoxScrollbar_1}>
                    <Typography className={classes.contactUsFont}>{"Update Name and Picture"}</Typography>
                    <label htmlFor="upload-screenshot" className={classes.avatarWrapper}>
                        <Avatar alt="Tony" src={icon} className={classes.avatar} />
                        <img alt="Tony" src={srcImage} className={classes.camera} />
                        <Typography className={classes.editText}>Edit Picture</Typography>
                    </label>
                    <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleChangeLogo}
                        data-testid={"inputref"}
                        id="upload-screenshot"
                    />

                    <OutlinedInput
                        id="outlined-adornment-weight"
                        value={name}
                        onChange={handleChangeName}
                        classes={{
                            root: classes.outlinedInput,
                            focused: classes.focused,
                            input: classes.inputText,
                            notchedOutline: classes.notchedOutline,
                        }}
                        inputProps={{
                            'aria-label': 'address',
                            className: classes.placeholder,
                            placeholder: 'Name',
                        }}
                        style={{ width: '80%' }}
                    />
                    <Box className={classes.buttonBoxStyle}>

                        <Button
                            variant="contained"
                            className={classes.acceptBtn_Supplier}
                            onClick={updateData}
                            data-test-id={"saveBtn"}
                            fullWidth={true}
                        >
                            Save
                        </Button>

                    </Box>
                </Box>
            </Dialog>
        );
    }
}

export const userStyles = (theme: Theme) => ({
    contactUsDialog: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '500px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        marginTop: '5%',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    camera: {
        position: 'absolute' as const,
        top: "40%",
        left: "50%",
        transform: 'translate(-50%,-50%)',

    },
    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '5px'
    },

    avatarWrapper: {
        position: 'relative' as const,
    },
    editText: {
        position: 'absolute' as const,
        top: "50%",
        left: "24%",
        color: "#fff",
        textWrap: "noWrap",
        fontWeight: 700
    },
    avatar: {
        height: '156px',
        width: '156px',
        opacity: "0.7",
    },

    contactUsFont: {
        fontWeight: 700,
        fontSize: "28px",
        fontFamily: "Poppins",
        color: '#0F172A',
        textAlign: 'center' as const
    },

    BoxScrollbar_1: {
        height: '100%',
        overflowX: 'hidden' as const,
        overflowY: 'auto' as const,
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
        scrollbarColor: "#D6D6D6 transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            backgroundColor: "#D6D6D6",
            padding: '30px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            minHeight: 24,
            minWidth: 14,
            borderRadius: 6,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
    },

    contentBoxStyle_1: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '15px',
        padding: '30px 10% 30px 10%'
    },

    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    focused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    placeholder: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },

    buttonBoxStyle: {
        width: '70%',
        marginBottom: '30px'
    },
    acceptBtn_Supplier: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        backgroundColor: "#B300ED",
        color: "#FFFFFF",
        borderRadius: '8px',
        padding: '10px 0px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

});

export default withStyles(userStyles)(CustomDialogBox)
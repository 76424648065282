import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productList: {id: string, attributes: {main_image: string, base_price: string, compare_at_price: string, name: string}}[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecommendationEngineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDefaultSettingProductCallId: string = "";
  getCustomSettingProductCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      productList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if ((responseJson && responseJson.length > 0) || (responseJson && responseJson.data)) {
      if (apiRequestCallId === this.getDefaultSettingProductCallId) {
        this.setState({productList: [...this.state.productList, ...responseJson.data]});
      }
      if (apiRequestCallId === this.getCustomSettingProductCallId) {
        this.setState({productList: [...this.state.productList, ...responseJson.data]});
      }
    } else if (errorReponse || responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getDefaultSettingProducts = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDefaultSettingProductCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.defaultSettingEndPoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCustomSettingProducts = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDUxLCJleHAiOjE2ODE4Njk5MDEsInRva2VuX3R5cGUiOiJsb2dpbiJ9.eOPjJTi1nQPrJMIq3SF0webrYzQHqrPLweX4byeN56Lyl09LdNeVKaR9JV4dJrS1-0x7aGQj1su8F6leBYyT-Q",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomSettingProductCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customSettingEndPoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { ChangeEvent } from "react";

import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    openSaveModal:boolean;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    imagePreview: string;
    value: number;
    progressVal: number;
    isSucessModalOpen: boolean;
    selectedItems: string[];
    categoryListData: any[],
    categoryListName: any[],
    categoryList: any[],
    AllCategoryData: any[],
    updatedSuccessDialog: boolean,
    isFeedback: boolean,
    feedbackEmail: string,
    feedbackMessage: string,
    isValidEmail: { valid: boolean, message: string },
    isLoading: boolean,
    progressPercentage: number,

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierSuppliersPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoryPageId: string = "";
    getCustomFormDetailSupplierId: string = "";
    formSubmitid: string = "";
    feedbackSupPageId: string = "";
    emailReg: RegExp;
    deleteCategoryAPICallId: string="";


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            imagePreview: "",
            value: 0,
            progressVal: 0,
            isSucessModalOpen: false,
            selectedItems: [],
            categoryListData: [],
            categoryListName: [],
            categoryList: [],
            AllCategoryData:[],
            updatedSuccessDialog: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",
            isLoading: false,
            isValidEmail: { valid: false, message: "" },
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestSupplierPageId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseSupplierPage = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestSupplierPageId === this.formSubmitid) {
                this.setState({ updatedSuccessDialog: true })
            }


            if (apiRequestSupplierPageId === this.getCustomFormDetailSupplierId) {
                this.setInitialData(responseSupplierPage.data)
            }

            if (apiRequestSupplierPageId === this.getCategoryPageId) {

                let categoriesName = responseSupplierPage.map((item: any) => ({
                    category_id: item.category_id,
                    category_name: item.category_name
                }));
                this.setState({ categoryListData: responseSupplierPage, })
                this.setState({ AllCategoryData: responseSupplierPage, })
                this.setState({ categoryListName: categoriesName })

            }
            if (apiRequestSupplierPageId === this.feedbackSupPageId) {
                this.setState({ isFeedback: false })
            }
        }

        // Customizable Area End
    }

    // Web Events

    handleSubmitSupplierPage = async () => {
        this.props.handleDataSaved();
        const categoryID = await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        this.state.categoryList.forEach((item) => {
            let subCategory = item.feature
            formdata.append("category_ids[]", item.category.category_id);
            if (subCategory && subCategory.length) {
                subCategory.forEach((item: { sub_category_id: string | Blob; }) => {
                    formdata.append("sub_category_ids[]", item.sub_category_id);

                })
            }
            else{
                formdata.append("sub_category_ids[]", "");
            }
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmitid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleSubmitFeedbackSupplier = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailSupplier(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackSupPageId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCustomFormSupplierDetail = async () => {
        this.setState({ isLoading: true })
        const categoryID = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailSupplierId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCategorySupplierPageList = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCategoryPageId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.categoryList}?directory_name=${"Suppliers"}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    async componentDidMount() {
        super.componentDidMount();
        this.getCategorySupplierPageList()
        this.getCustomFormSupplierDetail()
    }

    setInitialData = (data: any) => {
        this.setState({ isLoading: false })
            if (data.catalogue.data.attributes.categories && data.catalogue.data.attributes.categories.length>0) {
            const allcategories = data.catalogue.data.attributes.categories
            const output1 = allcategories.map((category: { category_name: any; category_id: any; sub_categories: any[]; options: any[]; }) => ({
                category: {
                    category_name: category.category_name,
                    category_id: category.category_id
                },
                feature: category.sub_categories.map(subCategory => ({
                    sub_category_id: subCategory.id,
                    sub_category_name: subCategory.name
                })),
                option: category.options.map(subCategory => ({
                    sub_category_id: subCategory.id,
                    sub_category_name: subCategory.name
                }))
            }));
            this.setState({ categoryList: output1, categoryListData: allcategories })

        }
        if (data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }

    selectCategoryDynamicPage = (event: any, index: any) => {
        let categoryName = event.target.value;
        let categoryId = this.state.categoryListName.find(
            (item) => item.category_name === categoryName
        )?.category_id;

        let options = this.state.AllCategoryData
            .find((item) => item.category_id === categoryId)?.sub_categories
            
        let updatedCategory = this.state.categoryList.map((item, currentIndex) => {
            if (currentIndex === index) {
                return {
                    ...item,
                    category: { category_name: categoryName, category_id: categoryId },
                    option: options || [],
                };
            } else {
                return item;
            }
        });

        this.setState({ categoryList: updatedCategory })
        this.props.handleDataChanged();

    };

    onAutoCompleteChangeSupplierPage = (event: any, newValue: any, index: number) => {
        let updatedCategory = this.state.categoryList.map((item, listindex) => {
            if (listindex == index) {
                return { ...item, feature: newValue }
            } else {
                return item
            }
        })
        this.setState({ categoryList: updatedCategory })
        this.props.handleDataChanged()
    }

    addCategory = () => {
        
        this.setState({ categoryList: [...this.state.categoryList, { category: { category_name: "", category_id: 0, }, feature: [], option: [] }] })
    }


    removeCategory = (value: number) => {
        let updatedCategory = this.state.categoryList.filter((item, index) => {
            return index !== value
        })
        if(value==0){
            this.handleRemoveCategoryAPI()
        }
        this.setState({ categoryList: updatedCategory })
    }






    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleFeedbackEmailChangeSupplier = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChangeSupplier = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailSupplier = (email: string) => {

        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }
    navigateToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal();
        }
        else{
            this.props.handleBack()
        }
    }
    handleRemoveCategoryAPI=async ()=>{
        const category_id=this.state.categoryList[0].category.category_id
     if(this.state.categoryList.length && category_id){
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const categlogueId = await getStorageData("category_id");
       
        const requestMessageDelete = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteCategoryAPICallId = requestMessageDelete.messageId;

        requestMessageDelete.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.categoryDeleteEndpoint}?id=${categlogueId}&category_id=${category_id}`
        );
        requestMessageDelete.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDelete.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );
        runEngine.sendMessage(requestMessageDelete.id, requestMessageDelete);
     }
    }
    // Customizable Area End
}
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    tabIndex: number,
    basicReview: any,
    loadmore: boolean,
    name: any,
    reviewText: string,
    editReview: boolean,
    editReviewId: any,
    contactData: {},
    filterValue: string,
    catalogueId: any,
    token: any,
    anchorEl: any
    ratingValue: number,
    domain_url:any
    description: any,
    aboutDataOpen: boolean,
    reviewDataOpen: boolean,
    hideRatingForm: boolean,
    similar_listings: any,
    userdetails: any,
    logo: any,
    bookmarked: boolean,
    bookmark_id: any,
    directory_id:any,
    openToast:boolean,
    searchDirectoryData:any,
    showDirectoryPopup:boolean,
    searchTermProp:string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BasicListingServiceController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    bookmarkApiId: any;
    deleteBookmarkApiId:any;
    reviewUpdateApiId: any;
    getBasicDetailApiId: any;
    postReviewApiId: any;
    getSearchDataReqId:string="";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            name: "",

            userdetails: {},
            hideRatingForm: false,
            anchorEl: null,
            ratingValue: 0,
            reviewText: "",
            tabIndex: 0,
            basicReview: [],
            loadmore: false,
            aboutDataOpen: false,
            reviewDataOpen: true,
            bookmark_id: null,
            description: "",
            isLoading: false,
            contactData: {},
            similar_listings: [],
            logo: "",
            filterValue: "recent",
            catalogueId: null,
            token: "",
            domain_url:"",
            openToast:false,
            editReview: false,
            editReviewId: null,
            bookmarked: false,
            directory_id:null,
            searchDirectoryData:[],
            showDirectoryPopup:false,
            searchTermProp:"",


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const messageDataId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (this.getBasicDetailApiId === messageDataId) {
            this.handleBasicResponse(responseJson, errorReponse)

        }
        else if (this.reviewUpdateApiId === messageDataId) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getBasicListServiceData(this.state.catalogueId)
        }
        else if (this.bookmarkApiId === messageDataId) {
            this.setState({ isLoading: false })
            this.getBasicListServiceData(this.state.catalogueId)
        }
        else if(this.deleteBookmarkApiId===messageDataId){
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if (this.postReviewApiId === messageDataId) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getBasicListServiceData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({openToast: true,isLoading: false })
               
            }
           
        }
        else if(messageDataId==this.getSearchDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
          }
        
       
       

    }

   

    handleAccordionToggle = (accordion: any) => {
        if (accordion == "aboutDataOpen") {
            this.setState({ aboutDataOpen: !this.state.aboutDataOpen })
        }
        else if (accordion == "reviewDataOpen") {
            this.setState({ reviewDataOpen: !this.state.reviewDataOpen })
        }


    }
   
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleUserRate = (event: any, val: any) => {
        this.setState({ ratingValue: val })
    }
    goToLoginPage = async () => {
        this.handleSignUp();
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    handleSignUp=async()=>{
        await  setStorageData("component",JSON.stringify({component:"BasicListingService",id:this.state.catalogueId}))
    }
    async componentDidMount() {
        const id = this.getCatalogueId()
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })
        this.getBasicListServiceData(id, this.state.filterValue);
    }
    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    
    handleChange = (event: any, newValue: number) => {

        const element:any = window.document.getElementById(`${newValue}-tab`);
        element?.scrollIntoView();
        this.setState({ tabIndex: newValue });
    };
    checkDescription=(description:string)=>{
        if(description) this.setState({aboutDataOpen:true})
    }
    handleBasicResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error && responseJson.data) {
            const { reviews } =responseJson.data;
            if(responseJson.data?.catalogue?.data?.attributes){

            
            const { logo, name, directory_id,review_posted, contact, bookmarked, domain_url,bookmark_id,similar_listings,description } = responseJson.data.catalogue.data.attributes
            this.setState({ isLoading: false,logo,hideRatingForm:review_posted, directory_id, name, bookmark_id,domain_url, bookmarked, contactData: contact, description,similar_listings, basicReview: reviews?.data })
            this.checkDescription(description)
        }
           
        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

        if (token != this.state.token && this.state.token == null) {
            this.setState({ token, userdetails: accountDetails },()=>{
                this.getBasicListServiceData(this.state.catalogueId)
            })
        }


    }
    colorDetect = (val: number) => {
        if (this.state.tabIndex === val) {
            return "#B300ED"
        } else {
            return '#000'
        }
    };
   

    getBasicListServiceData = async (id: any, filter?: any) => {
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken") || ""

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filter ?? this.state.filterValue}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBasicDetailApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    };
    handleLoadBtn = () => {
        this.setState({ loadmore: true })
    }
    navigateToAccount= (usertype:any) => {
        let accountType=usertype.toLowerCase()
          if (accountType == "vendor") {
              this.handleNavigation("VendorAccountDetails")
          }else if(accountType == "buyer" ){
              this.handleNavigation("BuyerAccountDetails")      
            }
    }
    handleFilterClick = (event: any, filter: any) => {
        this.setState({ filterValue: filter, anchorEl: null })
        this.getBasicListServiceData(this.state.catalogueId, filter)
    }
    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    handleBookmarkDeleteResponse=(responseJson:any,errorReponse:any)=>{
        if(responseJson && responseJson.success){
            this.setState({bookmarked:false})
        }
    }
    handleReviewText = (event: any) => {
        this.setState({ reviewText: event.target.value })
    }
    handleBasicSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    }
    handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingValue));
            formdata.append("comment", this.state.reviewText);

            const header = {
                token: this.state.token
            };

            const reviewMessageservice = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.postReviewApiId = reviewMessageservice.messageId;

            reviewMessageservice.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );
            reviewMessageservice.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            reviewMessageservice.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            reviewMessageservice.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(reviewMessageservice.id, reviewMessageservice);
            return true;

        }
        else {
            this.goToLoginPage()
        }

    }
    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data.attributes
        this.setState({ editReview: !this.state.editReview, reviewText: comment, ratingValue: rating, editReviewId: id })
    }
    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
        let formdata = new FormData();
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingValue));
        formdata.append("comment", this.state.reviewText);
        const header = {
            token: this.state.token
        };

        const reviewMessagebasicUpdate = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = reviewMessagebasicUpdate.messageId;

        reviewMessagebasicUpdate.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );
        reviewMessagebasicUpdate.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        reviewMessagebasicUpdate.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        reviewMessagebasicUpdate.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(reviewMessagebasicUpdate.id, reviewMessagebasicUpdate);
        return true;

    }
    handleBookmark = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })
            const token = this.state.token 
            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("directory_id", this.state.directory_id);

            const header = {
                token
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.bookmarkApiId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.bookmarkEndPoint}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        }
        else {
            this.goToLoginPage()
        }
    }
    handleBookmarkDelete= () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteBookmarkApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookmarkDeleteEndpoint+ `${this.state.bookmark_id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };
      handleCloseToast=()=>{
        this.setState({openToast:false})
      }
      

     
      handleSubmit=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
      }

     

      getSearchResultDirectory = () => {
        this.setState({isLoading:true})
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessageData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchDataReqId = searchRequestMessageData.messageId;
        searchRequestMessageData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessageData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchRequestMessageData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestMessageData.id, searchRequestMessageData);
    
    
      }
      handleDirectorySearch=(responseJson:any)=>{
         if(responseJson &&  responseJson.response){ 
           this.setState({isLoading:false,searchDirectoryData:responseJson.response })
         } 
      } 
      handleSearchInput =  (event:any) =>{   
         this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory()) 
            if(!event.target.value) this.setState({showDirectoryPopup:false});
      } 
 
      handleAdvancedSearch = (directory:string) => { 
         this.setState({showDirectoryPopup:false})
           const msg: Message = new Message( 
           getName(MessageEnum.NavigateToAdvancedSearch)  
            ); 
         msg.addData(getName(MessageEnum.NavigationPropsMessage),    this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData),  {directory,searchText:this.state.searchTermProp})
         this.send(msg); 
     
      }

       handleDirectoryPopupClose=()=>{ 
         this.setState({ 
             showDirectoryPopup: false})
      } 

    // Customizable Area End





}
// Customizable Area End


import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
interface UserDetails {
    full_name: string
}
interface Plan {
    id: number;
    name: string;
    price: string;
    description: string
}

interface SelectedPlan {
    selectedPlan: {
        plan: Plan;
        directory_Name: string;
        directory_id: number;
        description: string
    }
}
export interface MockResponse {
    message: string,
    user_listing_id: string,
    error: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    Username: any;
    isVlaidate: boolean;
    isSucessModal: boolean;
    isRaiseQuery: boolean;
    itemId: string;
    changeDomainEmail: string;
    isLoading: boolean;
    error: string;
    sucessMessage: string;
    errorMessage: string;
    isErrorModal: boolean;
    isFeedback: boolean;
    shortDescription: string;
    searchTermProp:string;
    showDirectoryPopup:boolean,
    searchDirectoryData:any
    isModalOpen:boolean,
    selectedPlan: SelectedPlan | null;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ClaimListingPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    claimListingAPICallId: string = "";
    submitQueryAPICallID: string = "";
    getResultApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            Username: null,
            isVlaidate: false,
            isSucessModal: false,
            isRaiseQuery: false,
            itemId: "",
            changeDomainEmail: "",
            isLoading: false,
            error: "",
            sucessMessage: "",
            errorMessage: "",
            isErrorModal: false,
            isFeedback: false,
            shortDescription: "",
            searchTermProp:"",
            showDirectoryPopup:false,
            searchDirectoryData:[],
            isModalOpen:false,
            selectedPlan:null
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        const storedData = await getStorageData("userdetails");
        const localStorageData = await getStorageData('selectedPlan');
        const parsedData = JSON.parse(localStorageData);
        const accountDetails = JSON.parse(storedData);
        this.setState({ Username: accountDetails , selectedPlan: parsedData });
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let ApiResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && ApiResponseJson) {
                switch (apiRequestCallId) {
                    case this.claimListingAPICallId:
                        this.claimListingAPIResponse(ApiResponseJson)
                        break;
                    case this.submitQueryAPICallID:
                        this.submitQueryListingAPIResponse(ApiResponseJson);
                        break;
                    case this.getResultApiId:
                        this.handleDirectorySearchResponse(ApiResponseJson);
                        break;    
                    default:
                        break;
                }
            }
        }

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const propData = message.getData(
                getName(MessageEnum.SessionResponseData)
            );
            await setStorageData('searchItem_id', JSON.stringify(propData));
        }
    }

    // Web Events
    handleCloseSucessModal = () => {
        this.setState({ isSucessModal: false })
    }

    handleCloseErrorModal = () => {
        this.setState({ isErrorModal: false })
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true, isErrorModal: false, isLoading: false })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false })
    }

    handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ changeDomainEmail: event.target.value, error: "" })
    }

    handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shortDescription: event.target.value })
    }

    goToCheckOutPage = () => {
        if(this.state.selectedPlan?.selectedPlan?.plan.name=="BASIC"){
            this.setState({isModalOpen:true})
            
        }
        else{
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "ListingCheckOutPage");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
        }
      
    }
    goToSearchListingage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SearchListing");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    navigatevendorAccount = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    navigateToHomeScreen = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    navigateToVendor = async () => {
        const usertype = await getStorageData("user_type")
        if (usertype == "vendor" || "Vendor") {
            this.navigatevendorAccount()
        }
    }

    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.navigateToHomeScreen()
    }

    claimListingAPI = async () => {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const itemIdString = await getStorageData("searchItem_id");
        const itemIdObject = JSON.parse(itemIdString);
        const actualItemId = Number(itemIdObject.searchItem_id);

        const directoryId = await getStorageData("directory_id")

        const httpBody = {
            data: {
                attributes: {
                    user_listing: {

                        email: this.state.changeDomainEmail,
                        directory_id: directoryId,
                        catalogue_id: actualItemId,
                        subscription_id:this.state.selectedPlan?.selectedPlan?.plan.id
                        

                    }
                }
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.claimListingAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.claimListingAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
      }

    submitQueryDescription = async () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const itemIdString = await getStorageData("searchItem_id");
        const itemIdObject = JSON.parse(itemIdString);
        const actualItemId = Number(itemIdObject.searchItem_id);

        const httpBody ={
        "id": actualItemId,
        "description": this.state.shortDescription
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitQueryAPICallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userRequestAPIEndPoint}`
        );
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }


    // API Response

    claimListingAPIResponse = async (ApiResponseJson: MockResponse) => {
        const apiResponse = ApiResponseJson
        if (apiResponse?.message) {
            setTimeout(() => {
                this.setState({
                    isSucessModal: true,
                    sucessMessage: apiResponse?.message
                });
            }, 1000);
        } else {
            this.setState({
                isErrorModal: true,
                errorMessage: apiResponse?.error
            })
        }

        apiResponse?.user_listing_id && await setStorageData("user_listing_id", apiResponse?.user_listing_id)
    }

   
    getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
          const searchDataRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
    
        this.getResultApiId = searchDataRequestMessage.messageId;
        searchDataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchDataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchDataRequestMessage.id, searchDataRequestMessage);
    
    
      }
    submitQueryListingAPIResponse = (ApiResponseJson: any) => {
        const apiResponse = ApiResponseJson
        if (apiResponse?.message) {
            this.goToSearchListingage()
        }
    }

    handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
    }
    
    
    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
    // Customizable Area End
}

import React, { Component } from "react";
import {
    Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';


type MyProps = {open?:any,onClose:any};

export class CustomErrorToast extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { open,onClose}: any = this.props
        return (
            <div>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={4000} onClose={onClose}>
                    <MuiAlert variant="filled" onClose={() => { }} severity="error">
                        Account must be a Buyer
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

export const userStyles: any = (theme: any) => ({

    cards: {
        backgroundColor: "#fff",
        padding: '25px',
        boxShadow: "none"
    },

    cardHeader: {
        display: 'flex',
        "@media(max-width:600px)": {
            flexDirection: 'column',
        },
    },
    imgContainer: {
        marginRight: '15px',
    },
    restroImg: {
        width: '110px',
        '& img': {
            width: '110px',
            height: '110px',
            padding: '10px',
            border: '4px solid',
            borderRadius: '100%',
            objectFit: 'fill',
            [theme.breakpoints.down('sm')]: {
                width: '80px',
                height: '80px',
            },
        }
    },
    cardBtnStyle: {
        padding: '1px 20px',
        boxShadow: 'none',
        '& span:nth-child(1)': {
            fontSize: '14px',
            fontWeight: '40end0',
            textTransform: 'initial',
            fontFamily: 'Poppins, sans-serif',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 10
        },
    },
    cardBtnContainer: {
        padding: '0px',
        paddingRight: 20
    },
    marginTop10: {
        marginTop: 10
    },
    cardHeading: {
        fontFamily: 'Poppins, sans-serif',
        color: "#000",
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },


    },
    footerDescription: {
        fontSize: 14,
        fontWeight: 400,
        color: "#666",
        fontFamily: 'Poppins, sans-serif',
        marginTop: 30
    },
    cardDescriptionContainer: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '15px',
        justifyContent: "end"
    },
    ml5: {
        marginLeft: 5
    },
    cardDescriptionHead: {
        color: "#A7A7A7",
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500
    },
    width500: {
        width: 500
    },
    marketUl: {
        display: "flex",
        alignItems: "baseline",
        paddingLeft: 0,
        marginTop: "5px",
        "& li": {
            width: 152,
            whiteSpace: "nowrap"
        },
        "& li:first-child": {
            listStyle: "none"
        }
    },
    heading16: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 700
    },
    cardContent: {
        display: "flex", paddingLeft: 45, paddingRight: 45, width: "100%",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            padding: 0
        },
    }



})
export const favouriteFilledIconStyle = { height: 19, color: "red", marginRight: 5 }


export default withStyles(userStyles)(CustomErrorToast)
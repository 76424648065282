export const suppliersImg1 = require("../assets/cat_img_1.png");
export const suppliersImg2 = require("../assets/cat_img_2.png");
export const suppliersImg3 = require("../assets/cat_img_3.png");
export const suppliersImg4 = require("../assets/cat_img_4.png");
export const ratings = require("../assets/ratings.svg");
export const gridView = require("../assets/grid_view.svg");
export const listView = require("../assets/list_view.svg");
export const logoImage = require("../assets/image_logo_top.png");
export const searchIcon = require("../assets/image2.png");
export const downArrow = require("../assets/image_vect.png");



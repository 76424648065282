import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    OutlinedInput,
    Dialog,
    Theme,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import ContactsPageController, {
    Props,
    configJSON,
} from "./ContactsPageController";
import { rightTickIcon, CloseICon } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';

// Customizable Area End

export class ContactsPage extends ContactsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.boxWrapperStyle}>
                <Backdrop className={classes.backdropStyle3} open={this.state.isLoadContactData}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Typography className={classes.fontStyle_1} data-testid={'contacts'}>{configJSON.contacts}</Typography>
                <Box className={classes.textFeildBoxStyle}>
                    <Box className={classes.boxWidth}>
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.address}
                            onChange={this.handleChangeAddress}
                            data-testid={'changeaddress'}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.address,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.fburl}
                            onChange={this.handleChangeFbUrl}
                            data-testid={"change_fburl"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.facebookUrl,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.instaurl}
                            onChange={this.handleChangeInstaUrl}
                            data-testid={"change_instaurl"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.instausername,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.linkdlnurl}
                            onChange={this.handleChangeLinkdlnUrl}
                            data-testid={"change_lindlnurl"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.linkdlinLink,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />
                    </Box>

                    <Box className={classes.boxWidth}>
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            type="text"
                            value={this.state.updatedContactData?.phonenumber}
                            onChange={this.handleChangePhoneNumber}
                            data-testid={"change-phno"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.phoneNumber,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.twitterurl}
                            onChange={this.handleChangeTwitterUrl}
                            data-testid={"change_twturl"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.twitterUsername,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.contactus}
                            onChange={this.handleChangeContactUs}
                            data-testid={"change_contacturl"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.contactusname,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedContactData?.youtubeurl}
                            onChange={this.handleChangeYouTubeUrl}
                            data-testid={"change_utuburl"}
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: configJSON.youtubeUsername,
                                className: classes.placeholder_1,
                            }}
                            classes={{
                                root: classes.outlinedInput_2,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline_1,
                                input: classes.inputText_1
                            }}
                            className={classes.nameTextfeild_2}
                        />
                    </Box>
                </Box>

                <Box className={classes.saveBtnWrapper_8}>
                    <Box className={classes.buttonBoxContact}>
                        <Typography className={classes.fontStyle5Contact}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4Contact}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4Contact} onClick={this.handleOpenFeedbackModalContact} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                    </Box>

                    <Box className={classes.buttonBoxStyle}>
                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                        <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.updateContactListData} />


                    </Box>
                </Box>

                <Dialog
                    open={this.state.isSucess}
                    PaperProps={{ className: classes.dialogStyles_3 }}
                    onClose={this.handleCloseSuccessDialog}
                    data-test-id="backtoaccount"
                    maxWidth="md"
                >
                    <Box className={classes.dialogBoxStyle_3}>
                        <img
                            src={rightTickIcon}
                            className={classes.rightTickIcon_3}
                        />
                        <Typography className={classes.welcomeFontStyle_3}>{configJSON.congratulation}</Typography>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.buttonStyle_3}
                            onClick={this.goVendorAccount}
                            data-testid="vendoraccount"
                        >
                            {configJSON.backtoaccount}
                        </Button>

                    </Box>
                </Dialog>
                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModalContact} handleSubmitFeedback={this.handleSubmitFeedbackContact} handleFeedbackEmailChange={this.handleFeedbackEmailChange} handleFeedbackMessageChange={this.handleFeedbackMessageChange} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    boxWrapperStyle: {
        margin: '33px 46px 50px 45px',
        border: '2px solid #C8C9CA',
        padding: '40px 5% 40px 5%',
        borderRadius: '6px',

        [theme.breakpoints.down('md')]: {
            margin: "0",
        },
    },
    backdropStyle3: {
        zIndex: 1200,
        color: '#fff',
    },
    fontStyle_1: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    textFeildBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '3%',
        gap: '50px',
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: 'unset',
            gap: "30px",
        },
    },

    boxWidth: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '20px'
    },
    contentBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '7px'
    },

    textFeildBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '40px'
    },

    fontStyle1: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    fontStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },

    nameTextfeild_2: {
        // marginTop: '13%'
    },

    formcontrol: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
    },

    boxWidth_3: {
        marginTop: '7%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    BtnTextStyle: {
        textTransform: 'initial' as const,
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        fontWeight: 400,
    },

    addMoreBtn: {
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        backgroundColor: "#B300ED",
        width: '15%',
        borderRadius: '10px',
    },

    addIconStyle: {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
    },
    saveBtnWrapper_8: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15%",
        [theme.breakpoints.down('md')]: {
            gap: "20px",
            alignItems: "unset",
            flexDirection: "column",
        },
    },
    buttonBoxContact: {
        display: 'flex',
        width: '45%',
        gap: '10px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    fontStyle4Contact: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        cursor: "pointer",
    },
    fontStyle5Contact: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#262424',
        fontSize: '14px',
    },

    buttonBoxStyle: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // marginTop: '15%'
    },
    acceptBtn_8: {
        padding: "16px 71px",
        background: "#B300ED",
        color: "#FFF",
        height: "55px",
        width: "241px",
        textWrap: "nowrap",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "Poppins",
        textTransform: "initial",

        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    declineBtn_8: {
        padding: "2px 81px",
        background: "#FFF",
        color: "#B300ED",
        textAlign: "center",
        lineHeight: "50.956px",
        borderRadius: "6px",
        textTransform: "initial",
        border: "1px solid #B300ED",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    outlinedInput_2: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    focused: {},
    notchedOutline_1: {
        borderWidth: '1px',
    },
    placeholder_1: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText_1: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },

    dialogStyles_3: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    dialogBoxStyle_3: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        paddingTop: '30px'
    },

    rightTickIcon_3: {
        height: '65px',
        width: '65px',
    },

    buttonStyle_3: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset"
        },
    },

    welcomeFontStyle_3: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
});

export default withStyles(userStyles)(ContactsPage)
// Customizable Area End
import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Grid,
  Dialog,
  TextField,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { restaurantLogo, groupBuyer, checkedBox, groupVender, uncheckedBox, closeModalIcon } from "./assets"

// Customizable Area End

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props, configJSON, tabsList
} from "./EmailAccountRegistrationController";
// Customizable Area End
export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  display = (classes: any) => {
    return (
      <Box className={classes.errorBoxStyle}>
        {this.state.confirmPassSucess ? <CheckCircleOutlineOutlinedIcon className={classes.sucessIconStyle} /> : <ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
        <Typography style={{
          color: !this.state.confirmPassSucess || this.state.confirmPassErr ? '#D53333' : '#1AA663',
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 400,
        }}>
          {this.state.confirmPassSucessText}
        </Typography>
      </Box>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes }: any = this.props
    const { emailErrorText, fnameErrorText, pswdErrorText, toastErrMsg, confirmPassSucessText } = this.state;
    const { enteremail, entername, passnotmatch, passmatch } = configJSON;

    const errorMappings: { [key: string]: { stateProp: string; toastCheck: string | null } } = {
      emailErrorMessage: { stateProp: emailErrorText, toastCheck: enteremail },
      fnameErrorMessage: { stateProp: fnameErrorText, toastCheck: entername },
      passErrorMessage: { stateProp: pswdErrorText, toastCheck: passnotmatch },
      confirmPasswordMsg: { stateProp: confirmPassSucessText, toastCheck: passmatch },
      firstnameMsg: { stateProp: fnameErrorText, toastCheck: null },
      emailMessage: { stateProp: emailErrorText, toastCheck: null },
      passworMessage: { stateProp: pswdErrorText, toastCheck: null },
    };

    const messages: { [key: string]: any } = {};

    for (const key in errorMappings) {
      const { stateProp, toastCheck } = errorMappings[key];
      messages[key] = stateProp || (toastCheck && toastErrMsg === configJSON[toastCheck]);
    }
    // Customizable Area End
    return (
      // Required for all blocks
      // Customizable Area Start
      <>
        <Dialog
          open={this.state.openModal}
          maxWidth="md"
          PaperProps={{ className: classes.dialogStyles }}
          onClose={this.handleCloseRegisterModal}
          data-testid={"closeRegisterModal"}
        >
          <Box className={classes.containerScrollbar}>
            <Grid item xs={12} className={classes.closeIconGridStyle}>
              <img src={closeModalIcon}
                className={classes.closeIconStyle2}
                onClick={this.handleCloseRegisterModal}
              />
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ padding: '30px' }} >
              <Grid item xs={12} className={classes.resLogoGridStyle}>
                <img src={restaurantLogo} className={classes.restaurtimageStyle} />
              </Grid>

              <Grid item xs={12} className={classes.titleGrid}>
                <Typography className={classes.welcomeFont}>{configJSON.welcomeText}</Typography>
                <Typography className={classes.credFontStyle}>{configJSON.continueText}</Typography>
              </Grid>

              <Grid item xs={12} >
                <StyledTextField
                  variant="outlined"
                  placeholder={configJSON.fullname}
                  value={this.state.firstName}
                  data-testid={"fullname-test"}
                  onChange={this.handleChangeFirstName}
                  error={messages.fnameErrorMessage}
                  fullWidth
                  helperText={
                    (messages.fnameErrorMessage) &&
                    <Box className={classes.errorBoxStyle} >
                      {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                      <Typography style={{
                        color: "#D53333",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {messages.firstnameMsg}
                      </Typography>
                    </Box>
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes["editNameStyle"] },
                  }}
                  className={classes.textFeidStyle}
                />
              </Grid>

              <Grid item xs={12} >
                <StyledTextField
                  variant="outlined"
                  placeholder={configJSON.email}
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  data-testid={"email-test"}
                  error={messages.emailErrorMessage}
                  helperText={
                    (messages.emailErrorMessage) &&
                    <Box className={classes.errorBoxStyle}>
                      {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                      <Typography style={{
                        color: "#D53333",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {messages.emailMessage}
                      </Typography>
                    </Box>
                  }
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes["editNameStyle"] },
                  }}
                  className={classes.textFeidStyle}
                />
              </Grid>

              <Grid item xs={12} >
                <StyledTextField
                  variant="outlined"
                  placeholder={configJSON.pass}
                  fullWidth
                  value={this.state.password}
                  type="password"
                  data-testid={"password-test"}
                  onChange={this.handleChangePassword}
                  error={messages.passErrorMessage}
                  helperText={
                    (messages.passErrorMessage) &&
                    <Box className={classes.errorBoxStyle}>
                      {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                      <Typography style={{
                        color: "#D53333",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {messages.passworMessage}
                      </Typography>
                    </Box>
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes["editNameStyle"] },
                  }}
                  className={classes.textFeidStyle}
                />
              </Grid>

              <Grid item xs={12} >
                <StyledTextField
                  variant="outlined"
                  type="password"
                  placeholder={configJSON.reEnterPass}
                  data-testid={"confirmpassword-test"}
                  value={this.state.confirmPassword}
                  onChange={this.handleChangeConfirmPass}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes["editNameStyle"] },
                  }}
                  error={messages.confirmPasswordMsg}
                  helperText={
                    (messages.confirmPasswordMsg) &&
                    this.display(classes)
                  }
                  className={classes.textFeidStyle}
                />
              </Grid>
              <Grid item xs={12} >
                <Typography className={classes.termsConditions}>
                  {configJSON.bySignIn} <span
                    className={classes.spanStyle}
                    data-testid={"terms"}
                    onClick={this.termsConditionAPI}>{
                      configJSON.terms}
                  </span> {configJSON.seeOur} <span
                    data-testid={"privacy"}
                    onClick={this.privacyPolicyAPI}
                    className={classes.spanStyle}>{configJSON.privicay}</span>.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth
                  variant="contained"
                  type="submit"
                  data-testid={"sbumit-button"}
                  onClick={this.SignUpFormValidation}
                  className={classes.continueButtonStyle}
                >{configJSON.continueBtn}</Button>
              </Grid>
              <Grid item xs={12} className={classes.loginGridStyle} >
                <Typography className={classes.spanTextColor}>
                  {configJSON.alreadyHaveAcc} <span
                    data-testid={"gotologin"}
                    onClick={this.goToLogin}
                    className={classes.spanStyle2}>{configJSON.login}</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Dialog>

        <Dialog
          open={this.state.isTermsConditions}
          maxWidth="md"
          PaperProps={{ className: classes.termsdialogStyles }}
        >
          <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ padding: 30 }}>
            <Grid item xs={12} className={classes.tabGridStyle}>
              <Tabs
                textColor="secondary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                variant="scrollable"
                scrollButtons="auto"
                value={this.state.tabValue}
                onChange={this.handleChangeTab}
                data-test-id={"tabchange"}
              >
                {tabsList.map((item: any) => (
                  <Tab
                    data-test-id={item.value}
                    fullWidth
                    key={item.key}
                    onClick={() => {
                      this.setState({ tabValue: item.key });
                    }}
                    label={item.label}
                    className={classes.tabTextStyle}
                  >
                  </Tab>
                ))}
              </Tabs>
            </Grid>

            <Grid item xs={12} >
              <Box className={classes.boxScroll}>
                {
                  this.state.tabValue === 0 && (
                    <Typography className={classes.tabContentText} dangerouslySetInnerHTML={{ __html: this.state.termsCondition }}></Typography>
                  )
                }

                {
                  this.state.tabValue === 1 && (
                    <Typography className={classes.tabContentText} dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy }}></Typography>
                  )
                }

              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonBoxStyle}>
                <Button
                  variant="contained"
                  onClick={this.handleCloseRegisterModal}
                  className={classes.acceptBtn}
                >{configJSON.acceptBtn}</Button>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseRegisterModal}
                  className={classes.declineBtn}
                >{configJSON.declineBtn}</Button>
              </Box>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.openSelectAccount}
          maxWidth="md"
          PaperProps={{ className: classes.dialogWrapper }}
          onClose={this.handleCloseLoginModal}
          data-testid={"closemodal"}
        >
          <Box className={classes.Scrollbar}>
            <Grid item xs={12} className={classes.closeIconGridStyle}>
              <img src={closeModalIcon}
                className={classes.closeIconStyle2}
                onClick={this.handleCloseLoginModal}
              />
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ padding: '30px' }}>
              <Grid item xs={12} className={classes.LogoGridStyle}>
                <img src={restaurantLogo} className={classes.imageStyle} />
              </Grid>

              <Grid item xs={12} className={classes.chooseAccountGridStyle}>
                <Typography className={classes.textColor}>{configJSON.chooseAccount}</Typography>
                {this.state.toastErrMsg ? <p className={classes.emailErrorShowText}>{this.state.toastErrMsg}</p> : ""}
              </Grid>

              <Grid item xs={12} className={classes.gridStyle}
                style={{
                  border: this.state.buyerAccount ? '2px solid #DE76FF' : '2px solid #DDDDDD',
                }}
                data-testid={"buyer-box"}
                onClick={this.handleSelectBuyerAccount}>
                <Box className={classes.boxStyle}>
                  <Box className={classes.groupIconBox} >
                    <img src={groupBuyer} className={classes.groupStyle} />
                    <Typography className={classes.profileText}>{configJSON.buyer}</Typography>
                  </Box>

                  <Box className={classes.checkBoxStyle} >
                    {this.state.buyerAccount ? <img src={checkedBox} /> : <img src={uncheckedBox} />}
                  </Box>
                </Box>

                <Box style={{ margin: '20px' }}>
                  <Typography className={classes.descriptionText}>
                    {configJSON.buyerTxt}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} className={classes.gridStyle}
                style={{
                  border: this.state.vendorAccount ? '2px solid #DE76FF' : '2px solid #DDDDDD',
                }}
                data-testid={"vendor-box"}
                onClick={this.handleSelectVendorAccount}>
                <Box className={classes.boxStyle}>
                  <Box className={classes.groupIconBox}>
                    <img src={groupVender} className={classes.groupStyle} />
                    <Typography className={classes.profileText}>{configJSON.vendor}</Typography>
                  </Box>

                  <Box className={classes.checkBoxStyle} >
                    {this.state.vendorAccount ? <img src={checkedBox} /> : <img src={uncheckedBox} />}
                  </Box>
                </Box>

                <Box style={{ margin: '20px' }}>
                  <Typography className={classes.descriptionText} >
                    {configJSON.vendorText}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} className={classes.btngridStyle}>
                <Button fullWidth variant="contained"
                  className={classes.buttonBackground}
                  data-testid={"continue-button"}
                  onClick={this.SignUpFormApiIntegration}
                >
                  {configJSON.continueBtn}</Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const StyledTextField = withStyles({
  root: {
    fontSize: "14",
    "& input::placeholder": {
      fontSize: "14",
      fontWeight: 400,
      fontFamily: "Poppins"
    },
    "& .MuiInputBase-input-283:focus": {
      backgroundColor: "transparent",
      outline: 'none'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF"
    },
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#FFFFFF",
      margin: 0,
      paddingLeft: 10
    },
  },
})((props: any) => <TextField {...props} />);

export const webStyles = () => ({
  dialogStyles: {
    overflowX: 'hidden' as const,
    width: '530px',
    backgroundColor: ' #FFFFFF',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4vw',
    marginBottom: '4vw'
  },

  titleGrid: {
    marginTop: '10%',
    marginBottom: '2%'
  },

  editNameStyle: {
    fontFamily: "Poppins",
    "&::placeholder": {
      fontFamily: "Poppins",
    },
  },

  termsdialogStyles: {
    overflow: 'hidden',
    width: '650px',
    backgroundColor: ' #FFFFFF',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4vw',
    marginBottom: '4vw'
  },
  tabGridStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resLogoGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  restaurtimageStyle: {
    height: '56px',
    width: '243px',
  },

  buttonBoxStyle: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },

  welcomeFont: {
    color: "#000000",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins"
  },

  textFeidStyle: {
    width: "100%",
    borderRadius: '1px',
    backgroundColor: '#FAF5FF',
  },

  credFontStyle: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },

  errorBoxStyle: {
    display: 'flex',
    marginTop: '2px',
  },

  Scrollbar: {
    height: '100%',
    padding: '10px',
    overflowY: 'scroll' as const,
    overflowX: 'hidden' as const,
    scrollbarColor: "#D6D6D6 transparent",
    "&::-webkit-scrollbar": {
      width: 4,
      height: 6,
      backgroundColor: "#D6D6D6",
      padding: '30px',
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#B300ED",
      borderRadius: 6,
      minWidth: 14,
      minHeight: 24,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D6D6D6",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "#B300ED",
    },
  },

  helperText: {
    color: "#D53333",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },

  errorIconStyle: {
    fontSize: '18px',
    margin: '3px 10px 0px -10px',
  },

  sucessIconStyle: {
    fontSize: '18px',
    margin: '3px 10px 0px -10px',
    color: '#1AA663'
  },

  sucesshelperText: {
    color: "#1AA663",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },

  termsConditions: {
    color: "#3D2E2E",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: "center" as const
  },

  spanStyle: {
    color: "#1D1AAE",
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins"
  },

  spanStyle2: {
    color: "#B300ED",
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "Poppins"
  },

  continueButtonStyle: {
    backgroundColor: "#B300ED",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#FFFFFF",
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  loginGridStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  spanTextColor: {
    color: "#4F4F4F",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: "center" as const
  },

  boxScroll: {
    maxHeight: '350px' as const,
    overflowY: 'scroll' as const,
    margin: '20px',
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D6D6D6",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb": {
      minWidth: 14,
      background: "#B300ED",
      minHeight: 24,
      borderRadius: 6,
    },
    "&::-webkit-scrollbar": {
      padding: '30px',
      height: 6,
      width: 4,
      backgroundColor: "#D6D6D6",
    },

  },

  containerScrollbar: {
    height: '100%',
    overflowY: 'scroll' as const,
    overflowX: 'hidden' as const,
    padding: '10px',
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D6D6D6",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb": {
      minWidth: 14,
      background: "#B300ED",
      minHeight: 24,
      borderRadius: 6,
    },
    "&::-webkit-scrollbar": {
      padding: '30px',
      height: 6,
      width: 4,
      backgroundColor: "#D6D6D6",
    },

  },

  acceptBtn: {
    backgroundColor: "#B300ED",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#FFFFFF",
    width: '40%',
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  declineBtn: {
    backgroundColor: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#B300ED",
    width: '40%',
    borderColor: '#B300ED',
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#FFFFFF",
    }
  },
  tabTextStyle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: '22px',
    color: "#454545",
    textTransform: 'initial' as const,
  },

  tabContentText: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: '16px',
    color: "#000000",
    textTransform: 'initial' as const,
    lineHeight: '24px',
    whiteSpace: 'pre-line' as const,
  },

  dialogWrapper: {
    overflow: 'hidden',
    width: '520px',
    backgroundColor: ' #FFFFFF',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageStyle: {
    height: '56px',
    width: '243px',
    marginTop: '20px',
  },

  chooseAccountGridStyle: {
    margin: "15px 0px 0px 0px"
  },

  textColor: {
    color: "#454545",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins"
  },
  emailErrorShowText: {
    color: "#D53333",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },

  textStyle: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },

  boxStyle: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  gridStyle: {
    backgroundColor: '#FAF5FF',
    height: '140px',
    width: '400px',
    borderRadius: '5px',
    margin: '20px'
  },

  btngridStyle: {
    margin: '10px'
  },

  groupIconBox: {
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
  },

  checkBoxStyle: {
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '-7px',
    marginTop: '10px'
  },

  groupStyle: {
    marginRight: '10px',
    width: '31px',
    height: '28px',
    marginLeft: '10px',
    cursor: 'pointer'
  },

  buttonBackground: {
    backgroundColor: "#B300ED",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#FFFFFF",
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  profileText: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#000000",
  },

  descriptionText: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#000000",
  },

  LogoGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIconGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px 10px 0px 0px'
  },
  closeIconStyle2: {
    height: '25px',
    width: '25px',
    cursor: 'pointer'
  },

})

export default withStyles(webStyles)(EmailAccountRegistration)
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface MainPayment {
  paymentable_type: string
  paymentable_id: number
  account_id: number
  token: string
  order_date: string
  payment_method_details: PaymentMethodDetails
  order: number
  order_total: number
  next_payment: string
  subscription: string
  plan: string
  status: string
  item_name: string
  item_description: string
  logo: any
}

export interface PaymentMethodDetails {
  billing_details: any
  card: Card
  created: number
}



export interface Card {
  brand: string
  checks: Checks
  country: string
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  last4: string
  three_d_secure_usage: ThreeDSecureUsage
}

export interface Checks {
  address_line1_check: any
  address_postal_code_check: any
  cvc_check: string
}

export interface ThreeDSecureUsage {
  supported: boolean
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  isViewPayment: boolean;
  currentData: MainPayment;
  isViewDetail: boolean;
  isViewMain: boolean;
  paymentData: any;
  counts: number | any,
  rowsPerPage: number | any,
  page: number | any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getPaymentId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      isViewMain: true,
      isViewDetail: false,
      isViewPayment: false,
      paymentData: null,
      currentData: {} as MainPayment,
      counts: 0,
      rowsPerPage: 5,
      page: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data
          });
        }
        if (apiRequestCallId === this.getPaymentId) {
          console.log("responseJson",responseJson)
          this.paymentHandleResponse(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getPaymentHistoryList()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.page !== prevState.page) {
      this.getPaymentHistoryList();
    }
  }
  getPaymentHistoryList = () => {
    const header = {
      token: localStorage.getItem("loginToken"),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentHistory}?page=${Number(this.state.page) + 1}&per_page=${this.state.rowsPerPage}`
    );
    console.log("===========>>",`${configJSON.paymentHistory}?page=${Number(this.state.page) + 1}&per_page=${this.state.rowsPerPage}`)
    console.log("===========>>",header)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlePayment = (currentData: any) => {
    console.log("this.state.currentData0",JSON.stringify(currentData))

    let values = currentData
    this.setState({ currentData: values })
    this.setState({ isViewMain: false, isViewDetail: true })
  }
  handleViewDetailPayment = () => {
    this.setState({ isViewDetail: true, isViewPayment: false })
  }
  handleViewDetailMain = () => {
    this.setState({ isViewDetail: false, isViewPayment: true })
  }
  goToMockPath = () => {
    this.setState({ isViewMain: true, isViewDetail: false, isViewPayment: false })
  }
  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ page: 0 });
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  paymentHandleResponse = (responseJson:any) =>{
  this.setState({ paymentData: responseJson.payment_history.data })
  this.setState({ counts: responseJson.pagination.total_count })
}
  // Customizable Area End
}

import React, { ChangeEvent } from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    InputBase,
    Button,
    Divider,
    Dialog,
    Grid,
    TextField
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import SearchListingController, {
    Props,
    configJSON,
    mockPath,
} from "./SearchListingController";
import { logoImage, searchIcon, closeIcon, downArrow, dummyIcon, sucessIcon, deleteImage, rightTickIcon } from "./assets";
import AppBarPage from "../../../components/src/AppBar.web";
// Customizable Area End

export class SearchListing extends SearchListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const errorIcon = (this.state.isError && this.state.changeDomain !== "") ? <img src={deleteImage} className={classes.icon_style} /> : null;
        const successIcon = this.state.successMessage ? <img src={sucessIcon} className={classes.icon_style} /> : null;
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box className={classes.root}>
                    <AppBarPage
                        handleClick={this.goToLoginPage}
                        logoImg={logoImage}
                        serachIcon={searchIcon}
                        username={this.state.Username}
                        dummyicon={dummyIcon}
                        downarrow={downArrow}
                        goToHomePage={this.gotoHomePage}
                        handleHeaderNavigation={this.handleHeadernavigation}
                        handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                        handleUserSignout={this.handleUserSignout}
                        data-testId={"signInBtn"}
                        handleSubmit={this.handleSubmitSearch}
                        showDirectoryPopup={this.state.showDirectoryPopup}
                        searchDirectoryData={this.state.searchDirectoryData}
                        handleSearchInput={this.handleSearchInput}
                        handleDirectoryPopupClose={this.handleDirectoryPopupClose}   
                    />

                    <Box className={classes.pathBlock}>
                        {mockPath.map((path, index) => {
                            const isPathLast = index === mockPath.length - 1
                            return (
                                <>
                                    <Typography
                                        data-testid={"mockpath"}
                                        onClick={() => this.goToMockPath(path.path)}
                                        className={classes.paths}
                                        style={{ color: isPathLast ? "#0F172A" : "#475569" }}
                                        key={path.name}>{path.name}</Typography>
                                    {!isPathLast && <Typography className={classes.paths} style={{ margin: "0 10px" }}>{">"}</Typography>}
                                </>
                            )
                        })}
                    </Box>

                    {this.state.isNavigate === false ?
                        <Box className={classes.contentBoxStyle}>
                            <Box className={classes.welcomeTextBox}>
                                <Box className={classes.textBox}>
                                    <Typography className={classes.fontsStyle1}>{configJSON.welcomeText}</Typography>
                                    <Typography className={classes.fontsStyle2}>{configJSON.welcomeDescription}</Typography>
                                </Box>

                                <Box className={classes.search}>
                                    <Box className={classes.searchIcon}>
                                        <img src={searchIcon} className={classes.groupImageStyle} />
                                    </Box>
                                    <InputBase
                                        placeholder={configJSON.companyName}
                                        inputProps={{ 'aria-label': 'search' }}
                                        value={this.state.searchInput}
                                        onChange={this.handleChangeSearch}
                                        data-testid={"change_companyname"}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput2,
                                        }}
                                    />
                                </Box>
                            </Box>

                            {
                                this.state.listingData?.map((item, index) => (
                                    <Box className={classes.boxMargin} key={item?.id}>
                                        {console.log(item, 'itemclaimed==>')}
                                        <Box className={classes.buttonBoxStyle}>
                                            <Button
                                                disabled={item.attributes.claim_status !== "Unclaimed"}
                                                variant="contained"
                                                className={classes.buttonStyle}
                                                onClick={() => this.goToClaimListingPage(item?.id)}
                                                data-testid={"claimListing_1"}
                                            >
                                                {configJSON.claimListing}
                                            </Button>
                                        </Box>
                                        <Box className={classes.boxStyle1}>
                                            <Box>
                                                <img src={item.attributes.logo} className={classes.groupIcon} />
                                            </Box>

                                            <Box className={classes.typographyBox}>
                                                <Typography className={classes.textStyle} data-tesid={"listing_data"}>
                                                    {item.attributes.name}
                                                </Typography>
                                                <Typography className={classes.textStyle4}>
                                                    {item.attributes.short_description}
                                                </Typography>
                                                <Typography className={classes.textStyle3}>
                                                    {item.attributes.domain_url}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {
                                            index < this.state.listingData.length - 1 && (
                                                <Divider style={{ backgroundColor: '#D9D9D9' }} />
                                            )
                                        }
                                    </Box>
                                ))
                            }

                            <Box className={classes.boxStyle4}>
                                <Typography className={classes.textStyle5}>{configJSON.cantFind}</Typography>
                                <Button
                                    variant="contained"
                                    className={classes.buttonStyle}
                                    onClick={this.handleAddListing}
                                    data-testid={"addListing"}
                                >
                                    {configJSON.addListing}
                                </Button>
                            </Box>

                        </Box>

                        :

                        // Domain search box
                        <Box className={classes.contentBoxStyle}>
                            <Box className={classes.welcomeTextBox}>
                                <Box className={classes.textBox}>
                                    <Typography className={classes.fontsStyle1}>{configJSON.welcomeText}</Typography>
                                    <Typography className={classes.fontsStyle2}>{configJSON.welcomeDescription}</Typography>
                                </Box>

                                <Box className={classes.domain_Serach}>
                                    <Box className={classes.search}>
                                        <Box className={classes.searchIcon}>
                                            <img src={searchIcon} className={classes.groupImageStyle} />
                                        </Box>
                                        <InputBase
                                            placeholder={configJSON.companyName}
                                            inputProps={{ 'aria-label': 'search' }}
                                            value={this.state.searchInput}
                                            onChange={this.handleChangeSearch}
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput2,
                                            }}
                                        />
                                    </Box>

                                    <Box className={classes.search}>
                                        <Box className={classes.searchIcon}>
                                            <img src={searchIcon} className={classes.groupImageStyle} />
                                        </Box>
                                        <InputBase
                                            placeholder={configJSON.domain}
                                            inputProps={{ 'aria-label': 'search' }}
                                            value={this.state.changeDomain}
                                            data-testid={"company_name"}
                                            onChange={(event: any) => this.handleChangeDomain(event, true)}
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput2,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            {
                                this.state.listingData.map((item, index) => (
                                    <Box className={classes.boxMargin} key={item.id}>
                                        <Box className={classes.buttonBoxStyle}>
                                            <Button
                                                variant="contained"
                                                onClick={() => this.goToClaimListingPage(item.id)}
                                                data-testid={"claimListing"}
                                                className={classes.buttonStyle}
                                            >
                                                {configJSON.claimListing}
                                            </Button>
                                        </Box>
                                        <Box className={classes.boxStyle1}>
                                            <Box>
                                                <img
                                                    className={classes.groupIcon}
                                                    src={item.attributes.logo}
                                                />
                                            </Box>

                                            <Box className={classes.typographyBox}>
                                                <Typography className={classes.textStyle}>
                                                    {item?.attributes?.name}
                                                </Typography>
                                                <Typography className={classes.textStyle4}>
                                                    {item?.attributes?.short_description}
                                                </Typography>
                                                <Typography className={classes.textStyle3}>
                                                    {item?.attributes?.domain_url}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {
                                            index < this.state.listingData.length - 1 && (
                                                <Divider style={{ backgroundColor: '#D9D9D9' }} />
                                            )
                                        }
                                    </Box>
                                ))
                            }

                            <Box className={classes.boxStyle4}>
                                <Typography className={classes.textStyle5}>{configJSON.cantFind}</Typography>
                                <Button
                                    variant="contained"
                                    onClick={this.handleAddListing}
                                    className={classes.buttonStyle}
                                    data-testid={"addListing"}
                                >
                                    {configJSON.addListing}
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box >

                <Dialog
                    open={this.state.isDescriptionModal}
                    data-testid={"openDescriptionModal"}
                    maxWidth="md"
                    onClose={this.handleCloseModal}
                    PaperProps={{ className: classes.dialogStyle}}
                >

                    <Box className={classes.BoxScrollbar}>
                        <Grid container>
                            <Grid item xs={12} className={classes.cancelGridStyle}>
                                <img
                                    src={closeIcon}
                                    className={classes.closeIconStyle}
                                    onClick={this.handleCloseModal}
                                    data-testId={"closeModal"}
                                />
                            </Grid>
                        </Grid>

                        <Box className={classes.detailsBoxStyle}>
                            <Typography className={classes.headingStyle}>{configJSON.heading1}</Typography>
                            <Typography className={classes.headingStyle2}>{configJSON.tankuText}</Typography>

                            <Box className={classes.uploadBoxStyle} data-testid={"upload_logo"} onClick={this.handleUploadLogoClick}>
                                <input hidden
                                    ref={this.inputRef}
                                    data-testid={"input_ref"}
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={this.handleLogoChange}
                                />
                                {this.state.logoPreview && (
                                    <img
                                        data-testid={"logo_preview"}
                                        src={URL.createObjectURL(this.state.logoPreview)}
                                        alt="Logo Preview"
                                        className={classes.uploadIconStyle}
                                        id="logo"
                                    />
                                )}

                                <Typography className={classes.uploadTextStyle}>{configJSON.uploadImage}</Typography>
                            </Box>

                            {this.state.logoErrorMessage && <Typography className={classes.errorFontStyle1}>{this.state.logoErrorMessage}</Typography>}

                            <Box className={classes.textFeildBox}>
                                <StyledTextField
                                    variant="outlined"
                                    placeholder={configJSON.name}
                                    value={this.state.changeName}
                                    onChange={this.handleChangeName}
                                    data-testid={"changename"}
                                    error={this.state.isNameError}
                                    helperText={
                                        <Typography className={classes.errorFontStyle}>{this.state.nameErrorMessage}</Typography>
                                    }
                                    fullWidth
                                    className={classes.nameTextfeild}
                                />

                                <StyledTextField
                                    variant="outlined"
                                    placeholder={configJSON.domain}
                                    value={this.state.changeDomain}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleChangeDomain(event, false)}
                                    data-testid={"change_domain"}
                                    error={this.state.isError}
                                    fullWidth
                                    helperText={
                                        <>
                                            {this.state.isError ? <Typography className={classes.errorFontStyle}>{this.state.errorMessage}</Typography>
                                                :
                                                <Typography className={classes.sucessFontStyle}>{this.state.successMessage}</Typography>
                                            }
                                        </>
                                    }
                                    InputProps={{
                                        style: { border: this.state.isError ? '1px solid red' : this.state.successMessage && '1px solid #059669' },
                                        endAdornment: (
                                            <>
                                                {errorIcon}
                                                {successIcon}
                                            </>
                                        )
                                    }}
                                    className={classes.nameTextfeild}
                                />
                            </Box>

                            <Box className={classes.textFeildBox}>
                                <StyledTextField
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    placeholder={configJSON.shortDescription}
                                    data-testid={"change_description"}
                                    value={this.state.changeDescription}
                                    onChange={this.handleChangeDescription}
                                    fullWidth
                                    className={classes.descriptionTextfeild}
                                />
                            </Box>

                            <Box className={classes.ButtonBox}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    data-testid={"continueBtn"}
                                    className={classes.continueButton}
                                    onClick={this.handleCreateListing}
                                >
                                    {this.state.isError ? configJSON.backtosearch : configJSON.continue}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Dialog>

                <Dialog open={this.state.isModalOpen}
                        maxWidth="md"
                        PaperProps={{ className: classes.dialogStyles }}
                        onClose={()=>this.setState({isModalOpen:false})}
                        data-testId={"openModal"}
                        
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.cancelGridStyle}>
                                <img
                                    data-testid={"closeModal"}
                                    src={closeIcon}
                                    onClick={()=>this.setState({isModalOpen:false})}
                                    className={classes.closeIconStyle}
                                />
                            </Grid>
                        </Grid>

                        <Box className={classes.dialogBoxStyle}>
                            <img
                                src={rightTickIcon}
                                className={classes.rightTickIcon}
                            />
                            <Typography className={classes.welcomeFontStyle}>{configJSON.congratulation}</Typography>
                            <Typography className={classes.welcomeFontStyle2}>{configJSON.addListingScuccess}</Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                data-testid={"gotoaccount"}
                                onClick={this.gotoVenderaccount}
                            >
                                {configJSON.backtoaccounts}
                            </Button>
                        </Box>
                    </Dialog>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const StyledTextField = withStyles({
    root: {
        fontSize: "14",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontSize: "14",
            borderRadius: '8px',
            fontFamily: "Poppins",
        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontSize: "14",
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "& .MuiInputBase-input-283:focus": {
            backgroundColor: "transparent",
            outline: 'none',
        },
        backgroundColor: "#FFFFFF",
        "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: "#FFFFFF",
            margin: 0,
            paddingLeft: 10
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#CBD5E1',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1"
        },

        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#00FF00',
        },
    },
})((props: any) => <TextField {...props} />);

export const userStyles = () => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f8f2ff',
    },

    groupImageStyle: {
        height: '24px',
        width: '24px'
    },

    icon_style: {
        width: '20px',
        height: '20px'
    },

    domain_Serach: {
        display: 'flex',
        gap: '20px',
        width: '70%'
    },

    search: {
        position: 'relative' as const,
        marginLeft: 0,
        borderRadius: '10px',
        width: '650px',
        display: 'flex',
        alignItems: 'center' as const,
        backgroundColor: '#FFFFFF',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '300px'
        }
    },

    searchIcon: {
        height: '100%',
        padding: '10px',
        color: 'black',
        display: 'flex',
        position: 'absolute' as const,
        alignItems: 'center' as const,
        justifyContent: 'center' as const,
    },

    sucessFontStyle: {
        color: '#059669',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: "Poppins",
        paddingTop: '10px'
    },

    errorFontStyle: {
        color: '#DC2626',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: "Poppins",
        paddingTop: '10px',
        backgroundColor: 'white'
    },

    errorFontStyle1: {
        color: '#DC2626',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: "Poppins",
        backgroundColor: 'white'
    },

    inputRoot: {
        width: '100%',
    },

    inputInput2: {
        width: '100%',
        padding: '12px 20px',
        paddingLeft: '40px',
        border: 'none',
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: "Poppins",
        height: '30px' as const,
        '&::placeholder': {
            color: '#0F172A',
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: "Poppins"
        },
    },

    pathBlock: {
        display: "flex",
        margin: "9% 0 0 6vw",
    },

    paths: {
        fontSize: '14px',
        fontWeight: 400,
        color: "#475569",
        cursor: "pointer",
        fontFamily: "Poppins"
    },

    contentBoxStyle: {
        margin: "35px 6vw",
        backgroundColor: '#FFFFFF',
        paddingBottom: '40px'
    },

    welcomeTextBox: {
        display: 'flex',
        gap: 20,
        justifyContent: 'center',
        flexDirection: 'column' as const,
        alignItems: 'center',
        backgroundColor: '#B300ED',
        borderRadius: '12px',
        padding: "4% 35px 35px",
    },

    textBox: {
        width: '619px',
        display: 'flex',
        gap: '25px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '350px',
        }
    },

    fontsStyle1: {
        fontSize: '26px',
        fontWeight: 600,
        color: "#FFFFFF",
        fontFamily: "Poppins",
        textAlign: 'center' as const,

        "@media (min-width: 300px) and (max-width: 950px)": {
            fontSize: '16px',
            fontWeight: 400,
        }
    },

    fontsStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: "#FFFFFF",
        fontFamily: "Poppins",
        lineHeight: '20px',
        textAlign: 'center' as const,

        "@media (min-width: 300px) and (max-width: 950px)": {
            fontSize: '12px',
            fontWeight: 300,
        }
    },

    boxMargin: {
        margin: '2% 5% 2% 5%',
        position: 'relative' as const,
    },

    boxStyle1: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',

        "@media (min-width: 300px) and (max-width: 950px)": {
            padding: '2px',
        }
    },

    groupIcon: {
        width: '122px',
        height: '120px',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '72px',
            height: '70px',
        }
    },

    typographyBox: {
        flex: 1,
        marginLeft: '5%',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: 5,

        "@media (min-width: 300px) and (max-width: 950px)": {
            marginLeft: '2%',
            display: 'flex',
        }
    },

    textStyle: {
        alignSelf: 'flex-start',
        fontSize: '22px',
        fontWeight: 500,
        color: "#005ACF",
        fontFamily: "Poppins",

        "@media (min-width: 300px) and (max-width: 950px)": {
            fontSize: '16px',
            fontWeight: 400,
        }
    },

    textStyle2: {
        alignSelf: 'flex-start',
        fontSize: '14px',
        fontWeight: 500,
        color: "#005ACF",
        fontFamily: "Poppins",
    },

    textStyle3: {
        alignSelf: 'flex-start',
        fontSize: '14px',
        fontWeight: 400,
        color: "#005ACF",
        fontFamily: "Poppins",
    },

    textStyle4: {
        alignSelf: 'flex-start',
        fontSize: '14px',
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins",
        lineHeight: '33px',
        textAlign: 'justify' as const
    },

    buttonBoxStyle: {
        position: 'absolute' as const,
        right: '10px',
    },

    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },

        "@media (min-width: 300px) and (max-width: 950px)": {
            padding: '5px 20px',
            fontSize: '10px' as const,
        }
    },

    boxStyle4: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        marginTop: '40px',
    },

    textStyle5: {
        fontSize: '18px',
        fontWeight: 500,
        color: "#000000",
        fontFamily: "Poppins",
    },

    dialogStyle: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '624px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
        marginTop: '5%',
        padding: '10px'
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '3px'
    },

    detailsBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24,
        marginBottom: '40px'
    },

    headingStyle: {
        fontSize: '28px',
        fontWeight: 700,
        color: "#0F172A",
        fontFamily: "Poppins",
        lineHeight: '32px'
    },

    headingStyle2: {
        fontSize: '16px',
        fontWeight: 500,
        color: "#707070",
        fontFamily: "Poppins",
        lineHeight: '32px',
        textAlign: 'center' as const,
        padding: '0px 30px 0px 30px'
    },

    uploadBoxStyle: {
        height: '75px',
        width: '116px',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center' as const,
        alignItems: 'center' as const,
        gap: '10px',
        border: '2px dotted #ED00CE' as const,
    },

    uploadTextStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins",
        textAlign: 'center' as const
    },

    uploadIconStyle: {
        height: '24px',
        width: '24px',
        cursor: 'pointer'
    },

    nameTextfeild: {
        width: "100%",
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
    },

    descriptionTextfeild: {
        width: "100%",
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
    },

    textFeildBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center' as const,
        alignItems: 'center' as const,
        gap: '15px',
        width: '75%'
    },

    ButtonBox: {
        display: 'flex',
        justifyContent: 'center' as const,
        alignItems: 'center' as const,
        gap: '15px',
        width: '65%'
    },

    continueButton: {
        fontSize: "16px",
        fontWeight: 400,
        backgroundColor: "#B300ED",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        height: '56px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    BoxScrollbar: {
        height: '100%',
        overflowX: 'hidden' as const,
        overflowY: 'scroll' as const,
        scrollbarColor: "#D6D6D6 transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            padding: '30px',
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            borderRadius: 6,
            minHeight: 24,
            minWidth: 14,
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
    },

    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    dialogBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px'
    },

    welcomeFontStyle2: {
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter, sans-serif',
        lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    rightTickIcon: {
        height: '75px',
        width: '75px'
    },

});

export default withStyles(userStyles)(SearchListing)
// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area Start
export interface MyComponentState {
    checkboxes: { id: number; support_type: string; active: boolean }[];
}

export interface MyComponentState2 {
    checkboxes: { id: number; training_type: string; active: boolean }[];
}

export interface ServiceData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    service: {
                        supporturl: string;
                        trainingurl: string;
                        language: string;
                        support: string[];
                        training: string[]
                    };
                    service_screenshots: [],
                    progress_percentage: number
                };
            };
        };
    };

}
export interface UserRole {
    id: number,
    name: string,
    active:boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    handleDataSaved:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    supportCheckbox: MyComponentState;
    trainingChecbox: MyComponentState2;
    serviceData: {
        supporturl: string;
        trainingurl: string;
        language: string;
        support: string[];
        training: string[]
    };
    updatedServiceData: {
        supporturl: string;
        trainingurl: string;
        language: string|any;
        support: string[];
        training: string[]
    };
    isSucess: boolean;
    serviceScreenShotArray: any[],
    isFeedback: boolean;
    editProfilePic: any;
    imagePreview: any;
    error: boolean;
    feedbackEmail: string;
    feedbackMessage: string;
    isValidEmail: { valid: boolean, message: string };
    isLoadData: boolean;
    userRole: UserRole[];
    progressPercentage: number,
    selectedLanguage:any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ServicesPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    getServicesListAPICalledId: string = "";
    updateServiceListingDataAPICallId: string = "";
    getGoodCompanyAPICalledId: string = "";
    feedbackServiceId: string = "";
    emailReg: RegExp;
    deleteScreenShotsAPICallId: string = "";


    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            supportCheckbox: {
                checkboxes: [
                    { id: 1, support_type: 'Email Help Desk', active: false },
                    { id: 2, support_type: 'Phone Support', active: false },
                    { id: 3, support_type: '24/7 Live Rep', active: false },
                    { id: 4, support_type: 'Chat', active: false },
                    { id: 5, support_type: 'Knowledgebase', active: false },
                    { id: 6, support_type: 'FAQ Forum', active: false }
                ],
            },
            trainingChecbox: {
                checkboxes: [
                    { id: 1, training_type: 'Documentation', active: false },
                    { id: 2, training_type: 'Videos', active: false },
                    { id: 3, training_type: 'Live Online', active: false },
                    { id: 4, training_type: 'In-Person', active: false },
                    { id: 5, training_type: 'Webinars', active: false },
                ],
            },
            serviceData: {
                supporturl: "",
                trainingurl: "",
                language: "",
                support: [],
                training: []
            },
            updatedServiceData: {
                supporturl: "",
                trainingurl: "",
                language: "",
                support: [],
                training: []
            },
            isSucess: false,
            serviceScreenShotArray: [],
            isFeedback: false,
            editProfilePic: "",
            imagePreview: "",
            error: false,
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            isLoadData: false,
            userRole: [],
            progressPercentage: 0,
            selectedLanguage:[]
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getServicesList();
        this.getGoodCompany();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getServicesListAPICalledId:
                        this.handleServiceLitsResponse(responseJson);
                        break;
                    case this.updateServiceListingDataAPICallId:
                        this.handleUpdateServiceListResponse(responseJson);
                        break;
                    case this.feedbackServiceId:
                        this.setState({ isFeedback: false })
                        break;
                    case this.deleteScreenShotsAPICallId:
                        this.setState({ isLoadData: false });
                        break;
                    case this.getGoodCompanyAPICalledId:
                        this.handleGoodCompanyRes(responseJson);
                        break;
                    default:
                        break;
                }
            }
        }
    }
    // Web Events
    handleSupportCheckboxChange = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const updatedCheckboxes = this.state.supportCheckbox.checkboxes.map((checkbox) =>
            checkbox.id === id ? { ...checkbox, active: event.target.checked } : checkbox
        );
        this.setState({
            supportCheckbox: { checkboxes: updatedCheckboxes }
        });
        this.props.handleDataChanged()
    };
    handleGoodCompanyRes = (response: any) => {
        this.setState({ supportCheckbox: { checkboxes: response.supports } })
        this.setState({ trainingChecbox: { checkboxes: response.trainings } })
        const filterSelcted=response.languages.filter((item:any)=>item.active)
        this.setState({userRole: response.languages,selectedLanguage:filterSelcted })

    }
    handleTrainingCheckboxChange = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const updatedCheckboxes = this.state.trainingChecbox.checkboxes.map((checkbox) =>
            checkbox.id === id ? { ...checkbox, active: event.target.checked } : checkbox
        );
        this.setState({
            trainingChecbox: { checkboxes: updatedCheckboxes }
        });
        this.props.handleDataChanged()
    };

    handleUpdatedServiceData = () => {
        this.setState({ updatedServiceData: { ...this.state.serviceData } });
    };

    handleChangeTrainingUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedServiceData: { ...this.state.updatedServiceData, trainingurl: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangeSuopportUrl = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedServiceData: { ...this.state.updatedServiceData, supporturl: event.target.value }
        })
    this.props.handleDataChanged()
    }

    handleChangeLanguage = (event: any) => {
        this.setState({
            updatedServiceData: { ...this.state.updatedServiceData, language: event.target.value }
        })
    }
    
    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
          this.props.handleBack();
        }
    }


    // API
    getServicesList = async () => {
        this.setState({ isLoadData: true })
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getServicesListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getGoodCompany = async () => {
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getGoodCompanyAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.supporTrainingEndpoint}?catalogue_id=${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateServiceListingData = async () => {
        this.props.handleDataSaved()
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata:any = new FormData();
        formdata.append("[service][supporturl]", this.state.updatedServiceData?.supporturl);
        formdata.append("[service][trainingurl]", this.state.updatedServiceData?.trainingurl)
        if(this.state.selectedLanguage?.length){
            this.state.selectedLanguage?.forEach((item:any) => {
                formdata.append("language_ids[]", item.id)
        });
        }
        else{
            formdata.append("language_ids[]", "")
        }
        
        this.state.supportCheckbox.checkboxes.forEach((checkbox) => {
            if (checkbox.active) {
                formdata.append("support_ids[]", checkbox.id);
            }
        });

        this.state.trainingChecbox.checkboxes.forEach((checkbox) => {
            if (checkbox.active) {
                formdata.append("training_ids[]", checkbox.id);
            }
        });

        this.state.serviceScreenShotArray.forEach((item) => {
            !item?.id && formdata.append("service_screenshots[]", item, item.name);
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateServiceListingDataAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSubmitFeedbackService = async (event: any) => {
        let formdata = new FormData();
        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailService(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }
        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackServiceId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDeleteScreenShots = async (attachment_id: number) => {
        this.setState({ isLoadData: true })
        const categoryid = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const requestMessageDeleteApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteScreenShotsAPICallId = requestMessageDeleteApi.messageId;

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.attachmentDeleteEndpoint + `?id=${categoryid}&attachment_id=${attachment_id}&attachment_name=service_screenshots`
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );
        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
    };

    // API Response
    handleServiceLitsResponse = (responseJson: ServiceData) => {
        const apiresponse = responseJson?.data?.catalogue?.data?.attributes?.service
        const screenshotResponse = responseJson?.data?.catalogue?.data?.attributes?.service_screenshots
        let supportData = apiresponse?.support
        let trainingData = apiresponse?.training

        const supportCheckboxData = this.state.supportCheckbox.checkboxes.map((checkbox) => {
            if (supportData && supportData.includes(checkbox.support_type)) {
                return { ...checkbox, isChecked: true }
            } else {
                return checkbox
            }
        })

        const trainingCheckboxData = this.state.trainingChecbox.checkboxes.map((checkbox) => {
            if (trainingData && trainingData.includes(checkbox.training_type)) {
                return { ...checkbox, isChecked: true }
            } else {
                return checkbox
            }
        })
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
        this.setState({
            supportCheckbox: { checkboxes: supportCheckboxData },
            trainingChecbox: { checkboxes: trainingCheckboxData }
        });

        if (screenshotResponse) {
            this.setState({ serviceScreenShotArray: screenshotResponse })
        }

        if (apiresponse) {
            this.setState({
                isLoadData: false,
                serviceData: {
                    ...this.state.serviceData,
                    supporturl: apiresponse?.supporturl,
                    trainingurl: apiresponse?.trainingurl,
                    language: apiresponse?.language,
                    support: apiresponse?.support,
                    training: apiresponse?.training,
                }
            },
                () => this.handleUpdatedServiceData()
            )
        }
    }

    handleUpdateServiceListResponse = (responseJson: []) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucess: true })
            this.getServicesList()
        }
    }


    onScreenshotUpload = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const reader: any = new FileReader();

        const files = e.target.files;

        if (!files || files.length === 0) {
            return;
        }

        const file = files[0];

        reader.onloadend = () => {
            this.setState({
                editProfilePic: file,
                imagePreview: reader.result
            });

            if (file.size > +3e+7) {
                this.setState({ error: true })
                return
            }

            this.setState({ serviceScreenShotArray: [...this.state.serviceScreenShotArray, file] })
        }
        reader.readAsDataURL(file);

        this.props.handleDataChanged()
    };

    formatFileSizeServiceFile = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
    }

    removeScreensHot = (screenshotId: { id: number,name:string }) => {
        const updateData = this.state.serviceScreenShotArray.filter((item) => item.name !== screenshotId.name)
        this.setState({ serviceScreenShotArray: updateData })
        if (screenshotId.id) {
            this.handleDeleteScreenShots(screenshotId.id)
        }
    }

    handleCloseSuccessDialog = () => {
        this.setState({ isSucess: false });
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleCloseErrorDialog = () => {
        this.setState({ error: false });
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailService = (email: string) => {
        const emailRegex = this.emailReg;

        return emailRegex.test(email);
    }

    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ selectedLanguage: newValue })
        this.props.handleDataChanged()
    }

    // Customizable Area End
}

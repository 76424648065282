import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    categoryId?: any;
    handleDataChanged:()=>void;
    handleDataSaved:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    imagePreview: string;
    value: number;
    progressVal: number;
    isOpenSucessModal: boolean;
    shortDescription: string;
    longDescription: string;
    domain: string;
    updatedSuccessDialog: boolean;
    isFeedback: boolean,
    feedbackEmail: string,
    feedbackMessage: string,
    allcountriesdata: any,
    selectedCountries: any,
    isLoading: boolean,
    isValidEmail: { valid: boolean, message: string },
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierAboutPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formSubmitid: string = "";
    getCustomFormDetailAboutid: string = "";
    feedbackSupAboutId: string = "";
    getCountriesId: string = "";
    emailReg: RegExp;



    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            imagePreview: "",
            value: 0,
            progressVal: 0,
            isOpenSucessModal: false,
            shortDescription: "",
            longDescription: "",
            domain: "",
            updatedSuccessDialog: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",
            allcountriesdata: [],
            selectedCountries: [],
            isLoading: false,
            isValidEmail: { valid: false, message: "" },
            progressPercentage: 0

            // Customizable Area End
        };

        // Customizable Area Start
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestAboutId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseAbout = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestAboutId === this.formSubmitid) {
                this.setState({ updatedSuccessDialog: true })
            }

            if (apiRequestAboutId === this.getCustomFormDetailAboutid) {
                this.setInitialData(responseAbout.data)
            }

            if (apiRequestAboutId === this.getCountriesId) {
                this.setState({ allcountriesdata: responseAbout.countries })
            }

            if (apiRequestAboutId === this.feedbackSupAboutId) {
                this.setState({ isFeedback: false })
            }
        }

        // Customizable Area End
    }

    // Web Events

    async componentDidMount() {
        super.componentDidMount();
        this.getFormDetailAbout()
        this.getCountries()

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
        if (this.props.categoryId !== prevProps.categoryId) {
            this.getFormDetailAbout();
        }
    }

    goToVendorAccountSuppliersAbout = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleSubmitAbout = async (event: any) => {

        this.props.handleDataSaved();
        const categoryid = this.props.categoryId || await getStorageData("category_id");

        let formdata = new FormData();

        formdata.append("short_description", this.state.shortDescription);
        formdata.append("description", this.state.longDescription);

        this.state.selectedCountries.length > 0 ? this.state.selectedCountries.forEach((item: any) => {
            formdata.append("market_ids[]", item.country_id);
            })  :  formdata.append("market_ids[]", "")
       

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmitid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryid}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSubmitFeedbackAbout = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmail(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })
        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackSupAboutId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFormDetailAbout = async () => {
        this.setState({ isLoading: true })
        const categoryid = this.props.categoryId || await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailAboutid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCountries = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCountriesId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCountries}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    setInitialData = (data: any) => {
        this.setState({ isLoading: false })
        if (data.catalogue.data.attributes.short_description) {
            this.setState({ shortDescription: data.catalogue.data.attributes.short_description })
        }

        if (data.catalogue.data.attributes.description) {
            this.setState({ longDescription: data.catalogue.data.attributes.description })
        }

        if (data.catalogue.data.attributes.domain_url) {
            this.setState({ domain: data.catalogue.data.attributes.domain_url })
        }

        if (data.catalogue.data.attributes.markets) {
            this.setState({ selectedCountries: data.catalogue.data.attributes.markets })
        }
        if (data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }

    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ selectedCountries: newValue })
        this.props.handleDataChanged()
    }

    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleChangeShortDescribe = (event: any) => {
        const desc = event.target.value;
        this.setState({ shortDescription: desc })
        this.props.handleDataChanged()
    }

    handleChangeLongDescribe = (event: any) => {
        const desc = event.target.value;
        this.setState({ longDescription: desc })
        this.props.handleDataChanged()
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleFeedbackEmailChangeAbout = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChangeAbout = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmail = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }
   
    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails, userStyles } from "./PremiumListing.web";
// Customizable Area End

// Customizable Area Start
import BasicListingSupplierController, {
    Props,
} from "./BasicListingSupplierController";
import { Backdrop, Box, CircularProgress, Tab, Tabs, Typography,Link } from "@material-ui/core";
import AppBarPage  from "../../../components/src/AppBar.web";
import { logoImage, searchIcon, downArrow } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Reviews from "../../../components/src/Reviews.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import Slider from "react-slick";
import  CustomErrorToast  from "../../../components/src/CustomErrorToast.web";

// Customizable Area End

// Customizable Area Start
export class BasicListingSupplier extends BasicListingSupplierController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderItems = (classes: any) => {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
               
              ]
        };
        return (<div>
            <Box>
                <Slider className={classes.basicSlider} {...settings}>
                    {this.state.similar_listings && this.state.similar_listings.map((list: any) => {
                        return (
                            <div key={list.id}>
                                 <Link color="inherit" href={`/basiclistingsupplier/${list.id}`} underline="none">
                                <img className={classes.basicImg} src={list.logo} alt="similar-logo"/>
                                <Typography className={`${classes.marginTop10} ${classes.color666} ${classes.heading12}`}>by {list.name}</Typography>
                                <Typography className={` ${classes.marginTop10} ${classes.heading12} ${classes.ellipsisMultiline}`}>{list.short_description}</Typography>
                          </Link>
                            </div>

                        )
                    })}


                </Slider>

            </Box>
        </div>)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { isLoading, logo,
            name,
            tabValue,
            basicReview,

        }: any = this.state
        const tabsData = ["About", "Reviews"]

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                 serachIcon={searchIcon}
                    logoImg={logoImage}
                    handleUserSignout={this.handleBasicSignout}
                    downarrow={downArrow}
                    goToHomePage={()=>this.handleNavigation("Home")}
                    username={this.state.userdetails}
                    handleHeaderNavigation={()=>this.navigateToAccount(this.state.userdetails?.user_type)}
                    handleClick={this.goToLoginPage}
                    dummyicon={""}
                    data-testId={"signInBtn"}
                    searchText={this.state.searchTermProp}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                   
                />
                <Box className={classes.root}>
                    <Box className={`${classes.container}`}>
                        <Box className={classes.headerContainer}>
                            <ListingHeader userdetails={this.state.userdetails} logo={logo} handleBookmarkDelete={this.handleBookmarkDelete}
                                bookmarked={this.state.bookmarked}
                                data-testid="bookmark" domain_url={this.state.domain_url} handleBookmarkCall={this.handleBookmark}
                                name={name} />

                            <Box className={classes.navBar}>
                                <Tabs
                                    data-testid="tabs"
                                    value={tabValue}
                                    onChange={this.handleChange}
                                    centered
                                    classes={{ centered: classes.supplierTabContainer }}
                                    TabIndicatorProps={{ style: { height: '3px', backgroundColor: "#B300ED" } }}
                                >
                                    {tabsData && tabsData.map((item: any, index: any) => (
                                        <Tab value={index} data-testid={item} label={item} style={{ color: this.colorDetect(index) }} />

                                    ))}

                                </Tabs>
                            </Box>
                        </Box>

                        <Box id={`0-tab`} className={classes.accordionContainer}>
                            <Accordion data-testid="aboutDataOpen" onChange={() => this.handleAccordionToggle('aboutDataOpen')} expanded={this.state.aboutDataOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>About</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ paddingLeft:"56px" }}>
                                    <Box className={classes.integrationContainer}>
                                        <Box>
                                            <Typography className={`${classes.heading400}`}>
                                                {this.state.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Reviews id={`1-tab`} hideRatingForm={this.state.hideRatingForm}
                            handleRating={this.handleUserRate}
                            handleClose={this.handleClose}
                            ratingVal={this.state.ratingValue}
                            reviewComment={this.state.reviewText}
                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadBtn}
                            accordionState="reviewDataOpen"
                            anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            isEdit={this.state.editReview}

                            handleReviewSubmit={this.handleReviewSubmit}

                            loadmore={this.state.loadmore}
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            filterValue={this.state.filterValue}
                            handleReviewComment={this.handleReviewText}
                            data-testid="reviewAccordion"
                            reviewData={basicReview}
                            handleAccordionToggle={this.handleAccordionToggle}
                            expanded={this.state.reviewDataOpen}
                            handleEditBtn={this.handleEditBtn}
                            handleReviewUpdate={this.handleReviewUpdate}
                        />

                        <Box className={`${classes.accordionContainer} ${classes.headerContainer}`}>
                            <Box style={{ padding: "30px 40px 0px 40px" }}>
                                <Typography className={classes.similarHeading}>Similar Listings</Typography>

                            </Box>
                            <Box style={{ padding: "0px 30px" }}>
                                {this.renderItems(classes)}
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <CustomErrorToast data-testid= "customToast" open={this.state.showAlertText} onClose={this.handleAlertText}/>

            </div>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
export default withStyles(userStyles)(BasicListingSupplier)
// Customizable Area End

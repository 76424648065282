import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
export interface CustomFormResponse {
    data: {
        catalogue: {
            data: {
                attributes: {
                    short_description: string,
                    description: string,
                    domain_url: string,
                    progress_percentage: number,
                    markets: [{ name: string, }]
                }
            }
        }
    }
}

export interface Country {
    countries: [
        { name: string }
    ]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    incrementProgress: (value: number) => void;
    categoryId?: any;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    catlougue_id: number;
    short_description: string;
    long_description: string;
    user_domain: string;
    isValidUrl: boolean;
    allcountriesdata: any[];
    marketsData: any[];
    isSuccessDialog: boolean;
    isFeedback: boolean;
    isValidEmail: { isValid: boolean, message: string };
    feedbackEmail: string;
    feedbackMessage: string;
    progressPercentage: number;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AboutController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCustomFormDetailid: string = "";
    getCountriesDataAPICallId: string = "";
    submitFormDataAPIcallId: string = "";
    feedbackAPICallId: string = "";
    emailReg: RegExp;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            catlougue_id: 0,
            short_description: "",
            long_description: "",
            user_domain: "",
            isValidUrl: false,
            allcountriesdata: [],
            marketsData: [{ name: "" }],
            isSuccessDialog: false,
            isFeedback: false,
            isValidEmail: { isValid: false, message: "" },
            feedbackEmail: "",
            feedbackMessage: "",
            progressPercentage: 0,
            // Customizable Area End
        };

        // Customizable Area Start
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        const catlougue_id = await getStorageData("category_id");
        this.setState({ catlougue_id: catlougue_id },  ()=> this.getCustomFormDetail())
        this.getCountriesData()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getCustomFormDetailid:
                        this.handleCustomFormDetailsAPIResponse(responseJson);
                        this.props.incrementProgress(this.state.progressPercentage)
                        break;
                    case this.getCountriesDataAPICallId:
                        this.handleCountriesDataAPIResponse(responseJson);
                        break;
                    case this.submitFormDataAPIcallId:
                        this.setState({ isSuccessDialog: true });
                        this.getCustomFormDetail()
                        break;
                    case this.feedbackAPICallId:
                        this.setState({ isFeedback: false });
                        break;
                    default:
                        break
                }
            }
        }
    }

    // Events
    handleChangeShortDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ short_description: event.target.value })
        this.props.handleDataChanged();
    }

    handleChangeLongDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ long_description: event.target.value })
        this.props.handleDataChanged();
    }

    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ marketsData: newValue })
        this.props.handleDataChanged();
    }

    handleCloseSuccessDialog = () => {
        this.setState({ isSuccessDialog: false });

    }
    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { isValid: false, message: "" } })
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailAbout = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }

    // API Integrations

    getCustomFormDetail = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${this.state.catlougue_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCountriesData = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCountriesDataAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCountries}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSubmitAbout = async (event: any) => {
        this.props.handleDataSaved();
        let formdata = new FormData();

        formdata.append("description", this.state.long_description);
        formdata.append("short_description", this.state.short_description);

        this.state.marketsData.length > 0 ? this.state.marketsData.forEach((item: any) => {
            formdata.append("market_ids[]", item.country_id);
            })  :  formdata.append("market_ids[]", "")

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitFormDataAPIcallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${this.state.catlougue_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSubmitFeedbackAboutCustom = async () => {
        let formdata = new FormData();
        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { isValid: true, message: "Email is required" } })
            return
        }
        else if (!this.validateEmailAbout(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { isValid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { isValid: false, message: "" } })
        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // API Response
    handleCustomFormDetailsAPIResponse = (responseJson: CustomFormResponse) => {
        const apiResponse = responseJson.data.catalogue.data
        this.setState({
            short_description: apiResponse.attributes.short_description,
            long_description: apiResponse.attributes.description,
            user_domain: apiResponse.attributes.domain_url,
            marketsData: apiResponse.attributes.markets,
            progressPercentage: apiResponse.attributes.progress_percentage
        })
    }

    handleCountriesDataAPIResponse = (responseJson: any) => {
        this.setState({ allcountriesdata: responseJson.countries })
    }

    // Customizable Area End
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

export interface AboutAPIResponse {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    name: string;
                    logo: string
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;

    // Customizable Area Start
    incrementProgress: (value: number) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    Username: any;
    profilePic: string;
    imagePreview: string;
    value: number;
    progressVal: number;
    isOpenEditModal: boolean;
    catelougueDetails: {
        name: string,
        logo: string
    };

    updateCatelougueDetails: {
        name: string,
        logo: string
    };
    logoPreview: File | null;
    editProfilePic: File | null;
    categoryId:any;
    showDirectoryPopup:boolean,
  searchDirectoryData:any,
  searchTermProp:string,
  formDataChanged:boolean,
  openSaveModal:boolean

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class ServicesCustomFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCatlougeDetailsAPICalledId: string = "";
    updateCatelouguetListingDataAPICallId: string = "";
    getSearchResultDataId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area Start

            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            Username: null,
            imagePreview: "",
            value: 0,
            progressVal: 0,
            isOpenEditModal: false,
            profilePic: '',
            catelougueDetails: {
                name: "",
                logo: ""
            },

            updateCatelougueDetails: {
                name: "",
                logo: ""
            },
            logoPreview: null,
            editProfilePic: null,
            categoryId:"",
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchTermProp:"",
            formDataChanged:false,
            openSaveModal:false

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const propData = message.getData(
                getName(MessageEnum.SessionResponseData)
            );
            const idToSet = propData?.data?.catalogue_id;
            if (idToSet !== undefined) {
                this.setState({categoryId: JSON.stringify(idToSet)})
                await setStorageData('service_category_id', JSON.stringify(idToSet));
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getCatlougeDetailsAPICalledId:
                        this.handleCateLougueAPIResponse(responseJson);
                        break;
                    case this.updateCatelouguetListingDataAPICallId:
                        this.handleUpdateCatlougueListResponse(responseJson);
                        break;
                    case this.getSearchResultDataId:
                    this.handleDirectorySearch(responseJson);
                        break;
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,) {
        if(prevState.categoryId !== this.state.categoryId){
            this.getCatlougeDetails()
        }
    }

    handleChange = (event: any, newValue: number) => {
        if(this.state.formDataChanged) this.setState({openSaveModal:true})
        else this.setState({ value: newValue });
        
       
    };

    a11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    incrementProgress = (value: number) => {
        this.setState({ progressVal: value })
    }

    handleOpenEditModal = () => {
        this.setState({ isOpenEditModal: true }, () => { this.getCatlougeDetails() })
    }

    handleCloseOpenEditModal = () => {
        this.setState({ isOpenEditModal: false })
    }

    handleUpdateCatlougueData = () => {
        this.setState({ updateCatelougueDetails: { ...this.state.catelougueDetails } })
    }

    handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ updateCatelougueDetails: { ...this.state.updateCatelougueDetails, name: event.target?.value } })
    }

    handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const reader: any = new FileReader();
        const files = e.target?.files;

        if (!files || files.length === 0) {
            return;
        }
        const file = files[0];
        reader.onloadend = () => {
            this.setState({
                editProfilePic: file,
                imagePreview: reader.result
            });

        }
        reader.readAsDataURL(file);
    };

    // API
    getCatlougeDetails = async () => {
        const categoryID = this.state.categoryId || await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCatlougeDetailsAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateCatlouguetListingData = async () => {
        const categoryID = this.state.categoryId ||  await getStorageData("service_category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata = new FormData();
        if (this.state.editProfilePic !== null) {
            formdata.append("logo", this.state.editProfilePic);
        }
        formdata.append("name", this.state.updateCatelougueDetails?.name);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateCatelouguetListingDataAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleCateLougueAPIResponse = (responseJson: AboutAPIResponse) => {
        const apiresponse = responseJson?.data?.catalogue?.data?.attributes
        if (responseJson) {
            this.setState({
                catelougueDetails: {
                    ...this.state.catelougueDetails,
                    name: apiresponse?.name,
                    logo: apiresponse?.logo,
                }
            }, () => this.handleUpdateCatlougueData()
            )
        }

    }
    signoutUser = async () => {
        await removeStorageData("loginToken")
        this.homePageNavigation()
      }

    handleUpdateCatlougueListResponse = (responseJson: any) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isOpenEditModal: false })
        }
    }

    homePageNavigation = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
      }
      headerNavigation = async () => {
        const usertype = await getStorageData("user_type")
        if (usertype == "vendor" || "Vendor") {
          this.vendorAccountNavigation()
        }
      }
      
      vendorAccountNavigation = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
      }
      handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
      }
    
      handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }

      getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchResultDataId = searchRequestMessage.messageId;
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
    
    
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }

      handleDirectorySearch=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
      }

      handleAdvancedSearch = (directory:string) => { 
        this.setState({
            showDirectoryPopup:false
        }) ;
        const msg: Message = new Message( 
          getName(MessageEnum.NavigateToAdvancedSearch)
        ); 
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
           msg.addData(getName(MessageEnum.AdvancedSearchTermData),   {directory,searchText:this.state.searchTermProp}); 
              this.send(msg);
    
      }
      handleDataChanged=()=>{
        this.setState({formDataChanged:true})
      }
      handleOpenSaveModal=()=>{
        this.setState({openSaveModal:true})
      }

      handleDataSaved=()=>{
        this.setState({formDataChanged:false})
      }

     

      handleCloseSaveModal=()=>{
        this.setState({openSaveModal:false})
      }

      handleBack=()=>{
        this.setState({value:this.state.value-1})
      }

     
    

    // Customizable Area End
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export interface SocialItem{
    id: string,
    type: string,
    attributes:{
        id: string,
        name: string,
        title: string,
        icon: {
            url:string
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    socialItemsData:SocialItem[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SocialLinksController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getSocialLinksApiID: string="";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            socialItemsData:[]
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getSocialLinks();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(apiRequestCallId=== this.getSocialLinksApiID){
                if(responseJson && responseJson.data){
                    this.setState({socialItemsData:responseJson.data})
                }
            }

            runEngine.debugLog("API Message Recived", message);
                

        }
        // Customizable Area End
    }

    // Customizable Area Start

    getSocialLinks = () => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
        };

        const searchdataRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSocialLinksApiID = searchdataRequestMessage.messageId;
        searchdataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );
        searchdataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getSocialLinkEndpoint}`);

        searchdataRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(searchdataRequestMessage.id, searchdataRequestMessage);


    }

    // Customizable Area End
}

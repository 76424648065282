import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";

import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    openSaveModal:boolean;
    handleOpenSaveModal:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    documentArrayFile: any[],
    updatedSuccessDialog: boolean,
    error: boolean,
    isFeedback: boolean,
    feedbackEmail: string,
    feedbackMessage: string,
    isValidEmail: { valid: boolean, message: string },
    isLoading: boolean,
    progressPercentage: number

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierFilesPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formSubmitFilePageid: string = "";
    feedbackSupFilesId: string = "";
    emailReg: RegExp;
    getSupplierFormDetailAboutid: any;
    deleteFilesApiId: any;


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            documentArrayFile: [],
            updatedSuccessDialog: false,
            error: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            isLoading: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestProductPageId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestProductPageId === this.formSubmitFilePageid) {
                this.setState({ updatedSuccessDialog: true })
            }

            if (apiRequestProductPageId === this.feedbackSupFilesId) {
                this.setState({ isFeedback: false })
            }

            if (apiRequestProductPageId === this.getSupplierFormDetailAboutid) {
                this.setCatalogueData(responseJson.data)
            }
            else if (apiRequestProductPageId === this.deleteFilesApiId) {
                this.setState({ isLoading: false })
            }
        }

        // Customizable Area End
    }

    handleSubmitSupplierFilePage = async () => {
        this.props.handleDataSaved();
        const categoryID = await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        this.state.documentArrayFile.forEach((item) => {
            !item?.id && formdata.append("files[]", item, item.name);

        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmitFilePageid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleSubmitFeedbackFile = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailFile(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackSupFilesId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    async componentDidMount() {
        super.componentDidMount();
        this.getFormDetail();
    }


    onDocumentUploadSupplierFile = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const files = e.target.files;

        if (!files || files.length === 0) {
            return;
        }

        const file = files[0];
        reader.onloadend = () => {

            if (file.size > +3e+7) {
                this.setState({ error: true })
                return
            }

            this.setState({ documentArrayFile: [...this.state.documentArrayFile, file] })
        }
        reader.readAsDataURL(file);
        this.props.handleDataChanged();
    };

    removeDocumentSupplierFile = (documentData: any) => {
        const updateData = this.state.documentArrayFile.filter((item) => item.name !== documentData.name)
        this.setState({ documentArrayFile: updateData })
        if (documentData.id) {
            this.handleFileDelete(documentData.id)
        }

    }
    setCatalogueData = (data: any) => {
        this.setState({ isLoading: false })
        if (data.catalogue.data.attributes.files) {
            this.setState({ documentArrayFile: data.catalogue.data.attributes.files })
        }
        if (data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }
    getFormDetail = async () => {
        this.setState({ isLoading: true })
        const categoryid = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSupplierFormDetailAboutid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleCloseErrorDialog = () => {
        this.setState({ error: false });
    }

    formatFileSizeSupplierFile = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }


    handleFeedbackEmailChangeFile = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChangeFile = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }


    validateEmailFile = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }


    handleFileDelete = async (attachment_id: any) => {
        this.setState({ isLoading: true })
        const categoryid = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken")
        };
        const requestMessageDeleteApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteFilesApiId = requestMessageDeleteApi.messageId;

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.attachmentDeleteEndpoint + `?id=${categoryid}&attachment_id=${attachment_id}&attachment_name=files`
        );
        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageDeleteApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );

        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
        return true;
    };

    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
            this.props.handleBack();
        }
    }


    // Customizable Area End
}
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    tabValue: number,
    basicReview: any,
    loadmore: boolean,
    name: any,
    description: any,
    aboutDataOpen: boolean,
    reviewDataOpen: boolean,
    hideRatingForm:boolean,
    similar_listings: any,
    userdetails:any,
    logo:any,
    contactData:{},
    filterValue: string,
    catalogueId:any,
    token:any,
    anchorEl:any
    ratingValue:number ,
    reviewText:string ,
    editReview:boolean,
    editReviewId:any,
    bookmarked:boolean,
    bookmark_id:any,
    domain_url:any,
    directory_id:any,
    showAlertText:boolean,
    searchDirectoryData:any,
    showDirectoryPopup:boolean,
    searchTermProp:string,

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BasicListingSupplierController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBasicDetailApiId:any;
    postReviewApiId:any;
    bookmarkApiId:any;
    reviewUpdateApiId:any;
    deleteBookmarkApiId:any;
    getSearchApiDataReqId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            name: "",
            description: "",
            isLoading: false,
            contactData:{},
            tabValue: 0,
            basicReview: [],
            loadmore: false,
            aboutDataOpen: false,
            reviewDataOpen: true,
            logo:"",
            filterValue: "recent",
            catalogueId:null,
            token:"",
            userdetails:{},
            hideRatingForm:false,
            anchorEl:null,
            ratingValue:0,
            reviewText:"",
            editReview:false,
            bookmarked:false,
            editReviewId:null,
            bookmark_id:null,
            similar_listings: [],
            domain_url:"",
            showAlertText:false,
            directory_id:null,
            searchDirectoryData:[],
            showDirectoryPopup:false,
            searchTermProp:"",

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (this.getBasicDetailApiId === messageId) {
            this.handleBasicResponse(responseJson, errorReponse)

        }
        else if (this.postReviewApiId === messageId) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getBasicListSupplierData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({showAlertText: true,isLoading: false })
               
            }
           
        }
        else if(this.deleteBookmarkApiId===messageId){
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if (this.bookmarkApiId === messageId) {
            this.setState({ isLoading: false })
            this.getBasicListSupplierData(this.state.catalogueId)
        }
        else if (this.reviewUpdateApiId === messageId) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getBasicListSupplierData(this.state.catalogueId)
        }
        else if(messageId==this.getSearchApiDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
        }

    }

    colorDetect = (val: number) => {
        if (this.state.tabValue === val) {
            return "#B300ED"
        } else {
            return '#000'
        }
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };
    handleAccordionToggle = (accordion: any) => {
        if (accordion == "aboutDataOpen") {
            this.setState({ aboutDataOpen: !this.state.aboutDataOpen })
        }
        else if (accordion == "reviewDataOpen") {
            this.setState({ reviewDataOpen: !this.state.reviewDataOpen })
        }


    }
    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }
   

   
   
    async componentDidMount() {
        const id = this.getCatalogueId()
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })
        this.getBasicListSupplierData(id, this.state.filterValue);
    }
    handleBookmarkDeleteResponse=(responseJson:any,errorReponse:any)=>{
        if(responseJson && responseJson.success){
            this.setState({bookmarked:false})
        }
    }
    handleAlertText=()=>{
        this.setState({showAlertText:false})
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    checkAbout=(about:string)=>{
        if(about) this.setState({aboutDataOpen:true})
    }
    handleBasicResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error && responseJson.data) {
            const { reviews } = responseJson.data;
            if(responseJson.data?.catalogue?.data?.attributes){
            const { logo, name,description,contact,review_posted, directory_id,bookmarked,bookmark_id,similar_listings,domain_url } = responseJson.data.catalogue.data.attributes
            this.setState({ isLoading: false, directory_id,hideRatingForm:review_posted, logo,bookmark_id, name,similar_listings,domain_url,contactData: contact,bookmarked, description, basicReview: reviews?.data })
            this.checkAbout(description)
        }
           
        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    handleLoadBtn = () => {
        this.setState({ loadmore: true })
    }
    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    } 
   
    handleReviewText = (event: any) => {
        this.setState({ reviewText: event.target.value })
    }
    navigateToAccount= (usertype:any) => {
        let accountType=usertype.toLowerCase()
          if (accountType == "vendor") {
              this.handleNavigation("VendorAccountDetails")
          }else if(accountType == "buyer" ){
              this.handleNavigation("BuyerAccountDetails")      
            }
    }
    handleBasicSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    }
    handleBookmark = async () => {

        if(this.state.token){
        this.setState({ isLoading: true })
        const token = this.state.token ??""
        let formdata = new FormData();
        formdata.append("catalogue_id", this.state.catalogueId);
        formdata.append("directory_id", this.state.directory_id);

        const header = {
            token
        };

        const requestMessageBookmarkAdd = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.bookmarkApiId = requestMessageBookmarkAdd.messageId;

        requestMessageBookmarkAdd.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.bookmarkEndPoint}`
        );
        requestMessageBookmarkAdd.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageBookmarkAdd.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );
        requestMessageBookmarkAdd.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );


        runEngine.sendMessage(requestMessageBookmarkAdd.id, requestMessageBookmarkAdd);

        return true;
        }
        else{
                this.goToLoginPage()
        }
    }

    handleFilterClick = (event: any, filter: any) => {
        this.setState({ filterValue: filter, anchorEl: null })
        this.getBasicListSupplierData(this.state.catalogueId, filter)
    }
     handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingValue));
            formdata.append("comment", this.state.reviewText);

            const header = {
                token: this.state.token
            };

            const reviewMessagePost = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.postReviewApiId = reviewMessagePost.messageId;

            reviewMessagePost.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );
            reviewMessagePost.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            reviewMessagePost.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            reviewMessagePost.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(reviewMessagePost.id, reviewMessagePost);
            return true;

        }
        else {
            this.goToLoginPage()
        }

    }
    goToLoginPage = async () => {
        this.handlePostSign();
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handlePostSign=async()=>{
        await  setStorageData("component",JSON.stringify({component:"BasicListingSupplier",id:this.state.catalogueId}))

    }
    handleUserRate = (event: any, val: any) => {
        this.setState({ ratingValue: val })
    }
    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data.attributes
        this.setState({ editReview: !this.state.editReview, reviewText: comment, ratingValue: rating, editReviewId: id })
    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

        if (token != this.state.token && this.state.token == null) {
            this.setState({ token, userdetails: accountDetails },()=>{
                this.getBasicListSupplierData(this.state.catalogueId)
            })
        }


    }
    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
        let formdata = new FormData();
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingValue));
        formdata.append("comment", this.state.reviewText);
        const header = {
            token: this.state.token
        };

        const reviewMessageUpdate = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = reviewMessageUpdate.messageId;

        reviewMessageUpdate.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );
        reviewMessageUpdate.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        reviewMessageUpdate.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        reviewMessageUpdate.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(reviewMessageUpdate.id, reviewMessageUpdate);
        return true;

    }
    handleBookmarkDelete= () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
        const requestMessagedeleteItem = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteBookmarkApiId = requestMessagedeleteItem.messageId;
    
        requestMessagedeleteItem.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookmarkDeleteEndpoint+ `${this.state.bookmark_id}`
        );
        requestMessagedeleteItem.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessagedeleteItem.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteMethod
        );
    
        runEngine.sendMessage(requestMessagedeleteItem.id, requestMessagedeleteItem);
        return true;
      };

    getBasicListSupplierData = async (id: any,filter?: any) => {
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken")||""

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filter ?? this.state.filterValue}`;

        const requestMessageCatalogue = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBasicDetailApiId = requestMessageCatalogue.messageId;

        requestMessageCatalogue.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        requestMessageCatalogue.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageCatalogue.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);

    };
    handleChange = (event: any, newValue: number) => {

        const element:any = window.document.getElementById(`${newValue}-tab`);
        element?.scrollIntoView();
        this.setState({ tabValue: newValue });
    };
    handleAdvancedSearch = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msgData: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msgData);
    
      }
      handleSubmit=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
      }

      handleDirectorySearch=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({isLoading:false,searchDirectoryData:responseJson.response})
        }
      }

      handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }

      getSearchResultDirectory = () => {
        this.setState({isLoading:true})
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestDataMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchApiDataReqId = searchRequestDataMsg.messageId;
        searchRequestDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchRequestDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestDataMsg.id, searchRequestDataMsg);
    
    
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }



    // Customizable Area End





}
// Customizable Area End


import React from "react";

// Customizable Area Start
import {
    Typography,
    Grid,
    Radio,
    Chip,
    Checkbox,
    TextField,
    Button,
    Theme,
    CircularProgress,
    Backdrop,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
// Customizable Area End

// Customizable Area Start
import SupplierBrandsPageController, {
    Props,
    configJSON,
} from "./SupplierBrandsPageController";
import { CloseICon, deleteIcon_1 } from "./assets";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Customizable Area End

export class SupplierBrandsPage extends SupplierBrandsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props

        // Customizable Area End
        return (
            <Grid container className={classes.brand_mainContainer}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item container alignContent="center" >
                        <Grid item xs={6} sm={6} md={5} lg={5} className={classes.brandTypoContent}>
                            <Typography className={classes.brand_font_1}>{configJSON.areubrand}</Typography>
                        </Grid>
                        <Grid item container alignItems="center" xs={3} sm={3} md={3} lg={3}>
                            <Radio
                                value="yes"
                                data-testid={"yes_radio_btn"}
                                checked={this.state.selectedOption === 'yes'}
                                onChange={() => this.handleRadioChange('yes')}
                                checkedIcon={<span className={clsx(classes.brand_icon, classes.brand_checkedIcon)} />}
                                icon={<span className={classes.brand_icon} />}
                            />
                            <Typography className={classes.brand_labelFont}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item container alignItems="center" xs={3} sm={3} md={3} lg={3}>
                            <Radio
                                value="no"
                                data-testid={"no_radio_btn"}
                                checked={this.state.selectedOption === 'no'}
                                onChange={() => this.handleRadioChange('no')}
                                checkedIcon={<span className={clsx(classes.brand_icon, classes.brand_checkedIcon)} />}
                                icon={<span className={classes.brand_icon} />}
                            />
                            <Typography className={classes.brand_labelFont}>{configJSON.no}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {this.state.selectedOption == "yes" ?
                                <Typography className={classes.brand_font_2} data-test-id="pleaselistyourbrands">{configJSON.listyourproduct}</Typography>
                                :
                                <Typography className={classes.brand_font_2} data-test-id="whichbrandsdoyou">{configJSON.whichBrands}</Typography>
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={this.state.selectedBrandList}
                                getOptionLabel={(option) => option.name}
                                data-test-id={`autocomplete`}
                                value={this.state.selectedBrandListValue}
                                onChange={this.onAutoCompleteChange}
                                disableClearable={true}
                                // freeSolo
                                className={classes.autocompleteBrand}
                                renderOption={(option, { selected }) => (

                                    <div style={{ fontFamily: "Poppins" }} >
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{
                                                color: selected ? "#B300ED" : '#64748B',
                                                marginRight: 8,

                                            }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </div>
                                )}
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: any, index: number) => (
                                        <Chip variant="outlined" deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />} label={option.name} {...getTagProps({ index })} className={classes.chipbrand} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder={this.state.selectedBrandListValue.length > 0 ? "" : " Select Brands"}
                                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonBoxMargin}>
                    <Grid item container >
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.brand_feedBack_Text}>
                            <Typography className={classes.brand_fontStyle_2}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.brand_font_5}>{configJSON.no}</Typography>
                            <Typography className={classes.brand_font_5} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.brand_feedBack_Text_button}>
                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.gotoVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"saveBtn"} handleClick={this.handleSubmit} />
                        </Grid>
                    </Grid>
                </Grid>

                <Backdrop className={classes.backdrop} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <CustomToast data-testid="successToast" open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />
                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackBrand} handleFeedbackEmailChange={this.handleFeedbackEmailChangeBrand} handleFeedbackMessageChange={this.handleFeedbackMessageChangeBrand} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Grid >

        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    brand_mainContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },
    brandTypoContent: {
        display: "flex",
        alignItems: "center",
    },
    brand_font_1: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins',
    },

    brand_fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    brand_downloadText: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#B300ED',
        fontFamily: 'Poppins',
        background: 'none',
    },

    about_boxFeild: {
        padding: '10px',
        border: '2px solid #CBD5E1',
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
        display: 'flex',
        gap: '20px',
    },

    brand_iconStyle: {
        height: '18px',
        width: '18px',
        cursor: 'pointer',
    },

    brand_font_2: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },

    brand_font_5: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },

    brand_feedBack_Text: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '20%',

    },
    buttonBoxMargin: {
        marginTop: '10%',
        marginBottom: '3%'

    },
    brand_feedBack_Text_button: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'end',
        marginTop: '20%',

    },
    brand_icon: {
        borderRadius: "50%",
        width: 18,
        height: 18,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },

    brand_checkedIcon: {
        backgroundColor: "#B300ED",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 18,
            height: 18,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#B300ED",
        },
    },

    brand_labelFont: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#0F172A',
        fontFamily: 'Poppins',
        lineHeight: '20px'
    },
    autocompleteBrand: {

        borderRadius: '8px',
        padding: "6px 0",
        border: "1px solid  #CBD5E1",
        marginBottom: "42px",
        backgroundColor: "#f6f0ff",
        fontFamily: "Poppins",
      '& .MuiInputBase-root':{
       fontFamily: "Poppins",
      },
      '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },
        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent"
        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        },

        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
        },

        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },


    },
    chipbrand: {
        margin: "0 3px",
        border: "none",
        fontFamily:'Poppins'
    },
    declineBtn_8_SupplierBrand: {
        fontFamily: "Poppins",
        padding: "2px 81px",
        fontWeight: 400,
        color: "#B300ED",
        textAlign: "center",
        lineHeight: "50.956px",
        borderRadius: "6px",
        textTransform: "initial",
        border: "1px solid #B300ED",
        fontSize: "18px",
        background: "#FFF",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },
    saveBtnBrand: {
        borderRadius: "8px",
        background: "#B300ED",
        padding: "15.5px 71px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textTransform: "initial",
        textWrap: "nowrap",
        marginLeft: 15,
        height: "55px",
        width: "241px",

        '&:hover': {
            background: "#B300ED",
        },

        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
            lineHeight: "50.956px",
        },
    },
    successboxSupplierBrand: {
        width: "529px",
        borderRadius: "8px 8px 32px 8px",
        background: "#FFF",
        height: "329px",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    },
    successMediaDialogBrand: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: "20px",
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },

    detailSuccessTypoABrand: {
        color: "#0F172A",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        margin: "20px 0 51px"
    },
    backToAccountBtnBrand: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
});

export default withStyles(userStyles)(SupplierBrandsPage)
// Customizable Area End
import React, { Component } from "react";
import {
    Box,
    Button,
    Typography,
    withStyles,
    Theme,
    Dialog,
    OutlinedInput,
    TextField,

} from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';


const styles: any = (theme: Theme) => ({
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%'
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins'
    },
    fontStyle5: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    contactUsDialog: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '600px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        marginTop: '5%',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '5px'
    },
    contactUsFont: {
        fontWeight: 700,
        fontSize: "28px",
        fontFamily: "Poppins",
        color: '#0F172A',
        textAlign: 'center' as const,
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        },
    },
    BoxScrollbar_1: {
        height: '100%',
        overflowX: 'hidden' as const,
        overflowY: 'scroll' as const,
        scrollbarColor: "transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            backgroundColor: "transparent",
            padding: '30px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            minHeight: 24,
            minWidth: 14,
            borderRadius: 6,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
    },

    contentBoxStyle_1: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '15px',
        padding: '30px 10% 30px 10%',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 5% 15px 5%',
        },
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    outlinedInput_1: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        borderRadius: '8px',
    },
    focused: {},
    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },
    notchedOutline: {
        borderWidth: '1px',
    },
    sendBtnBox_1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8%',
        marginBottom: '8%'
    },
    sendButton: {
        backgroundColor: "#B300ED",
        fontSize: "18px",
        fontWeight: 400,
        color: "#FFFFFF",
        fontFamily: "Poppins",
        width: '65%',
        padding: '5px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    email: {
        fontFamily: "Poppins",
        color: "#828589",
        fontSize: "14px",
        lineHeight: "normal",
        borderRadius: "6px",
        fontWeight: 400,
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "& .MuiInputBase-input:focus": {
            borderTop: "1px solid #CBD5E1",

            outline: 'none',
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontSize: "14",
            fontWeight: 400,
            fontFamily: "Poppins",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
        "& .Mui-focused": {
            border: "1px solid #CBD5E1",
        }

    },
    errorBoxStyle: {
        display: 'flex',
        marginTop: '2px',
        // gap:"10px",
    },
    errorIconStyle: {
        fontSize: '18px',
        margin: '3px 10px 0px -10px',
    },
})

type MyProps = { handleCloseFeedbackModal?: () => void, handleSubmitFeedback?: (event: any) => Promise<void>, handleFeedbackEmailChange?: (event: any) => void, handleFeedbackMessageChange?: (event: any) => void, isFeedback: boolean; feedbackEmail?: string, feedbackMessage?: string, closeIcon: any, classes: Record<string, string>, isValidEmail?: any };


class CustomFormFeedback extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes }: any = this.props
        return (

            <Dialog
                data-testid={"openDescriptionModal"}
                open={this.props.isFeedback}
                onClose={this.props.handleCloseFeedbackModal}
                maxWidth="md"
                PaperProps={{ className: classes.contactUsDialog }}
            >

                <Box className={classes.cancelGridStyle}>
                    <img
                        src={this.props.closeIcon}
                        className={classes.closeIconStyle}
                        onClick={this.props.handleCloseFeedbackModal}
                        data-testid={"closeModal"}
                    />
                </Box>
                <Box className={classes.BoxScrollbar_1}>
                    <Typography className={classes.contactUsFont}>Tell Us How We Can Improve</Typography>
                    <Box className={classes.contentBoxStyle_1}>
                        <TextField
                            id="outlined-adornment-weight"
                            inputProps={{
                                'aria-label': 'address',
                                className: classes.placeholder,
                                placeholder: 'Email',
                            }}
                            value={this.props.feedbackEmail}
                            onChange={this.props.handleFeedbackEmailChange}
                            className={classes.email}
                            variant="outlined"
                            error={this.props?.isValidEmail?.isValid}
                            helperText={
                                this.props?.isValidEmail?.isValid &&
                                <Box className={classes.errorBoxStyle}>
                                    {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                    <Typography style={{
                                        color: "#D53333",
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                    }}>
                                        {this.props?.isValidEmail?.message}
                                    </Typography>
                                </Box>
                            }

                        />

                        <OutlinedInput
                            id="outlined-adornment-weight"
                            rows={6}
                            multiline
                            inputProps={{
                                'aria-label': 'address',
                                placeholder: 'Your Message',
                                className: classes.placeholder,
                            }}
                            classes={{
                                root: classes.outlinedInput_1,
                                input: classes.inputText,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline,
                            }}
                            value={this.props.feedbackMessage}
                            onChange={this.props.handleFeedbackMessageChange}


                        />
                    </Box>
                    <Box className={classes.sendBtnBox_1}>
                        <Button
                            fullWidth
                            className={classes.sendButton}
                            data-test-id={"submitFeedback"}
                            variant="contained"
                            onClick={this.props.handleSubmitFeedback}

                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
    }
}


// export { CustomFormFeedback }
export default withStyles(styles)(CustomFormFeedback)
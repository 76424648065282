Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.apiContentType = "application/json"
exports.showProfile = "/account_block/accounts/show_profile";
exports.getReview = "bx_block_catalogue/reviews";
exports.getApiMethod = "GET";
exports.roleAPIEndPoint = "bx_block_roles_permissions/account_details/:account_detail_id/view_role";
exports.departmentAPIEndPoint = "/account_block/departments";
exports.typeOfBusiness = "account_block/type_of_business"
exports.updateDetailsAPIEndPoint = "/update_survey";
exports.countryListAPIEndPoint = "/account_block/accounts/:account_id/show_country";
exports.resetPassword = "bx_block_forgot_password/change_password";
exports.updateProfileUrl = "account_block/accounts/update_profile";
exports.updateReview = "bx_block_catalogue/update_review";
exports.getBookmarksEndPoint = "bx_block_content_management/bookmarks";
exports.passformate = "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character";
exports.welcomeName = "Welcome Tony";
exports.oldPassErrText = "Your password doesn’t match";


exports.putApiMethod = "PUT";
exports.postApiMethod = "POST";
exports.btnExampleTitle = "CLICK ME";



exports.getSearchResultEndPoint="/bx_block_catalogue/search_listing"

// Customizable Area End
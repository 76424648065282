import React from "react";

// Customizable Area Start
import {
    Typography,
    OutlinedInput,
    Grid,
    Button,
    Box,
    Dialog,
    Chip,
    TextField,
    Theme,
    Paper,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import SupplierAboutPageController, {
    Props,
    configJSON,
} from "./SupplierAboutPageController";
import { CloseICon, deleteIcon_1 } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import Autocomplete from '@material-ui/lab/Autocomplete';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomToast from "../../../components/src/CustomToast.web";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Customizable Area End

export class SupplierAboutPage extends SupplierAboutPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props

        // Customizable Area End
        return (
            <Grid container spacing={5} className={classes.aboutContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.about_descriptionFont}>{configJSON.shortDescription}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.about_paraFont}>{configJSON.longDescription}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-test-id={'describe'}
                                rows={4}
                                multiline
                                inputProps={{
                                    placeholder: configJSON.shortDescription,
                                    className: classes.about_placeholder,
                                    'aria-label': 'address',
                                }}
                                classes={{
                                    input: classes.about_inputText,
                                    root: classes.about_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.about_notchedOutline,
                                }}
                                value={this.state.shortDescription}
                                onChange={this.handleChangeShortDescribe}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.about_descriptionFont}>{configJSON.longDescriptio}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.about_paraFont}>{configJSON.longDescriptionText}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-test-id={'longDescribe'}
                                rows={6}
                                multiline
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.longDescriptio,
                                    className: classes.about_placeholder,
                                }}
                                classes={{
                                    root: classes.about_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.about_notchedOutline,
                                    input: classes.about_inputText
                                }}
                                value={this.state.longDescription}
                                onChange={this.handleChangeLongDescribe}


                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            < Typography className={classes.about_descriptionFont}>{configJSON.vendorurl}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.about_paraFont}>{configJSON.serviceDescription}</Typography>
                        </Grid>

                        <Box>
                            <TextField
                                variant="outlined"
                                className={classes.domainSupplier}
                                placeholder="Domain"
                                InputProps={{
                                    readOnly: true,
                                }}

                                value={this.state.domain}
                            />

                        </Box>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.about_market}>Markets</Typography>

                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={this.state.allcountriesdata}
                                getOptionLabel={(option) => option.country_name}
                                data-test-id={`autocomplete`}
                                value={this.state.selectedCountries}
                                onChange={this.onAutoCompleteChange}
                                disableClearable={true}

                                // freeSolo
                                ListboxProps={{
                                    className: classes.myCustomListSupplierAbout
                                }}
                                className={classes.autocompleteSupplierAbout}
                                renderOption={(option, { selected }) => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img alt="flag" height={16} width={25} src={option.flag} />
                                        <Typography className={classes.flagLabel}>{option.country_name}</Typography>


                                    </div>
                                )}
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: any, index: number) => (
                                        <Chip key={index} deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />} variant="outlined" label={option.country_name} {...getTagProps({ index })} className={classes.chipbrandSupplierAbout} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder={this.state.selectedCountries.length > 0 ? "" : " Select Markets"}
                                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                                    />
                                )}
                            />

                        </Grid>


                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container>
                        <Grid item xs={12} sm={12} md={9} lg={9} className={classes.feed_Text}>
                            <Typography className={classes.about_fontStyle}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.about_font_4}>{configJSON.no}</Typography>
                            <Typography className={classes.about_font_4} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} container className={classes.about_buttonBoxStyle}>
                            <Button variant="contained" className={classes.saveBtn} onClick={this.handleSubmitAbout} data-test-id="saveBtn">Save & Publish</Button>

                        </Grid>
                    </Grid>
                </Grid>

                <CustomToast open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />

                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackAbout} handleFeedbackEmailChange={this.handleFeedbackEmailChangeAbout} handleFeedbackMessageChange={this.handleFeedbackMessageChangeAbout} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Grid>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    aboutContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },

    about_descriptionFont: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    about_paraFont: {
        fontFamily: "Poppins",
        color: "#262424",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 400,
    },
    about_outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        width: '100%',
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    focused: {},
    about_notchedOutline: {
        borderWidth: '1px',
    },
    about_placeholder: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "14px",
        fontFamily: "Poppins",
    },
    flagLabel: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "14px",
        fontFamily: "Poppins",
        marginLeft: 15
    },

    about_inputText: {
        fontFamily: "Poppins",
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "16px",
        color: '#000000',
    },
    about_buttonBoxStyle: {
        marginTop: '5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            width: "100%",
        },
    },
    about_font_4: {
        fontSize: '14px',
        color: '#6200EA',
        fontWeight: 400,
        fontFamily: 'Poppins',
        cursor: "pointer"
    },

    feed_Text: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '5%',

    },

    about_fontStyle: {
        fontSize: '14px',
        color: '#2D2E2F',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    saveBtn: {
        height: "56px",
        padding: "16px 71px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        textTransform: "initial",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        cursor: "pointer",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",

        '&:hover': {
            background: "#B300ED",
        },

        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },

    },
    domainSupplier: {
        fontSize: "14px",
        color: "#828589",
        fontWeight: 400,
        fontFamily: "Poppins",
        border: "1px solid #CBD5E1",
        borderRadius: "6px",
        width: "587px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        background: "#FAF5FF",
        lineHeight: "normal",
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none',
        },
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
        }
    },
    successboxSupplierAbout: {
        width: "529px",
        borderRadius: "8px 8px 32px 8px",
        background: "#FFF",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        height: "329px",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },
    successMediaDialogAbout: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: "20px",
    },

    detailSuccessTypoAbout: {
        color: "#0F172A",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        margin: "20px 0 51px"
    },
    backToAccountBtnAbout: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    about_market: {
        marginTop: "30px",
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "14px",
    },
    autocompletebox: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
    },
    autocompleteSupplierAbout: {
        padding: "6px 0",
        marginBottom: "42px",
        borderRadius: '8px',
        border: "1px solid  #CBD5E1",
        width: "587px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        backgroundColor: '#FAF5FF',
        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },
        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent",

        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",

        },

        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
            minHeight: "40px",
        },

        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },


    },
    chipbrandSupplierAbout: {
        border: "none",
        margin: "0 3px",
        fontSize: "16px",
    },
    myCustomListSupplierAbout: {
        maxHeight: "331px",
        maxwidth: "590px",
        overflow: 'auto',
        padding: "4px 0",
        margin: "0",
        borderRadius: "8px",
        border: "1px solid #E2E8F0",
        background: "#FFF",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "23.34px",
            backgroundColor: "#A09B9B33",
            borderRadius: "30px",

        },
        "&::-webkit-scrollbar-thumb": {
            width: "6px",
            height: "23.34px",
            backgroundColor: "#B300ED",
            borderRadius: "30px",

        }
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
});

export default withStyles(userStyles)(SupplierAboutPage)
// Customizable Area End
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    name: string,
    description: string,
    logo: any,
    reviewData: any,
    currentDate: any,
    loadmore: boolean,
    similar_listings: any,
    reviewText: string,
    ratingValue: number,
    catalogueId: any,
    editReview: boolean,
    hideRatingForm: boolean,
    userdetails: any,
    anchorEl: any,
    editReviewId: any,
    filterValue: string,
    contactData: any
    token: any,
    reviewOpen: boolean,
    bookmarked:boolean,
    bookmark_id:any,
    domain_url:any,
    reviewError:boolean,
    directory_id:any,
    showDirectoryPopup:boolean,
    searchDirectoryData:any,
    searchTermProp:string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BasicListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBasicDetailApiId: any;
    postReviewApiId: any;
    bookmarkApiId: any;
    reviewUpdateApiId: any;
    deleteBookmarkApiId:any;
    getSearchResultDataReqId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            name: "",
            logo: "",
            reviewData: [],
            currentDate: moment(),
            similar_listings: [],
            loadmore: false,
            description: "",
            reviewText: "",
            ratingValue: 0,
            catalogueId: 0,
            editReview: false,
            hideRatingForm: false,
            userdetails: {},
            anchorEl: null,
            editReviewId: null,
            filterValue: "recent",
            token: null,
            reviewError:false,
            contactData: {},
            reviewOpen: true,
            bookmarked:false,
            bookmark_id:null,
            domain_url:"",
            directory_id:null,
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchTermProp:"",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const messageDataId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

       
        if (this.postReviewApiId === messageDataId) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getBasicListData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({reviewError: true,isLoading: false })
               
            }
          
            
        }
        else  if (this.getBasicDetailApiId === messageDataId) {
            this.handleBasicResponse(responseJson, errorReponse)

        }
        else if (this.bookmarkApiId === messageDataId) {
            this.setState({ isLoading: false })
            this.getBasicListData(this.state.catalogueId)
        }
        else if(this.deleteBookmarkApiId===messageDataId){
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if (this.reviewUpdateApiId === messageDataId) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getBasicListData(this.state.catalogueId)
        }
        else if(messageDataId==this.getSearchResultDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
          }

    }

    getBasicListData = async (id: any, filter?: any) => {
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken") || ""

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filter ?? this.state.filterValue}`;

        const requestMessageData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBasicDetailApiId = requestMessageData.messageId;

        requestMessageData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        requestMessageData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessageData.id, requestMessageData);

    };
    handleBookmarkDeleteResponse=(responseJson:any,errorReponse:any)=>{
        if(responseJson && responseJson.success){
            this.setState({bookmarked:false})
        }
    }


    handleBasicResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error && responseJson.data) {
            const { reviews } =responseJson.data;
            if(responseJson.data?.catalogue?.data?.attributes){

            
            const { logo,directory_id, name, review_posted,description ,contact, bookmarked,bookmark_id ,similar_listings,domain_url} = responseJson.data.catalogue.data.attributes
            this.setState({ isLoading: false,hideRatingForm:review_posted, logo,similar_listings, name,bookmarked,bookmark_id,domain_url, contactData: contact, description, reviewData: reviews?.data,directory_id })
            }
        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    handleLoadBtn = () => {
        this.setState({ loadmore: true })
    }

    handleUserRate = (event: any, val: any) => {
        this.setState({ ratingValue: val })
    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

        if (token != this.state.token && this.state.token == null) {
            this.setState({ token, userdetails: accountDetails },()=>{
                this.getBasicListData(this.state.catalogueId)
            })
        }


    }

    async componentDidMount() {
        const id = this.getCatalogueId()
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })
        this.getBasicListData(id, this.state.filterValue);
    }

    handleReviewText = (event: any) => {
        this.setState({ reviewText: event.target.value })
    }
    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data.attributes
        this.setState({ editReview: !this.state.editReview, reviewText: comment, ratingValue: rating, editReviewId: id })
    }
    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    } 

    handleBookmark = async () => {
        if (this.state.token) {
            this.setState({ isLoading: true })
            const token = await getStorageData("loginToken")
            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("directory_id", this.state.directory_id);

            const header = {
                token
            };

            const requestMessageBookmark = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.bookmarkApiId = requestMessageBookmark.messageId;

            requestMessageBookmark.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.bookmarkEndPoint}`
            );
            requestMessageBookmark.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessageBookmark.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            requestMessageBookmark.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(requestMessageBookmark.id, requestMessageBookmark);

            return true;
        }
        else {
            this.goToLoginPage()
        }
    }
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };
    handleFilterClick = (event: any, filter: any) => {
        this.setState({ filterValue: filter, anchorEl: null })
        this.getBasicListData(this.state.catalogueId, filter)
    }

    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }

    navigateToAccount= (usertype:any) => {
        let accountType=usertype.toLowerCase()
          if (accountType == "vendor") {
              this.handleNavigation("VendorAccountDetails")
          }else if(accountType == "buyer" ){
              this.handleNavigation("BuyerAccountDetails")      
            }
      }

   

    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
        let formdata = new FormData();
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingValue));
        formdata.append("comment", this.state.reviewText);
        const header = {
            token: this.state.token
        };

        const reviewMessagebasic = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = reviewMessagebasic.messageId;

        reviewMessagebasic.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );
        reviewMessagebasic.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        reviewMessagebasic.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        reviewMessagebasic.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(reviewMessagebasic.id, reviewMessagebasic);
        return true;

    }

    handleAccordion = () => {
        this.setState({ reviewOpen: !this.state.reviewOpen })
    }
    handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingValue));
            formdata.append("comment", this.state.reviewText);

            const header = {
                token: this.state.token
            };

            const reviewMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.postReviewApiId = reviewMessage.messageId;

            reviewMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );
            reviewMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            reviewMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            reviewMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(reviewMessage.id, reviewMessage);
            return true;

        }
        else {
            this.goToLoginPage()
        }

    }

    goToLoginPage =  () => {
        
        this.handleStorageData();
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleStorageData=async()=>{
        await setStorageData("component",JSON.stringify({component:"BasicListing",id:this.state.catalogueId}))

    }

    handleBookmarkDelete= () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
        const requestMessageDelete = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteBookmarkApiId = requestMessageDelete.messageId;
    
        requestMessageDelete.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookmarkDeleteEndpoint+ `${this.state.bookmark_id}`
        );
        requestMessageDelete.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessageDelete.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteMethod
        );
    
        runEngine.sendMessage(requestMessageDelete.id, requestMessageDelete);
        return true;
      };

      handleCloseToast=()=>{
        this.setState({reviewError:false})

      }

      handleAdvancedSearch =  (directory:string) => { 
        this.setState({showDirectoryPopup:false}) 
         const msg: Message = new Message( 
          getName(MessageEnum.NavigateToAdvancedSearch) 
        ); 
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
         msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg); 
    
      }
      handleSubmit=(event:any)=>{ 
        event.preventDefault();
         this.handleAdvancedSearch("All") 
      } 
 
      handleSearchInput = (event:any) =>{   
        this.setState({searchTermProp:  event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory()) 
        if(!event.target.value)  this.setState({showDirectoryPopup:false});
      } 

      
      getSearchResultDirectory = () => { 
        this.setState({isLoading:true}) 
        const header = { 
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );  
    
        this.getSearchResultDataReqId = searchRequestMessage.messageId;  
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
           );  
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
     
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
      );
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
     
    
      } 
      handleDirectorySearch=(responseJson:any)=>{ 
        if(responseJson && responseJson.response){ 
          this.setState({isLoading:false,searchDirectoryData: responseJson.response})
        }
      }
      handleDirectoryPopupClose= ()=> { 
         this.setState({showDirectoryPopup:false}) 
      }
 

    // Customizable Area End





}
// Customizable Area End


Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.defaultSettingEndPoint = "bx_block_recommendation/default_recommendation_setting";
exports.customSettingEndPoint = "bx_block_recommendation/custom_recommendation_setting";
exports.httpGetMethod = "GET";
exports.apiContentType = "application/json";
// Customizable Area End
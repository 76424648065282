import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    Username: any;
    profilePic: string;
    imagePreview: string;
    value: number;
    progressVal: number;
    isOpenEditModal: boolean;
    editProfilePic: any;
    title: string;
    categoryId: any;
    showDirectoryPopup:boolean,
    searchDirectoryData:any,
    searchTermProp:string,
    formDataChanged:boolean,
    openSaveModal:boolean




    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierCustomFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCustomFormidSupplier: string = "";
    form_SubmitidSupplier: string = "";
    getSearchResultApiDataId:string='';

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),

        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            Username: null,
            imagePreview: "",
            value: 0,
            progressVal: 0,
            isOpenEditModal: false,
            profilePic: '',
            editProfilePic: "",
            title: "",
            categoryId: "",
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchTermProp:"",
            formDataChanged:false,
            openSaveModal:false
            


            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);


        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const NavigationData = message.getData(
                getName(MessageEnum.SessionResponseData)
            );
            const idToSet = NavigationData?.data?.catalogue_id;
            if (idToSet !== undefined) {
                this.setState({ categoryId: JSON.stringify(idToSet) })
                await setStorageData('category_id', JSON.stringify(idToSet));
            }
        }


        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestSupplierId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );


            let responseSupplierCustomForm = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestSupplierId == this.getCustomFormidSupplier) {
                const {name,logo}=responseSupplierCustomForm.data.catalogue.data.attributes
                this.setState({title:name,imagePreview:logo})

            }

            if (apiRequestSupplierId == this.form_SubmitidSupplier) {
                this.handleCloseOpenEditModal()
            }
             if(apiRequestSupplierId==this.getSearchResultApiDataId){
                this.handleDirectorySearchResponse(responseSupplierCustomForm)
              }




        }

        // Customizable Area End
    }

    handleSubmitSupplier = async () => {
        const categoryID = this.state.categoryId || await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        formdata.append("name", this.state.title);

        if (this.state.editProfilePic) {
            formdata.append("logo", this.state.editProfilePic);
        }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.form_SubmitidSupplier = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}/supplier`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    getCustomFormSupplier = async () => {
        const category_ID = this.state.categoryId || await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormidSupplier = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${category_ID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async componentDidMount() {
        super.componentDidMount();
        const storedData = await getStorageData("userdetails");
        const accountDetails = await JSON.parse(storedData);
        this.setState({ Username: accountDetails });
        // this.props.incrementProgress(75)
        this.getCustomFormSupplier()

    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,) {
        if (prevState.categoryId !== this.state.categoryId) {
            this.getCustomFormSupplier()
        }
    }

    handleChange = (event: any, newValue: number) => {
        if(this.state.formDataChanged) this.setState({openSaveModal:true})
        else this.setState({ value: newValue });
        
    };

    allProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    incrementProgress = (value: number) => {
        this.setState({ progressVal: value })
    }

    handleOpenEditModal = () => {
        this.setState({ isOpenEditModal: true })
    }

    handleCloseOpenEditModal = () => {
        this.setState({ isOpenEditModal: false })
    }

    supplierVendorAccountNavigation = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    supplierHomePageNavigation = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    headerSupplierNavigation = async () => {
        const usertype = await getStorageData("user_type")
        if (usertype == "vendor" || "Vendor") {
            this.supplierVendorAccountNavigation()
        }
    }

    signoutSupplierUser = async () => {
        await removeStorageData("loginToken")
        this.supplierHomePageNavigation()
    }
    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
    }
    

    onScreenshotUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const reader: any = new FileReader();

        const files = e.target.files;

        if (!files || files.length === 0) {
            return;
        }

        const file = files[0];

        reader.onloadend = () => {
            this.setState({
                editProfilePic: file,
                imagePreview: reader.result
            });

        }
        reader.readAsDataURL(file);
    };

    handleChangeTitle = (event: any) => {
        this.setState({ title: event.target.value })
    }


    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
    }
  
    
      handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }

      getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchResultApiDataId = searchRequestMessage.messageId;
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
    
    
      }
      

      handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
      handleBack=()=>{
        this.setState({value:this.state.value-1})
      }

      handleDataChanged=()=>{
        this.setState({formDataChanged:true})
      }
      handleDataSaved=()=>{
        this.setState({formDataChanged:false})
      }

      handleOpenSaveModal=()=>{
        this.setState({openSaveModal:true})
      }

      handleCloseSaveModal=()=>{
        this.setState({openSaveModal:false})
      }

     
    

    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { line, profilePic, callIcon, emailIcon } from './assets';
// Customizable Area End

// Customizable Area Start
import CustomisableusersubscriptionsController, {
  Props,
} from "./CustomisableusersubscriptionsController";
// Customizable Area End

export class OrderDetail extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  orderDetailGrid = ({ title, value, height }: any) => {
    return (
      <Grid item xs={12}>
        <Box style={{ height: height, display: "flex", justifyContent: "center", borderRight: "1px solid #D9D9D9" }}>
          <Box padding={2}>
            <Typography style={{ color: "#707070", fontSize: "16px", fontWeight: 400, lineHeight: "30px" }}>{title}</Typography>
            <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 500 }}>{value}</Typography>
          </Box>
        </Box>
      </Grid>
    );
  };

  orderSubscriptionGrid = ({ title, value }: any) => {
    return (
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box padding={2} style={{ color: "#000", fontSize: "18px", fontWeight: 500, lineHeight: "14px" }}>
            {title ? <>
              <Typography>{title}</Typography>
              <Typography style={{ fontWeight: 400, lineHeight: "50px" }}>{value}</Typography>
            </> :
              <Button data-test-id="view-btn" variant="outlined" color="primary"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #6200EA",
                  background: "#FFF",
                  textTransform: "capitalize" as const,
                  color: "var(--primary-purple-600, #6200EA)",
                  fontSize: "14px",
                  fontWeight: 400
                }} onClick={() => console.log("clicked")}>
                View
              </Button>
            }
          </Box>
        </Box>
      </Grid>
    );
  };

  lineImg = () => {
    return (
      <>
        <img src={line} style={{ marginTop: "10px", marginRight: "2px" }} />
        <img src={line} style={{ marginTop: "10px", marginRight: "2px" }} />
        <img src={line} style={{ marginTop: "10px", marginRight: "2px" }} />
        <img src={line} style={{ marginTop: "10px", marginRight: "2px" }} />
      </>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Grid item xs={12} >
          <Grid item xs={12} className={classes.orderContainer}>
            {this.orderDetailGrid({
              title: "Order #",
              value: "324234522",
            })}
            {this.orderDetailGrid({
              title: "Order Date",
              value: "01-Sep-23",
            })}
            {this.orderDetailGrid({
              title: "Order Total",
              value: "01-Sep-23",
            })}
            {this.orderDetailGrid({
              title: "",
              value: "",
              height: "100%"
            })}
            {this.orderDetailGrid({
              title: "COMPLETED",
              value: this.lineImg()
            })}
          </Grid>

          <Divider />

          <Grid item xs={12} style={{ margin: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Grid item xs={12} style={{ margin: "20px 50px 20px 20px " }}>
              <Box padding={2} style={{ borderBottom: "1px solid #D9D9D9" }}>
                <Typography className={classes.itemTitle}>ITEMS</Typography>
              </Box>
              <Box padding={2} style={{ display: "flex", flexDirection: "row", marginTop: "20px", borderBottom: "1px solid #D9D9D9" }}>
                <Box style={{ marginBottom: "20px" }}>
                  <img src={profilePic} className={classes.profileImg} />
                </Box>

                <Box style={{ justifyContent: "center", display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                  <Typography className={classes.itemTitle}>Dummy Product</Typography>
                  <Typography className={classes.titleStyle}>Lorem Ipsum</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} style={{ margin: "20px 20px 20px 50px" }}>
              <Box padding={2} style={{ borderBottom: "1px solid #D9D9D9" }}>
                <Typography className={classes.itemTitle}>ORDER TOTAL</Typography>
              </Box>
              <Box padding={2} style={{ display: "flex", flexDirection: "column", marginTop: "20px", borderBottom: "1px solid #D9D9D9" }}>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", margin: "0 50px 10px 50px" }}>
                  <Typography className={classes.titleStyle}>Subtotal</Typography>
                  <Typography className={classes.titleStyle}>$124</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", margin: "0 50px 10px 50px" }}>
                  <Typography className={classes.titleStyle}>Tax</Typography>
                  <Typography className={classes.titleStyle}>$4</Typography>
                </Box>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", margin: "10px 50px 10px 50px" }}>
                <Typography className={classes.itemTitle}>Total</Typography>
                <Typography className={classes.itemTitle}>$128</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ margin: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Grid item xs={12} style={{ margin: "20px 50px 20px 20px " }}>
              <Box padding={2} style={{ borderBottom: "1px solid #D9D9D9" }}>
                <Typography className={classes.itemTitle}>BILLING ADDRESS</Typography>
              </Box>
              <Box padding={2} style={{ display: "flex", flexDirection: "column" }}>
                <Box style={{ marginTop: "5px" }}>
                  <Typography className={classes.addressText}>
                    A-320 New Street. F Block, Stark Aveneue, London -232432
                  </Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", marginTop: "5px", lineHeight: "14px" }}>
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <img src={callIcon} className={classes.callEmailIconImg} />
                    <Typography className={classes.addressText}>+44-24234235</Typography>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <img src={emailIcon} className={classes.callEmailIconImg} />
                    <Typography className={classes.addressText}>peter03@gmail.com</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} style={{ margin: "20px 20px 20px 50px" }}>
              <Box padding={2} style={{ borderBottom: "1px solid #D9D9D9" }}>
                <Typography className={classes.itemTitle}>PAYMENT METHOD</Typography>
              </Box>
              <Box style={{ margin: "20px 50px 10px 50px" }}>
                <Typography className={classes.titleStyle} style={{ fontSize: "18px" }}>Visa Card</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: "50px" }}>
            <Box padding={2} style={{ borderBottom: "1px solid #D9D9D9" }}>
              <Typography className={classes.itemTitle}>RELATED SUBSCRIPTIONS</Typography>
            </Box>
            <Grid item xs={12} className={classes.orderContainer}>
              {this.orderSubscriptionGrid({
                title: "Subscription",
                value: "#53662"
              })}
              {this.orderSubscriptionGrid({
                title: "Status",
                value: "Active"
              })}
              {this.orderSubscriptionGrid({
                title: "Next Payment",
                value: "02-Oct-2023"
              })}
              {this.orderSubscriptionGrid({
                title: "Total",
                value: "$99.00"
              })}
              {this.orderSubscriptionGrid({
                title: "",
                value: ""
              })}
            </Grid>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const userStyles = () => ({
  payTableCellBtn: {
    borderRadius: "8px",
    border: "1px solid #6200EA",
    background: "#FFF",
    textTransform: "capitalize" as const,
    color: "var(--primary-purple-600, #6200EA)",
    fontSize: "14px",
    fontWeight: 400
  },
  subScripBoxStyle: {
    display: "flex",
    justifyContent: "center",
  },
  subscripTitleStyle: {
    color: "#000",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "14px"
  },
  subscripValueStyle: {
    fontWeight: 400,
    lineHeight: "50px"
  },
  callEmailIconImg: {
    marginTop: "4px",
    marginRight: "5px",
    width: "18px",
    height: "18px",
  },
  addressText: {
    maxHeight: "8em",
    color: "#707070",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    width: "50%",
    marginBottom: "10px"
  },
  profileImg: {
    width: "60%",
    height: "auto",
    marginRight: "40px"
  },
  itemTypo: {
    marginBotttom: "20px"
  },
  boxStyle: {
    display: "flex",
    justifyContent: "center",
    borderRight: "1px solid #D9D9D9",
  },
  itemTitle: {
    color: "#000",
    fontSize: "18px",
    fontWeight: 500,
  },
  itemTitleBox: {
    color: "#000",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "14px",
    marginBotton: "60px",
    borderBottom: "1px solid #D9D9D9",
  },
  itemContainer: {
    margin: "40px 20px"
  },
  lineImg: {
    marginTop: "10px",
    marginRight: "2px"
  },
  orderContainer: {
    display: "flex",
  },
  titleStyle: {
    color: "#707070",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px"
  },
  subTitleStyle: {
    color: "#000",
    fontSize: "18px",
    fontWeight: 500
  }
});
// Customizable Area End

export default withStyles(userStyles)(OrderDetail);
import React from "react";
// Customizable Area Start
import "./index.css";

import JoinUsLandingPageController, {
  Props,
  configJSON,
  mockPaths
} from "./JoinUsLandingController.web";

import {
  joinUsEventsImg,
  joinUsServiceImg,
  joinUsSuppliers,
  joinUsSupplierBtnImg,
  joinUsSoftwareImg,
  topheaderlogo,
  joinUsIllustration,
  dummyImage,
  downArrow
} from "./assets";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box, Paper, Container, Avatar, Popover } from "@material-ui/core";
import { userStyles } from "./LandingPage.web";
// Customizable Area End



export class JoinUsLandingPage extends JoinUsLandingPageController {



  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { getContents } = this.state

    return (
      <Box style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column",marginTop:"3%" }}>
        <Paper className={classes.landingBanner}>

          <Container className={classes.container} >
            <Box sx={classes.landingHeader}>
              <Grid container spacing={2} className={classes.headingGridContainer}>
                <Grid item xs={12}>
                  <Box className={classes.pathBlock}>
                    {mockPaths.map((path, index) => {
                      const isPathLast = index === mockPaths.length - 1
                      return (
                        <>
                          <Typography
                            data-testid={"mockpath"}
                            onClick={() => this.goToMockPath(path.path)}
                            className={classes.paths}
                            style={{ color: isPathLast ? "#BCBCBC" : "#FFFFFF" }}
                            key={path.name}>{path?.name}</Typography>
                          {!isPathLast && <Typography className={classes.paths} style={{ margin: "0 5px" }}>{"/"}</Typography>}
                        </>
                      )
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.landingHeaderRow}>
                  <img data-testid={"gotoHome"} onClick={this.navigateToHome} src={topheaderlogo} alt="Logo" className={classes.topheaderlogo} />
                  {this.state.loginToken ?
                    <Box className={classes.avatarWrapper} id="profileMenu">
                      <Avatar alt="Tony" src={this.state.Username?.profile_image || dummyImage} className={classes.avatar} />
                      <Box className={classes.textWrapper}>
                        <Typography variant="subtitle1" className={classes.welcomeText}>
                          {"Welcome Back"}
                        </Typography>
                        <Box className={classes.nameAndArrow} onClick={this.handleProfileMenuOpen} data-testid={"openPopover"}>
                          <Typography className={classes.nameText}>{this.state.Username?.full_name}</Typography>
                          <img src={downArrow} className={classes.downArrowIcon} />
                        </Box>
                        <Popover
                          data-testid={"closePopover"}
                          open={Boolean(this.state.popoverAnchorEl)}
                          anchorEl={this.state.popoverAnchorEl}
                          onClose={this.handlePopoverClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Box className={classes.profilePaper}>
                            <Typography className={classes.fontsStyle} onClick={this.handleHeaderNavigation} data-testid={"gotoprofile"}>My Profile</Typography>
                            <Typography className={classes.fontsStyle} onClick={this.handleUserSignout} data-testid={"signout"}>Sign Out</Typography>
                          </Box>
                        </Popover>
                      </Box>
                    </Box>
                    :
                    <Button onClick={this.goToLoginPage} data-testId="signInBtn" className={classes.signinBtn}>{configJSON.btnSignin}</Button>
                  }
                </Grid>
              </Grid>
            </Box>

          </Container>
          <Box className={classes.joinUsHeaderFull}>
            <Box className={classes.landingAbout}>
              <Grid className={classes.aboutContainer} container >
                <Grid item sm={6} xs={12} >
                  <Typography variant="h2" className={classes.joinUsTitle}>{configJSON.joinUsHeaderTitle}</Typography>
                  <Typography variant="body1" data-testid={"title_1"} className={classes.joinUsSubtitle}>{getContents?.data[0]?.attributes?.title}</Typography>
                  <Typography variant="body1" className={classes.landingAboutDetail}>{getContents?.data[0]?.attributes?.subtitle}</Typography>
                </Grid>
                <Grid item sm={6} xs={12} className={classes.joinUsIllusImgGrid} style={{ paddingLeft: "60px" }}>
                  <img src={joinUsIllustration} alt="Illustration" className={classes.joinUsIllusImage} />
                </Grid>

              </Grid>
              <Box className={classes.joinUsTextContainer}>
                <Typography className={classes.joinUsDirectoriesText} variant="h3">{getContents?.data[1]?.attributes?.title}</Typography>
                <Typography className={classes.joinUSDirectoriesDescription} variant="body1">{getContents?.data[1]?.attributes?.text}</Typography>
              </Box>

            </Box>

            <Box className={classes.joinUsRestaurantSuppliers}>
              <Box className={`${classes.restaurantSuppliersRow} ${classes.suppliersRow}`}>
                <Grid container className={classes.illustrationImgGrid} >
                  <Grid item lg={5} md={6} sm={12} className={`${classes.illustration1} ${classes.illustration1Supplier}`}>
                    <img src={joinUsSuppliers} alt="Illustration 1" className={classes.illustration1Img} />
                  </Grid>
                  <Grid item lg={7} md={6} sm={12} className={classes.resturantSupplierContent}>
                    <Typography variant="h2" className={classes.joinUsSuppliersTitle}>
                      {getContents?.data[2]?.attributes?.title}
                    </Typography>
                    <Typography variant="body1" className={`${classes.joinUsSuppliersSubtitle} ${classes.supplierSubTitle}`}>
                      {getContents?.data[2]?.attributes?.subtitle}
                    </Typography>
                    <Typography variant="body1" className={classes.joinUsSuppliersDetail}>
                      {getContents?.data[2]?.attributes?.text}
                    </Typography>
                    <Button
                      onClick={() => this.goToSupplier('Suppliers')}
                      data-testid={"supplier-button"}
                      className={`${classes.joinUsSupplierBtn} ${classes.supplierBtn}`}>
                      <img className={classes.joinUsSupplierBtnImg} src={joinUsSupplierBtnImg} />
                      {configJSON.joinUsSupplierBtnText}</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Box className={`${classes.joinUsSoftwareSections} `}>
          <Box className={`${classes.restaurantSuppliersRow} ${classes.softwarerow}`}>
            <Grid container >
              <Grid item lg={7} md={6} sm={12} className={classes.weeklyChitContent}>
                <Typography variant="h2" className={classes.joinUsSuppliersTitle}>
                  {getContents?.data[4]?.attributes?.title}
                </Typography>
                <Typography variant="body1" className={`${classes.softwareSubtitle} ${classes.joinUsSuppliersSubtitle}`} >
                  {getContents?.data[4]?.attributes?.subtitle}
                </Typography>
                <Typography variant="body1" className={classes.joinUsSuppliersDetail}>
                  {getContents?.data[4]?.attributes?.text}
                </Typography>
                <Button
                  onClick={() => this.goToSupplier('Software')}
                  data-testid={"softwares-button"}
                  className={`${classes.joinUsSupplierBtn} 
                  ${classes.softwareBtn}`}>
                  <img className={classes.joinUsSupplierBtnImg} src={joinUsSupplierBtnImg} />
                  {configJSON.joinUsSoftwareBtnText}</Button>
              </Grid>
              <Grid item lg={5} md={6} sm={12} className={`${classes.illustration2} ${classes.illustration2Grid}`}>
                <img className={`${classes.illustration1Img} ${classes.joinUsSoftwareImg}`} src={joinUsSoftwareImg} alt="Illustration 2" />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.joinUsSections}>
          <Box className={`${classes.restaurantSuppliersRow} ${classes.servicesRow}`}>
            <Grid container className={classes.illustrationImgGrid} >
              <Grid item lg={5} md={6} sm={12} className={`${classes.illustration1} ${classes.illustration1Supplier}`}>
                <img src={joinUsServiceImg} alt="Illustration 1" className={classes.illustration1Img} />
              </Grid>
              <Grid item lg={7} md={6} sm={12} className={classes.resturantSupplierContent}>
                <Typography variant="h2" className={classes.joinUsSuppliersTitle}>
                  {getContents?.data[3]?.attributes?.title}
                </Typography>
                <Typography variant="body1" className={`${classes.joinUsSuppliersSubtitle} ${classes.servicesSubTitle}`}>
                  {getContents?.data[3]?.attributes?.subtitle}

                </Typography>
                <Typography variant="body1" className={classes.joinUsSuppliersDetail}>
                  {getContents?.data[3]?.attributes?.text}
                </Typography>
                <Button onClick={() => this.goToSupplier('Services')}
                  data-testid={"services-button"}
                  className={`${classes.joinUsSupplierBtn} 
                  ${classes.servicesBtn}`}>
                  <img className={classes.joinUsSupplierBtnImg}
                    src={joinUsSupplierBtnImg} />{configJSON.joinUsServicesBtnText}</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={`${classes.joinUsEventsSections} `}>
          <Box className={`${classes.restaurantSuppliersRow} ${classes.eventsrow}`}>
            <Grid container >
              <Grid item lg={7} md={6} sm={12} className={classes.weeklyChitContent}>
                <Typography variant="h2" className={classes.joinUsSuppliersTitle}>
                  {getContents?.data[5]?.attributes?.title}
                </Typography>
                <Typography variant="body1" className={`${classes.eventsSubtitle} ${classes.joinUsSuppliersSubtitle}`} >
                  {getContents?.data[5]?.attributes?.subtitle}</Typography>
                <Typography variant="body1" className={classes.joinUsSuppliersDetail}>
                  {getContents?.data[5]?.attributes?.text}
                </Typography>
              </Grid>
              <Grid item lg={5} md={6} sm={12} className={`${classes.illustration2} ${classes.illustration2Grid}`}>
                <img className={`${classes.illustration1Img} ${classes.joinUsEventsImg}`} src={joinUsEventsImg} alt="Illustration 2" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

export const joinUsStyles = () => ({
  ...userStyles(),
  topHomeLink: {
    fontSize: "16px",
    lineHeight: "14px",
    color: "white",
    fontFamily: "Poppins"
  },
  topJoinUsLink: {
    fontSize: "16px",
    lineHeight: "14px",
    color: "#313131",
    fontFamily: "Poppins"
  },
  topBreadCrum: {
    marginBottom: "13px",
    color: "white"
  },

  paths: {
    fontSize: '14px',
    fontWeight: 400,
    color: "#FFFFFF",
    cursor: "pointer",
    fontFamily: "Poppins"
  },

  pathBlock: {
    display: "flex",
  },

  joinUsSoftwareSections: {
    margin: "42px 0 21px 0",
    display: "block",
    fontFamily: "Poppins",
    "@media(max-width: 600px)": {
      margin: "40px 0",
    },
  },
  joinUsEventsSections: {
    margin: "21px 0 152px 0",
    display: "block",
    fontFamily: "Poppins",
    "@media(max-width: 600px)": {
      margin: "40px 0",
    },
  },
  joinUsSections: {
    margin: "21px 0",
    display: "block",
    fontFamily: "Poppins",
    "@media(max-width: 600px)": {
      margin: "40px 0",
    },
  },
  joinUsRestaurantSuppliers: {
    display: "block",
    fontFamily: "Poppins",
    "@media(max-width: 600px)": {
      margin: "40px 0",
    },
  },
  joinUsHeaderFull: {
    background:
      "linear-gradient(0deg, rgba(255,255,255,.90) 28%, rgba(255,255,255,0) 100%)",

    width: "100vw"
  },
  joinUsTitle: {
    color: "#333",
    fontSize: "52px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "80.956px",
    margin: "0 0 26px 0",
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      fontSize: "38px",
    },
  },

  joinUsSubtitle: {
    color: "#FFF1FD",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "35px",
    margin: "0 0 26px 0",
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      fontSize: "28px",
    },

  },
  joinUsIllusImage: {

  },
  joinUsIllusImgGrid: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 600px)": {
      marginTop: "50px",
    }
  },
  joinUsTextContainer: {
    textAlign: "center" as "center",
    marginTop: "117px",
    marginBottom: "77px"
  },
  joinUsDirectoriesText: {
    fontSize: "48px",
    lineHeight: "80.96px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#333"
  },
  joinUSDirectoriesDescription: {
    fontSize: "24px",
    lineHeight: "40.48px",
    fontWeight: 300,
    fontFamily: "Poppins",
    color: "#313131"
  },

  suppliersRow: {
    backgroundColor: "#FFF5DC"
  },
  joinUsSuppliersTitle: {
    color: "#333",
    fontSize: "48px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 500,
    lineHeight: "80.956px",
    "@media(max-width: 992px)": {
      marginTop: "30px"
    },

    "@media(max-width: 606px)": {

      lineHeight: "60px",

    }
  },
  joinUsSuppliersSubtitle: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontStyle: "normal",
    lineHeight: "35px",
    marginTop: "46px",
    "@media(max-width: 606px)": {
      fontSize: "24px",
      // marginTop: "0px",
    }
  },
  supplierSubTitle: {
    color: "#FFBB13",
    fontWeight: 300,
  },
  joinUsSuppliersDetail: {
    color: "#666",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "30px",
    marginTop: "30px",
  },
  joinUsSupplierBtn: {
    borderRadius: "8px",
    padding: "15px",
    color: "#FFF",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    display: "block",
    border: 0,
    marginTop: "51px",
    cursor: "pointer",
    width: "fit-content",
    textTransform: "none" as "none",
    "& span": {
      display: "flex"
    },
    '&:hover': {
      backgroundColor: '#B300ED',

    },

  },
  supplierBtn: {
    background: "#FFBB13",
  },
  joinUsSupplierBtnImg: {
    marginRight: "8px"
  },
  softwarerow: {
    backgroundColor: "FFECF5"
  },
  softwareSubtitle: {
    color: "#F62B8D",
    marginTop: "16px !important",
    fontWeight: 600,
  },
  joinUsSoftwareImg: {
    paddingLeft: "50px"
  },
  softwareBtn: {
    background: "#F62B8D"
  },
  servicesSubTitle: {
    color: "#6BCAD3",
    fontWeight: 300,
  },
  servicesBtn: {
    background: "#6BCAD3"
  },
  servicesRow: {
    backgroundColor: "#F0F7F8"
  },
  eventsrow: {
    backgroundColor: "F5F1FB"
  },
  eventsBtn: {
    background: "#7A4BD3"
  },
  eventsSubtitle: {
    color: "#7A4BD3",
    fontWeight: 600,
  },
  joinUsEventsImg: {
    padding: "100px"
  },

  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '30px',
    padding: '10px',

    "@media (min-width: 300px) and (max-width: 950px)": {
      marginRight: '5px',
      padding: '3px',
    }
  },
  avatar: {
    marginRight: '8px',
    height: '56px',
    width: '56px',

    "@media (min-width: 300px) and (max-width: 950px)": {
      marginRight: '5px',
      height: '30px',
      width: '30px'
    }
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column' as const,
    marginLeft: '8px',
  },
  welcomeText: {
    marginBottom: '0px',
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "Poppins",

    "@media (min-width: 300px) and (max-width: 950px)": {
      fontSize: '5px',
      fontWeight: 300,
    }
  },
  nameText: {
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "Poppins",

    "@media (min-width: 300px) and (max-width: 950px)": {
      fontSize: '8px',
      fontWeight: 300,
    }
  },
  downArrowIcon: {
    marginLeft: '4px',
    width: '10px',
    height: '8px',
    cursor: 'pointer',

    "@media (min-width: 300px) and (max-width: 950px)": {
      width: '5px',
      height: '5px',
    }
  },
  nameAndArrow: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(joinUsStyles)(JoinUsLandingPage);
// Customizable Area End
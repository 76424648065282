// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean,
    accordionTab: number,
    servicesReview: any,
    loadmore: boolean,
    name: any,
    contactData: any,
    categories: any,
    servicesfiles: any,
    servicesaboutOpen: boolean,
    servicescategoriesOpen: boolean,
    servicesfilesOpen: boolean
    servicesreviewAccordion: boolean,
    contactAccordion: boolean
    markets: any,
    domain_url:string,
    isLoading: boolean
    catalogueId: any,
    token: any,
    logo: any,
    about: any,
    tab: number,
    hideRatingForm: boolean,
    editReview: boolean,
    userdetails: any,
    anchorEl: any,
    ratingVal: number,
    directory_id:any,
    reviewComment: string,
    editReviewId: any,
    filterValue:string,
    bookmark_id: any,
    reviewAlert:boolean,
    bookmarked:boolean,
    description:string,
    featured:boolean,
    searchTermProp:string,
    searchDirectoryData:any,
    showDirectoryPopup:boolean,
    moreCategories:boolean,
    claim_status:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PremiumListingServiceController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getServicesCatalogueDataApiId: any;
    servicesbookmarkApiId: any;
    premiumpostReviewApiId: any;
    reviewUpdateApiId: any;
    deleteBookmarkApiId:any;
    getSearchResultDataReqId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            name: "",
            loading: false,
            accordionTab: 0,
            servicesReview: [],
            loadmore: false,
            contactData: {
                "mobile": "",
                "address": "",
                "website": "https://www.landmarskstudio.com"
            },
            categories: [],
            servicesfiles: [],
            servicesaboutOpen: true,
            servicescategoriesOpen: false,
            servicesfilesOpen: false,
            servicesreviewAccordion: true,
            contactAccordion: true,
            markets: [],
            isLoading: false,
            catalogueId: null,
            token: null,
            logo: "",
            about: {},
            tab: 0,
            directory_id:null,
            hideRatingForm: false,
            editReview: false,
            userdetails: {},
            anchorEl: null,
            ratingVal: 0,
            reviewComment: "",
            bookmark_id:null,
            editReviewId: null,
            filterValue:"recent",
            bookmarked:false,
            domain_url:"",
            reviewAlert:false,
            description:"",
            featured:false,
            searchTermProp:"",
            searchDirectoryData:[],
            showDirectoryPopup:false,
            moreCategories:false,
            claim_status:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const messageid = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (this.getServicesCatalogueDataApiId === messageid) {
            this.handleCatalogueResponse(responseJson, errorReponse)
        }
        else if (this.servicesbookmarkApiId === messageid) {
            this.setState({ isLoading: false })
            this.getServicesCatalogueData(this.state.catalogueId)
        }
        else if (this.reviewUpdateApiId === messageid) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getServicesCatalogueData(this.state.catalogueId)
        }
        else if (this.premiumpostReviewApiId === messageid) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getServicesCatalogueData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({reviewAlert: true, isLoading: false })
               
            }
           
            
        }
        else if(this.deleteBookmarkApiId===messageid){
            this.setState({ isLoading:false })
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if(messageid==this.getSearchResultDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
          }

    }

    async componentDidMount() {

        const id = this.getCatalogueId();
        this.getServicesCatalogueData(id,this.state.filterValue);
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })
    }
    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data &&  data.attributes
        this.setState({ editReview: !this.state.editReview, reviewComment: comment, ratingVal: rating, editReviewId: id })
    }

    colorDetect = (val: number) => {
        if (this.state.tab === val) {
            return "#B300ED"
        } else {
            return '#000'
        }
    };

    handleToggle = (accordion: any) => {
        if (accordion == "servicesaboutOpen") {
            this.setState({ servicesaboutOpen: !this.state.servicesaboutOpen })
        }
        else if (accordion == "servicescategoriesOpen") {
            this.setState({ servicescategoriesOpen: !this.state.servicescategoriesOpen })
        }
        else if (accordion == "servicesfilesOpen") {
            this.setState({ servicesfilesOpen: !this.state.servicesfilesOpen })
        }
        else if (accordion == "servicesreviewAccordion") {
            this.setState({ servicesreviewAccordion: !this.state.servicesreviewAccordion })
        }
        else if (accordion == "contactAccordion") {
            this.setState({ contactAccordion: !this.state.contactAccordion })
        }

    }
    handleRating = (event: any, val: any) => {
        this.setState({ ratingVal: val })
    }
    handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingVal));
            formdata.append("comment", this.state.reviewComment);

            const header = {
                token: this.state.token
            };

            const servicepostreviewMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.premiumpostReviewApiId = servicepostreviewMessage.messageId;

            servicepostreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
            servicepostreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            servicepostreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            servicepostreviewMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );


            runEngine.sendMessage(servicepostreviewMessage.id, servicepostreviewMessage);
            return true;

        }
        else {
            this.goToLoginPage()
        }

    }
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };
    handleFilterClick=(event:any,filter:any)=>{
        this.setState({filterValue:filter,anchorEl:null})
        this.getServicesCatalogueData(this.state.catalogueId,filter)
    }

    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }

    getServicesCatalogueData = async (id: any,filter?:any) => {

        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken") || ""

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

        const endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filter??this.state.filterValue}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        this.getServicesCatalogueDataApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleCatalogueResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            const { reviews } = responseJson.data;
            if(responseJson.data?.catalogue?.data?.attributes){

            
            const { logo, name,markets,featured,directory_id,description ,domain_url,contact, review_posted,categories,bookmarked,bookmark_id,features_pdf,claim_status } = responseJson.data.catalogue.data.attributes
            this.setState({ isLoading: false,description,servicesfilesOpen:this.checkForLength(features_pdf),servicescategoriesOpen:this.checkForLength(categories),featured,directory_id,hideRatingForm:review_posted,markets,domain_url,bookmarked,bookmark_id, servicesfiles: features_pdf, logo, name, contactData: contact, servicesReview: reviews?.data, categories,claim_status })
            this.checkAboutClose(markets,description);
            this.checkContactClose(contact);
          
            }
        } else {
            this.setState({isLoading: false})
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

       
           this.handleUpdate(token,accountDetails)
        


    }
    checkAboutClose=(markets:any,description:string)=>{
        if(!markets.length && !description) this.setState({servicesaboutOpen:false})
    }
    checkForLength=(data:any)=>{
        return data && data.length
    }

    checkContactClose=(contactData:any)=>{
        if(!contactData.phonenumber && !contactData.address && !contactData.contactus) this.setState({contactAccordion:false})

    }
    handleUpdate=(token:any,accountDetails:any)=>{
        if (token != this.state.token && this.state.token == null) {
        this.setState({ token, userdetails: accountDetails },()=>{
            this.getServicesCatalogueData(this.state.catalogueId)
        })
    }
    }

    
    handleLoadMore = () => {
        this.setState({ loadmore: true })
    }

    handleBookmarkClick = async () => {

        if(this.state.token){
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken")
        let formdata = new FormData();
        formdata.append("catalogue_id", this.state.catalogueId);
        formdata.append("directory_id", this.state.directory_id);


        const header = {
            token
        };

        const servicesbookmarkmessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.servicesbookmarkApiId = servicesbookmarkmessage.messageId;

        servicesbookmarkmessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.bookmarkEndPoint}`
        );
        servicesbookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );
        servicesbookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        servicesbookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(servicesbookmarkmessage.id, servicesbookmarkmessage);

        return true;
        }
        else {
            this.goToLoginPage();
        }
    }
    goToLoginPage = async () => {
        this.handleSignUp();
      const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    handleSignUp=async()=>{
        await  setStorageData("component",JSON.stringify({component:"PremiumListingService",id:this.state.catalogueId}))

}
    
    handleTabChange = (event: any, newValue: number) => {
        const element:any = window.document.getElementById(`${newValue}-tab`);
        element?.scrollIntoView();
        this.setState({ tab: newValue });
    };

    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
      
        const header = {
            token: this.state.token
        };
        let formdata = new FormData();
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingVal));
        formdata.append("comment", this.state.reviewComment);

        const serviceupdateMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = serviceupdateMessage.messageId;
        serviceupdateMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        serviceupdateMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        serviceupdateMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );
        serviceupdateMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );


        runEngine.sendMessage(serviceupdateMessage.id, serviceupdateMessage);
        return true;

    }

    handleReviewComment = (event: any) => {
        this.setState({ reviewComment: event.target?.value })
    }
    handleBookmarkDeleteResponse=(responseJson:any,errorReponse:any)=>{
        if(responseJson && responseJson.success){
            this.setState({bookmarked:false})
        }
        else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    navigateToAccount= (userData:any) => {
        let data=userData.toLowerCase();
        if (data== "vendor") {
            this.handleNavigation("VendorAccountDetails")
        }else if(data=="buyer" ){
            this.handleNavigation("BuyerAccountDetails")      
          }
    }
    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    } 
    handleBookmarkDelete= () => {
        this.setState({isLoading:true})
        const header = {
            "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
        const requestMessagebookmarkDeleteApi = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteBookmarkApiId = requestMessagebookmarkDeleteApi.messageId;
    
        requestMessagebookmarkDeleteApi.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookmarkDeleteEndpoint+ `${this.state.bookmark_id}`
        );
        requestMessagebookmarkDeleteApi.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessagebookmarkDeleteApi.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteMethod
        );
    
        runEngine.sendMessage(requestMessagebookmarkDeleteApi.id, requestMessagebookmarkDeleteApi);
        return true;
    };
    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleReviewAlert=()=>{
        this.setState({reviewAlert:false})
    }


    handleSubmit=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
    }
    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }

      handleDirectorySearch=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({isLoading:false,searchDirectoryData:responseJson.response})
        }
      }
    handleAdvancedSearch = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }  

      getSearchResultDirectory = () => {
        
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };

        this.setState({isLoading:true})
    
      const searchdataRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchResultDataReqId = searchdataRequestMessage.messageId;
        
        searchdataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
        searchdataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
      searchdataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
      runEngine.sendMessage(searchdataRequestMessage.id, searchdataRequestMessage);
    
    
      }  


      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
     
      


    // Customizable Area End





}
// Customizable Area End


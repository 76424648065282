import React from "react";

// Customizable Area Start
import { Typography, Button, Box, Switch, Theme, Dialog, Grid, } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { logoImage, searchIcon, personImage, downArrow, closeIcon, rightCheck } from './assets';

// Customizable Area End

// Customizable Area Start
import PlanSelectionController, {
    Props
} from "./PlanSelectionController";
import AppBarPage from "../../../components/src/AppBar.web";
// Customizable Area End

// Customizable Area Start
const planSelectTheme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#fff",
        },
    },
    typography: {
        subtitle1: {
            margin: "20px 0px",
        },
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        fontSize: 18
    },
    overrides: {
        MuiSwitch: {
            colorSecondary: {
                "&$checked": {
                    color: "#FFF"
                }
            },
            track: {
                "$checked$checked + &": {
                    opacity: 1,
                    backgroundColor: "#8833FF",
                },
                opacity: 1,
                backgroundColor: "#8833FF",
                borderRadius: 40,
            },
            root: {
                padding: 6
            },
        },
    },
});

// For testing purpose 
const customPlan = {
    detailsDesc: "Showcase your business with rich media and drive traffic to your website",
    price: 29.99,
    features: [
        { name: "Logo", includeds: true },
        { name: "Short Description", includeds: true },
        { name: "Contact Info", includeds: true },
        { name: "Similar Listings", includeds: false },
        { name: "Integrations", includeds: true },
        { name: "Distributors/Brands Linking", includeds: true },
        { name: "Events I'm Attenging", includeds: true },
        { name: "Photos", includeds: true },
        { name: "Videos", includeds: true },
        { name: "Support Links", includeds: true },
        { name: "Call to action button", includeds: true },
        { name: "Verified Check", includeds: true },
        { name: "Upload reviews from Google", includeds: false },
        { name: "Respond to Reviews", includeds: true },
        { name: "Analytic Dashboard", includeds: true },
        { name: "Catalog Upload (required for item specific searching, Suppliers directory only)", includeds: true },
        { name: "In Good Company Badges", includeds: true },
        { name: "PR Marketing Deck", includeds: true },
    ],
    extra: [
        { name: "Featured Listing", value: "Extra $$" },
        { name: "Hosting Events", value: "Extra $$" }
    ]
}
const mockPlans: any = [
    {
        planTitle: "BASIC",
        detailsDesc: "Everything you need to get started to showcase your product to restaurants",
        features: [
            { name: "Logo", includeds: true },
            { name: "Short Description", includeds: true },
            { name: "Contact Info", includeds: true },
            { name: "Similar Listings", includeds: true },
            { name: "Integrations", includeds: true },
            { name: "Distributors/Brands Linking", includeds: true },
            { name: "Events I'm Attenging", includeds: true },
            { name: "Photos", includeds: true },
            { name: "Videos", includeds: false },
            { name: "Support Links", includeds: false },
            { name: "Call to action button", includeds: false },
            { name: "Verified Check", includeds: false },
            { name: "Upload reviews from Google", includeds: false },
            { name: "Respond to Reviews", includeds: false },
            { name: "Analytic Dashboard", includeds: false },
            { name: "Catalog Upload (required for item specific searching, Suppliers directory only)", includeds: false },
            { name: "In Good Company Badges", includeds: false },
            { name: "PR Marketing Deck", includeds: false },
        ],
        extra: [
            { name: "Featured Listing", value: "Not available" },
            { name: "Hosting Events", value: "Extra $$" }
        ]
    },
    {
        planTitle: "ENHANCED",
        ...customPlan
    },
    {
        planTitle: "GLOBAL",
        ...customPlan
    },
]
const mockTabs = ["Supplier", "Softwares", "Services", "Events"]
const planColors = [{ main: "#6CC9D1", secondary: "#E4FDFF" }, { main: "#F62B8D", secondary: "#FFEEF6" }, { main: "#5C15C2", secondary: "#F6F0FF" }]

// Customizable Area End

export class PlanSelection extends PlanSelectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const filteredPlanSelection: any = this.state?.planDetails.filter((elm: any) => { 
            if (this.state.selectedTab === "Supplier") {
                return elm.data?.directory_name === 'Suppliers';
            } else if (this.state.selectedTab === "Softwares")
            {
                return elm.data?.directory_name === 'Software';
            } else if (this.state.selectedTab === "Services") 
            {
                return elm.data?.directory_name === 'Services';
            }

            return true;
        });
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
               <AppBarPage
                    // handleClick={this.goToLoginPage}
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    username={this.state.Username}
                    dummyicon={this.state.Username?.profile_image || personImage}
                    downarrow={downArrow}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleSearchInput={this.handleSearchInput}
                    handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                    handleSubmit={this.handleSubmitSearch}
                    data-testId={"signInBtn"}
                    goToHomePage={this.navigateToHomeScreen}
                    handleHeaderNavigation={this.navigateToVendor}
                    handleUserSignout={this.handleUserSignout}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                <ThemeProvider theme={planSelectTheme}>
                    <Box className={classes.billingBlock}>
                        <Box className={classes.planMainBlock}>
                            <Typography className={classes.paths}>Select Plan</Typography>
                        </Box>
                        <Box className={classes.tabsBlock}>
                            {mockTabs.map(tab => { const isTabSelected = this.state.selectedTab === tab
                                return (<Typography
                                        className={classes.tabItem}
                                        style={{ 
                                            background: isTabSelected ? "#B300ED" : "none",
                                         color: isTabSelected ? "#FFF" : "#2A0066"
                                         }}
                                        data-test-id={`tab${tab}`}
                                        key={tab}
                                    >{tab}
                                    </Typography>
                                )
                            })}
                        </Box>
                        <Box className={classes.description}>
                            <Typography align="center">
                                Whether you're in the business of food, beverage, table linens,
                                restaurant cleaning supplies or even merchandise, we're your one
                                stop connection to the industry folks doing the ordering and planning.
                                Our supplier directories and listings are where the rubber meets the
                                road for the food and beverage community. Claim your listing today.
                            </Typography>
                        </Box>
                        <Box className={classes.switchBlock}>
                            <Typography style={{ paddingTop: 6 }}>Monthly</Typography>
                            <Switch checked={this.state.isYearlyPlanSelected} onChange={this.handlePlanChange} data-testid="switchPlan" />
                            <Typography style={{ paddingTop: 6 }}>Yearly</Typography>
                        </Box>
                        <Box className={classes.plansBlock}>
                            {filteredPlanSelection && filteredPlanSelection.length && filteredPlanSelection[0].data.subscriptions?.filter((plan: any) => plan.type_of === `${!this.state.isYearlyPlanSelected ? "monthly" : "yearly"}`).map((plan: any, planIndex: number) => {
                                return (
                                    <Box key={plan.planTitle} data-testid={"planTitle"}>
                                        <Box className={classes.planHeader} style={{ background: planColors[planIndex].main }}>
                                            <Typography variant="h5" data-testid={"planTitle"} className={classes.planTitle}>{plan.name}</Typography>
                                            <Typography className={classes.planDescription}>{plan.description}</Typography>
                                        </Box>
                                        <Box className={classes.priceBlock} style={{ color: planColors[planIndex].main }}>
                                            {plan.price != "0.0" ?
                                                <>
                                                    <Typography variant="h5" style={{ fontWeight: 600 }}> {`$ ${plan.price}`} </Typography>
                                                    <Typography variant="h5" style={{ fontWeight: 400, fontSize: "16px" }}>{`${!this.state.isYearlyPlanSelected ? "MONTHLY" : "YEARLY"}`}</Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography variant="h5" style={{ fontWeight: 600 }}> FREE</Typography>
                                                    <Typography variant="h5" style={{ fontWeight: 400, fontSize: "16px" }}> ALWAYS</Typography>
                                                </>
                                            }
                                        </Box>
                                        <Box className={classes.planBody}>
                                            <Box className={classes.featureBlock}>
                                                {plan.subscription_features.map((feature: any) => (
                                                    <Box className={classes.featureItem} key={feature.name}>
                                                        {feature.active ? <CheckIcon htmlColor="#059669" /> : <CloseIcon htmlColor="#DC2626" />}
                                                        <Typography>{feature.name}</Typography>
                                                    </Box>

                                                ))}
                                            </Box>
                                            <Box>
                                                {plan.subscription_events.map((item: any) => (
                                                    <Box
                                                        className={classes.extraPlanBlock}
                                                        style={{ background: planColors[planIndex].secondary }}
                                                        key={item.name}
                                                    >
                                                        <Typography>{item.name}</Typography>
                                                        <Typography style={{ fontSize: 14 }}>{item.description}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                            <Box className={classes.buttonPlanBlock}>
                                                <Button className={classes.selectButton} data-test-id={"planselectId"} style={{ background: planColors[planIndex].main }} onClick={() => this.handleSelectPlan(plan, this.state.selectedTab)} >Select</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                        <Dialog open={this.state.isModalOpen}
                                maxWidth="md"
                                PaperProps={{ className: classes.dialogStyles }}
                                // onClose={this.handleModalClose}
                                data-testId={"openModal"}
                            >
                                <Grid container>
                                    <Grid item xs={12} className={classes.cancelGridStyle}>
                                        <img
                                            data-testid={"closeinstruction"}
                                            src={closeIcon}
                                            onClick={this.handleModalClose}
                                            className={classes.closeIconStyle}
                                        />
                                    </Grid>
                                </Grid>
                                <Box className={classes.dialogBoxStyle}>
                                    <img
                                        src={rightCheck}
                                        className={classes.rightTickIcon}
                                    />
                                    <Typography className={classes.welcomeFontStyle}>Congratulations !</Typography>
                                    <Typography className={classes.welcomeFontStyle2}>Your plan has been updated to BASIC</Typography>    
                                    <Button
                                    data-test-id="backtoVP"
                                        variant="contained"
                                        fullWidth
                                        className={classes.buttonStyle}
                                        onClick={this.navigatevendorAccount}
                                    >
                                        Back to accounts
                                    </Button>
                                </Box>
                        </Dialog>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const planStyles: any = (theme: Theme) => ({
    plansBlock: {
        gap: 30,
        justifyContent: "space-between",
        margin: "0 6vw", display: "flex",

        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.down('xs')]: {
            display: "block",
        },
    },
    welcomeFontStyle: {
        fontSize: '24px',fontWeight: 700,
        fontFamily: 'Poppins', lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    welcomeFontStyle2: {
        fontSize: '18px', fontWeight: 400,
        fontFamily: 'Poppins',lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    rightTickIcon: {
        height: '75px', width: '75px'
    },
    dialogBoxStyle: {
        display: 'flex',   flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center', gap: '20px',
        marginBottom: '40px'
    },
    dialogStyles: {
        width: '529px',overflow: 'hidden',
        borderRadius: '8px 8px 32px 8px',  alignItems: 'center',
        backgroundColor: ' #FFFFFF',
        justifyContent: 'center',
        display: 'flex',
    },
    closeIconStyle: {
        cursor: 'pointer',
        width: '32px',
        padding: '10px',
        height: '32px',
    },
    planHeader: {
        textAlign: "center" as "center",
        padding: "0 50px",
        borderRadius: "12px 12px 0px 0px",
        color: "#FFF",
        [theme.breakpoints.down('xs')]: {
            padding: "0 30px",
        },
    },
    cancelGridStyle: {
        justifyContent: 'flex-end',
        display: 'flex', alignItems: 'center'
    },
    planTitle: {
        paddingTop: 20,lineHeight: "4.5rem",
        fontWeight: 600,
    },
    planDescription: {
        fontSize: 16, paddingBottom: 90,
        lineHeight: "2rem",
    },
    priceBlock: {
        width: 158,height: 158,
        borderRadius: "50%",
        left: 120,  background: "#FFF",
        bottom: 50, display: "flex",
        position: "relative" as "relative",
        justifyContent: "center",
        flexDirection: "column" as "column",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            left: "50%", translate: "-50%",
        },
    },
    planBody: {
        boxShadow: "0px 4px 4px 0px #00000040"
    },
    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400, fontFamily: 'Poppins',
        color: '#FFFFFF', backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px', padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    featureBlock: {
        padding: "80px 50px 15px",
        marginTop: "-140px", background: "#FFF",
        [theme.breakpoints.down('xs')]: {
            padding: "70px 10px 15px",
        },

    },
    featureItem: {
        padding: "0.5rem 0",
        display: "flex",gap: "1rem",
    },
    extraPlanBlock: {
        padding: "15px 0 15px 110px",
        [theme.breakpoints.down('xs')]: {
            padding: "15px 0 15px 60px",
        },
    },
    buttonPlanBlock: {
        padding: "60px 120px",
        [theme.breakpoints.down('xs')]: {
            marginBottom: "20px",
        },
    },
    selectButton: {
        textTransform: "none" as "none",
        color: "#FFF",fontWeight: 400,
        width: "100%",
    },
    paths: {
        color: "#475569",  cursor: "pointer",
        fontSize: "24px",
    },
    planMainBlock: {
        margin: "30px 0 0 6vw",
        paddingBottom: 60, display: "flex",
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 40,
        },
    },
    billingBlock: {
        background: "#F8F2FF", fontFamily: "Poppins",
        paddingBottom: 60, marginTop:"8%",
        "@media(max-width: 1200px)": {
            marginTop:"10%",
          },
          "@media(max-width: 1000px)": {
            marginTop:"13%",
          },
          "@media(max-width: 950px)": {
            marginTop:"10%",
          },          
          "@media(max-width: 820px)": {
            marginTop:"10%",
          },
          "@media(max-width: 760px)": {
            marginTop:"12%",
          },
          "@media(max-width: 630px)": {
            marginTop:"15%",
          },
          "@media(max-width: 480px)": {
            marginTop:"15%",
        },
    },
    tabsBlock: {
        borderRadius: 12, display: "flex",
        margin: "35px 16vw 25px", background: "#EAD8FE",
        height: "4rem",
        [theme.breakpoints.down('xs')]: {
            height: "3rem",
            margin: "0 10px",
            justifyContent: "space-between",
        },
    },
    tabItem: {
        height: "100%", width: "17vw", justifyContent: "center",
        display: "flex",
        fontWeight: 700, alignItems: "center",
        borderRadius: 12, fontSize: 16,
        cursor: "pointer", margin: "auto 0",
        [theme.breakpoints.down('xs')]: {
            width: "unset", padding: "0 10px",
            fontSize: "12px",
        },
    },
    description: {
        color: "#262424", margin: "0 20vw",
        [theme.breakpoints.down('xs')]: {
            margin: "0 5vw",
            marginTop: "5vw",
        },
    },
    switchBlock: {
        margin: "50px 0", justifyContent: "center",
        display: "flex", gap: 20,
        [theme.breakpoints.down('xs')]: {
            margin: "40px 0",
        },
    },
    
})
// Customizable Area End

export default withStyles(planStyles)(PlanSelection);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import React, { ChangeEvent } from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}
export interface PaymentMethods {
  id: number
  type: string,
  brand: string,
  exp_month: number,
  exp_year: number,
  last4: string
  status: boolean,
  attributes: ReviewAttributes
}

export interface ReviewAttributes {
  id: number
  comment: string
  rating: number
  created_at: string
  updated_at: string
  listing_name: string
  logo_url: string
}

interface S {
  // Customizable Area Start
  token: string;
  data: any;
  paymentMethodData: any;
  isOpen: boolean;
  cardNumber: string;
  cardName: string;
  cvvNumber: string;
  expNumber: string;
  isSubmitted: boolean;
  toastErrMsg: string;
  cardNumberErrorText: string;
  cardNameErrorText: string;
  cvvErrorText: string;
  expNumberErrorText: string;
  cardNumberError: boolean,
  cardNameError: boolean,
  cvvError: boolean,
  expNumberError: boolean,
  counts: number | any,
  rowsPerPage: number | any,
  page: number | any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PaymentMethodController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getPaymentMethodId: any;
  createAddCardAPICallId: string = "";
  addPaymentCardId: string = "";
  deleteCardApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      data: null,
      paymentMethodData: null,
      isOpen: false,
      cardNumber: "",
      cardName: "",
      cvvNumber: "",
      expNumber: "",
      isSubmitted: false,
      toastErrMsg: "",
      cardNumberErrorText: "",
      cardNameErrorText: "",
      cvvErrorText: "",
      expNumberErrorText: "",
      cardNumberError: false,
      cardNameError: false,
      cvvError: false,
      expNumberError: false,
      counts: 0,
      rowsPerPage: 5,
      page: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCurrentToken();
    this.getPaymentMethod()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getCurrentToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getCurrentToken = () => {
    const myMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(myMsg);
  };

  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestId === this.getPaymentMethodId) {
        this.setState({ paymentMethodData: responseJson.cardinformation });
        this.setState({ counts: responseJson.meta.total_count })
      }
      if (apiRequestId === this.deleteCardApiId) {
        this.getPaymentMethod();
      }
      if(apiRequestId === this.addPaymentCardId){
        this.getPaymentMethod();
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.page !== prevState.page) {
      this.getPaymentMethod();
    }
  }
  getPaymentMethod = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPaymentMethodId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCardDetail}?page=${Number(this.state.page) + 1}&per_page=${this.state.rowsPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewCard = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };

    const attrs = {
      "token": localStorage.getItem("loginToken"),
    }
    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAddCardAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreatecard
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isOpen: false })
    this.getPaymentMethod()
  }
  HandleNextSubmit = () => {
    this.createNewCard()
    // this.setState({ isSubmitted: false })
  }

  handleChangeCardName = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    if (value.length > 0) {
      if (value.length <= 2) {
        this.setState({
          cardNameError: true,
          cardNameErrorText: 'Please enter a valid card name',
        });
      }
      else {
        this.setState({ cardNameError: false, cardNameErrorText: "" });
      }
    }
    this.setState({ cardName: event.target.value })
  }

  handleAddCardToken = async (e: any, elements: any, stripe: any) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
    const { paymentMethod }: any = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    const { token } = await stripe.createToken(cardElement);
    this.handleAddCardApiCall(token, paymentMethod)
    this.setState({cardName:""})
  };

  handleAddCardApiCall = (token: any, paymentMethod: any) => {
    const header = {
      token: localStorage.getItem("loginToken"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      "billing_details": paymentMethod.billing_details,
      "brand": paymentMethod.card?.brand,
      "exp_month": paymentMethod.card?.exp_month,
      "exp_year": paymentMethod.card?.exp_year,
      "last4": paymentMethod.card?.last4,
      "status": true
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addPaymentCardId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCards
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleCancelCard()
  }

  handleCancelCard = () => {
    this.setState({ isOpen: false })
  }


  handleDelete = (item: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("loginToken"),
    };
    const requestMessagedeleteItem = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCardApiId = requestMessagedeleteItem.messageId;

    requestMessagedeleteItem.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cadrDeleteEndpoint + `${item?.id}`
    );
    requestMessagedeleteItem.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessagedeleteItem.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessagedeleteItem.id, requestMessagedeleteItem);

  }
  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ page: 0 });
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };
  handleCloseCards = ()=>{
    this.setState({ isOpen: false })
  }
  // Customizable Area End
}

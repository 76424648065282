// Customizable Area Start
import React, { Component } from 'react';
import { Container, Grid, Typography, Button, Paper, Box, Avatar, Popover } from "@material-ui/core";
import {
    topheaderlogo,
    illustration
  } from "./assets";
  import { configJSON } from "./LandingPageWebController.web";
// Customizable Area End

class CommonLandingHeader extends Component<any> {
    // Customizable Area Start
    // Customizable Area End

render(){
    // Customizable Area Start
    const { classes, username, goToHomePage, headerNavigation, signout, handleProfileMenuOpen, handlePopoverClose, popoverAnchorE1, goToLogin, displayTextResturantPage, buyerPageData, handleSearchInput, searchResultsVisible, handleInnerClick, values, handleAdvancedSearch } = this.props;    return (
      <Paper className={classes.landingBanner}>

      <Container className={classes.container} >
        <Box className={classes.landingHeader}>
          <Grid container spacing={2} className={classes.headingGridContainer}>
            <Grid item xs={12} className={classes.landingHeaderRow}>
              <img src={topheaderlogo} alt="Logo" className={classes.topheaderlogo} onClick={goToHomePage} />

              {localStorage.getItem("loginToken") ?
                <Box className={classes.avatarWrapper} id="profileMenu">
                  <Avatar alt="Tony" src={username?.profile_image || this.props.dummyicon} className={classes.avatar} />
                  <Box className={classes.textWrapper}>
                    <Typography variant="subtitle1" className={classes.welcomeText} style={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '14px',
                      color:"#262424",
                      
                    }}>
                      {"Welcome Back"}
                    </Typography>
                    <Box className={classes.nameAndArrow} onClick={handleProfileMenuOpen} >
                      <Typography className={classes.nameText}>{username?.full_name}</Typography>
                      <img src={this.props.downarrow} className={classes.downArrowIcon} />
                    </Box>
                    <Popover
                      open={Boolean(popoverAnchorE1)}
                      anchorEl={popoverAnchorE1}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box className={classes.profilePaper}>
                        <Typography className={classes.fontsStyle} onClick={headerNavigation}>My Profile</Typography>
                        <Typography className={classes.fontsStyle} onClick={signout}>Sign Out</Typography>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
                :
                <Button onClick={goToLogin} className={classes.signinBtn}>{configJSON.btnSignin}</Button>
              }
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.landingBannerContent}>
          <Container className={classes.container}>
            <Box className={`${classes.row} ${classes.searchRow}`}>
              {displayTextResturantPage(classes, handleSearchInput, searchResultsVisible, handleInnerClick, values, handleAdvancedSearch)}
            </Box>
          </Container>
        </Box>

      </Container>
      <Box className={classes.landingAboutFull}>
        <Box className={classes.landingAbout}>

          <Grid className={classes.aboutContainer} container >
            <Grid item sm={6} xs={12} >
              <Typography variant="h2" className={classes.landingAboutTitle}>{buyerPageData.about_us.title}</Typography>
              <Typography variant="body1" className={classes.landingAboutDetail}>{buyerPageData.about_us.subtitle}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.illusImgGrid} style={{ paddingLeft: "60px" }}>
              <img src={illustration} alt="Illustration" className={classes.landingAboutImage} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
      )
    // Customizable Area End
}}
  

export default CommonLandingHeader;
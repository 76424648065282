import React, { Component } from "react";
import {
    Box,
    AppBar,
    InputBase,
    Button,
    Typography,
    Avatar,
    Popover,
    Link,
    Modal
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
    searchIconSuppliers,
    searchIconServices,
    searchIconSoftwares,
    searchIconEvents,
} from '../../blocks/landingpage/src/assets'
import SearchIcon from '@material-ui/icons/Search';
import { appbarLogo } from './assets';
type MyProps = {
    handleClick?: () => void,
    goToHomePage?: () => void,
    handleHeaderNavigation?: () => void,
    handleUserSignout?: () => void,
    handleFocus?: () => void,
    handleSearchInput?: any,
    searchText?: string
    logoImg: any;
    serachIcon: any,
    username: any,
    downarrow: any,
    dummyicon: any,
    showDirectoryPopup?: boolean,
    searchDirectoryData?: any,
    handleAdvancedSearch?: any,
    handleSubmit?: any,
    handleDirectoryPopupClose?: () => void
};
interface MyState {
    isSignout: boolean;
    popoverAnchorEl: HTMLElement | null;

}
export class AppBarPage extends Component<MyProps, MyState> {
    boxref: any = React.createRef<HTMLDivElement>();
    constructor(props: any) {
        super(props);

        this.state = {
            isSignout: false,
            popoverAnchorEl: null,
        };
    }

    handleCloseContainer = (event: any) => {
        console.log("event handle", event);
        if (this.boxref.current && !this.boxref.current.contains(event.target) && this.props?.handleDirectoryPopupClose) {
            this.props.handleDirectoryPopupClose();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleCloseContainer)
    }

    componentDidUnmount() {
        document.removeEventListener('mousedown', this.handleCloseContainer)
    }

    handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        console.log("Profile menu opened");
        this.setState({ popoverAnchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ popoverAnchorEl: null });
    };

    getDirectoryImg = (directory: string) => {
        if (directory == "Suppliers") return `${searchIconSuppliers}`;
        else if (directory == "Events") return `${searchIconEvents}`;
        else if (directory == "Services") return `${searchIconServices}`;
        else if (directory == "Software") return `${searchIconSoftwares}`;
    }

    handleClick1 = () => {
        if (this.boxref.current) {
            this.boxref.current.style.display = 'display';
        }
    }
    formatText=(text:any)=>{
        if(text?.length>26){
            let str=text.slice(0,27)+"...";
            return str;
        }
        return text;
    }

    render() {
        const { classes }: any = this.props
        const { popoverAnchorEl } = this.state;

        return (
            <AppBar position="static" className={classes.appbar} style={{ boxShadow: "none" }}>
                <Box className={classes.boxWrapper} style={{ padding: localStorage.getItem("loginToken") ? 0 : '10px 3px 10px 3px' }}>
                    <img src={appbarLogo} className={classes.logoStyle} onClick={this.props.goToHomePage} />

                    <div className={classes.searchWrapper}>
                        <Box className={classes.search}>
                            <Box className={classes.searchIcon}>
                                <SearchIcon className={classes.groupImageStyle} />
                            </Box>
                            <form style={{ margin: 0, width: "100%" }} data-testId="advanceSearch" onSubmit={this.props.handleSubmit}>
                                <InputBase
                                    value={this.props.searchText}
                                    placeholder={'search items in all directory '}
                                    inputProps={{ 'aria-label': 'search' }}
                                    fullWidth
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    onFocus={this.props.handleFocus}
                                    onChange={this.props.handleSearchInput}
                                />
                            </form>
                            {this.props.showDirectoryPopup &&
                            <div className={classes.searchResultWrapper} ref={this.boxref}>
                                {this.props.searchDirectoryData?.map((item: any) => (
                               
                                <Box
                                    key={item.id}
                                    onClick={()=>this.props.handleAdvancedSearch(item.directory_name)}
                                    className={classes.searchResultRow}
                                >
                                    <Box className={classes.searchResultImgWrapper}>
                                        <img alt="directory"
                                            src={this.getDirectoryImg(item.directory_name)}
                                        />
                                    </Box>
                                    <Box className={classes.searchResultTextBox}>
                                        <Typography className={classes.searchResultLastText}>
                                            <Typography className={classes.searchResultFirstText}>
                                               {this.formatText(this.props.searchText)}
                                            </Typography>
                                            in {item.directory_name} ({item.count} results)
                                        </Typography>
                                    </Box>
                                </Box>
                                )
                                )}
                                  <Link underline='none' href={`Catalogue/All`} >
                                <Typography  className={classes.seeAllText}>See all directories</Typography>
                                </Link>
                            </div>
                            }
                        </Box>
                    </div>
                    {localStorage.getItem("loginToken") ?
                        <Box
                            className={classes.avatarWrapper}
                            id="profileMenu"
                        >
                            <Avatar alt="Tony" src={this.props.username?.profile_image || this.props.dummyicon} className={classes.avatar} />
                            <Box className={classes.textWrapper}>
                                <Typography variant="subtitle1" className={classes.welcomeText}>
                                    {"Welcome Back"}
                                </Typography>
                                <Box className={classes.nameAndArrow} onClick={this.handleProfileMenuOpen} >
                                    <Typography className={classes.nameText}>{this.props.username?.full_name}</Typography>
                                    <img src={this.props.downarrow} className={classes.downArrowIcon} />
                                </Box>
                                <Popover
                                    open={Boolean(popoverAnchorEl)}
                                    anchorEl={popoverAnchorEl}
                                    onClose={this.handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box className={classes.profilePaper}>
                                        <Typography className={classes.fontsStyle} onClick={this.props.handleHeaderNavigation}>My Profile</Typography>
                                        <Typography className={classes.fontsStyle} onClick={this.props.handleUserSignout}>Sign Out</Typography>
                                    </Box>
                                </Popover>
                            </Box>
                        </Box>
                        :
                        <Button
                            variant="contained"
                            onClick={this.props.handleClick}
                            className={classes.signinBtn}>
                            Sign In
                        </Button>
                    }
                </Box>
            </AppBar >
        );
    }
}

export const userStyles = () => ({
    appbar: {
        position: "fixed" as const,
        marginTop: "45px",
        zIndex: 1000,
        width: '100%',
        background: 'linear-gradient(90deg, rgb(123, 53, 196), rgb(143, 109, 243),rgb(194, 134, 241))',
    },
    boxWrapper: {
        display: 'flex',
        gap: '40px',
        justifyContent: 'center',
        alignItems: 'center'
    },

    profilePaper: {
        width: "100%",
        maxWidth: "400px",
        padding: "20px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '15px' as const,
        borderRadius: '8px',
        backgroundColor: "#F8F2FF",
        textWrap: "nowrap"
    },

    logoStyle: {
        height: '30px',
        width: '130px',
        marginLeft: '64px',
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "@media (min-width: 300px) and (max-width: 950px)": {
            height: '30px',
            maxWidth: '100px',
            marginLeft: '8%',
        }
    },

    groupImageStyle: {
        height: '24px',
        width: '24px',
        color: "#94A3B8"
    },

    searchWrapper: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    search: {
        position: 'relative' as const,
        marginLeft: 0,
        borderRadius: '10px',
        width: '829px',
        height: "36px",
        display: 'flex',
        alignItems: 'center' as const,
        backgroundColor: '#f0f0f0',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '150px',
        }
    },

    searchIcon: {
        height: '100%',
        padding: '10px',
        color: 'black',
        display: 'flex',
        position: 'absolute' as const,
        alignItems: 'center' as const,
        justifyContent: 'center' as const,
    },

    fontsStyle: {
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: "Poppins",
        cursor: "pointer"
    },

    inputRoot: {
        width: '100%',
    },

    inputInput: {
        width: '100%',
        paddingLeft: '40px',
        padding: '12px 20px',
        border: 'none',
        color: '#0F172A',
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: "Poppins",
        '&::placeholder': {
            color: '#0F172A',
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: "Poppins"
        },
    },
    signinBtn: {
        borderRadius: "8px",
        background: "#FFF",
        color: "#9429C5",
        fontSize: "14px",
        fontStyle: "bold",
        fontWeight: 500,
        lineHeight: "14px",
        border: 0,
        cursor: "pointer",
        fontFamily: "Poppins",
        height: "36px",
        width: "162px",
        marginRight: 20,
        textTransform: "none" as "none",
        '&:hover': {
            backgroundColor: 'white',
        },
        "@media(max-width: 520px)": {
            height: "6.923vw",
            width: "31.154vw",
        }
    },

    avatarWrapper: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '30px',
        padding: '10px',

        "@media (min-width: 300px) and (max-width: 950px)": {
            marginRight: '5px',
            padding: '3px',
        }
    },
    avatar: {
        marginRight: '8px',
        height: '56px',
        width: '56px',

        "@media (min-width: 300px) and (max-width: 950px)": {
            marginRight: '5px',
            height: '30px',
            width: '30px'
        }
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column' as const,
        marginLeft: '8px',
    },
    welcomeText: {
        marginBottom: '0px',
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Poppins",

        "@media (min-width: 300px) and (max-width: 950px)": {
            fontSize: '5px',
            fontWeight: 300,
        }
    },
    nameText: {
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Poppins",

        "@media (min-width: 300px) and (max-width: 950px)": {
            fontSize: '8px',
            fontWeight: 300,
        }
    },
    downArrowIcon: {
        marginLeft: '4px',
        width: '10px',
        height: '8px',
        cursor: 'pointer',

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '5px',
            height: '5px',
        }
    },
    nameAndArrow: {
        display: 'flex',
        alignItems: 'center',
    },
    searchResultWrapper: {
        width: "100%",
        backgroundColor: "white",
        position: "absolute" as "absolute",
        top: "80px",
        borderRadius: "10px"
    },
    searchResultRow: {
        display: "flex",
        padding: "12px 20px",
        cursor: "pointer"
    },
    searchResultFirstText: {
        color: '#000',
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "14px",
        marginRight: "18px",
        fontWeight: 400,
        display: "inline"
    },

    searchResultTextBox: {
        display: "flex",
        alignItems: "center"
    },

    searchResultLastText: {
        color: '#C1B5B5',
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "14px",
        fontWeight: 400,
    },
    searchResultImgWrapper: {
        marginRight: "18px",
    },
    seeAllText: {
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "14px",
        margin: "10px 0 35px 88px",
        fontWeight: 400,
        color: "#5577FF",
        cursor: "pointer"
    },

});

export default withStyles(userStyles)(AppBarPage)
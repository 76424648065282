import React from "react";

// Customizable Area Start
import { Button, Typography, Dialog, Grid, TextField, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { rightTickIcon } from "./assets";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
// Customizable Area End

// Customizable Area Start
import NewPasswordController, {
  Props,
  configJSON
} from "./NewPasswordController";

// Customizable Area End
export class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Dialog
          open={this.state.isOpenNewPassModal}
          onClose={this.handleCloseModal}
          maxWidth="md"
          data-testid={"closemodal"}
          PaperProps={{ className: classes.dialogStyle }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{ padding: "65px" }}
          >
            <Grid item xs={12}>
              <Typography className={classes.fontStyle1}>
                {configJSON.recover}
              </Typography>
              <Typography className={classes.fontStyle1}>
                {configJSON.createPass}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <StyledTextField
                variant="outlined"
                value={this.state.newPassword}
                type="password"
                data-testid={"passowrd"}
                onChange={this.handleChangeNewPassword}
                fullWidth
                placeholder={configJSON.forgetPass}
                helperText={
                  this.state.isPassError &&
                  <Box className={classes.errorBoxStyle}>
                    {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                    <Typography style={{
                      color: "#D53333",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}>
                      {this.state.passErrMsg}
                    </Typography>
                  </Box>
                }
                InputProps={{
                  disableUnderline: true
                }}
                className={classes.nameTextfeild}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledTextField
                className={classes.nameTextfeild}
                placeholder={configJSON.reenterpass}
                variant="outlined"
                type="password"
                value={this.state.confirmNewPassword}
                onChange={this.handleChangeConfirmPassword}
                helperText={
                  (this.state.isConfirmPassSuccess || this.state.isConfirmPassErr) &&
                  <Box className={classes.errorBoxStyle}>
                    {this.state.isConfirmPassSuccess ? <CheckCircleOutlineOutlinedIcon className={classes.sucessIconStyle} /> : <ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                    <Typography style={{
                      color: this.state.isConfirmPassSuccess ? "#1AA663" : '#D53333',
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}>
                      {this.state.confirmPassErrText}
                    </Typography>
                  </Box>
                }
                data-testid={"confirmpass"}
                fullWidth
                InputProps={{
                  disableUnderline: true
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={this.newPasswordAPI}
                className={classes.buttonBackground}
                data-testid={"continueBtn"}
              >
                {configJSON.continuwBtn}
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        {this.state.showSucessMessage &&
          <Dialog
            open={this.state.isOpenoSucessModal}
            onClose={this.handleCloseModal}
            maxWidth="md"
            PaperProps={{ className: classes.dialogStyle }}
          >
            <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ padding: '55px' }}>
              <Grid item xs={12} className={classes.emailIconGridStyle}>
                <img src={rightTickIcon} className={classes.imageStyle} />
              </Grid>

              <Grid item xs={12} className={classes.textGridStyle}>
                <Typography className={classes.ChangedPassFontStyle}>{configJSON.passChanged}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.textGridStyle}>
                <Typography className={classes.insFontStyle}>{configJSON.loginToCont}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={this.goToLoginPage}
                  fullWidth variant="contained"
                  className={classes.buttonBackground}
                  data-testid={"navigateToLogin"} >{configJSON.login}</Button>
              </Grid>
            </Grid>
          </Dialog>
        }
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export const StyledTextField = withStyles({
  root: {
    fontSize: "14",
    "& input::placeholder": {
      fontSize: "14",
      fontWeight: 400,
      fontFamily: "Poppins"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD"
    },
    "& .MuiInputBase-input-283:focus": {
      backgroundColor: "transparent",
      outline: "none"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE76FF"
    },
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#FFFFFF",
      margin: 0,
      paddingLeft: 10
    },
  }
})((props: any) => <TextField {...props} />);

export const userStyles = () => ({
  dialogStyle: {
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
    width: "510px",
    borderRadius: "8px",
    backgroundColor: " #FFFFFF",
    display: "flex"
  },

  errorBoxStyle: {
    display: 'flex',
    marginTop: '2px',
  },

  sucessIconStyle: {
    fontSize: '18px',
    margin: '3px 10px 0px -10px',
    color: '#1AA663'
  },

  errorIconStyle: {
    fontSize: '18px',
    margin: '3px 10px 0px -10px',
  },

  nameTextfeild: {
    backgroundColor: "#FAF5FF",
    width: "100%",
    borderRadius: "1px"
  },

  fontStyle1: {
    fontWeight: 600,
    color: "#454545",
    fontSize: "22px"
  },

  buttonBackground: {
    backgroundColor: "#B300ED",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#FFFFFF",
    textTransform: "initial" as const,
    "&:hover": {
      backgroundColor: "#B300ED"
    }
  },

  helperText: {
    color: "#D53333",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400
  },

  emailIconGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageStyle: {
    height: '88px',
    width: '88px',
  },

  textGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  ChangedPassFontStyle: {
    color: "#000000",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },

  insFontStyle: {
    color: "#000000",
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: 'center' as const
  },
});

export default withStyles(userStyles)(NewPassword);
// Customizable Area End

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logoImage = require("../assets/image_logo_top.png");
export const searchIcon = require("../assets/image.png");
export const personImage = require("../assets/image_person.png");
export const downArrow = require("../assets/image_vect.png");
export const personImage1 = require("../assets/group_avatar.png");
export const accountIcon = require("../assets/group_account_details.png");
export const listIcon = require("../assets/group_path.png");
export const reviewsIcon = require("../assets/group_reviews.png");
export const signoutIcon = require("../assets/group_signout.png");
export const editIcon = require("../assets/group_edit.png")
export const bookmarkIcon = require("../assets/group_bookmarks.png")
export const notificationIcon = require("../assets/group_notification.png")
export const successCheckIcon = require("../assets/group_check.png")
export const closeIcon = require("../assets/group_close.png")
export const accDetails = require('../assets/accout_details.svg');
export const revSumbit = require('../assets/reviews_submitted.svg');
export const sigOut = require('../assets/signout.svg');
export const notification = require('../assets/notification.svg');
export const bookMark = require('../assets/bookMark.svg');
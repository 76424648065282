import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from '@stripe/react-stripe-js';
import { ChangeEvent } from "react";


// Customizable Area Start
interface Plan {
    id: number;
    name: string;
    price: string;
    description: string
}
export interface SearchDir{
    directory_id: number,
    directory_name:string,
    count: number
}
interface SelectedPlan {
    selectedPlan: {
        plan: Plan;
        directory_Name: string;
        directory_id: number;
        description: string
    }
}
interface Coupon {
    id: number;
    attributes: {
        discount: string;
        code: string
    }
}

export const base64ToFile =  async(base64: string) => {
    const byteCharacters = Buffer.from(base64.replace(/^data:image\/[a-zA-Z]+;base64,/, ''),'base64'); 
    const blob :any=new Blob([byteCharacters], { type: 'image/png' });
    return new File([blob],"logo.png", { type:  'image/png'  });
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    Username: any;
    isPaymentButton: boolean;
    isModalOpen: boolean;
    selectedPlan: SelectedPlan | null;
    taxValue: number;
    couponValue: number;
    isCardCheck: string;
    totalAmount: number;
    enterCvv: string;
    enterName: string;
    searchItemId: any;
    catlougue_id: any;
    isNameError: boolean;
    nameErrorMessage: string;
    isPaymentFailed: boolean;
    paymentMethodData: any;
    isLoader: boolean;
    listName: string;
    taxId: number;
    cuponData: Coupon[];
    isCouponCode: boolean
    couponText: string;
    currentDiscount: number;
    errorCouponCode: string;
    showDirectoryPopup:boolean,
    searchTermProp:string,
    searchDirectoryData:SearchDir[]
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ListingCheckOutPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    submitPaymentAPICallid: string = "";
    nameReg: RegExp;
    paymentMethodAPICallId: string = "";
    getTaxValueAPICallId: string = "";
    getCuponCodeAPICallID: string = "";
    postCouponCodeID: string = "";
    getDirectoryDatatApiId: string="";
    createListingAPICallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            Username: null,
            isPaymentButton: false,
            isModalOpen: false,
            selectedPlan: null,
            taxValue: 0,
            couponValue: 0,
            isCardCheck: "",
            totalAmount: 0,
            enterCvv: "",
            enterName: "",
            searchItemId: null,
            catlougue_id: null,
            isNameError: false,
            nameErrorMessage: "",
            isPaymentFailed: false,
            paymentMethodData: null,
            isLoader: false,
            listName: "",
            taxId: 0,
            cuponData: [],
            isCouponCode: false,
            couponText: "",
            currentDiscount: 0,
            errorCouponCode: "",
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchTermProp:""
            // Customizable Area End
        };

        // Customizable Area Start
        this.nameReg = /^[a-zA-Z ]*$/;
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ Username: accountDetails });

        const localStorageData = await getStorageData('selectedPlan');
        const parsedData = JSON.parse(localStorageData);
        this.setState({ selectedPlan: parsedData });

        this.handlePaymentCalculation()
        this.getTaxValue()
        const catlougueid = await getStorageData("catlougue_Id")
        if (catlougueid) {
            this.setState({ catlougue_id: catlougueid })
        }

        const itemIdString = await getStorageData("user_listing_id");
        this.setState({ searchItemId: itemIdString })

        const listingname = await getStorageData("listing_name")
        this.setState({ listName: listingname })

        this.getCuponCodes()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.paymentMethodAPICallId:
                       this.handlePostPaymentApiResponse(responseJson)
                        break;
                    case this.submitPaymentAPICallid:
                        this.handleCReatePaymentAPIResponse(responseJson);
                        break;

                    case this.getTaxValueAPICallId:
                        this.setState({
                            taxValue: responseJson.data[0].attributes.tax,
                            taxId: responseJson.data[0].attributes.id
                        });
                        this.handlePaymentCalculation();
                        break;

                    case this.getCuponCodeAPICallID:
                        this.handleCuponAPIResponse(responseJson)
                        break
                    case this.postCouponCodeID:
                        this.handleCouponResponse(responseJson)
                        break
                    case this.getDirectoryDatatApiId:
                        this.handleDirectorySearchResponse(responseJson)   
                        break; 

                    case this.createListingAPICallId:
                        this.handleCReateListingAPIResponse(responseJson)
                        break;

                    default:
                        break;
                }
            }
        }
    }

    // Web Events
    goToLoginPage = () => {
        this.props.navigation.navigate("SearchListing2", { type: "EmailAccountLoginBlock" })
    }

    handleModalClose = () => {
        this.setState({ isModalOpen: false },()=>this.handleVendorAccountNavigation())
    }

    handleFailedModalClose = () => {
        this.setState({ isPaymentFailed: false, enterName: "" })
    }

    handleChangeCvv = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ enterCvv: event.target.value })
    }
    handleDirectorySearchResponse=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({searchDirectoryData:responseJson.response})
        }
    }

    handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        this.setState({ enterName: newName });

        if (!this.nameReg.test(newName)) {
            this.setState({ isNameError: true, nameErrorMessage: configJSON.nameErrorText });
        } else {
            this.setState({ nameErrorMessage: '' });
        }
    }

    handleCardCheck = (value: string) => {
        if (value === "card") {
            this.setState({ isPaymentButton: true })
        } else {
            this.setState({ isPaymentButton: false })
        }
        this.setState({ isCardCheck: value })
    }

    handleVendorAccountNavigation = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    goToLandingScreen = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleHeaderNavigation = async () => {
        const usertype = await getStorageData("user_type")
        if (usertype == "vendor" || "Vendor") {
            this.handleVendorAccountNavigation()
        }
    }

    signoutUser = async () => {
        await removeStorageData("loginToken")
        this.goToLandingScreen()
    }

    handleRemoveCoupon = () => {
        this.setState({
            isCouponCode: false,
            couponValue: 0,
            couponText: "",
            currentDiscount: 0,
        }, () => {
            this.handlePaymentCalculation();
        });
    }
    handleSubmitSearch=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearchNavigation("All")
    }

    handlePaymentCalculation = () => {
        const { selectedPlan, taxValue, currentDiscount } = this.state;
        const planPrice = Number(selectedPlan?.selectedPlan.plan.price) || 0;
        const subTotal = planPrice;
        // const tax = (subTotal * taxValue) / 100;
        let total = subTotal + taxValue - currentDiscount;
        if (total < 0) {
            total = 0;
        }

        const formattedTotal = parseFloat(total.toFixed(2));
        this.setState({ totalAmount: formattedTotal });
    }

    handleAdvancedSearchNavigation = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }

    SubmitPaymentButton = async (e: any, elements: any, stripe: any) => {
        e.preventDefault();
        const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);

        const { paymentMethod }: any = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        this.setState({ paymentMethodData: paymentMethod })
        this.handleAddnewCard(paymentMethod)
    };

    handleCreatePayment = async() => {
        this.setState({ isLoader: true })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };
        const attributess = {
            subscription_id: this.state.selectedPlan?.selectedPlan?.plan?.id,
            amount: this.state.totalAmount,
            paymentable_id: this.state.searchItemId,
            paymentable_type: "BxBlockCatalogue::UserListing",
            token: this.state.paymentMethodData?.id,
            tax_id: this.state.taxId,
        };

        const data = {
            attributes: attributess,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitPaymentAPICallid = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postPayment
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
    }

    handleAddnewCard = (paymentMethod: any) => {
        const header = {
            token: localStorage.getItem("loginToken"),
            "Content-Type": configJSON.validationApiContentType,
        };

        const httpBody = {
            "payment_method_id": paymentMethod.id
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.paymentMethodAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCardApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchResultDirectory = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
          const searchDataRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
    
        this.getDirectoryDatatApiId = searchDataRequestMessage.messageId;
        searchDataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchDataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
      );
    
      runEngine.sendMessage(searchDataRequestMessage.id, searchDataRequestMessage);
    
    
      }

    getTaxValue = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTaxValueAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.taxValueAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCuponCodes = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCuponCodeAPICallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cuponCodeAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    // API Responses

    handleCReatePaymentAPIResponse = async (responseJson: { payment_intent: { data: {} } }) => {
        const apiresponse = responseJson
        if (apiresponse?.payment_intent?.data) {
            this.setState({
                isModalOpen: true,
                isLoader: false
            })
            await removeStorageData("user_listing_id")
            await removeStorageData("selectedPlan")

        } else {
            this.setState({ isPaymentFailed: true, isLoader: false })
        }
    }

    handleCReateListingAPIResponse = async (responseJson: { payment_intent: {  } }) => {
        const apiresponse = responseJson
       
        if (apiresponse?.payment_intent) {
            this.setState({
                isModalOpen: true,
                isLoader: false
            })


        } else {
            this.setState({ isPaymentFailed: true, isLoader: false })
        }
        await   removeStorageData("logo")
        await removeStorageData("selectedPlan")
    }

    handleCuponAPIResponse = (responseJson: { data: Coupon[] }) => {
        const couponCode = responseJson.data
        const couponValue = responseJson.data.reduce((total: number, coupon: Coupon) => {
            return total + parseFloat(coupon.attributes.discount);
        }, 0);
        this.setState({ cuponData: couponCode, couponValue }, () => {
            this.handlePaymentCalculation();
        });
    }
    handleCouponResponse = (responseJson: any) => {
        const couponCode = responseJson
        if (couponCode.message) {
            this.setState({ isCouponCode: true, currentDiscount: couponCode?.amount }, () => {
                this.handlePaymentCalculation();
            })
        } else {
            this.setState({ errorCouponCode: couponCode?.error })
        }
    }
    handleChangeCoupon = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ couponText: event.target.value, errorCouponCode: "" })
    }
    handleApplyCouponCode = () => {
        if (this.state.couponText) {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("loginToken"),
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.postCouponCodeID = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.couponCodeAPIEndPoint}?code=${this.state.couponText}&amount=${this.state.selectedPlan?.selectedPlan?.plan?.price}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
    handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }

   

  

    handlePostPaymentApiResponse=(responseJson: { message: string })=>{
        if (responseJson?.message) {
            if(this.state.searchItemId){
                this.handleCreatePayment()
            }
            else{
                this.createListingAPICall()
            }    
            

        }
    }


    createListingAPICall = async () => {
        this.setState({isLoader:true})
        let formData:any= new FormData();
        const domain_url=await getStorageData("domain_url")
         const directoryId = await getStorageData("directory_id")
        const name=await getStorageData("name")
         const short_description= await getStorageData("short_description")
        const logo= await getStorageData("logo")

         


        formData.append("domain_url",domain_url );
        formData.append("directory_id",directoryId );
        formData.append("name",name );
        formData.append('short_description',short_description)
        formData.append("paymentable_type", "BxBlockCatalogue::Catalogue")
        formData.append("subscription_id",this.state.selectedPlan?.selectedPlan?.plan?.id)
        formData.append("amount",this.state.totalAmount)
        formData.append("token",this.state.paymentMethodData?.id)
        formData.append("tax_id", this.state.taxId)
        if(logo){ formData.append('logo',await base64ToFile(logo)) }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createListingAPICallId = requestMessage.messageId;
       
        const header = {
            token: await getStorageData("loginToken"),
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createListingAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
       

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);
   
};  



    // Customizable Area End
}

//@ts-nocheck
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
export interface Bookmark {
    id: number
    account_id: number
    catalogue_id: number
    catalogue_name: string
    short_description: string
    created_at: string
    logo_url: string
  }
// Customizable Area End


export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    classes: any
}



export interface S {
    // Customizable Area Start
    tabVal: string;
    bookmarksData: Bookmark[];
    counts: number;
    rowsPerPageReview: number | any;
    pageReview: number | any;
    // Customizable Area End
}


export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class BookmarkController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBookmarksApiCallId: any;
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            tabVal: "Suppliers",
            bookmarksData: [],
            counts: 0,
            rowsPerPageReview: 5,
            pageReview: 0,
            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const token = await getStorageData("loginToken");
        this.getBookmarks()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.tabVal !== this.state.tabVal || prevState.pageReview !== this.state.pageReview ) {
        this.getBookmarks()
        }
    }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            if (responseJson && !responseJson.errors) {
                this.handleApiSuccessResponse(responseJson, apiRequestCallId);
            }
            if (responseJson.errors) {
                this.handleApiFailureResponse(responseJson, apiRequestCallId);
            }
        }
    }

    handleApiSuccessResponse = (responseJson: any, apiRequestCallId: string) => {
        if (apiRequestCallId === this.getBookmarksApiCallId) {
            this.setState({ bookmarksData: responseJson.catalouges,counts: responseJson.meta.total_count  })
        }
    }
    handleApiFailureResponse = (responseJson: any) => {
        console.log(responseJson, "Error Occured!!!");
    }

    handleChangeTab = (event: any, newValue: string) => {
        this.setState({ tabVal: newValue ,pageReview:0})
    };

    getBookmarks = () => {
        const headers = {
            token: localStorage.getItem("loginToken"),
            "Content-Type": "application/json",
        };
        const getBookmarksMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBookmarksApiCallId = getBookmarksMessage.messageId;
        getBookmarksMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getBookmarksEndPoint}?page=${Number(this.state.pageReview) + 1}&per_page=${this.state.rowsPerPageReview}&directory_name=${this.state.tabVal}`
        );
        getBookmarksMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        getBookmarksMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(getBookmarksMessage.id, getBookmarksMessage);
    }
    handleChangePageReview = (event: unknown, newPage: number) => {
        this.setState({ pageReview: newPage });
    };
    // Customizable Area End
}

import React from "react";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails, userStyles, getFileName } from "./PremiumListing.web";
// Customizable Area End

// Customizable Area Start
import PremiumListingServiceController, {
    Props,
} from "./PremiumListingServiceController";
import { Backdrop, Box, Chip, CircularProgress, Tab, Tabs,Button, Typography } from "@material-ui/core";
import AppBarPage  from "../../../components/src/AppBar.web";
import { logoImage, searchIcon, downArrow, pdflogo } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Contacts from "../../../components/src/Contacts.web";
import Reviews from "../../../components/src/Reviews.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import { tabsIndicatorStyle } from './PremiumListingSupplier.web'
import ListingAbout from "../../../components/src/ListingAbout.web";
import  CustomErrorToast  from "../../../components/src/CustomErrorToast.web";
// Customizable Area End

// Customizable Area Start
export class PremiumListingService extends PremiumListingServiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderChipsData = (classes: any, chips: any) => {
        return (<Box className={classes.chipContainer}>
            {chips && chips.map((chip: any) => (
                <Box key={chip}>
                    <Chip className={classes.chipStyle} label={chip.name} />

                </Box>
            )

            )}

        </Box>)
    }
    renderTabs = (classes: any, tabsArray: any, accordionTab: any) => {
        return (<Box className={classes.navBar}>
            <Tabs
                value={this.state.tab}
                onChange={this.handleTabChange}
                centered
                classes={{ centered: classes.supplierTabContainer }}
                TabIndicatorProps={{ style: tabsIndicatorStyle }}
                data-testid="tabs"
            >
                {tabsArray && tabsArray.map((item: any, index: any) => (
                    <Tab key={index} value={index} data-testid={item} label={item} style={{ color: this.colorDetect(index) }} />

                ))}

            </Tabs>
        </Box>)
    }
    showCategories = (classes: any, categoryData: any) => {
        return <div style={{ display: "flex", flexWrap: "wrap" }}>
            {categoryData && categoryData.map((category: any, i: number) => (
                <Box className={classes.width550} key={i}>
                    <Typography className={`${classes.heading14} ${classes.marginTop20}`}>{category.category_name}</Typography>
                    <div>
                        {this.renderChipsData(classes, category.sub_categories)}
                    </div>
                </Box>
            ))}
        </div>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start

        const { isLoading,
            servicesfiles,
            name,
            accordionTab,
            contactData,
            markets

        }: any = this.state
        const { classes }: any = this.props

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                    logoImg={logoImage}
                    handleUserSignout={this.handleUserSignout}
                    downarrow={downArrow}
                    data-testId={"signInBtn"}
                    username={this.state.userdetails}
                    serachIcon={searchIcon}
                    goToHomePage={()=>this.handleNavigation("Home")}                 
                    dummyicon={""}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                    handleClick={this.goToLoginPage}
                    handleHeaderNavigation={()=>this.navigateToAccount(this.state.userdetails?.user_type)}
                />
                <Box className={classes.root}>
                    <Box className={`${classes.container} `}>
                        <Box  className={classes.headerContainer}>
                            <ListingHeader   userdetails={this.state.userdetails} domain_url={this.state.domain_url}   handleBookmarkDelete={this.handleBookmarkDelete} bookmarked={this.state.bookmarked} contactData={contactData} data-testid="bookmark" handleBookmarkCall={this.handleBookmarkClick} showClaimed={this.state.claim_status=="Unclaimed"} showComapnyRating={true} showContact={false} showFeatured={this.state.featured} name={name} logo={this.state.logo} />
                            {this.renderTabs(classes, tabsArray, accordionTab)}

                        </Box>
                        <Box id={`0-tab`}>


                            <ListingAbout expanded={this.state.servicesaboutOpen} markets={markets} data-testid="servicesaboutOpen" description={this.state.description} handleAccordionToggle={this.handleToggle} accordionState="servicesaboutOpen" />
                        </Box>

                        <Box id={`1-tab`} className={classes.accordionContainer}>
                            <Accordion data-testid="servicescategoriesOpen" onChange={() => this.handleToggle('servicescategoriesOpen')} expanded={this.state.servicescategoriesOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Services</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{paddingLeft:"56px"}}>

                                    <Box className={classes.integrationContainer}>
                                        <Box>
                                            <Typography className={`${classes.heading16} `}>
                                                Categories
                                            </Typography>
                                        </Box>

                                        <Box className={classes.cardsContainer}>
                                            {this.showCategories(classes, this.state.categories?.slice(0, 4))}
                                            {this.state.moreCategories ? this.showCategories(classes, this.state.categories?.slice(4)) : ""}
                                        </Box>

                                        <Box className={`${classes.marginTop30}`}>
                                        {this.state.categories?.length>4?<Button data-testid="seemore" onClick={()=>this.setState({moreCategories:!this.state.moreCategories})} variant="text" className={classes.categoryBtn}>
                                                          {this.state.moreCategories?<Typography variant="body1">See less</Typography>:<Typography variant="body1">See more</Typography>}
                                                    </Button>:""}
                                                    
                                        </Box>

                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </Box>



                        <Box id={`2-tab`} className={classes.accordionContainer}>
                            <Accordion data-testid="servicesfilesOpen" onChange={() => this.handleToggle('servicesfilesOpen')} expanded={this.state.servicesfilesOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Files</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={classes.integrationContainer}>
                                        <Box className={classes.width550}>
                                            {servicesfiles && servicesfiles.map((item: any) => (
                                                <Box key={item.id} className={`${classes.fileContainer} ${classes.marginTop10}`}>
                                                    <Box className={classes.fileItem}>
                                                        <img src={pdflogo} alt="filelogo" />
                                                        <Typography className={`${classes.ml10} ${classes.marginTop10} ${classes.neutralGray} ${classes.heading400}`}>{getFileName(item.url)}</Typography>

                                                    </Box>
                                                    <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}
                                                    style={{   
                                                    fontSize: '18px',
                                                    fontWeight: 400,
                                                    lineHeight: '26px',}}
                                                    >Download</a>

                                                </Box>
                                            ))}
                                        </Box>




                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Reviews  expanded={this.state.servicesreviewAccordion} accordionState="servicesreviewAccordion" id="3-tab"  hideRatingForm={this.state.hideRatingForm}
                            handleEditBtn={this.handleEditBtn}
                            isEdit={this.state.editReview}
                            reviewData={this.state.servicesReview}
                            handleAccordionToggle={this.handleToggle}
                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadMore}
                            handleRating={this.handleRating}
                           anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            handleClose={this.handleClose}
                            ratingVal={this.state.ratingVal}
                            reviewComment={this.state.reviewComment}
                            handleReviewSubmit={this.handleReviewSubmit}
                            handleReviewComment={this.handleReviewComment}
                            data-testid="servicesreviewAccordion"
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            filterValue={this.state.filterValue}
                            loadmore={this.state.loadmore}
                            handleReviewUpdate={this.handleReviewUpdate} />

                        <Box id="4-tab">
                        <Contacts  accordionState="contactAccordion"  expanded={this.state.contactAccordion} data-testid="contactAccordion" contactData={contactData} handleAccordionToggle={this.handleToggle} />
                        </Box>

                    </Box>

                </Box>
                <CustomErrorToast data-testid= "errorToast" open={this.state.reviewAlert} onClose={this.handleReviewAlert}/>


            </>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
export const tabsArray = ["About", "Services", "Files", "Reviews", "Contact"]
export default withStyles(userStyles)(PremiumListingService)
// Customizable Area End

//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start

// Customizable Area End


export const configJSON = require("./config");

export interface Review {
    id: string
    type: string
    attributes: ReviewAttributes
}

export interface ReviewAttributes {
    id: number
    comment: string
    rating: number
    created_at: string
    updated_at: string
    listing_name: string
    logo_url: string
    profile_image: string | null
    user_email: string | null
    user_name: string | null
    review_reply: any,
}

export interface Props {
    navigation: any;
    id: string;
    classes: any
}



export interface S {
    // Customizable Area Start
    editedData: Review | null;
    newRly: string;
    selectedOption: string | null;
    reviewCollection: Review[];
    ratingVal: number | null;
    edit: boolean;
    selected: number | null;
    counts: number | any,
    rowsPerPage: number | any,
    page: number | any,
    selectedRows:any,
    // Customizable Area End
}


export interface SS {
    // Customizable Area Start
    id: number | any;
    // Customizable Area End
}



export default class ReviewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    updateReviewId: string = "";

    getReviewId: string = "";
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            ratingVal: null,
            editedData: null,
            reviewCollection: [],
            edit: false,
            selectedOption: "",
            selected: null,
            newRly: "",
            counts: 0,
            rowsPerPage: 5,
            page: 0,
            selectedRows:{}
            // Customizable Area End
        };


        // Customizable Area Start
        runEngine.attachBuildingBlock(this, this.subScribedMessages);


        // Customizable Area End
    }


    // Customizable Area Start


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestid = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );


            let response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestid === this.updateReviewId) {
                this.setState({ editedData: null, ratingVal: null })
                this.handleCloseEdit()
            }

            if (apiRequestid === this.getReviewId) {
                this.setState({ reviewCollection: response?.Review?.data });
                this.setState({ counts: response.meta.total_count })
            }


        }

        // Customizable Area End
    }
    componentDidMount(): any {
        this.getReview()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
        if (this.state.page !== prevState.page) {
            this.getReview();
        }
    }
    getReview = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReviewId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getReviewSubmitted}?page=${Number(this.state.page) + 1}&per_page=${this.state.rowsPerPage}`

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleCloseEdit = () => {
        this.setState({ edit: false,editedData:null,selected:null,selectedRows:{} })
    }

    handleUpdateReview = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const httpBody = {
            "catalogue_reviews_id": this.state.editedData.id,
            "comment": this.state.newRly
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateReviewId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateReviewSubmitted
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ edit: false })
        this.setState({ newRly: "" })

    };

    handleOpenEdit = (item: Review) => {
        this.setState({ edit: true, editedData: item, ratingVal: item.attributes.rating })
    }

    handleUpdate = () => {
        this.handleUpdateReview()
    }

    handleSelectRow = (event: any, item: Review,index:any) => {
        this.setState({ ratingVal: item?.attributes?.rating })
        this.setState({ edit: true, editedData: item, selected: event.target.value })
        this.setState((prevState) => ({
            selectedRows: {
              ...prevState.selectedRows,
              [index]: event.target.value,
            },
          }));          
    }


    handleReviewCommentChange = (event: any) => {
        this.setState({ newRly: event.target.value });
    }
    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({ page: newPage });
    };
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ page: 0 });
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    };
    // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { withStyles, createStyles, Theme, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { Button, Typography, Link, Backdrop, CircularProgress, Popover } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FolderIcon from '@material-ui/icons/Folder';
import IconButton from '@material-ui/core/IconButton';
import { featuredList } from "../../../components/src/CatalogueConstents.web";
import { ratings, downArrow, searchIcon, logoImage } from "./assets";
import AppBarPage from "../../../components/src/AppBar.web";
import CloseIcon from '@material-ui/icons/Close';
import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchIconEvents, searchIconSoftwares, searchIconSuppliers } from "../../landingpage/src/assets";
export const configJSON = require("./config");

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  countrySelect = () => {
    const { countryList } = this.state;
    return (
      <List
        component="nav"
        data-testid="countryselect"
        className={this.props.classes.countryListContainer}
        aria-label="mailbox folders"
      >
        {countryList.map((item: any, i: number) => (
          <ListItem
            button
            key={i}
            data-testid="countryFilterList"
            onClick={() => this.onCountryListClick(item)}
            divider={i !== countryList.length - 1}
            className={this.props.classes.countryListItems}
          >
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    )
  };

  regionSelect = () => {
    const { regionList } = this.state;
    return (
      <List
        component="nav"
        data-testid="region-select"
        className={`${this.props.classes.countryListContainer} ${this.props.classes.customScrollbar}`}
        aria-label="mailbox folders"
      >
        {regionList.map((item: any, i: number) => (
          <ListItem
            button
            key={i}
            onClick={() => this.onRegionListClick(item)}
            divider={i !== regionList.length - 1}
            className={this.props.classes.countryListItems}
            data-testid="region-list"
          >
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    )
  };

  categoryList = () => (
    <FormGroup
      className={this.props.classes.categoryFormGroup}
      data-testid='category-form-group'
    >
      {this.state.categoryList?.map((item: any, i: number) => {
        return (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                className={this.props.classes.categoryCheckbox}
                checked={this.checkCategory(item)}
                onChange={() => { this.handleCategory(item) }}
                name={item.category}
                style={{ color: this.checkCategory(item) ? this.themeColorDetect() : '#C8C9CA' }}
                data-testid={`item-${item.id}`}
              />
            }
            label={`${item.name} (${item.count})`}
            className={this.props.classes.categoryFormLabel}
            data-testid={`item-${item.id}`}
          />
        )
      })}
    </FormGroup>
  );


  subCategoryList = () => {
    const { classes } = this.props;
    return (
      <FormControl component="fieldset" className={`${classes.categoryContainer} ${this.state.selectedCategoryList.length && classes.customScrollbarSubcategory}`}>
        <FormLabel component="legend" className={classes.filterSubHeading}>Sub Categories</FormLabel>
        {this.state.selectedCategoryList && <div className={classes.search}>
          <div className={classes.searchIcon}> <SearchIcon /> </div>
          <InputBase
            placeholder="Search"
            value={this.state.searchSubCategories}
            data-testid="subcat-search"
            classes={{ root: classes.inputRoot, input: classes.inputInput }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => this.setState({ searchSubCategories: e.target.value })}
          />
        </div>}
        {this.state.selectedCategoryList.map((item: any, i: number) => (
          <React.Fragment key={i}>
            <Typography variant="body1" className={classes.subCategoryHeading}>{item.name}</Typography>
            <FormGroup style={{ marginTop: '0px' }}>
              {this.handlefilteredData(item.sub_categories)?.map((data: any, i: number) => {
                return (
                  <FormControlLabel
                    key={i}
                    data-testid='subcategory-checkbox'
                    control={
                      <Checkbox
                        className={classes.categoryCheckbox}
                        checked={this.checkSubCategory(data)}
                        name={data.name}
                        onChange={() => { this.handleSubCategory(data) }}
                        style={{ color: this.checkSubCategory(data) ? this.themeColorDetect() : '#C8C9CA' }}
                      />
                    }
                    label={`${data.name} (${data.count})`}
                    className={classes.categoryFormLabel}
                  />
                )
              })}
            </FormGroup>
            {(this.state.selectedCategoryList.length - 1) !== i && < Divider variant="middle" style={{ margin: '30px 15px 0px 15px' }} />}
          </React.Fragment>
        ))}
      </FormControl>
    )
  };

  getSupplierTypeList = (classes: any) => {
    return (
      <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
        <FormLabel component="legend" className={classes.filterSubHeading}>Supplier Type</FormLabel>
        <FormGroup style={{ marginTop: '10px' }}>
          {[{ id: 1, type: "Brands" }, { id: 2, type: "Distributors" }].map((data: any, i: number) => {
            return (
              <FormControlLabel
                key={i}
                data-testid='supplierType-checkbox'
                control={
                  <Checkbox
                    className={classes.categoryCheckbox}
                    checked={this.checkSupplierType(data)}
                    name={data.type}
                    onChange={() => this.handleSupplierType(data)}
                    style={{ color: this.checkSupplierType(data) ? this.themeColorDetect() : '#C8C9CA' }}
                  />
                }
                label={`${data.type}`}
                className={classes.categoryFormLabel}
              />
            )
          })}
        </FormGroup>

      </FormControl>
    )
  };


  selectedCategories = () => (
    <>
      {this.state.selectedCategoryList && this.state.selectedCategoryList.map((item: any, i: number) => {
        return (
          <Chip key={i} data-testid={item.name} label={item.name} onDelete={() => this.handleCategory(item)} className={this.props.classes.filterChips} />
        )
      })}
    </>
  );


  featuresList = () => (
    <List component="nav" className={this.props.classes.filterSelectBox} aria-label="mailbox folders" data-testid='feature-select'>
      {featuredList.map((item, i) => (
        <ListItem
          button
          key={item.label}
          onClick={() => this.handleFeatureClick(item)}
          divider={i !== featuredList.length - 1}
          className={this.props.classes.filterList}
          data-testid='feature-list'
        >
          <ListItemText primary={item.label} style={{ color: this.themeColorDetect() }} />
        </ListItem>
      ))}
    </List>
  );
  getScrollColor = (classes: any) => {
    const { value } = this.state
    if (value == "All" || value == "Suppliers") {
      return `${classes.customScrollbarAll}`
    }
    else if (value == "Software") {
      return `${classes.customScrollbarSoftware}`

    }
    else if (value == "Services") {
      return `${classes.customScrollbarServices}`

    }
    else if (value == "Events") {
      return `${classes.customScrollbarEvents}`
    }

  }


  formatString = (str: any) => {
    if (str && str.length > 150) {
      return str.slice(0, 150) + '...';
    }
    else {
      return str
    }
  }

  cataloguesList = () => {
    const { classes } = this.props;
    const { catalogueList } = this.state;

    return (
      <div id="scrollbar" className={this.getScrollColor(classes)}>


        <InfiniteScroll
          scrollableTarget="scrollbar"
          dataLength={this.state.recordCount}
          next={this.handleLoadMore}
          hasMore={true}
          loader={<div></div>}
          style={{ overflow: "hidden", padding: "0px 10px" }}
        >
          <Grid container spacing={3} data-testid="catalogGrid">

            {catalogueList && catalogueList.map((item: any) => {

              const id = item.data.id
              const { directory, logo, featured, name, region_name, short_description, location_name, payment_status, good_company_icons } = item.data.attributes
              return (
                <Grid key={id} item xs={12} sm={12} md={this.state.view == 1 ? 6 : 12} xl={this.state.view == 1 ? 4 : 6} data-testid="catalogCard">

                  <Card data-testid={`item-${id}`} className={classes.cards} >
                    <Link color="inherit" href={this.getListingUrl(directory, id, payment_status)} underline="none">
                      <Box className={classes.cardHeader}>
                        <div className={this.state.view == 0 ? classes.imgContainer : null}>
                          <Box className={this.state.view == 0 ? classes.restroImg : classes.cardImg}>
                            {logo ?
                              (
                                <CardMedia
                                  component="img"
                                  alt="No Logo"
                                  height="140"
                                  image={logo}
                                  title="Restaurant"
                                  style={{ borderColor: this.themeColorDetect(), textAlign: 'center' }}
                                />
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  height="135px"
                                  width="135px"
                                  borderRadius="50%"
                                  borderColor={this.themeColorDetect()}
                                  border={1}
                                  style={{ borderColor: this.themeColorDetect() }}
                                > No logo </Box>
                              )}
                          </Box>
                        </div>
                        <CardContent className={classes.cardContentEnd}>
                          <Box className={classes.cardContentBox} style={this.state.view == 0 ? cardEndStyle : {}}>
                            <Box>
                              <Typography variant="h6" component="h2" className={`${classes.cardHeading} ${classes.cardTitleElipis}`}>
                                {name}
                              </Typography>
                              <Box style={{ display: 'flex', visibility: "hidden" }}>
                                <FolderIcon className={classes.cardSubHeadingIcon} />
                                <Typography className={classes.cardSubHeading} style={{ visibility: "hidden" }} variant="body2" color="textSecondary" component="p">
                                  General Supplies
                                </Typography>
                              </Box>
                            </Box>

                            <Box>
                              <Box className={classes.cardDescriptionContainer} >
                                <Typography variant="body2" color="textSecondary" component="p" className={classes.cardDescriptionHead}>
                                  Good Company rating
                                </Typography>
                                {good_company_icons && good_company_icons.map((company: string) => {
                                  return (<img src={company} style={{ width: "25px", height: "25px", marginLeft: "2px" }} />)
                                })}
                              </Box>

                              <CardActions className={classes.cardBtnContainer} >
                                {
                                  featured && <Button variant="contained"
                                    className={classes.cardBtnStyle}
                                    style={{
                                      backgroundColor: '#F1C52E',
                                      color: '#161616'
                                    }} >
                                    Featured
                                  </Button>
                                }

                                {payment_status == "pending" &&
                                  <Button variant="contained"
                                    className={classes.cardBtnStyle}
                                    style={{
                                      backgroundColor: '#343A40',
                                      color: '#FFFDFD'
                                    }}>
                                    Unclaimed
                                  </Button>
                                }
                              </CardActions>
                            </Box>
                          </Box>

                          {this.state.view == 0 && (
                            <CardContent style={cardStyle}>
                              <Typography className={classes.footerTitle}
                                style={this.state.view == 0 ? {
                                  fontSize: '16px',
                                  marginTop: '25px',
                                } : {}}
                                variant="body2" color="textSecondary" component="p">
                                {region_name} {location_name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" component="p" className={`${classes.footerDescription}`}>
                                {this.formatString(short_description)}
                              </Typography>
                              <Button variant="text" className={classes.listBtn}>
                                <Typography variant="body1" >Read more</Typography>
                              </Button>
                            </CardContent>
                          )}
                        </CardContent>
                      </Box>
                      {this.state.view == 1 && (
                        <>
                          <Divider variant="middle" />
                          <CardContent style={{ paddingBottom: '0px' }}>
                            <Typography className={classes.footerTitle} variant="body2" color="textSecondary" component="p" >
                              {region_name} {location_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.footerDescription}>
                              {this.formatString(short_description)}
                            </Typography>
                          </CardContent>
                        </>
                      )}
                    </Link>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </InfiniteScroll>

      </div>

    )
  }

  renderIntegrations = (classes: any) => {
    return this.state.integrations.length > 0 ? this.state.integrations.map((data: any, i: number) => (
      <FormControlLabel
        key={i}
        data-testid='integration-checkbox'
        control={
          <Checkbox
            className={classes.categoryCheckbox}
            checked={this.checkIntegrationsType(data)}
            name={data.name}
            onChange={() => this.handleIntegrations(data)}
            style={{ borderRadius: "50%", color: this.checkIntegrationsType(data) ? this.themeColorDetect() : '#C8C9CA' }}
          />
        }
        label={<span style={{ display: "flex", alignItems: "center" }}><img src={data.logo} alt={data.name} style={{ borderRadius: "50%", width: "25px", height: "25px", marginRight: "10px" }} /> {data.name} ({data.count})</span>}
        className={classes.categoryFormLabel}
      />
    )
    ) : <Typography component="p" className={classes.heading14}>{configJSON.noResultText}</Typography>
  }

  renderMoreFilters = (classes: any) => {
    return (<div>


      <Grid item xs={12} sm={6} md={12}>
        <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} className={classes.subDivider} />
        <div className={classes.mt40}>
          <Button data-testid="hideFilters" variant="text" className={classes.categoryBtn} onClick={this.handleMoreSoftware}>
            <Typography variant="body1">Hide Filters</Typography>
          </Button>
        </div>
        <div className={classes.mt40}>
          <Typography component="p" className={classes.filterSubHeading}>Technology</Typography>
        </div>
        <div >
          <Typography component="p" className={classes.heading14}>Integrations</Typography>
          <div>
            <div className={classes.search}>
              <div className={classes.searchIcon}> <SearchIcon /> </div>
              <InputBase
                placeholder="Search"
                value={this.state.searchIntegrations}
                data-testid="integration-search"
                classes={{ root: classes.inputRoot, input: classes.inputInput }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={this.handleSearchIntegration}
              />
            </div>


            <div className={`${this.state.integrations.length > 10 && classes.customScrollbarSubcategory}`}>
              <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
                <FormGroup style={{ marginTop: '10px' }}>
                  {this.renderIntegrations(classes)}

                </FormGroup>

              </FormControl>
            </div>



          </div>
        </div>
      </Grid>


      <Grid item xs={12} sm={6} md={12}>
        <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
          <Typography component="p" className={classes.heading14}>Platform</Typography>
          <FormGroup style={{ marginTop: '10px' }}>
            {this.state.platform.map((data: any, i: number) => {
              return (
                <FormControlLabel
                  key={i}
                  data-testid='platform-checkbox'
                  control={
                    <Checkbox
                      className={classes.categoryCheckbox}
                      checked={this.checkPlatform(data)}
                      name={data.platform_type}
                      onChange={() => this.handlePlatforms(data)}
                      style={{ color: this.checkPlatform(data) ? this.themeColorDetect() : '#C8C9CA' }}
                    />
                  }
                  label={`${data.platform_type}`}
                  className={classes.categoryFormLabel}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} />

      </Grid>


      <Grid item xs={12} sm={6} md={12}>
        <Typography component="p" className={classes.filterSubHeading}>Service</Typography>
        <div>
          <Typography component="p" className={classes.heading600}>In Good Company</Typography>

          <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
            <FormGroup>
              {this.state.in_good_companies.map((data: any, i: number) => {
                return (
                  <FormControlLabel
                    key={i}
                    data-testid='goodCompany-checkbox'
                    control={
                      <Checkbox
                        className={classes.categoryCheckbox}
                        checked={this.checkGoodCompany(data)}
                        name={data.name}
                        onChange={() => { this.handleGoodCompany(data) }}
                        style={{ color: this.checkGoodCompany(data) ? this.themeColorDetect() : '#C8C9CA' }}
                      />
                    }
                    label={<span style={{ display: "flex", alignItems: "center" }}><img src={data.icon} alt={data.name} style={{ borderRadius: "50%", width: "25px", height: "25px", marginRight: "10px" }} /> {data.name} </span>}
                    className={classes.categoryFormLabel}
                  />
                )
              })}
            </FormGroup>

          </FormControl>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={12}>
        <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
          <Typography component="p" className={classes.heading600}>Data Ownership</Typography>
          <FormGroup style={{ marginTop: '10px' }}>
            {this.state.data_ownership.map((data: any, i: number) => {
              return (
                <FormControlLabel
                  key={i}
                  data-testid='dataOwnership-checkbox'
                  control={
                    <Checkbox
                      className={classes.categoryCheckbox}
                      checked={this.checkDataOwner(data)}
                      name={data.type}
                      onChange={() => { this.handleDataOwnerShip(data) }}
                      style={{ color: this.checkDataOwner(data) ? this.themeColorDetect() : '#C8C9CA' }}
                    />
                  }
                  label={`${data.data_ownership_type}`}
                  className={classes.categoryFormLabel}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} />

      </Grid>


      <Grid item xs={12} sm={6} md={12}>
        <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
          <Typography component="p" className={classes.heading600}>Support</Typography>
          <FormGroup style={{ marginTop: '10px' }}>
            {this.state.supports.map((data: any, i: number) => {
              return (
                <FormControlLabel
                  key={i}
                  data-testid='support-checkbox'
                  control={
                    <Checkbox
                      className={classes.categoryCheckbox}
                      checked={this.checkSupport(data)}
                      name={data.support_type}
                      onChange={() => { this.handleSupport(data) }}
                      style={{ color: this.checkSupport(data) ? this.themeColorDetect() : '#C8C9CA' }}
                    />
                  }
                  label={`${data.support_type}`}
                  className={classes.categoryFormLabel}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} />

      </Grid>

      <Grid item xs={12} sm={6} md={12}>
        <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
          <Typography component="p" className={classes.heading600}>Training</Typography>
          <FormGroup style={{ marginTop: '10px' }}>
            {this.state.trainings.map((data: any, i: number) => {
              return (
                <FormControlLabel
                  key={i}
                  data-testid='trainings-checkbox'
                  control={
                    <Checkbox
                      className={classes.categoryCheckbox}
                      checked={this.checkTrainings(data)}
                      name={data.training_type}
                      onChange={() => { this.handleTrainings(data) }}
                      style={{ color: this.checkTrainings(data) ? this.themeColorDetect() : '#C8C9CA' }}
                    />
                  }
                  label={`${data.training_type}`}
                  className={classes.categoryFormLabel}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} />

      </Grid>


      <Grid item xs={12} sm={6} md={12}>
        <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
          <Typography component="p" className={classes.heading600}>Languages</Typography>
          <FormGroup style={{ marginTop: '10px' }}>
            {this.state.languages.map((data: any, i: number) => {
              return (
                <FormControlLabel
                  key={i}
                  data-testid='language-checkbox'
                  control={
                    <Checkbox
                      className={classes.categoryCheckbox}
                      checked={this.checkLanguages(data)}
                      name={data.name}
                      onChange={() => { this.handleLanguages(data) }}
                      style={{ color: this.checkLanguages(data) ? this.themeColorDetect() : '#C8C9CA' }}
                    />
                  }
                  label={`${data.name}`}
                  className={classes.categoryFormLabel}
                />
              )
            })}
          </FormGroup>
        </FormControl>

      </Grid>
    </div>)

  }
  renderToggleFilter = (classes: any) => {
    return (
      <div className={classes.mt40}>
        <Button data-testid="moreSoftware" variant="text" className={classes.categoryBtn} onClick={this.handleMoreSoftware}>
          <Typography variant="body1">More Filters</Typography>
        </Button>
      </div>
    )
  }



  renderSoftwareFilter = (classes: any) => {
    return (
      <div>

        {!this.state.showSoftwareFilter ?
          this.renderToggleFilter(classes)
          :

          this.renderMoreFilters(classes)
        }

      </div>)
  }

  formatedDate = () => {
    const { defaultDate, date_range } = this.state
    if (defaultDate == "Select Date Range") {
      return defaultDate
    }
    else if (this.state.date_range != "Select Date Range") {
      let start_date = moment(date_range[0]).format('DD/MM/YYYY');
      let end_date = moment(date_range[1]).format('DD/MM/YYYY');
      return `${start_date}-${end_date}`
    }
    else {
      return "Select Date Range"
    }
  }
  renderEventFilter = (classes: any) => {
    return (
      <div>
        <Grid item xs={12} sm={6} md={12}>
          <FormControl component="fieldset" className={classes.categoryContainer} style={{ width: '100%' }}>
            <Typography component="p" className={classes.heading14}>Digital/Physical</Typography>
            <FormGroup style={{ marginTop: '10px' }}>
              {[{ id: 1, type: "Digital" }, { id: 2, type: "Physical" }].map((data: any, i: number) => {
                return (
                  <FormControlLabel
                    key={i}
                    data-testid='eventType-checkbox'
                    control={
                      <Checkbox
                        className={classes.categoryCheckbox}
                        checked={this.checkEventType(data)}
                        name={data.type}
                        onChange={() => this.handleEventType(data)}
                        style={{ color: this.checkEventType(data) ? this.themeColorDetect() : '#C8C9CA' }}
                      />
                    }
                    label={`${data.type}`}
                    className={classes.categoryFormLabel}
                  />
                )
              })}
            </FormGroup>
          </FormControl>
          <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} />

        </Grid>

        <Grid item xs={12} sm={6} md={12}>

          <Typography className={classes.filterHeading} style={{ fontSize: '14px', marginTop: '30px' }}>Date</Typography>
          <div className={classes.mt10}>
            <style>
              {`.p-datepicker{
                        background:#FFF;
                        border-radius: 8px;
                        border: 1px solid #E2E8F0;
                        padding:10px;
                        box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
                        width:431px !important;
                        height:auto;
                      }`}
              {`.p-datepicker-year{
                        font-size:14px;
                        font-weight:700;
                        font-family: 'Poppins, sans-serif';
                        color:black;
                       
                      }`}
              {`.p-monthpicker-month{
                        font-size:14px;
                        font-weight:400;
                        font-family: 'Poppins, sans-serif';
                        border: 1px solid #E2E8F0;
                        margin:5px 13px;
                        width:100px;
                        padding:5px;
                        border-radius:7px;
                      }`}
              {`.p-datepicker-prev{
                        border:none;
                        color: #94A3B8;
                        background:#fff;
                      }`}
              {`.p-datepicker-next{
                        background:#fff;
                        border:none;
                        color: #94A3B8;
                      }`}
              {`.p-highlight{
                      color: #7e629b;
                      border: 1px solid #ded8e7;
                      background: #d5cce166;
                      }`}
              {
                `.p-datepicker-header{
                          border:none;
                        }`
              }

              {
                `.p-inputtext{
                  border-radius: 15px;
                  padding: 6px 10px;
                  color: black;
                  font-family: 'Poppins, sans-serif';
                  font-Size: 12px;
                  font-weight: 400;
                  width: 194px;        
              }`

              }
            </style>
            <div
              className={classes.countryContainer}
            >
              <Typography style={{ color: this.state.selectedMonth == 'Select Month' ? '#A3A3A3' : '#2D2E2F' }}>{this.state.selectedMonth}</Typography>
              {this.state.selectedMonth == 'Select Month' ? <ExpandMore aria-describedby={"month"} onClick={this.handleMonthPicker} /> : <CloseIcon onClick={this.handleMonthClear} />}
              <Popover
                id={"month"}
                data-testid="month"
                open={Boolean(this.state.monthAnchor)}
                anchorEl={this.state.monthAnchor}
                onClose={this.handleMonthClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Calendar inline data-testid="monthCalendar" hideOnDateTimeSelect={true} placeholder="Select Month" numberOfMonths={3} value={this.state.selectedMonth} onChange={this.handleMonth} view="month" dateFormat="MM/yy" />
              </Popover>

            </div>
          </div>

        </Grid>

        {this.state.selectedMonth != "Select Month" && <Grid item xs={12} sm={6} md={12}>


          <div
            className={`${classes.countryContainer} ${classes.mt10}`}
          >
            <Typography style={{ color: this.state.defaultDate == 'Select Date Range' ? '#A3A3A3' : '#2D2E2F' }}>{this.formatedDate()}</Typography>
            {this.state.defaultDate == 'Select Date Range' ? <ExpandMore aria-describedby={"date-range"} onClick={this.handleDatePicker} /> : <CloseIcon onClick={this.handleDateClear} />}


            <Popover
              id={"date-range"}
              data-testid="date-range"
              open={Boolean(this.state.dateAnchor)}
              anchorEl={this.state.dateAnchor}
              onClose={this.handleDateClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Calendar inline data-testid="dateRange" hideOnDateTimeSelect={true} placeholder="Select Date Range" value={this.state.date_range} onChange={this.handleDateRange} selectionMode="range" />

            </Popover>

          </div>





        </Grid>}

      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { isLoading } = this.state
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false} disableGutters
          style={{ background: this.themeDetect() }}
        >
          <AppBarPage
            logoImg={logoImage}
            data-testid="appSignIn"
            handleClick={this.goToLoginPage}
            serachIcon={searchIcon}
            downarrow={downArrow}
            goToHomePage={() => this.handleNavigation("Home")}
            handleHeaderNavigation={() => this.navigateToAccount(this.state.userdetails?.user_type)}
            data-testId={"signInBtn"}
            username={this.state.userdetails}
            handleUserSignout={this.handleSignoutClick}
            dummyicon={""}
            handleAdvancedSearch={this.handleAdvancedSearch}
            handleSubmit={this.handleSubmit}
            searchText={this.state.searchTermProp}
            handleSearchInput={this.handleSearchInput}
            showDirectoryPopup={this.state.showDirectoryPopup}
            searchDirectoryData={this.state.searchDirectoryData}
            handleDirectoryPopupClose={this.handleDirectoryPopupClose}
          />
          <Box className={classes.navBar}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              centered
              classes={{ centered: classes.tabContainer }}
              TabIndicatorProps={{ style: { height: '3px', backgroundColor: this.themeColorDetect() } }}
            >
              <Tab value={"All"} label="All" style={{ color: this.tabColorDetect("All") }} />
              <Tab value={"Suppliers"} label="Suppliers" style={{ color: this.tabColorDetect("Suppliers") }} />
              <Tab value={"Software"} label="Software" style={{ color: this.tabColorDetect("Software") }} />
              <Tab value={"Services"} label="Services" style={{ color: this.tabColorDetect("Services") }} />
              <Tab value={"Events"} label="Events" style={{ color: this.tabColorDetect("Events") }} />
            </Tabs>
          </Box>
          <Container maxWidth={false} className={classes.main}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <Typography className={classes.provider}>{this.state.value}</Typography>
                <Box className={classes.filterBox}>
                  <Typography className={classes.filterHeading}>Filters</Typography>
                  <Typography className={classes.filterHeading} style={{ fontSize: '14px', marginTop: '30px' }}>Location</Typography>
                  <Grid container style={{ marginTop: '15px' }}>
                    <Grid item xs={12} sm={6} md={12}>
                      <div
                        className={classes.countryContainer}
                        onClick={this.handleCountry}
                        data-testid="countryFilter"
                      >
                        <Typography style={{ color: this.state.country == 'Region' ? '#A3A3A3' : '#2D2E2F' }}>{this.state.country}</Typography>


                        {this.state.country == "Region" ? <ExpandMore aria-describedby="country" /> : <CloseIcon data-testid="clearCountry" onClick={this.clearCountry} />}





                      </div>
                      <Popover
                        id={"countryAnchor"}
                        data-testid="country"
                        open={Boolean(this.state.countryAnchor)}
                        anchorEl={this.state.countryAnchor}
                        onClose={this.handleCountryClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {this.countrySelect()}
                      </Popover>

                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <div
                        data-testid="region-filter"
                        onClick={this.state.countryId && this.handleRegion}
                        className={classes.countryContainer}
                      >
                        <Typography style={{ color: this.state.region == 'Country' ? '#A3A3A3' : '#2D2E2F' }}>{this.state.region}</Typography>
                        {this.state.region == "Country" ? <ExpandMore /> : <CloseIcon data-testid="clearRegion" onClick={this.clearRegion} />}
                      </div>
                      <Popover
                        id={"regionAnchor"}
                        data-testid="region"
                        open={Boolean(this.state.regionAnchor)}
                        anchorEl={this.state.regionAnchor}
                        onClose={this.handleRegionClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {this.regionSelect()}
                      </Popover>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" style={{ margin: '35px 15px 15px 15px' }} />

                  <Grid container style={{ marginTop: '15px' }}>
                    <Grid item xs={12} sm={6} md={12}>
                      <FormControl component="fieldset" className={`${classes.categoryContainer} ${this.state.categoryList.length > 15 && classes.customScrollbar}`}>
                        <FormLabel component="legend" className={classes.filterSubHeading}>Categories</FormLabel>
                        {this.categoryList()}
                      </FormControl>
                    </Grid>
                    {this.state.value !== "Events" && <Grid item xs={12} sm={6} md={12}>
                      <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} className={classes.subDivider} />
                      {this.subCategoryList()}
                    </Grid>}

                    {this.state.value == "Suppliers" && <Grid item xs={12} sm={6} md={12}>
                      <Divider variant="middle" style={{ margin: '25px 15px 15px 15px' }} className={classes.subDivider} />
                      {this.getSupplierTypeList(classes)}
                    </Grid>}
                    {this.state.value == "Software" && this.renderSoftwareFilter(classes)}

                    {this.state.value == "Events" && this.renderEventFilter(classes)}

                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Box className={classes.features}>
                  <Box className={classes.paginationContainer}>

                    <div className={classes.searchDirectory}>
                      <div className={classes.searchIconDir}> <SearchIcon /> </div>
                      <InputBase
                        type="text"
                        data-testid="search-advance"
                        placeholder={`Search in ${this.state.value}`}
                        value={this.state.search}
                        onChange={this.handleSearchCatalogue}
                        classes={{ root: classes.inputRootDirectory, input: classes.inputInputDir }}
                        inputProps={{ 'aria-label': 'search' }}
                      />
                      {this.state.showSearchResult && <Box className={classes.searchResultWrapper}>

                        <Box className={classes.titleWrapper}>
                          <Typography className={classes.categoriesTitle}>Listing {`(${this.state.listing_count})`}</Typography>
                          <Divider variant="middle" style={{ margin: '24px 15px 15px 15px', width: "70%" }} />
                        </Box>
                        {this.state.listingResult.map((item: any) => (
                          <Link color="inherit" href={this.getListingUrl(item.directory, item.id, item.payment_status)} underline="none">
                            <Box
                              key={item.id}

                              className={classes.searchResultRow}
                            >
                              <Box className={classes.searchResultImgWrapper}>
                                <img
                                  className={classes.advancedSearchIcon}
                                  src={searchIconEvents}
                                />
                              </Box>
                              <Box className={classes.searchResultTextBox}>
                                <Typography className={classes.searchResultLastText}>
                                  <Typography className={classes.searchResultFirstText}>
                                    {this.state.search}
                                  </Typography>
                                  in {item.name} ({item.count} results)
                                </Typography>
                              </Box>
                            </Box>
                          </Link>)
                        )}


                        <Box className={classes.titleWrapper}>
                          <Typography className={classes.categoriesTitle}>Categories {`(${this.state.categories_count})`}</Typography>
                          <Divider variant="middle" style={{ margin: '25px 15px 15px 15px', width: "70%" }} />
                        </Box>
                        {this.state.categoriesResult.map((item: any) => (<Box
                          key={item.id}
                          onClick={() => this.handleCategoryCheck({ categories: this.state.categoryList }, item.id, null)}
                          className={classes.searchResultRow}
                        >
                          <Box className={classes.searchResultImgWrapper}>
                            <img
                              className={classes.advancedSearchIcon}
                              src={searchIconSuppliers}
                            />
                          </Box>
                          <Box className={classes.searchResultTextBox}>
                            <Typography className={classes.searchResultLastText}>
                              <Typography className={classes.searchResultFirstText}>
                                {this.state.search}
                              </Typography>
                              in {item.name} ({item.count} results)
                            </Typography>
                          </Box>
                        </Box>)
                        )}


                        <Box className={classes.titleWrapper}>
                          <Typography className={classes.categoriesTitle}>Product Group {`(${this.state.productGroup_count})`}</Typography>
                          <Divider variant="middle" style={{ margin: '25px 15px 15px 15px', width: "70%" }} />
                        </Box>
                        {this.state.productGroupResult.map((item: any) => (<Box
                          key={item.id}
                          onClick={() => this.handleCategoryCheck({ categories: this.state.categoryList }, item.category_id, item.id)}
                          className={classes.searchResultRow}
                        >
                          <Box className={classes.searchResultImgWrapper}>
                            <img
                              className={classes.advancedSearchIcon}
                              src={searchIconSoftwares}
                            />
                          </Box>
                          <Box className={classes.searchResultTextBox}>
                            <Typography className={classes.searchResultLastText}>
                              <Typography className={classes.searchResultFirstText}>
                                {this.state.search}
                              </Typography>
                              in {item.name} ({item.count} results)
                            </Typography>
                          </Box>
                        </Box>)
                        )}





                      </Box>}
                    </div>
                  </Box>

                  <Box className={classes.filterContainer}>
                    <Grid container>
                      <Grid className={classes.filterChipsContainer} item xs={12} sm={12} md={8}>
                        {this.state.selectedCategoryList.length !== 0 && <Typography className={classes.sortFilter} component="span">Applied filters:</Typography>}
                        {this.selectedCategories()}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>

                        <Box className={classes.filterContainer} style={{ marginTop: '10px', justifyContent: "flex-end", alignItems: 'center', marginBottom: this.state.selectedCategoryList.length == 0 ? '10px' : '0px' }}>
                          <Button className={classes.filterMainBox}
                            onClick={this.toggleFeature}
                            style={{ borderColor: this.themeColorDetect() }}
                            data-testid="feature-filter"
                          >
                            <Typography style={{ color: this.themeColorDetect(), textTransform: "none" }}>Sort by: {this.state.feature}</Typography>
                            {!Boolean(this.state.anchorEl) ? <ExpandMore style={{ color: this.themeColorDetect() }} /> : <ExpandLess style={{ color: this.themeColorDetect() }} />}

                          </Button>
                          <Popover
                            id={"test"}
                            data-testid="sortBy"
                            open={Boolean(this.state.anchorEl)}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            {this.featuresList()}
                          </Popover>
                          <Box className={classes.viewContainer}>
                            <IconButton
                              onClick={() => { this.handleView(1) }}
                              className={classes.viewItem}
                              style={{ borderRight: '1px solid #ced3d9' }}
                              data-testid="view-grid"
                            >
                              <ViewModuleOutlinedIcon style={{ color: this.viewDetect(1) }} />
                            </IconButton>
                            <IconButton
                              onClick={() => { this.handleView(0) }}
                              className={classes.viewItem}
                              data-testid="view-list"
                            >
                              <ViewListOutlinedIcon style={{ color: this.viewDetect(0) }} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                {this.cataloguesList()}
              </Grid>
            </Grid>
          </Container>
        </Container>
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  tabStyle: {
    height: '3px',
  },
};


export const cardStyle = { paddingBottom: '0px', padding: '0px' }

export const cardEndStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }

export const cataloguePageStyle = (theme: Theme) =>
  createStyles({
    rootView: {
      width: '100%',
      height: '20px',
      background: 'linear-gradient(90deg, rgba(122,54,197,1) 0%, rgba(148,128,232,1) 35%, rgba(156,109,242,1) 72%, rgba(204,146,245,1) 100%)',
    },
    navBar: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0px 10px',
      borderBottom: '1px solid #D6D6D6',
      marginTop: "9%",
      "@media(max-width: 1000px)": {
        marginTop: "14%",
      },
      "@media(max-width: 760px)": {
        marginTop: "16%",
      },
      "@media(max-width: 630px)": {
        marginTop: "20%",
      },
      "@media(max-width: 480px)": {
        marginTop: "30%",
      },
    },
    tabContainer: {
      padding: '5px 0px',
      '& button': {
        minWidth: '110px'
      },
      '& button span:first-child': {
        fontSize: '18px',
        fontWeight: 400,
        textTransform: 'capitalize',
        fontFamily: 'Poppins, sans-serif'
      },
      '& button.Mui-selected span:first-child': {
        fontWeight: '600'
      },
      "@media(max-width:540px)": {
        justifyContent: 'flex-start',
        overflow: 'scroll'
      },
      '& + span': {
        "@media(max-width:540px)": {
          display: 'none'
        },
      }
    },
    tabIndicator: {
      "@media(max-width:540px)": {
        display: 'none'
      },
    },
    main: {
      padding: '30px 15px',
    },
    provider: {
      fontSize: '18px',
      fontWeight: 600,
      paddingLeft: '25px',
      color: "#000",
      fontFamily: 'Poppins, sans-serif'
    },
    paging: {
      fontSize: '14px',
      fontWeight: 500,
      color: "#FFBB13",
      fontFamily: 'Poppins, sans-serif'
    },
    sortFilter: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#666666',
      marginRight: '15px',
      fontFamily: 'Poppins, sans-serif'
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: '10px 0px',
    },
    filterChips: {
      backgroundColor: '#F8F9FA',
      borderRadius: '3px',
      height: '28px',
      margin: '0px 15px 10px 0px',
      '& span': {
        fontSize: '12px',
        fontWeight: '400',
        color: '#212529',
        fontFamily: 'Poppins, sans-serif'
      },
      '& svg': {
        width: '17px',
        height: '17px'
      }
    },
    filterChipsContainer: {
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      marginTop: "15px"
    },
    filterMainBox: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      padding: '4px 10px',
      marginRight: '10px',
      borderRadius: '4px',
      paddingRight: '2px',
      border: '1px solid #FFBB13',
      position: 'relative',
      width: '200px',
      height: "30px",
      justifyContent: 'space-between',
      '& p': {
        color: '#FFBB13',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Poppins, sans-serif',
        marginRight: '3px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& svg': {
        color: '#FFBB13',
        width: '20px',
        height: '20px',
      },
      "@media(max-width:600px)": {
        width: '100%',
      },
    },
    filterSelectBox: {
      width: '200px',
      backgroundColor: "#fff",
      height: "auto",
      boxShadow: '0px 0px 1px',
      borderRadius: '2px',
      zIndex: 99,
      padding: '0px'
    },
    filterList: {
      '& div span': {
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Poppins, sans-serif',
      }
    },
    viewContainer: {
      display: 'flex',
      backgroundColor: '#fff',
      border: '1px solid #ced3d9',
      borderRadius: '3px',
      margin: '0px 15px',
      "@media(max-width:600px)": {
        display: 'none',
      },
      "@media((min-width: 992px) and (max-width: 1024px))": {
        marginLeft: '0px'
      },
    },
    viewItem: {
      padding: '2px 5px',
      borderRadius: 'unset',
      height: "30px"
    },
    filterBox: {
      backgroundColor: '#fff',
      marginTop: '100px',
      padding: '45px 25px',
    },
    filterHeading: {
      fontSize: '16px',
      fontWeight: 700,
      color: "#2D2E2F",
      fontFamily: 'Poppins, sans-serif'
    },
    filterSubHeading: {
      fontSize: '14px',
      fontWeight: 700,
      color: '#2D2E2F !important',
      fontFamily: 'Poppins, sans-serif'
    },
    inputRoot: {
      color: 'inherit',
      width: '98%'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      fontSize: '12px',
      fontWeight: 400,
      color: '#2D2E2F'
    },
    search: {
      position: 'relative',
      marginRight: '40px',
      marginLeft: 0,
      border: '1px solid #ECE1E1',
      borderRadius: '13px',
      marginTop: '20px',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: '18px',
        height: '18px',
      }
    },
    searchIconDir: {
      padding: theme.spacing(0, 3),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: '18px',
        height: '18px',
      }
    },
    inputInputDir: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      fontSize: '14px',
      fontWeight: 400,
      color: '#2D2E2F',
      fontFamily: "Poppins"

    },
    searchDirectory: {
      position: 'relative',
      marginRight: '20px',
      marginLeft: 0,
      borderRadius: '8px',
      width: "387px",
      height: "54px",
      background: "#ffff"
    },
    inputRootDirectory: {
      color: 'inherit',
      width: '98%',
      marginTop: "10px"
    },
    countryContainer: {
      border: '1px solid #ECE1E1',
      display: 'flex',
      padding: '4px 10px',
      position: 'relative',
      borderRadius: '13px',
      marginRight: '40px',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '15px',
      '& p': {
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Poppins, sans-serif'
      },
      '& svg': {
        fontSize: '22px',
        color: '#A3A3A3',
      },
    },
    subDivider: {
      "@media((min-width: 600px) and (max-width: 960px))": {
        display: 'none',
      },
    },
    countryListContainer: {
      width: 200,
      backgroundColor: "#fff",
      height: "auto",
      boxShadow: '0px 0px 1px',
      borderRadius: '2px',
      zIndex: 99,
      padding: '0px',
    },
    customScrollbar: {
      height: 280,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },


      "&::-webkit-scrollbar-thumb:active": {
        background: "#B300ED",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#B300ED",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "#B300ED",
      },

    },
    countryListItems: {
      padding: '3px 16px',
      '& div span': {
        color: '#000',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Poppins, sans-serif',
      },
    },
    categoryContainer: {
      marginTop: '20px',
      width: "100%"
    },
    customScrollbarRegion: {
      height: 400,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },


      "&::-webkit-scrollbar-thumb:active": {
        background: "#B300ED",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#B300ED",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "#B300ED",
      },

    },
    customScrollbarSubcategory: {
      height: 300,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },


      "&::-webkit-scrollbar-thumb:active": {
        background: "#B300ED",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#B300ED",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "#B300ED",
      },

    },
    customScrollbarAll: {
      height: 1021,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar-thumb": {
        background: "#FFBB13",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },



      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "#FFBB13",
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: "#FFBB13",
      },

    },
    customScrollbarSoftware: {
      height: 1021,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#F62B8D",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: "#F62B8D",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "#F62B8D",
      },

    },
    customScrollbarServices: {
      height: 962,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#6BCAD3",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },


      "&::-webkit-scrollbar-thumb:focus": {
        background: "#6BCAD3",
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: "#6BCAD3",
      },

    },
    customScrollbarEvents: {
      height: 962,
      overflowY: 'scroll' as const,
      overflowX: 'hidden' as const,
      scrollbarColor: "#D6D6D6",
      "&::-webkit-scrollbar-thumb:active": {
        background: "#7A4BD3",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#7A4BD3",
        borderRadius: 6,
        minWidth: 14,
        minHeight: 24
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "#7A4BD3",
      },
      "&::-webkit-scrollbar": {
        width: 4,
        height: 6,
        backgroundColor: "#D6D6D6",
        padding: '30px',
      },

      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D6D6D6",
      },


    },
    categoryCheckbox: {
      padding: '0px',
      paddingRight: '10px',
    },
    categoryFormGroup: {
      marginTop: '15px'
    },
    categoryFormLabel: {
      margin: '0px',
      marginTop: '5px',
      alignItems: 'flex-start',
      '& span:nth-child(2)': {
        fontSize: '14px',
        fontWeight: 400,
        color: '#2D2E2F',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    subCategoryHeading: {
      marginTop: "25px",
      marginBottom: '10px',
      fontSize: "14px",
      fontWeight: 500,
      lineHeight:"20px",
      fontFamily:'Poppins',
      color: "#2D2E2F"
    },
    categoryBtn: {
      padding: '0px',
      margin: '0px',
      justifyContent: 'flex-start',
      marginTop: '10px',
      display: 'flex',
      '& span p': {
        fontSize: '14px',
        fontWeight: 700,
        color: '#027A97',
        textTransform: 'initial',
      },
    },
    cards: {
      backgroundColor: "#fff",
      padding: '25px',
      boxShadow: "none",
      "@media((min-width: 992px) and (max-width: 1024px))": {
        padding: '14px'
      },
      height: 300
    },
    cardHeader: {
      display: 'flex',
      "@media(max-width:600px)": {
        flexDirection: 'column',
      },
    },
    cardContentEnd: {
      width: "100%",
      height: 175,
      "@media((min-width: 992px) and (max-width: 1024px))": {
        padding: '14px'
      },
      "@media(max-width: 767px)": {
        padding: '14px'
      },
    },
    cardContentBox: {
      "@media(max-width: 767px)": {
        alignItems: 'self-start !important',
        flexDirection: 'column'
      },
    },
    imgContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
    },
    restroImg: {
      width: '201px',
      '& img': {
        width: '201px',
        height: '201px',
        padding: '6px',
        border: '1px solid',
        borderRadius: '100%'
      }
    },
    cardImg: {
      '& img': {
        width: '135px',
        height: '135px',
        padding: '6px',
        border: '1px solid',
        borderRadius: '100%',
        marginRight: 15,
        marginTop: 10,
        objectFit: "contain",
        "@media((min-width: 992px) and (max-width: 1024px))": {
          width: '98px',
          height: '98px'
        },
      }
    },
    cardHeading: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '20px',
      fontWeight: 500,
      marginBotton: 5
    },
    cardSubHeadingIcon: {
      fontSize: '17px',
      marginRight: '10px',
    },
    cardSubHeading: {
      fontSize: '14px',
      fontWeight: 400,
      color: "#000",
      marginBottom: '10px',
      fontFamily: 'Poppins, sans-serif',
    },
    cardDescriptionContainer: {
      display: 'flex',
      alignItems: "center",
      marginBottom: '15px'
    },
    cardDescriptionHead: {
      fontSize: '14px',
      fontWeight: 500,
      color: "#A7A7A7",
      marginRight: '15px',
      fontFamily: 'Poppins, sans-serif',
      textWrap: "noWrap"
    },
    cardDesIcons: {
      fontSize: '17px',
      marginRight: '10px',
    },
    cardBtnContainer: {
      padding: '0px'
    },
    cardBtnStyle: {
      padding: '1px 20px',
      boxShadow: 'none',
      '& span:nth-child(1)': {
        fontSize: '14px',
        fontWeight: '400',
        textTransform: 'initial',
        fontFamily: 'Poppins, sans-serif',
      }
    },
    footerTitle: {
      fontSize: '14px',
      fontWeight: 500,
      color: "#000",
      marginTop: '5px',
      marginBottom: '10px',
      fontFamily: 'Poppins, sans-serif',
    },
    footerDescription: {
      fontSize: '12px',
      fontWeight: 400,
      color: "#000",
      fontFamily: 'Poppins, sans-serif',
      marginTop: '10px',
      overflowWrap: "anywhere"
    },
    listBtn: {
      padding: '0px',
      margin: '0px',
      justifyContent: 'flex-start',
      marginTop: '20px',
      '& span p': {
        fontSize: '14px',
        fontWeight: 400,
        color: '#21A6DF',
        textTransform: 'initial',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    backdrop: {
      zIndex: 1200,
      color: '#fff',
    },
    paginationColorAll: {
      "& ul": {
        "& li button": {
          color: "#FFBB13"
        }
      }

    },
    paginationColorSoftware: {
      "& ul": {
        "& li button": {
          color: "#F62B8D"
        }
      }

    },
    paginationColorServices: {
      "& ul": {
        "& li button": {
          color: "#6BCAD3"
        }
      }

    },
    paginationColorEvents: {
      "& ul": {
        "& li button": {
          color: "#7A4BD3"
        }
      }

    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    cardTitleElipis: {
      whiteSpace: "nowrap",
      textOverflow: " ellipsis",
      maxWidth: "230px"
    },
    mt10: {
      marginTop: 10
    },
    mt40: {
      marginTop: 40
    },
    heading14: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: "14px",
      fontWeight: 500
    },
    heading600: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: "14px",
      fontWeight: 600
    },
    expandMore: {
      position: "relative",
      top: "8px",
      left: "-42px",
      fontSize: '22px',
      color: '#a3a3a3',
    },
    countryPopover: {
      top: 300,
      left: 300,
      width: 300,
      height: 200
    },
    searchResultWrapper: {

      width: "650px",
      height: "545px",
      backgroundColor: "white",
      position: "absolute" as "absolute",
      top: "64px",
      left: "-258px",
      borderRadius: "10px",
      zIndex: 1,
      padding: "40px",
      overflowY: "scroll"
    },

    searchResultRow: {
      display: "flex",
      padding: "12px 0px",
      cursor: "pointer"
    },
    searchResultImgWrapper: {
      marginRight: "18px",
    },
    searchResultTextBox: {
      display: "flex",
      alignItems: "center"
    },
    searchResultFirstText: {
      color: '#000',
      fontFamily: "Poppins",
      fontSize: "20px",
      lineHeight: "14px",
      marginRight: "18px",
      fontWeight: 400,
      display: "inline"
    },
    searchResultLastText: {
      color: '#C1B5B5',
      fontFamily: "Poppins",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    advancedSearchIcon: {
      height: "30px",
      width: "30px"
    },
    categoriesTitle: {
      color: " #9429C5",
      fontFamily: "Poppins",
      fontSize: "18px",
      lineHeight: "14px",
      fontWeight: 500,
      paddingBottom: "28px",
      textWrap: "nowrap"
    },
    titleWrapper: {
      display: "flex",
      alignItems: "baseline"
    }


  });




export { Catalogue };

export default withStyles(cataloguePageStyle)(Catalogue);
// Customizable Area End
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";

export const options: string[] = ["Accounting", "Accounting 2", "Accounting 3"];
import { getStorageData } from "../../../framework/src/Utilities";

export interface SupplierDistributionData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    progress_percentage: number,
                    is_it_distributor: boolean,
                    distributors: {
                        data: []
                    }
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    handleDataSaved:()=>void;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    openSaveModal:boolean;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    selectedOption: string;
    chipData: string[];
    isDistributor: boolean;
    distributorListData: any[];
    selectedDistributorList: any[];
    selectedDistributorListValue: any[];
    updatedSuccessDialog: boolean;
    isFeedback: boolean,
    feedbackEmail: string,
    feedbackMessage: string,
    forbrandToggle: boolean,
    forinitialRenderData: any,
    isValidEmail: { valid: boolean, message: string },
    isLoading: boolean,
    progressPercentage: number

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SupplierDistributorsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDistributionId: string = "";
    formSubmitDistributionid: string = "";
    getCustomFormDetailDistributionid: string = "";
    feedbackSupDistributorId: string = "";
    emailReg: RegExp;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            selectedOption: 'yes',
            chipData: ["Distributor 1", "Distributor 2", "Distributor 3", "Distributor 4"],
            isDistributor: false,
            distributorListData: [],
            selectedDistributorList: [],
            selectedDistributorListValue: [],
            updatedSuccessDialog: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",

            forbrandToggle: false,
            forinitialRenderData: [],
            isValidEmail: { valid: false, message: "" },
            isLoading: false,
            progressPercentage: 0

            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestDistributionId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responsedistribution = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestDistributionId === this.formSubmitDistributionid) {
                this.setState({ updatedSuccessDialog: true })
            }

            if (apiRequestDistributionId === this.getDistributionId) {
                const newdata = responsedistribution.yes.concat(responsedistribution.No)
                if (newdata) {
                    let updateddata = newdata.map((item: any) => {
                        return {
                            item: item.id, name: item.name, is_it_distributor: item.is_it_distributor
                        }
                    })
                    this.setState({ isLoading: false, distributorListData: updateddata })
                    this.handleOptionFilterLogic(this.state.selectedOption)
                }
            }

            if (apiRequestDistributionId === this.getCustomFormDetailDistributionid) {
                this.handleSupplierDataResponse(responsedistribution)
            }

            if (apiRequestDistributionId === this.feedbackSupDistributorId) {
                this.setState({ isFeedback: false })
            }
        }

        // Customizable Area End
    }

    // Web Events


    handleSubmitDistribution = async () => {
        this.props.handleDataSaved();
        const categoryID = await getStorageData("category_id");
        let formdata = new FormData();

        const header = {
            token: localStorage.getItem("loginToken"),
        };
        const is_it_distribution_boolean = this.state.selectedOption === "yes" ? "true" : "false";
        formdata.append("is_it_distributor", is_it_distribution_boolean);

        if(this.state.selectedDistributorListValue.length){
            this.state.selectedDistributorListValue.forEach((item) => {
                formdata.append("distributor_ids[]", item.item);
            })
        }else{
            formdata.append("distributor_ids[]", "");

        }
        

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.formSubmitDistributionid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCustomForm}/${categoryID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    handleSubmitFeedbackDistributor = async (event: any) => {

        let formdata = new FormData();

        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailDistributor(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })

        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);


        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackSupDistributorId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBrandListDistribution = async () => {
        this.setState({ isLoading: true })
        const categoryid = await getStorageData("category_id");

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDistributionId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryid}/distributor_list`

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getFormDetailDistribution = async () => {
        const categoryid = await getStorageData("category_id");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomFormDetailDistributionid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getBrandListDistribution()
        this.getFormDetailDistribution()
    }

  
    handleDistributorList = () => {
        const fromapiresponseID = Array.isArray(this.state.forinitialRenderData)
              ? this.state.forinitialRenderData.map((item: any) => +item.id)
              : [];
      
          if (fromapiresponseID.length > 0) {
              const filteredData = Array.isArray(this.state.selectedDistributorList)
                  ? this.state.selectedDistributorList.filter(item => fromapiresponseID.includes(item.item))
                  : [];
      
              this.setState({selectedDistributorListValue: filteredData});
          }
      };
      

    handleRadioChange = (value: string) => {
        this.setState({
            selectedOption: value,
        });
        this.handleOptionFilterLogic(value)

    };

    handleOptionFilterLogic = (value: string) => {
        let updatedData;
      if (value === "yes") {
            updatedData = this.state.distributorListData
                .filter((item: any) => item.hasOwnProperty('is_it_distributor') && item.is_it_distributor === true) // Ensure only branded items are included
                .reduce((uniqueItems: any[], item: any) => {
                   if (!uniqueItems.some(existingItem => existingItem.item === item.item)) {
                        uniqueItems.push(item);
                    }
                    return uniqueItems;
                }, []);
        } else {
            updatedData = this.state.distributorListData
                .filter((item: any) => !item.hasOwnProperty('is_it_distributor') || item.is_it_distributor === false) // Filter for non-branded or undefined
                .reduce((uniqueItems: any[], item: any) => {
                   if (!uniqueItems.some(existingItem => existingItem.item === item.item)) {
                        uniqueItems.push(item);
                    }
                    return uniqueItems;
                }, []);
        }
    
       this.setState({ selectedDistributorList: updatedData });
    }
   

    onAutoCompleteChange = (event: any, newValue: any,) => {
        this.setState({ selectedDistributorListValue: newValue })
        this.props.handleDataChanged();
    }

    handleCloseSuccessDialog = () => {
        this.setState({ updatedSuccessDialog: false });
    }

    handleOpenFeedbackModal = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModal = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleFeedbackEmailChangeDistrbutor = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChangeDistributor = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailDistributor = (email: string) => {
        const emailRegex = this.emailReg;
        return emailRegex.test(email);
    }

    handleSupplierDataResponse = (responseJson: SupplierDistributionData) => {
        const isitDistributor = responseJson.data.catalogue.data.attributes.is_it_distributor;

        const is_it_distributor_boolean = isitDistributor === true ? "yes" : "no";
        this.handleRadioChange(is_it_distributor_boolean);

        let branddata = responseJson.data.catalogue.data.attributes.distributors
        this.setState({ forbrandToggle: true, forinitialRenderData: branddata, isLoading: false }, () => this.handleDistributorList())
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
    }
    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
                this.props.handleBack();
        }
    }
    // Customizable Area End
}
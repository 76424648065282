import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  handleClose: any;
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  openRegister: boolean;
  openLogin: boolean;
  openForgetPassword: boolean;
  openResetPassword: boolean;
  openTermsCondition: boolean;
  buyerPageData: any;
  vendorPageData: any;
  searchResults: any;
  searchResultsVisible: boolean;
  searchTextValue: string;
  isSignout: boolean;
  popoverAnchorEl: HTMLElement | null;
  Username: any;
  supplierCount:number,
  eventCount:number,
  serviceCount:number,
  softwareCount:number,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      open: false,
      openRegister: false,
      openLogin: false,
      openForgetPassword: false,
      openResetPassword: false,
      openTermsCondition: false,
      isSignout: false,
      popoverAnchorEl: null,
      Username: null,
      buyerPageData: {
        heading: " ",
        about_us: { title: " ", subtitle: " " },
        restaurent_supplier: { title: " ", subtitle: " ", main_text: " " },
        weekly_chit: { title: " ", subtitle: " ", main_text: " " },
        in_good_company: { title: " ", subtitle: " ", main_text: " " },
        selling_res_software: { title: " ", subtitle: " ", main_text: " " },
      },
      vendorPageData: {
        heading:{},
        about_us: { title: " ", subtitle: " " },
        too_many_marketting_option: {
          title: " ",
          subtitle: " ",
          main_text: " ",

        },
        the_one_stop_marketing: {
          title: " ",
          subtitle: " ",
          main_text: " ",
        },
        marketing_journey: {
          title: " ",
          subtitle: " ",
          main_text: " ",
          circle_one: {
            main_topic: " ",
            main_text: " ",
            description: " "
          },
          circle_two: {
            main_topic: " ",
            main_text: " ",
            description: " "
          },
          circle_three: {
            main_topic: " ",
            main_text: " ",
            description: " "
          },
          circle_four: {
            main_topic: " ",
            main_text: " ",
            description: " "
          }

        },
        celebrating_dieference_makers: {
          title: " ",
          subtitle: " ",
          main_text: " ",
          sub_section_one: {
            title: " ",
            text: " "
          },
          sub_section_two: {
            title: " ",
            text: " "
          },
          sub_section_three: {
            title: " ",
            text: " "
          },
          sub_section_four: {
            title: " ",
            text: " "
          },
          sub_section_five: {
            title: " ",
            text: " "
          }
        },
        listing_subscription_pricing: { title: " ", subtitle: " ", main_text: " " },

      },
      searchResults: {
        suppliers: 14,
        events: 2,
        services: 0,
        softwares: 0
      },
      searchResultsVisible: false,
      searchTextValue: "",
      supplierCount:0,
      eventCount:0,
      serviceCount:0,
      softwareCount:0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleApiSuccessResponse(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.handleApiFailureResponse(errorResponse)
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  getBuyerPageDataReqId: string = "";
  getVendorPageDataReqId: string = "";
  getSearchResultDataReqId: string = "";

  async componentDidMount(): Promise<void> {
    this.getBuyerPageContent();
    this.getVendorPageContent();

    const storedData = await getStorageData("userdetails");
    const accountDetails = JSON.parse(storedData);
    this.setState({ Username: accountDetails });
  }

  handleApiSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getBuyerPageDataReqId) {

      this.setState({
        buyerPageData: {
          heading: responseJson.heading[0],
          about_us: { title: responseJson.about_us[0].title, subtitle: responseJson.about_us[0].subtitle },
          restaurent_supplier: { title: responseJson.restaurant_supplier[0].title, subtitle: responseJson.restaurant_supplier[0].subtitle, main_text: responseJson.restaurant_supplier[0].main_text },
          weekly_chit: { title: responseJson.weekly_chit[0].title, subtitle: responseJson.weekly_chit[0].subtitle, main_text: responseJson.weekly_chit[0].main_text },
          in_good_company: { title: responseJson.in_good_company[0].title, subtitle: responseJson.in_good_company[0].subtitle, main_text: responseJson.in_good_company[0].main_text },
          selling_res_software: { title: responseJson.selling_res_software[0].title, subtitle: responseJson.selling_res_software[0].subtitle, main_text: responseJson.selling_res_software[0].main_text },
        }
      })

    }
   else  if (apiRequestCallId === this.getSearchResultDataReqId) {
      let data=responseJson.response
      data && data.forEach((item:any)=>{
            switch(item.directory_name){
              case 'Services':
                this.setState({serviceCount:item.count});
                break;
              case 'Events':
                  this.setState({eventCount:item.count});
                  break;  
              case 'Software':
                    this.setState({softwareCount:item.count});
                    break;
              case 'Suppliers':
                      this.setState({supplierCount:item.count});
                      break;     
              default:
                break;
            }
      })
   }
     
    else if (apiRequestCallId === this.getVendorPageDataReqId) {
      console.log(responseJson, "userdata")

      this.setState({
        vendorPageData: {
          heading: responseJson.heading[0],
          about_us: { title: responseJson.about_us[0]?.title, subtitle: responseJson.about_us[0]?.subtitle },
          too_many_marketting_option: {
            title: responseJson.too_many_marketting_option[0].title,
            subtitle: responseJson.too_many_marketting_option[0].subtitle,
            main_text: responseJson.too_many_marketting_option[0].main_text,

          },
          the_one_stop_marketing: {
            title: responseJson.the_one_stop_marketing[0].title,
            subtitle: responseJson.the_one_stop_marketing[0].subtitle,
            main_text: responseJson.the_one_stop_marketing[0].main_text,
          },
          marketing_journey: {
            title: responseJson.marketing_journey[0].title,
            subtitle: responseJson.marketing_journey[0].subtitle,
            circle_one: {
              main_topic: responseJson.marketing_journey[0].main_topic_circle1,
              main_text: responseJson.marketing_journey[0].main_text1,
              description: responseJson.marketing_journey[0].description1
            },
            circle_two: {
              main_topic: responseJson.marketing_journey[0].main_topic_circle2,
              main_text: responseJson.marketing_journey[0].main_text2,
              description: responseJson.marketing_journey[0].description2
            },
            circle_three: {
              main_topic: responseJson.marketing_journey[0].main_topic_circle3,
              main_text: responseJson.marketing_journey[0].main_text3,
              description: responseJson.marketing_journey[0].description3
            },
            circle_four: {
              main_topic: responseJson.marketing_journey[0].main_topic_circle4,
              main_text: responseJson.marketing_journey[0].main_text4,
              description: responseJson.marketing_journey[0].description4
            }

          },
          celebrating_dieference_makers: {
            title: responseJson.celebrating_dieference_makers[0].title,
            subtitle: responseJson.celebrating_dieference_makers[0].subtitle,
            main_text: responseJson.celebrating_dieference_makers[0].main_text,
            sub_section_one: {
              title: responseJson.celebrating_dieference_makers[0].subsection_title1,
              text: responseJson.celebrating_dieference_makers[0].subsection_text1,
            },
            sub_section_two: {
              title: responseJson.celebrating_dieference_makers[0].subsection_title2,
              text: responseJson.celebrating_dieference_makers[0].subsection_text2,
            },
            sub_section_three: {
              title: responseJson.celebrating_dieference_makers[0].subsection_title3,
              text: responseJson.celebrating_dieference_makers[0].subsection_text3,
            },
            sub_section_four: {
              title: responseJson.celebrating_dieference_makers[0].subsection_title4,
              text: responseJson.celebrating_dieference_makers[0].subsection_text4,
            },
            sub_section_five: {
              title: responseJson.celebrating_dieference_makers[0].subsection_title5,
              text: responseJson.celebrating_dieference_makers[0].subsection_text5,
            }
          },
          listing_subscription_pricing: {
            title: responseJson.listing_subscription_pricing[0].title,
            subtitle: responseJson.listing_subscription_pricing[0].subtitle,
            main_text: responseJson.listing_subscription_pricing[0].main_text
          },

        },
      })

    }


  }
  handleApiFailureResponse = (errorResponse: any) => {
    console.log(errorResponse);
  }
  


  getBuyerPageContent = () => {

    const dataRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBuyerPageDataReqId = dataRequestMessage.messageId;
    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBuyerDataEndPoint
    );

    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(dataRequestMessage.id, dataRequestMessage);

    return true;
  }

  getVendorPageContent = () => {

    const dataRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorPageDataReqId = dataRequestMessage.messageId;
    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorDataEndPoint
    );

    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(dataRequestMessage.id, dataRequestMessage);

    return true;
  }


  goToLogin = () => {
    this.props.navigation.navigate("Home2", { type: "EmailAccountLoginBlock" })
  }

  goToVenderaccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToBuyeraccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerAccountDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  goToHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ popoverAnchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchorEl: null });
  };

  handleHeaderNavigation = async () => {
    const usertype = await getStorageData("user_type")
    if (usertype == "Buyer") {
      this.goToBuyeraccount()
    } else if (usertype == "vendor" || "Vendor") {
      this.goToVenderaccount()
    }
  }

  handleUserSignout = async () => {
    await removeStorageData("loginToken")
  }

  handleSearchInput = (e: any) => {
    this.setState({ searchTextValue: e.target.value },()=>this.getSearchResult());
    if (e.target.value.trim().length > 0) {
      this.setState({ searchResultsVisible: true })
    } else {
      this.setState({ searchResultsVisible: false })
    }
  }

  handleInnerClick = (e: any) => {
    e.stopPropagation();
  }

  handleOuterClick = () => {
    this.setState({ searchResultsVisible: false })
  }

  navigateToJoinUs = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToJoinUs)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleAdvancedSearch = (directory:string) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToAdvancedSearch)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTextValue})
    this.send(msg);

  }

  getSearchResult = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const searchdataRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchResultDataReqId = searchdataRequestMessage.messageId;
    searchdataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    searchdataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.getSearchResultEndPoint}?query=${this.state.searchTextValue}`
    );

    searchdataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(searchdataRequestMessage.id, searchdataRequestMessage);

    return true;
  }
  // Customizable Area End
}

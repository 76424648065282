import React from "react";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails, userStyles } from "./PremiumListing.web";
// Customizable Area End

// Customizable Area Start
import PremiumListingEventsController, {
    Props,
} from "./PremiumListingEventsController";
import { Backdrop, Card, CardContent, Button, CardMedia, styled, CircularProgress, Grid, Box, FormControl, MenuItem, Link, Select, InputAdornment, Popover, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import AppBarPage from "../../../components/src/AppBar.web";
import { logoImage, searchIcon, downArrow } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Contacts from "../../../components/src/Contacts.web";
import Reviews from "../../../components/src/Reviews.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import CustomErrorToast from "../../../components/src/CustomErrorToast.web";
const CustomSelecttwo = styled(Select)({
    backgroundColor: '#FAF5FF',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height: "50px",

    '& .MuiSelect-outlined': {
        borderRadius: '8px',
        background: "none"

    },
});

// Customizable Area End

// Customizable Area Start
export class PremiumListingEvents extends PremiumListingEventsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderSearchBar = (classes: any) => {
        return <TextField
            variant="outlined"
            placeholder="Search"
            fullWidth
            data-testid="searchTextField"
            name="search"
            value={this.state.searchText}
            onChange={this.handleSearchTextevent}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon style={{ color: "#94A3B8" }} />
                    </InputAdornment>
                ),
            }}
            className={classes.textFieldRoot}
        />
    }
    renderExhibitorCardsdata = (classes: any, exhibitorsList: any) => {
        return (
            <Box style={{ width: "100%" }} className={classes.integrationContainer}>
                <Grid container spacing={2} md={12} style={{ marginTop: '10px' }}>
                    <Grid item md={5} sm={12}>
                        <Box style={{ width: "100%" }}>
                            <Box>
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%", background: "#FAF5FF", height: "50px" }}>
                                    <CustomSelecttwo
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        fullWidth
                                        data-testid={"select"}
                                        placeholder={"Select"}
                                        value={this.state.categoryId}
                                        onChange={this.handleExhibitorCategoryFilter}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "200px",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={"defaultValue"} value={-1}>Select</MenuItem>
                                        {this.state.categoryListData?.map((category: any) =>
                                            <MenuItem key={category.category_id} value={category.category_id}>
                                                {category.category_name}
                                            </MenuItem>
                                        )

                                        }
                                    </CustomSelecttwo>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={5} sm={12}>
                        <Box>
                            {this.renderSearchBar(classes)}
                        </Box>
                    </Grid>
                    <Grid md={2} sm={12} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}>
                        <Box className={`${classes.marginRight35}`}>
                            <Box>
                                <Button data-testid="recentFilter" onClick={this.handleExhibitorClick}>
                                    <Typography className={`${classes.recentText} ${classes.mr20} ${classes.reviewText3}`} style={{ marginLeft: "18px", fontSize: "14px", fontWeight: 700, lineHeight: "22px" }}>{this.state.exhibitorFilterValue=='featured'?"Featured First":this.state.exhibitorFilterValue}</Typography>
                                    <img height={7} src={downArrow} alt="downArrow" />
                                </Button>
                                <Popover
                                    id={"test"}
                                    data-testid="Featured"
                                    open={this.state.exhibitors_anchorEl}
                                    anchorEl={this.state.exhibitors_anchorEl}
                                    onClose={this.handleClose}
                          
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box style={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }} >
                                                 <Button style={{
                                                    
                                                    textTransform: "none"
                                                }} onClick={(e) =>this.handleExhibitorFilterClick("featured")}   >Featured First </Button>
                                                <Button style={{
                                                   
                                                    textTransform: "none"
                                                }} onClick={(e) =>this.handleExhibitorFilterClick("recent")}   >Recent </Button>
                                                <Button style={{
                                                   
                                                    textTransform: "none"
                                                }} onClick={(e) =>this.handleExhibitorFilterClick("ascending")} >Ascending </Button>
                                                <Button style={{
                                                    
                                                    textTransform: "none"
                                                }} onClick={(e) =>this.handleExhibitorFilterClick("descending")} >Descending </Button>
                                            </Box>
                                </Popover>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className={`${classes.marginTop30} ${classes.cardsContainer}`}>
                    {this.handlefilteredData() && this.handlefilteredData().map((item: any) => (
                        <>
                            <Link color="inherit" href={this.getListingUrl2(item.id, item.payment_status)} underline="none">
                                <Card key={item.id} className={classes.cardItem}>
                                    <CardMedia
                                        component="img"
                                        alt="Contemplative Reptile"
                                        height="180"
                                        width="1"
                                        image={item.logo}
                                        title="Restaurant"
                                        style={{ borderColor: "#D9D9D9" }}
                                    />
                                    <CardContent style={{ paddingLeft: 45, paddingRight: 45 }}>
                                        <Typography className={`${classes.cardTitle} ${classes.eventElipsis}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </>
                    ))}

                </Box>

            </Box>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { isLoading,
            logo,
            name,
            contactData,

        }: any = this.state
        const tabsArray = ["About", "Exhibitors", "Reviews", "Contact"]

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                    logoImg={logoImage}
                    handleClick={this.goToLoginPage}
                    handleUserSignout={this.handleUserSignout}
                    serachIcon={searchIcon}
                    downarrow={downArrow}
                    data-testId={"signInBtn"}
                    goToHomePage={() => this.handleNavigation("Home")}
                    username={this.state.userdetails}
                    dummyicon={""}
                    handleHeaderNavigation={() => this.navigateToAccount(this.state.userdetails?.user_type)}
                    searchText={this.state.searchTermProp}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                <Box className={classes.root}>
                    <Box className={`${classes.container} ${classes.customScrollbar}`}>
                        <Box className={classes.headerContainer}>
                            <ListingHeader userdetails={this.state.userdetails} handleBookmarkDelete={this.handleBookmarkDelete} domain_url={this.state.domain_url} bookmarked={this.state.bookmarked} data-testid="bookmark" handleBookmarkCall={this.handleBookmarkEvent} name={name} logo={logo} classes={classes} showContact={false} showComapnyRating={false} showFeatured={false} showClaimed={false} />
                            <Box className={classes.navBar}>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    centered
                                    data-testid="tabs"
                                    classes={{ centered: classes.supplierTabContainer }}
                                    TabIndicatorProps={{ style: { height: '3px', backgroundColor: "#B300ED" } }}
                                >
                                    {tabsArray && tabsArray.map((item: any, index: any) => (
                                        <Tab key={index} value={index} data-testid={item} label={item} style={{ color: this.detectColor(index) }} />

                                    ))}

                                </Tabs>
                            </Box>
                        </Box>

                        <Box id="0-tab" className={classes.accordionContainer}>
                            <Accordion data-testid="aboutAccordion" onChange={() => this.handleToggle('aboutAccordion')} expanded={this.state.aboutAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>About</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.paddLeft56}>
                                    <Box style={{ width: "100%" }} className={classes.integrationContainer}>
                                        <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                            <Box className={classes.mr100}>
                                                <Typography className={`${classes.heading14} `}>
                                                    From
                                                </Typography>
                                                <Typography className={`${classes.heading400}`}>
                                                    {this.state.from}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.mr100}>
                                                <Typography className={`${classes.heading14} `}>
                                                    To
                                                </Typography>
                                                <Typography className={`${classes.heading400}`}>
                                                    {this.state.to}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.mr100}>
                                                <Typography className={`${classes.heading14} `}>
                                                    Event Type
                                                </Typography>
                                                <Typography className={`${classes.heading400}`}>
                                                    {this.state.event_type}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography className={`${classes.heading14} `}>
                                                    Location
                                                </Typography>
                                                <Typography className={`${classes.heading400}`}>
                                                    {this.state.location_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.marginTop10}>
                                            <Typography className={`${classes.heading14} `}>
                                                Categories
                                            </Typography>
                                            <Box style={{ marginLeft: "13px" }}>
                                                <ul className={classes.marketUl}>
                                                    {this.state.categories && this.state.categories.map((category: any) => (
                                                        <li key={category.category_id}>
                                                            <Typography className={`${classes.heading400}`}>
                                                                {category.category_name}
                                                            </Typography>
                                                        </li>
                                                    ))}

                                                </ul>
                                            </Box>
                                        </Box>

                                        <Box className={classes.marginTop10}>
                                            <Typography className={`${classes.heading14} `}>
                                                Description
                                            </Typography>
                                            <Typography className={`${classes.heading400}`}>
                                                {this.state.description}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box id="1-tab" className={classes.accordionContainer}>
                            <Accordion data-testid="exhibitorAccordion" onChange={() => this.handleToggle('exhibitorsOpen')} expanded={this.state.exhibitorsOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Exhibitors</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.paddLeft56}>
                                    {this.renderExhibitorCardsdata(classes, this.state.exhibitors)}
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Reviews id="2-tab" hideRatingForm={this.state.hideRatingForm}

                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadMore}
                            accordionState="reviewAccordion" anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            handleAccordionToggle={this.handleToggle}
                            loadmore={this.state.loadmore}
                            handleRating={this.handleRating}
                            handleReviewComment={this.handleReviewComment}
                            data-testid="reviewAccordion" classes={classes}
                            reviewData={this.state.reviewData}
                            handleClose={this.handleClose}
                            ratingVal={this.state.ratingVal}
                            handleEditBtn={this.handleEditBtn}
                            isEdit={this.state.editReview}
                            reviewComment={this.state.reviewComment}
                            handleReviewSubmit={this.handleReviewSubmit}
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            filterValue={this.state.filterValue}
                            expanded={this.state.reviewAccordion}


                            handleReviewUpdate={this.handleReviewUpdate} />

                        <Contacts id="3-tab" accordionState="contactAccordion" expanded={this.state.contactAccordion} data-testid="contactAccordion" classes={classes} contactData={contactData} handleAccordionToggle={this.handleToggle} />


                    </Box>

                </Box>
                <CustomErrorToast data-testid="errorToast" open={this.state.errorToast} onClose={this.handleErrorAlert} />


            </div>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
export default withStyles(userStyles)(PremiumListingEvents)
// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
interface DirectoryMenuList {
    Services: Category[];
    Suppliers: Category[];
    Softwares: Category[];
    Events: Category[];
}

export interface DirectoryAPIResponse {
    directories: Array<{
      directory_id: number;
      directory_name: string;
      categories: Array<{
        id: number;
        name: string;
      }>;
    }>;
  }
  
  interface Category {
    id: number;
    name: string;
  }
  
  

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    directoryMenuList: DirectoryMenuList
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TopNavController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDirectoryListAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            directoryMenuList: {
                Services: [],
                Suppliers: [],
                Softwares: [],
                Events: []
              },
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        this.getDirectoryList();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getDirectoryListAPICallId) {
                if (responseJson && !responseJson.errors) {
                  this.handleApiResponse(responseJson);
                }
              }
        }
    }
    getDirectoryList = () => {
        const header = {
          token: localStorage.getItem("loginToken")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getDirectoryListAPICallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCategoriesAPIEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }

    handleApiResponse(responseJson: DirectoryAPIResponse) {
        const suppliersCatalogue = responseJson.directories.filter((element:any) => element.directory_name === "Suppliers")[0]?.categories
        const servicesCatalogue = responseJson.directories.filter((element:any) => element.directory_name === "Services")[0]?.categories;
        const softwaresCatalogue = responseJson.directories.filter((element:any) => element.directory_name === "Software")[0]?.categories;
        const eventsCatalogue = responseJson.directories.filter((element:any) => element.directory_name === "Events")[0]?.categories;
        this.setState({
          directoryMenuList: {
            Services: servicesCatalogue,
            Suppliers: suppliersCatalogue,
            Softwares: softwaresCatalogue,
            Events: eventsCatalogue
          }
        })
    
      }

    


    // Customizable Area End
}
